import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Checkbox } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ReactSearchBox from 'react-search-box';
import DualListBox from 'react-dual-listbox';
export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <Options
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class Options extends Component {
    foodTypes = {
        "1": "Veg",
        "2": "Non-veg",
        "3": "Eggterian",
        "4": "Not Specified"
    }
    state = {
        options: [],
        totaloptions: 0,
        currentPageNum: 1,
        recordsPerPage: 10,
        fetchProgress: true,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        noDataMsg: "No options Yet.",
        selectedOptionId: null,
        foodTypes: this.props.CONSTANTS.foodTypes || [],
        optionGroups: [],
        allOptions: [],
        selectedOptGroupIds: [],
        displayOGTitleString: "",
        ogIds: [],
        filterOnline: '',
        filterPos: '',
        searchOptTxt: ''
    };

    componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        this._loadOptions();

    }

    componentDidMount() {
        $(this.modalUI.current).on("shown.bs.modal", () => {
            // if (this.modalRef.current.state.categories.length) return;
            // this._loadCategories();
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedOptionId: null
            });
            this.modalRef.current.formApi.reset();
        });

        this._getOptionGroups();
    }

    componentWillUnmount() {
        toast.dismiss();
    }
    filterOptions() {
        let pos = this.state.filterPos== "Active"?true:false;
        let online = this.state.filterOnline== "Active"?true:false;
        let filterOptions = this.state.searchOptTxt ? this.state.allOptions.filter(opt => { return opt.optionTitle.toUpperCase().indexOf(this.state.searchOptTxt.toUpperCase()) > -1 }) : this.state.allOptions;
        let filterPos = this.state.filterPos ? filterOptions.filter(itm => { return itm.optionPosActive == pos }) : filterOptions;
        let filterOnline = this.state.filterOnline ? filterPos.filter(itm => { return itm.optionOnlineActive == online }) : filterPos;
        this.setState({
            options: filterOnline
        })

    }
    async filterByOptions(event) {
        await this.setState({ searchOptTxt: event.target.value });
        this.filterOptions();
    }
    async filterPosStatus(event) {
        await this.setState({ filterPos: event.target.value });
        this.filterOptions();
    }
    async filterOnlineStatus(event) {
        await this.setState({ filterOnline: event.target.value });
        this.filterOptions();
    }
    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                        <>
                            <div className="row mx-0 justify-content-between align-items-center">
                                <div className="">
                                    <span className="categorycol">Options</span>
                                </div>
                                {/* <div>
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => { this._onSyncOptions() }}>
                                            Sync Options
                                      </button>
                                    </div> */}
                                <div className="">
                                    <button
                                        type="button"
                                        className="btnaddcategory float-right"
                                        data-toggle="modal"
                                        data-target="#optionModal"
                                        onClick={() => { this.setState({ selectedOptGroupIds: [] }) }}
                                    >
                                        <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                        &nbsp; Add option
                                    </button>
                                </div>
                            </div>
                                <div className="table-responsive mt-3 tableofauto">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th> <div>Option Name</div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Search Options..."
                                                            aria-label="Search"
                                                            onChange={this.filterByOptions.bind(this)}
                                                            value={this.state.searchItemTxt}
                                                            style={{ fontSize: 10, backgroundColor: "#FFFFFF" }}
                                                        />
                                                    </div></th>
                                                {/* <th>Description</th> */}
                                                {/* <th>Weight</th> */}
                                                <th>Online Status <select  onChange={obj => this.filterOnlineStatus(obj)} style={{width:15,backgroundColor:"#98a4af",border:"none"}}>
                                                    <option value="">All</option>

                                                    <option value="Active">Active</option>
                                                    <option value="InActive">In Active</option>
                                                </select></th>
                                                <th>Pos Status <select onChange={obj => this.filterPosStatus(obj)} style={{width:15,backgroundColor:"#98a4af",border:"none"}}>
                                                    <option value="">All</option>
                                                    <option value="Active">Active</option>
                                                    <option value="InActive">In Active</option>
                                                </select></th>
                                                <th>Sold At Store</th>
                                                <th>Food Type</th>
                                                <th className="actionWidth">Action</th>
                                            </tr>
                                        </thead>
                                        {this.state.options.length ? (
                                        <tbody>
                                            {this.state.options.map((o, idx) => (
                                                <tr key={String(idx)}>
                                                    <td>
                                                        {(this.state.currentPageNum - 1) *
                                                            this.state.recordsPerPage +
                                                            idx +
                                                            1}
                                                    </td>
                                                    <td>{o.optionTitle}</td>
                                                    {/* <td>
                                                            {o.optionDescription
                                                                ? o.optionDescription.length <= 50
                                                                    ? o.optionDescription
                                                                    : o.optionDescription.substr(0, 50) +
                                                                    "..."
                                                                : ""}
                                                        </td> */}
                                                    {/* <td>{o.optionWeight}</td> */}
                                                    <td>{o.optionOnlineActive ? "Active" : "InActive"}</td>
                                                    <td>{o.optionPosActive ? "Active" : "InActive"}</td>
                                                    <td>{o.optionSoldAtStore ? "Active" : "InActive"}</td>
                                                    <td>{this.foodTypes[o.optionFoodType]}</td>
                                                    <td>
                                                        <img
                                                            alt=""
                                                            src={EDIT_IMG}
                                                            data-toggle="modal"
                                                            data-target="#optionModal"
                                                            className="icon"
                                                            onClick={() => {
                                                                let ogIdFromDB = o.optionGroupRefIds ? JSON.parse(o.optionGroupRefIds) : []
                                                                /* let str=[]
                                                                for(let i=0;i<ogIdFromDB.length;i++){
                                                                    let ogidPresent= this.state.optionGroups.find((ogid)=>ogid.key==Number(ogIdFromDB[i]))
                                                                    str.push(ogidPresent.value)
                                                                }
                                                                this.setState({
                                                                    displayOGTitleString: str,
                                                                    ogIds : ogIdFromDB
                                                                }) */

                                                                this.setState({
                                                                    selectedOptionId: o.optionId,
                                                                    selectedOptGroupIds: ogIdFromDB
                                                                });
                                                                this.modalRef.current.setState({
                                                                    editMode: true
                                                                });
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__name",
                                                                    o.optionTitle
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__desc",
                                                                    o.optionDescription
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__sortOrder",
                                                                    o.optionSortOrder
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__weight",
                                                                    o.optionWeight
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__soldAtStore",
                                                                    o.optionSoldAtStore
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__refTitle",
                                                                    o.optionRefTitle
                                                                );

                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__onlineActive",
                                                                    o.optionOnlineActive
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__posActive",
                                                                    o.optionPosActive
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__foodType",
                                                                    o.optionFoodType
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__price",
                                                                    o.optionPrice
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__AggPrice",
                                                                    o.optionAggPrice
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "option__recommended",
                                                                    o.optionRecommended
                                                                );
                                                                /*   this.modalRef.current.formApi.setValue(
                                                                      "options__optGrpRefIds",
                                                                       str
                                                                  ); */
                                                            }}
                                                        />
                                                        {this.props.user.restarantMenuDelete == 1 ?
                                                        <img
                                                                alt=""
                                                                src={DELETE_IMG}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() =>
                                                                    this.confirmAndDelete(
                                                                        o.optionId,
                                                                        o.optionTitle
                                                                    )
                                                                }
                                                            />:null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                          ) : (
                                            <div className="noDataMsgContainer">
                                                <h5>No Options</h5>
                                            </div>
                                        )}
                                    </table>
                                </div>
                          
                        </>
                    )}
                </div>
                {/* {this._pagination()} */}
                <OptionModal
                    ref={this.modalRef}
                    submitOptionForm={this._submitOptionForm.bind(this)}
                    modalUiRef={this.modalUI}
                    foodTypes={this.state.foodTypes}
                    optionGroups={this.state.optionGroups}
                    selectedOptionGroupIds={(optGrpidsList) => { this.setState({ selectedOptGroupIds: optGrpidsList }) }}
                    // selectedOptionGroupNames={(optGrpNameList)=>{this.setState({displayOGTitleString: optGrpNameList})}}
                    // displayOGTiTle={this.state.displayOGTitleString}
                    displayOptGrpIds={this.state.selectedOptGroupIds}
                />
            </>
        );
    }

    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totaloptions / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totaloptions / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadOptions();
            }
        );
    }

    async _onSyncOptions() {
        const { upApiBasePath } = this.props.CONSTANTS;
        let opt = this.state.allOptions.map((opt, index) => {
            return {
                ref_id: opt.optionId,
                title: opt.optionTitle,
                ref_title: opt.optionRefTitle,
                description: opt.optionDescription,
                weight: opt.optionWeight,
                available: opt.optionAvailable,
                recommended: opt.optionRecommended,
                price: opt.optionPrice,
                sold_at_store: opt.optionSoldAtStore,
                sort_order: opt.optionSortOrder,
                opt_grp_ref_ids: Number(opt.optionGroupRefIds),
                nested_opt_grps: [],
                food_type: opt.optionFoodType,
                translations: []
            }
        })
        let body = {
            options: opt
        }
        const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/locations/" + this.props.user.restarantId + "/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));

        if (savedResponseResult.status == "error") {
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference)
            setTimeout(() => {
                toast.success(
                    "Successfully synched"
                );
            }, 1500);
            return toast.success(savedResponseResult.message);
        }

    }

    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: restId,
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanCatalogueResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            this.setState({
                saveProgress: false,

            });
            return toast.error("An error occurred while storing the response.")
        }
        if (!savedResponseResult.success) {
            toast.error(savedResponseResult.msg, { autoClose: 8000 });
            return this.setState({
                saveProgress: false
            });
        }
        this.setState({
            saveProgress: false
        });
    }

    async _loadOptions() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const optionResponse = await fetch(
            apiBasePath +
            apiRoutes.getOptions +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!optionResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const optionResponseResult = await optionResponse.json();
        if (!optionResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!optionResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: optionResponseResult.msg
            });
            return toast.error(optionResponseResult.msg);
        }
        throttleTime(
            () => {
                this.setState({
                    options: optionResponseResult.data,
                    totaloptions: optionResponseResult.total,
                    fetchProgress: false,
                    allOptions: optionResponseResult.data
                });
            },
            requestSendTime,
            500
        );

    }



    async _getOptionGroups() {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const optionGroupResponse = await fetch(
            apiBasePath +
            apiRoutes.getOptionGroups +
            `?user_id=${this.props.user.userId}&limit=${0
            }&offset=${0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!optionGroupResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const optionGroupsResponseResult = await optionGroupResponse.json();
        if (!optionGroupsResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        let optionGroupIdTitleList = optionGroupsResponseResult.data.map(o => {
            return {
                value: String(o.optionGroupId),
                label: o.optionGroupTitle
            };
        });

        this.setState({
            optionGroups: optionGroupIdTitleList
        })

    }

    async _submitOptionForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let optionId = this.state.selectedOptionId;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath +
            (optionId ? apiRoutes.editOption : apiRoutes.createOption),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: optionId ? "PUT" : "POST",
                body: JSON.stringify({
                    option_id: optionId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId,
                    option_title: formValues.option__name.trim(),
                    option_description: formValues.option__desc && formValues.option__desc.trim(),
                    option_weight: formValues.option__weight,
                    option_sold_at_store: formValues.option__soldAtStore,
                    option_sort_order: formValues.option__sortOrder,
                    option_online_active: formValues.option__onlineActive,
                    option_pos_active: formValues.option__posActive,
                    option_ref_title: formValues.option__refTitle,
                    option_food_type: formValues.option__foodType,
                    option_price: formValues.option__price,
                    option_agg_price:formValues.option__AggPrice,
                    option_recommended: formValues.option__recommended,
                    option_group_ref_ids: this.state.selectedOptGroupIds
                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${optionId ? "saving the changes" : "adding the option "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${optionId ? "saving the changes" : "adding the option "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1,
                selectedOptGroupIds: []
            },
            err => {
                this._loadOptions();
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${optionId ? "saved the changes" : "added " + formValues.option__name
            }.`,
            { autoClose: 4000 }
        );
    }

    confirmAndDelete(oId, name) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${name || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteOption(oId)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }

    async _deleteOption(oId) {
        if (this.state.deleteProgress) {
            return false;
        }

        this.setState({
            deleteProgress: true
        });
        const option = this.state.options.find(
            i => i.optionId == oId
        );
        const optionName = option.optionTitle;

        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deleteOption,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "DELETE",
                body: JSON.stringify({
                    option_id: oId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the option.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the option.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: "",
            currentPageNum:
                this.state.options.length > 1
                    ? this.state.currentPageNum
                    : this.state.currentPageNum - 1 > 0
                        ? this.state.currentPageNum - 1
                        : 1
        });
        this._loadOptions();
        toast.success(`Deleted ${optionName}`, { autoClose: 5000 });
    }
}

class OptionModal extends Component {
    state = {
        editMode: false,
        saveProgress: false,
    };

    handleOnSelect = selected => {

        this.props.selectedOptionGroupIds(selected)
        // this.props.selectedOptionGroupNames(listNames)
    }


    render() {
        const { modalUiRef, submitOptionForm } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="optionModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit option "
                                    : "Add option "}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitOptionForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {

                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="row mb-sm-3">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group required">
                                                        <label>Title
                                                            <Text
                                                                validate={val => {
                                                                    val = val && val.trim();
                                                                    if (!val) return ` title cannot be empty`;
                                                                    if (!val || val.length < 3 || val.length > 60) {
                                                                        return ` title must contain 3 to 60 Characters.`;
                                                                    }
                                                                }
                                                                }
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"option__name"}
                                                                type="text"
                                                                placeholder="Enter option  name"
                                                                className={
                                                                    formState.errors.option__name
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.option__name && (
                                                                <div className="input_error">
                                                                    {formState.errors.option__name}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> ref title
                                                            <Text
                                                                validate={val => {
                                                                    val = val && val.trim();
                                                                    if (!val) return `Ref title cannot be empty`;
                                                                    if (new RegExp(/^[0-9.]+$/).test(val)) {
                                                                        return `Enter a valid Ref title.`;
                                                                    }
                                                                    if (!val || val.length < 3 || val.length > 60) {
                                                                        return `Ref title must contain 3 to 60 Characters.`;
                                                                    }
                                                                }
                                                                }
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"option__refTitle"}
                                                                type="text"
                                                                placeholder="Enter ref title"
                                                                className={
                                                                    formState.errors.option__refTitle
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.option__refTitle && (
                                                                <div className="input_error">
                                                                    {formState.errors.option__refTitle}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> sort order
                                                            <Text
                                                                validate={val => {
                                                                    if (formState.touched.option__sortOrder && val === '') {
                                                                        return " sort order  cannot be empty";
                                                                    }
                                                                    if (isNaN(val)) {
                                                                        return "Please Enter a sort order.";
                                                                    }
                                                                    if (Number(val) > 1000) {
                                                                        return "sort order cannot be more than 1000.";
                                                                    }
                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"option__sortOrder"}
                                                                type="text"
                                                                placeholder="Enter Sort Order"
                                                                className={
                                                                    formState.errors.option__sortOrder
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.option__sortOrder && (
                                                                <div className="input_error">
                                                                    {formState.errors.option__sortOrder}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> Pos Price
                                                            <Text
                                                                validate={val => {
                                                                    if (!val && !formState.touched.option__price) {
                                                                        return;
                                                                    }
                                                                    if (isNaN(val)) {
                                                                        return "Please Enter a valid pos price.";
                                                                    }
                                                                    if (Number(val) > 10000) {
                                                                        return "pos  Price cannot be more than 10000 and cannot have more than 2 decimal points.";
                                                                    }
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "Pos Price"
                                                                    );
                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"option__price"}
                                                                type="text"
                                                                placeholder="Enter Price"
                                                                className={
                                                                    formState.errors.option__price
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.option__price && (
                                                                <div className="input_error">
                                                                    {formState.errors.option__price}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label>Aggregator Price
                                                            <Text
                                                                validate={val => {
                                                                    if (val && isNaN(val)) {
                                                                        return "Please Enter a valid  Aggregator price.";
                                                                    }
                                                                    if (val && Number(val) > 10000) {
                                                                        return "Aggregator Price cannot be more than 10000 and cannot have more than 2 decimal points.";
                                                                    }
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "Aggregator Price"
                                                                    );
                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"option__AggPrice"}
                                                                type="text"
                                                                placeholder="Enter Aggregator Price"
                                                                className={
                                                                    formState.errors.option__AggPrice
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.option__AggPrice && (
                                                                <div className="input_error">
                                                                    {formState.errors.option__AggPrice}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label>weight(gm)
                                                            <Text
                                                                validate={val => {
                                                                    // if (!val) return "please enter Item Weight"
                                                                    if (val && !new RegExp(/^[0-9.]+$/).test(val)) return "please input a Item Weight";
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "Item Weight"
                                                                    );
                                                                    if (Number(val) > 10000) {
                                                                        return "weight cannot be more than 10000 and cannot have more than 2 decimal points.";
                                                                    }
                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"option__weight"}
                                                                type="text"
                                                                placeholder="Enter Weight"
                                                                className={
                                                                    formState.errors.option__weight
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.option__weight && (
                                                                <div className="input_error">
                                                                    {formState.errors.option__weight}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label>Food Type
                                                            <Select
                                                                field="option__foodType"
                                                                validate={val =>
                                                                    this._validateSelectInput(val, "Food Type")
                                                                }

                                                                validateOnBlur
                                                                validateOnChange
                                                                className={
                                                                    formState.errors.option__foodType
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            >
                                                                <option value="" disabled>
                                                                    - Select Food Type -
                                                                </option>
                                                                {Object.keys(this.props.foodTypes).map((c, idx) => {
                                                                    let t = this.props.foodTypes[c];
                                                                    return (
                                                                        <option
                                                                            key={t.typeId}
                                                                            value={t.typeId}
                                                                        >
                                                                            {t.typeTitle}
                                                                        </option>
                                                                    );
                                                                })
                                                                }
                                                            </Select>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="option__onlineActive"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Online-Active<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="option__onlineActive"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="option__posActive"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Pos-Active<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="option__posActive"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="option__soldAtStore"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Sold At Store<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="option__soldAtStore"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="option__recommended"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Recommended<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="option__recommended"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6"></div>

                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div>
                                                        <label>Select Option Groups </label>
                                                        <DualListBox
                                                            options={this.props.optionGroups}
                                                            selected={this.props.displayOptGrpIds}
                                                            onChange={this.handleOnSelect}

                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group">
                                                <TextArea
                                                    validate={val => {

                                                        if (Number(val) > 500) {
                                                            return "option Description cannot be more than 500 Characters.";
                                                        }
                                                    }
                                                    }
                                                    // validate={val =>
                                                    //     validateText(
                                                    //         val,
                                                    //         10,
                                                    //         formState.touched.option__desc,
                                                    //         "option  Description",
                                                    //         400
                                                    //     )
                                                    // }
                                                    rows={5}
                                                    validateOnBlur
                                                    validateOnChange
                                                    field={"option__desc"}
                                                    className={
                                                        formState.errors.option__desc
                                                            ? "form-control input_error"
                                                            : "form-control"
                                                    }
                                                    placeholder="Enter  description"
                                                />
                                                {formState.errors.option__desc && (
                                                    <div className="input_error">
                                                        {formState.errors.option__desc}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    _setFormApi(formApi) {
        this.formApi = formApi;
    }
    _validateSelectInput(val, name) {
        if (!val) {
            return `Select ${name}.`;
        }
    }


}
