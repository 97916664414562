import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Radio, RadioGroup, Textfield } from "informed";
import { AuthContext, DataContext, DataProvider } from "../contextProvider";
import {
    throttleTime, validateText, validateStrictText, validateWholeNumber,
    enumerateDaysBetweenDates, validateInput
} from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import moment from "moment";
// const moment = require("moment")

export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
                return (
                    <DataContext.Consumer>
                        {({ fetchCoupons }) => {
                            return (
                                <Coupons
                                    user={user}
                                    authToken={authToken}
                                    CONSTANTS={CONSTANTS}
                                    isSuperAdmin={isSuperAdmin}
                                    fetchCoupons={fetchCoupons}
                                />
                            );
                        }}
                    </DataContext.Consumer>
                );
            }}
        </AuthContext.Consumer>
    );
};

class Coupons extends Component {

    state = {
        Coupons: [],
        dtype: "",
        fetchProgress: false,
        noDataMsg: "No Coupons Yet.",
        totalCoupons: 0,
        currentPageNum: 1,
        recordsPerPage: 5,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        selectedCouponId: null,
        coupons: []
    }

    componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        this._loadCoupons();
    }

    componentDidMount() {
        this._loadCoupons();
        $(this.modalUI.current).on("shown.bs.modal", () => {
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedCouponId: null
            });
            this.modalRef.current.formApi.reset();
        });
    }

    componentWillUnmount() {
        toast.dismiss();
    }

    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                        <>
                            <div className="row mx-0 justify-content-between align-items-center">
                                <div className="">
                                    <span className="categorycol">Coupons</span>
                                </div>
                                <div className="">
                                    <button
                                        type="button"
                                        className="btnaddcategory float-right"
                                        data-toggle="modal"
                                        data-target="#couponsModal"
                                    >
                                        <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                        &nbsp; Add Coupons
                                    </button>
                                </div>
                            </div>
                            {this.state.Coupons.length ? (
                                <div className="table-responsive mt-3 tableofauto">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                {/* <th> Bill Amount</th> */}
                                                <th>Coupons</th>
                                                <th>Discount-type</th>
                                                <th>Discount-Value</th>
                                                <th>valid-from</th>
                                                <th>valid-to</th>
                                                <th>Status</th>
                                                <th className="actionWidth">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Coupons.map((cu, idx) => (
                                                <tr key={String(idx)}>
                                                    <td>
                                                        {(this.state.currentPageNum - 1) *
                                                            this.state.recordsPerPage +
                                                            idx +
                                                            1}
                                                    </td>
                                                    {/* <td>{cu.billAmount}</td> */}
                                                    <td>{cu.coupon}</td>
                                                    <td>{cu.discountType}</td>
                                                    <td>{cu.discountValue}</td>
                                                    <td>{cu.validFrom}</td>
                                                    <td>{cu.validTo}</td>
                                                    <td>{cu.status}</td>
                                                    <td>
                                                        <img
                                                            alt=""
                                                            src={EDIT_IMG}
                                                            data-toggle="modal"
                                                            data-target="#couponsModal"
                                                            className="icon"
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedCouponId: cu.discountId
                                                                });
                                                                this.modalRef.current.setState({
                                                                    editMode: true
                                                                });
                                                                this.modalRef.current.formApi.setValue(
                                                                    "bill_amount",
                                                                    cu.billAmount
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "coupon",
                                                                    cu.coupon
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "discount_type",
                                                                    cu.discountType
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "discount_value",
                                                                    cu.discountValue
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "valid_from",
                                                                    cu.validFrom
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "valid_to",
                                                                    cu.validTo
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "discount_status",
                                                                    cu.status
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "max_discount_value",
                                                                    cu.discountValueMax
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "couponDescription",
                                                                    cu.description
                                                                );
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            src={DELETE_IMG}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() =>
                                                                this.confirmAndDelete(
                                                                    cu.coupon,
                                                                    cu.discountId
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="noDataMsgContainer">
                                    <h5>{this.state.noDataMsg}</h5>
                                </div>
                            )}
                        </>
                    )}
                    {this._pagination()}
                    <CouponsModal
                        ref={this.modalRef}
                        submitCouponForm={this._submitCouponForm.bind(this)}
                        modalUiRef={this.modalUI}
                    />
                </div>
            </>
        )
    }

    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totalCoupons / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totalCoupons / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadCoupons();
            }
        );
    }

    async _submitCouponForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let CouponId = this.state.selectedCouponId;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        let body = {
            user_id: this.props.user.userId,
            restarant_id: this.props.user.selectedRestarantId,
            billAmount: formValues.bill_amount,
            coupon: formValues.coupon.trim(),
            discountType: formValues.discount_type.trim(),
            discountValue: formValues.discount_value,
            validFrom: formValues.valid_from,
            validTo: formValues.valid_to,
            status: formValues.discount_status.trim(),
            discountValueMax: formValues.max_discount_value,
            discount_id: CouponId,
            description: formValues.couponDescription
        }
        if (formValues.discount_type == "Amount") {
            body.discountValueMax = null;
        }
        const savedResponse = await fetch(
            apiBasePath +
            (CouponId ? apiRoutes.editDiscounts : apiRoutes.createDiscounts),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: CouponId ? "PUT" : "POST",
                body: JSON.stringify(body)
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${CouponId ? "saving the changes" : "adding the discount"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${CouponId ? "saving the changes" : "adding the discount"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1
            },
            err => {
                this._loadCoupons();
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${CouponId ? "saved the changes" : "added " + "coupons"
            }.`,
            { autoClose: 4000 }
        );
    }

    async _loadCoupons() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const CouponsResponse = await fetch(
            apiBasePath +
            apiRoutes.getCoupons +
            `?user_id=${this.props.user.userId}
            &limit=${this.state.recordsPerPage
            }&offset=${(this.state.currentPageNum - 1) *
            this.state.recordsPerPage}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!CouponsResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const CouponsResult = await CouponsResponse.json();
        if (!CouponsResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!CouponsResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: CouponsResult.msg
            });
            return toast.error(CouponsResult.msg);
        }
        throttleTime(
            () => {
                this.setState({
                    Coupons: CouponsResult.data,
                    totalCoupons: CouponsResult.total,
                    fetchProgress: false
                });
            },
            requestSendTime,
            500
        );
    }



    confirmAndDelete(coupon, CouponId) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${coupon || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteDiscount(coupon, CouponId)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }

    async _deleteDiscount(coupon, CouponId) {
        if (this.state.deleteProgress) {
            return false;
        }
        this.setState({
            deleteProgress: true
        });

        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deleteDiscounts,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "DELETE",
                body: JSON.stringify({
                    discount_id: CouponId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the coupon.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the coupon.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: "",
            currentPageNum:
                this.state.Coupons.length > 1
                    ? this.state.currentPageNum
                    : this.state.currentPageNum - 1 > 0
                        ? this.state.currentPageNum - 1
                        : 1
        });
        this._loadCoupons();
        toast.success(` successfully Deleted ${coupon}`, { autoClose: 5000 });
    }
}
//  create form for coupons
class CouponsModal extends Component {
    state = {
        editMode: false,
        saveProgress: false,
        discountPercentage: "",
        discountAmountValue: "",
        dtype: ""
    };


    render() {
        const { modalUiRef, submitCouponForm } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="couponsModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit Coupons"
                                    : "Add Coupons"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitCouponForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {
                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="row mb-sm-3">
                                                {/* amount  */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val => {
                                                                if (!val && !formState.touched.bill_amount) {
                                                                    return;
                                                                }
                                                                if (isNaN(val)) {
                                                                    return "Please Enter a valid amount.";
                                                                }
                                                                if (Number(val) > 20000) {
                                                                    return "Amount cannot be more than 20000 and cannot have more than 2 decimal points.";
                                                                }
                                                                if (!Number(val)) { return " amount cannot be empty"; }
                                                                let notPositive = validateWholeNumber(
                                                                    val,
                                                                    formState.touched.coupon,
                                                                    "Amount"
                                                                );
                                                            }}
                                                            validateOnBlur
                                                            validateOnChange
                                                            field="bill_amount"
                                                            type="number"
                                                            placeholder="Enter Bill Amount"
                                                            className={
                                                                formState.errors.bill_amount
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.bill_amount && (
                                                            <div className="input_error">
                                                                {formState.errors.bill_amount}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* coupons code */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val =>
                                                                validateInput(
                                                                    val,
                                                                    3,
                                                                    formState.touched.couponDescription,
                                                                    "coupon description",
                                                                    25
                                                                )
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            field="couponDescription"
                                                            type="text"
                                                            placeholder="Enter Coupon Description"
                                                            className={
                                                                formState.errors.couponDescription
                                                                    ? "form-control mr-sm-4 input_error"
                                                                    : "form-control mr-sm-4"
                                                            }
                                                        />
                                                        {formState.errors.couponDescription && (
                                                            <div className="input_error">
                                                                {formState.errors.couponDescription}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val =>
                                                                validateInput(
                                                                    val,
                                                                    3,
                                                                    formState.touched.coupon,
                                                                    "coupon code"
                                                                )
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            field="coupon"
                                                            type="text"
                                                            placeholder="Enter Coupon Code"
                                                            className={
                                                                formState.errors.coupon
                                                                    ? "form-control mr-sm-4 input_error"
                                                                    : "form-control mr-sm-4"
                                                            }
                                                        />
                                                        {formState.errors.coupon && (
                                                            <div className="input_error">
                                                                {formState.errors.coupon}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* discount type */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Select
                                                            field="discount_type"
                                                            validate={val =>
                                                                this._validateSelectDiscountType(val, "DiscountType")
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            className={
                                                                formState.errors.discount_type
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        >
                                                            <option value="" disabled>
                                                                - Select DiscountType-
                                                            </option>
                                                            <option >Percentage</option>
                                                            <option>Amount</option>
                                                        </Select>
                                                        {formState.errors.discount_type && (
                                                            <div className="input_error">
                                                                {formState.errors.discount_type}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/*  discount value */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val => {
                                                                if (!val && !formState.touched.discount_value) {
                                                                    return;
                                                                }
                                                                if (isNaN(val < 0)) {
                                                                    return " Enter a discount value.";
                                                                }
                                                                if (this.state.dtype == "Percentage") {
                                                                    if (Number(val) > 100) {
                                                                        return "Discount percentage cannot be more than 100%.";
                                                                    }
                                                                }
                                                                if (this.state.dtype == "Amount") {
                                                                    if (Number(val) > 20000) {
                                                                        return "Discount Amount cannot be more than 20000.";
                                                                    }
                                                                }
                                                                if (!val) { return " discount value cannot be empty"; }
                                                                let notPositive = validateWholeNumber(
                                                                    val,
                                                                    "Amount"
                                                                );
                                                            }}
                                                            validateOnBlur
                                                            validateOnChange
                                                            field="discount_value"
                                                            type="number"
                                                            placeholder="Discount value(% or amount)"
                                                            value={this.state.discountPercentage || this.state.discountAmountValue}
                                                            onChange={e => {
                                                                this.setState({
                                                                    discountPercentage: Number(e.nativeEvent.target.value),
                                                                    // discountAmountValue: Number(e.nativeEvent.target.value)
                                                                });
                                                            }}
                                                            className={
                                                                formState.errors.discount_value
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.discount_value && (
                                                            <div className="input_error">
                                                                {formState.errors.discount_value}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* valid date from */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val => {
                                                                if (!val && !formState.touched.valid_from) {
                                                                    return;
                                                                }

                                                                if (!val) { return "  from date cannot be empty"; }
                                                                let notPositive = validateWholeNumber(
                                                                    val,
                                                                    formState.touched.validFrom,
                                                                    "Amount"
                                                                );
                                                            }}
                                                            validateOnBlur
                                                            validateOnChange
                                                            field="valid_from"
                                                            type="text"
                                                            placeholder="date valid_from(yyyy-mm-dd)"
                                                            className={
                                                                formState.errors.valid_from
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.valid_from && (
                                                            <div className="input_error">
                                                                {formState.errors.valid_from}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* valid to */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val => {
                                                                if (!val && !formState.touched.valid_to) {
                                                                    return;
                                                                }

                                                                if (!val) { return "  To date cannot be empty"; }
                                                                let notPositive = validateWholeNumber(
                                                                    val,
                                                                    formState.touched.validTo,
                                                                    "Amount"
                                                                );
                                                            }}
                                                            validateOnBlur
                                                            validateOnChange
                                                            field="valid_to"
                                                            type="text"
                                                            placeholder="date valid_to(yyyy-mm-dd)"
                                                            className={
                                                                formState.errors.valid_to
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.valid_to && (
                                                            <div className="input_error">
                                                                {formState.errors.valid_to}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* {this.formApi.getState().values.discount_type == "Percentage" ? */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            type="number"
                                                            validate={val => {
                                                                if (this.formApi.getState().values.discount_type == "Percentage") {
                                                                    if (!val && !formState.touched.max_discount_value) {
                                                                        return "Enter a max discount value.";
                                                                    }
                                                                    if (isNaN(val) < 0) {
                                                                        return " Enter a max discount value.";
                                                                    }
                                                                    if (!val) { return " discount value cannot be empty"; }
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "Value"
                                                                    );
                                                                }
                                                            }}
                                                            validateOnBlur
                                                            validateOnChange
                                                            field="max_discount_value"
                                                            placeholder="Enter max discount value"
                                                            className={
                                                                formState.errors.discount_value
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.max_discount_value && (
                                                            <div className="input_error">
                                                                {formState.errors.max_discount_value}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* : null} */}
                                                {/* status */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Select
                                                            field="discount_status"
                                                            validate={val =>
                                                                this._validateSelectDiscountStatus(val, "DiscountStatus")
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            className={
                                                                formState.errors.discount_status
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        >
                                                            <option value="" disabled>
                                                                - Select DiscountStatus -
                                                            </option>
                                                            <option >Active</option>
                                                            <option>InActive</option>
                                                        </Select>
                                                        {formState.errors.discount_status && (
                                                            <div className="input_error">
                                                                {formState.errors.discount_status}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    _setFormApi(formApi) {
        this.formApi = formApi;
    }

    _validateSelectDiscountType(val, name) {
        this.setState({
            dtype: val
        })
        if (!val) {
            return `Select ${name}.`;
        }
        // this.setState({
        //     dtype:val
        // })
    }

    _validateSelectDiscountStatus(val, name) {
        if (!val) {
            return `Select ${name}.`;
        }
    }

}