import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Checkbox } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import DualListBox from 'react-dual-listbox';
import ReactSearchBox from 'react-search-box'
export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <OptionGroups
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class OptionGroups extends Component {
    state = {
        optionGroups: [],
        totaloptionGroups: 0,
        currentPageNum: 1,
        recordsPerPage: 10,
        fetchProgress: true,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        noDataMsg: "No option Groups Yet.",
        selectedOptionGroupId: null,
        allOptionGroups: [],
        itemsData: [],
        selectedItemIdsList: [],
        searchTitleTxt: '',
        options: []
    };

    componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
    }

    componentDidMount() {
        this._loadOptionGroups();
        this._loadItemsData();
        this._loadOptions();
        $(this.modalUI.current).on("shown.bs.modal", () => {
            // if (this.modalRef.current.state.categories.length) return;
            // this._loadCategories();
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedOptionGroupId: null
            });
            this.modalRef.current.formApi.reset();
        });
    }

    componentWillUnmount() {
        toast.dismiss();
    }
    filterOptionGroups() {
        let filterOptGroups = this.state.searchTitleTxt ? this.state.allOptionGroups.filter(opt => { return opt.optionGroupTitle.toUpperCase().indexOf(this.state.searchTitleTxt.toUpperCase()) > -1 }) : this.state.allOptionGroups;
        this.setState({
            optionGroups: filterOptGroups
        })
    }
    async filterByOptionsGroups(event) {
        await this.setState({ searchTitleTxt: event.target.value });
        this.filterOptionGroups();
    }
    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                        <>
                            <div className="row mx-0 justify-content-between align-items-center">
                                <div className="">
                                    <span className="categorycol">Option Groups</span>
                                </div>
                                {/* <div>
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => {
                                                this._onSyncOptionGroups()
                                            }}
                                        >
                                            Sync optionGroups
                                        </button>
                                    </div> */}
                                <div className="">
                                    <button
                                        type="button"
                                        className="btnaddcategory float-right"
                                        data-toggle="modal"
                                        data-target="#optionGroupModal"
                                        onClick={() => { this.setState({ selectedItemIdsList: [] }) }}
                                    >
                                        <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                        &nbsp; Add option Group
                                    </button>
                                </div>
                            </div>

                            <div className="table-responsive mt-3 tableofauto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th><div>Name</div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        placeholder="Search Name..."
                                                        aria-label="Search"
                                                        onChange={this.filterByOptionsGroups.bind(this)}
                                                        value={this.state.searchTitleTxt}
                                                        style={{ fontSize: 10, backgroundColor: "#FFFFFF" }}
                                                    />
                                                </div></th>
                                            <th>Ref Title</th>
                                            <th>Description</th>
                                            <th>sort order</th>
                                            <th>max selectable</th>
                                            <th>min selectable</th>
                                            <th>Online active</th>
                                            <th>Pos Active</th>
                                            <th className="actionWidth">Action</th>
                                        </tr>
                                    </thead>
                                    {this.state.optionGroups.length ? (
                                        <tbody>
                                            {this.state.optionGroups.map((og, idx) => (
                                                <tr key={String(idx)}>
                                                    <td>
                                                        {(this.state.currentPageNum - 1) *
                                                            this.state.recordsPerPage +
                                                            idx +
                                                            1}
                                                    </td>
                                                    <td>{og.optionGroupTitle}</td>
                                                    <td>{og.optionGroupRefTitle}</td>
                                                    <td>
                                                        {og.optionGroupDescription
                                                            ? og.optionGroupDescription.length <= 50
                                                                ? og.optionGroupDescription
                                                                : og.optionGroupDescription.substr(0, 50) +
                                                                "..."
                                                            : ""}
                                                    </td>
                                                    <td>{og.optionGroupSortOrder}</td>
                                                    <td>{og.optionGroupMaxSelectable}</td>
                                                    <td>{og.optionGroupMinSelectable}</td>
                                                    <td>{og.optionGroupOnlineActive ? "Active" : "InActive"}</td>
                                                    <td>{og.optionGroupPosActive ? "Active" : "InActive"}</td>
                                                    <td>
                                                        <img
                                                            alt=""
                                                            src={EDIT_IMG}
                                                            data-toggle="modal"
                                                            data-target="#optionGroupModal"
                                                            className="icon"
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedOptionGroupId: og.optionGroupId
                                                                });
                                                                let itemIdFromDB = og.optionGroupItemRefIds ? JSON.parse(og.optionGroupItemRefIds) : []

                                                                this.setState({
                                                                    selectedItemIdsList: itemIdFromDB
                                                                })
                                                                this.modalRef.current.setState({
                                                                    editMode: true
                                                                });
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__name",
                                                                    og.optionGroupTitle
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__desc",
                                                                    og.optionGroupDescription
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__sortOrder",
                                                                    og.optionGroupSortOrder
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__minSelectable",
                                                                    og.optionGroupMinSelectable
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__maxSelectable",
                                                                    og.optionGroupMaxSelectable
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__refTitle",
                                                                    og.optionGroupRefTitle
                                                                );
                                                                // this.modalRef.current.formApi.setValue(
                                                                //     "optionGroup__displayInline",
                                                                //     og.optionGroupDisplayInline
                                                                // );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__onlineActive",
                                                                    og.optionGroupOnlineActive
                                                                );
                                                                this.modalRef.current.formApi.setValue(
                                                                    "optionGroup__posActive",
                                                                    og.optionGroupPosActive
                                                                );
                                                            }}
                                                        />
                                                        {this.props.user.restarantMenuDelete == 1 ?
                                                        <img
                                                            alt=""
                                                            src={DELETE_IMG}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                let options = this.state.options;
                                                                let ogOpts = options.filter(opt => { return JSON.parse(opt.optionGroupRefIds).indexOf(og.optionGroupId + '') > -1; });
                                                                if(ogOpts.length){
                                                                    return toast.error("There are still options for this option Group. ")
                                                                }
                                                                this.confirmAndDelete(
                                                                    og.optionGroupId,
                                                                    og.optionGroupTitle
                                                                )
                                                            }
                                                            }
                                                        />:null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <div className="noDataMsgContainer">
                                            <h5>{this.state.noDataMsg}</h5>
                                        </div>
                                    )}
                                </table>
                            </div>

                        </>
                    )}
                </div>
                {/* {this._pagination()} */}
                <OptionGroupModal
                    ref={this.modalRef}
                    submitOptionGroupForm={this._submitOptionGroupForm.bind(this)}
                    modalUiRef={this.modalUI}
                    itemsData={this.state.itemsData}
                    // displayItemTitle={this.state.displayItemTitleString}
                    itemIds={this.state.selectedItemIdsList}
                    setSelectedItemIds={(itemIdList) => { this.setState({ selectedItemIdsList: itemIdList }) }}
                // selectedItemNames={(itemNameList)=>{this.setState({displayItemTitleString:itemNameList})}}

                />
            </>
        );
    }

    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totaloptionGroups / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totaloptionGroups / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    async _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadOptionGroups();
            }
        );
    }

    async _onSyncOptionGroups() {
        if (this.state.saveProgress) {
            return false;
        }
        const { upApiBasePath } = this.props.CONSTANTS;
        let oGroups = this.state.allOptionGroups.map((o, index) => {
            return {
                ref_id: o.optionGroupId,
                title: o.optionGroupTitle,
                ref_title: o.optionGroupRefTitle,
                description: o.optionGroupDescription,
                min_selectable: o.optionGroupMinSelectable,
                max_selectable: o.optionGroupMaxSelectable,
                sort_order: o.optionGroupSortOrder,
                active: o.optionGroupActive,
                display_inline: o.optionGroupDisplayInline,
                item_ref_ids: o.optionGroupItemRefIds,
                translations: []
            }
        })
        this.setState({
            saveProgress: true
        });
        let body = {
            option_groups: oGroups
        }
        const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/locations/" + this.props.user.restarantId + "/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (savedResponseResult.status == "error") {
            this.setState({
                saveProgress: false
            });
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference)
            setTimeout(() => {
                toast.success(
                    "Successfully synched"
                );
            }, 1500);
            return toast.success(savedResponseResult.message);
        }
    }

    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: restId,
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanCatalogueResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            this.setState({
                saveProgress: false,

            });
            return toast.error("An error occurred while storing the response.")
        }
        if (!savedResponseResult.success) {
            toast.error(savedResponseResult.msg, { autoClose: 8000 });
            return this.setState({
                saveProgress: false
            });
        }
        this.setState({
            saveProgress: false
        });
    }

    async _loadOptionGroups() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const optionGroupResponse = await fetch(
            apiBasePath +
            apiRoutes.getOptionGroups +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!optionGroupResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const optionGroupResponseResult = await optionGroupResponse.json();
        if (!optionGroupResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!optionGroupResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: optionGroupResponseResult.msg
            });
            return toast.error(optionGroupResponseResult.msg);
        }
        throttleTime(
            () => {
                this.setState({
                    optionGroups: optionGroupResponseResult.data,
                    totaloptionGroups: optionGroupResponseResult.total,
                    fetchProgress: false,
                    allOptionGroups: optionGroupResponseResult.data
                });
            },
            requestSendTime,
            500
        )
    }
    async _loadOptions() {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const optionResponse = await fetch(
            apiBasePath +
            apiRoutes.getOptions +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!optionResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const optionResponseResult = await optionResponse.json();
        if (!optionResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!optionResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: optionResponseResult.msg
            });
            return toast.error(optionResponseResult.msg);
        }
        this.setState({
            options: optionResponseResult.data
        });

    }
    async _loadItemsData() {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const itemsResponse = await fetch(
            apiBasePath +
            apiRoutes.getMenuItems +
            `?user_id=${this.props.user.userId}&limit=0&offset=0&restarant_id=${this.props.user.selectedRestarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!itemsResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const itemsResponseResult = await itemsResponse.json();
        if (!itemsResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!itemsResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: itemsResponseResult.msg
            });
            return toast.error(itemsResponseResult.msg);
        }
        let itemIdTitleList = itemsResponseResult.data.map(i => {
            return {
                value: String(i.itemId),
                label: i.itemTitle
            };
        });

        this.setState({
            itemsData: itemIdTitleList
        });

    }

    async _submitOptionGroupForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let optionGroupId = this.state.selectedOptionGroupId;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath +
            (optionGroupId ? apiRoutes.editOptionGroup : apiRoutes.createOptionGroup),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: optionGroupId ? "PUT" : "POST",
                body: JSON.stringify({
                    option_group_id: optionGroupId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId,
                    option_group_title: formValues.optionGroup__name.trim(),
                    option_group_description: formValues.optionGroup__desc && formValues.optionGroup__desc.trim(),
                    option_group_min_selectable: formValues.optionGroup__minSelectable,
                    option_group_max_selectable: formValues.optionGroup__maxSelectable,
                    option_group_sort_order: formValues.optionGroup__sortOrder,
                    option_group_online_active: formValues.optionGroup__onlineActive,
                    option_group_pos_active: formValues.optionGroup__posActive,
                    option_group_ref_title: formValues.optionGroup__refTitle,
                    option_group_display_inline: formValues.optionGroup__displayInline,
                    option_group_item_ref_ids: this.state.selectedItemIdsList
                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${optionGroupId ? "saving the changes" : "adding the option group"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${optionGroupId ? "saving the changes" : "adding the option group"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1
            },
            err => {
                this._loadOptionGroups();
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${optionGroupId ? "saved the changes" : "added " + formValues.optionGroup__name
            }.`,
            { autoClose: 4000 }
        );
    }

    confirmAndDelete(ogId, name) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${name || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteOptionGroup(ogId)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }

    async _deleteOptionGroup(ogId) {
        if (this.state.deleteProgress) {
            return false;
        }

        this.setState({
            deleteProgress: true
        });
        const optionGroup = this.state.optionGroups.find(
            i => i.optionGroupId == ogId
        );
        const ogName = optionGroup.optionGroupTitle;

        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deleteOptionGroup,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "DELETE",
                body: JSON.stringify({
                    option_group_id: ogId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the option-group.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the option-group.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: "",
            currentPageNum:
                this.state.optionGroups.length > 1
                    ? this.state.currentPageNum
                    : this.state.currentPageNum - 1 > 0
                        ? this.state.currentPageNum - 1
                        : 1
        });
        this._loadOptionGroups();
        toast.success(`Deleted ${ogName}`, { autoClose: 5000 });
    }
}

class OptionGroupModal extends Component {
    state = {
        editMode: false,
        saveProgress: false
    };

    handleOnSelect = item => {
        this.props.setSelectedItemIds(item)
    }


    render() {
        const { modalUiRef, submitOptionGroupForm } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="optionGroupModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit option Group"
                                    : "Add option Group"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"

                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitOptionGroupForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {
                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="row mb-sm-3">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group required">
                                                        <label>Title
                                                            <Text
                                                                validate={val =>
                                                                    validateStrictText(
                                                                        val,
                                                                        3,
                                                                        formState.touched.optionGroup__name,
                                                                        "option group Name",
                                                                        30
                                                                    )
                                                                }
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"optionGroup__name"}
                                                                type="text"
                                                                placeholder="Enter option group name"
                                                                className={
                                                                    formState.errors.optionGroup__name
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.optionGroup__name && (
                                                                <div className="input_error">
                                                                    {formState.errors.optionGroup__name}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> ref title
                                                            <Text
                                                                validate={val => {
                                                                    val = val && val.trim();
                                                                    if (!val) return `Ref title cannot be empty`;
                                                                    if (new RegExp(/^[0-9.]+$/).test(val)) {
                                                                        return `Enter a valid Ref title.`;
                                                                    }
                                                                    if (!val || val.length < 3 || val.length > 60) {
                                                                        return `Ref title must contain 3 to 60 Characters.`;
                                                                    }
                                                                }
                                                                }
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"optionGroup__refTitle"}
                                                                type="text"
                                                                placeholder="Enter ref title"
                                                                className={
                                                                    formState.errors.optionGroup__refTitle
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.optionGroup__refTitle && (
                                                                <div className="input_error">
                                                                    {formState.errors.optionGroup__refTitle}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> sort order
                                                            <Text
                                                                validate={val => {
                                                                    if (formState.touched.optionGroup__sortOrder && val === '') {
                                                                        return " sort order  cannot be empty";
                                                                    }
                                                                    if (isNaN(val)) {
                                                                        return "Please Enter a sort order.";
                                                                    }
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "Item Sort Order"
                                                                    );
                                                                    if (Number(val) > 1000) {
                                                                        return "sort order cannot be more than 1000.";
                                                                    }
                                                                }}

                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"optionGroup__sortOrder"}
                                                                type="text"
                                                                placeholder="Enter Sort Order"
                                                                className={
                                                                    formState.errors.optionGroup__sortOrder
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.optionGroup__sortOrder && (
                                                                <div className="input_error">
                                                                    {formState.errors.optionGroup__sortOrder}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> min selectable
                                                            <Text
                                                                validate={val => {
                                                                    if (formState.touched.optionGroup__minSelectable && val === '') {
                                                                        return "min selectable cannot be empty";
                                                                    }
                                                                    if (isNaN(val)) {
                                                                        return "Please Enter a min selectable.";
                                                                    }
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "min selectable"
                                                                    );
                                                                    if (Number(val) > 1000) {
                                                                        return "min selectable cannot be more than 1000.";
                                                                    }
                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"optionGroup__minSelectable"}
                                                                type="text"
                                                                placeholder="Enter Min selectable"
                                                                className={
                                                                    formState.errors.optionGroup__minSelectable
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.optionGroup__minSelectable && (
                                                                <div className="input_error">
                                                                    {formState.errors.optionGroup__minSelectable}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> max selectable
                                                            <Text
                                                                validate={val => {
                                                                    if (formState.touched.optionGroup__maxSelectable && val === '') {
                                                                        return "max selectable cannot be empty";
                                                                    }
                                                                    if (isNaN(val)) {
                                                                        return "Please Enter a max selectable.";
                                                                    }
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        " max selectable"
                                                                    );
                                                                    if (Number(val) > 1000) {
                                                                        return "max selectable cannot be more than 1000.";
                                                                    }
                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"optionGroup__maxSelectable"}
                                                                type="text"
                                                                placeholder="Enter Max selectable"
                                                                className={
                                                                    formState.errors.optionGroup__maxSelectable
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.optionGroup__maxSelectable && (
                                                                <div className="input_error">
                                                                    {formState.errors.optionGroup__maxSelectable}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6" />
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="optionGroup__onlineActive"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Online-Active<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="optionGroup__onlineActive"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">                                                            <label
                                                            htmlFor="optionGroup__posActive"
                                                            className="row mx-0 w-100 justify-content-between"
                                                        >
                                                            Pos-Active<span className="">:</span>
                                                        </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="optionGroup__posActive"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="optionGroup__displayInline"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Display Inline<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="optionGroup__displayInline"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div>
                                                        <label>Select Items </label>
                                                        <DualListBox
                                                            options={this.props.itemsData}
                                                            selected={this.props.itemIds}
                                                            onChange={this.handleOnSelect}

                                                        />

                                                    </div>
                                                </div>

                                            </div>


                                            <div className="form-group">
                                                <TextArea
                                                    validate={val => {

                                                        if (Number(val) > 500) {
                                                            return "option group Description cannot be more than 500 Characters.";
                                                        }
                                                    }
                                                    }
                                                    // validate={val =>
                                                    //     validateText(
                                                    //         val,
                                                    //         10,
                                                    //         formState.touched.optionGroup__desc,
                                                    //         "option group Description",
                                                    //         400
                                                    //     )
                                                    // }
                                                    rows={5}
                                                    validateOnBlur
                                                    validateOnChange
                                                    field={"optionGroup__desc"}
                                                    className={
                                                        formState.errors.optionGroup__desc
                                                            ? "form-control input_error"
                                                            : "form-control"
                                                    }
                                                    placeholder="Enter  description"
                                                />
                                                {formState.errors.optionGroup__desc && (
                                                    <div className="input_error">
                                                        {formState.errors.optionGroup__desc}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    _setFormApi(formApi) {
        this.formApi = formApi;
    }



}
