import React, { Component } from 'react';
import { AuthContext } from "../contextProvider";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import Switch from 'react-switch';
import moment from 'moment'
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);
export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <StoreActions
                        {...props}
                        user={user}
                        authToken={authToken}
                        upAuthToken={up_Authorization}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};
class StoreActions extends Component {
    state = {
        fetchProgress: false,
        restarantData: [],
        IncludedPlatforms: [],
        excludedPlatforms: [],
        aggregatorPlatforms: [],
        activePlatforms: [],
        urbanStoreActionSyncDetails: []
    }
    componentDidMount() {
        this._loadRestarantDetails(this.props.user.selectedRestarantId);
        let list = [];
        let platformList = [];
        list = Object.keys(this.props.CONSTANTS.aggregatorPlatforms);
        list.map((i, idx) => {
            platformList.push(list[idx]);
        })
        this.setState({
            aggregatorPlatforms: platformList
        })
        this._loadUrbanStoreActionsSyncDetails()
    }
    handleChange(checked, agg) {
        let activePlat = this.state.activePlatforms;
        if (checked) {
            activePlat.push(agg);
        } else {
            var index = this.state.activePlatforms.indexOf(agg);
            activePlat.splice(index, 1);
        }
        this._onSyncStoreActions(checked, agg, activePlat);
    }
    render() {
        let aggregators = [];
        if (this.state.IncludedPlatforms.length) {
            aggregators = this.state.IncludedPlatforms;
        } else if (this.state.excludedPlatforms.length) {
            const agg = this.state.aggregatorPlatforms;
            const exPlat = agg.filter(x => !this.state.excludedPlatforms.includes(x));
            aggregators = exPlat
        }

        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            style="loadingProgress"
                        />
                    ) : (
                            <>
                                <div className="row mx-0 justify-content-between align-items-center">
                                    <div className="">
                                        <span className="categorycol">Store Actions </span>
                                    </div>
                                </div>
                                <div className="table-responsive mt-3 tableofauto">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.no</th>
                                                <th>Aggregator Name</th>
                                                <th>Status</th>
                                                <th>sync Status</th>
                                                <th>sync Action</th>
                                                <th>Last synched At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aggregators.map((agg, index) => {
                                                let aggStatus = this.state.activePlatforms.find(a => a == agg);
                                                let isChecked = aggStatus ? true : false;
                                                let syncDetails = this.state.urbanStoreActionSyncDetails.filter(u => u.platform == agg)[0]
                                                return (
                                                    <tr key={agg}>
                                                        <td>{index + 1} </td>
                                                        <td>{agg}</td>
                                                        <td>
                                                            <Switch
                                                                checked={isChecked}
                                                                onChange={checked => this.handleChange(checked, agg)}
                                                                onColor="#cccccc"
                                                                offColor="#cccccc"
                                                                onHandleColor="#42f705"
                                                                offHandleColor="#db2b0f"
                                                                handleDiameter={20}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={25}
                                                                width={55}
                                                                className="react-switch"
                                                                id="material-switch"
                                                            />
                                                        </td>
                                                        <td>{syncDetails ? (syncDetails.status == 1 ? "Success" : "Failed") : null}</td>
                                                        <td>{syncDetails ? syncDetails.action : null}</td>
                                                        <td>{syncDetails ? moment(syncDetails.lastSynched).format("DD/MM/YYYY HH:mm:ss") : null}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                </div>
            </>

        )
    }
    async _loadRestarantDetails(restarantId) {
        if (this.state.fetchProgress) return false;
        this.setState({
            fetchProgress: true
        });
        let fetchResponse = await fetch(
            this.props.CONSTANTS.apiBasePath +
            this.props.CONSTANTS.apiRoutes.getRestarantDetails +
            `?restarant_id=${restarantId}&user_id=${this.props.user.userId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!fetchResponse) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const restarantData = await fetchResponse.json(); //@TODO - handle error
        if (!restarantData.success) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("Could not get restaurant details.");
        }
        this.setState({
            restarantData: restarantData.data,
            IncludedPlatforms: restarantData.data.rIncludedPlatforms.rIncludedPlatforms,
            excludedPlatforms: restarantData.data.rExcludedPlatforms.rExcludedPlatforms,
            activePlatforms: restarantData.data.rActivePlatforms,
            fetchProgress: false
        })
    }
    async _updateActivePlatforms(activePlat) {
        const { CONSTANTS, authToken, user } = this.props
        let body = {
            restarant_id: user.selectedRestarantId,
            restarant_active_platforms: activePlat
        }

        let apiRoute = CONSTANTS.apiRoutes.updateActivePlatforms;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "PUT",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while updating the active platforms"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
    }
    async _onSyncStoreActions(isChecked, agg, activePlat) {
        if (this.state.saveProgress) {
            return false;
        }
        this.setState({
            saveProgress: true
        });
        let action = isChecked ? "enable" : "disable"
        const { upApiBasePath } = this.props.CONSTANTS;
        let body = {
            location_ref_id: String(this.props.user.selectedRestarantId),
            platforms: [agg],
            action: action
        }
        const savedResponse = await fetch(upApiBasePath + "/hub/api/v1/location/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
            retries: 3,
            retryDelay: 1000,
            retryOn: [500, 503]
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));

        if (savedResponseResult.status == "error") {
            let activePlat = this.state.activePlatforms
            var index = this.state.activePlatforms.indexOf(agg);
            activePlat.splice(index, 1);
            this.setState({
                saveProgress: false,
                activePlatforms: activePlat
            });
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference_id)
            this.setState({
                saveProgress: false,
                activePlatforms: activePlat
            });
            setTimeout(() => {
                toast.success(
                    "Successfully synched"
                );
            }, 1500);
            this._updateActivePlatforms(activePlat);
            return toast.success(savedResponseResult.message);
        }
    }
    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: String(restId),
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanStoreAddUpdateResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while saving the webhook response"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }

    }
    async _loadUrbanStoreActionsSyncDetails() {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const urbanStoreActionSyncDetails = await fetch(
            apiBasePath +
            apiRoutes.getUrbanStoreActionSyncDetails +
            `?user_id=${this.props.user.userId}&restarant_id=${this.props.user.selectedRestarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!urbanStoreActionSyncDetails) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const urbanStoreActionSyncDetailsResult = await urbanStoreActionSyncDetails.json();
        if (!urbanStoreActionSyncDetailsResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        this.setState({
            urbanStoreActionSyncDetails: urbanStoreActionSyncDetailsResult.data
        })

    }
}
