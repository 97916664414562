import React, { Component } from 'react';
import { AuthContext } from "../contextProvider";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import Switch from 'react-switch';
export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, upSatelliteScriptUrl, upEnv, CONSTANTS, isSuperAdmin }) => {
                return (
                    <SatelliteOrders
                        {...props}
                        user={user}
                        authToken={authToken}
                        upSatelliteScriptUrl={upSatelliteScriptUrl}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upEnv={upEnv}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};
class SatelliteOrders extends Component {
    state = {
        fetchProgress: false,
        restarantData: [],
        IncludedPlatforms: [],
        excludedPlatforms: [],
        aggregatorPlatforms: [],
        activePlatforms: []
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.src = this.props.upSatelliteScriptUrl;
        script.async = true;
        script.onload = () => this._scriptLoaded();
        document.body.appendChild(script);
    }
    async _generateAccessToken() {
        if (this.state.saveProgress) {
            return false;
        }
        this.setState({
            saveProgress: true
        });
        const { upApiBasePath } = this.props.CONSTANTS;
        let body = {
            username: this.props.user.urbanSatelliteUsername,
            stores: [String(this.props.user.selectedRestarantId)],
        }
        const savedResponse = await fetch(upApiBasePath + "/satellite/api/v1/widgets/tokens/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));

        if (savedResponseResult.status == "error") {
            this.setState({
                saveProgress: false,
            });
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            this.setState({
                saveProgress: false,
                access_token: savedResponseResult.access_token
            });
        }
    }
    async _scriptLoaded() {
        await this._generateAccessToken();
        window.STL.init({
            token: 'Bearer ' + this.state.access_token,
            //token:'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN3X3BvcytidXZ2XzIwMjN2XzQ2Nzk3MDk0IiwibGFzdF9uYW1lIjoiIiwibXVsdGlfYml6IjpmYWxzZSwiYXBwIjoic2F0ZWxsaXRlIiwicmVxdWVzdGVkX2J5IjoiYml6X2FkbV80Njc5NzA5NGpxa3Bscm5zZXYiLCJhcHBfdmVyIjoiMS4wLjAiLCJiaXpfaWQiOjQ2Nzk3MDk0LCJzdGFmZiI6dHJ1ZSwiZmlyc3RfbmFtZSI6IiIsInVzZXJfaWQiOjU1NDM2LCJiaXpfbmFtZSI6IkJ1dnZhcyIsImppdCI6IjkzYWI0OWY1NmEzZjQ5Y2NhNTcyMjYxMTFmZTgwZjEwIiwiZXhwIjoxNjA5OTMyMDM1LCJpYXQiOjE2MDczNDAwMzUsImFwcF9wbHQiOiJ3ZWItd2lkZ2V0IiwiZW1haWwiOiJzdGwud2lkZ2V0X3BvcytidXZ2YXMrNDY3OTcwOTRAdXJiYW5waXBlci5jb20ifQ.jONU7t495XkB5oSzS9023XclVBgp52qX67zCMYNOKQc',
            onload: () => {

            },
            env: this.props.upEnv
        });
        window.STL.render({
            container: "satelliteOrdersWidget",
            onload: () => {

            },
            route: "placed",
            height: "600px"
        })

    }
    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            style="loadingProgress"
                        />
                    ) : (
                            <>
                                <div className="row mx-0 justify-content-between align-items-center">
                                    <div className="">
                                        <span className="categorycol"> Satellite Orders </span>
                                    </div>
                                </div>
                                <div className="mt-3" id='satelliteOrdersWidget'>

                                </div>
                            </>
                        )}
                </div>
            </>

        )
    };
}
