import React, { Component } from 'react';
import { toast } from "react-toastify";
import { AuthContext, DataContext } from "../contextProvider";
import { CategoriesContext, CategoriesProvider } from "../context";
import Loader from "react-loader-spinner";
import { Form, Text, TextArea, Select, Radio, RadioGroup, Checkbox } from "informed";
import { SubCategoriesProvider, SubCategoriesContext } from "../context";
import { ItemRoutesProvider, ItemRoutesContext } from "../context/ItemRoutesContext";
import $ from "jquery";
import {
  MenuItemsContextProvider,
  MenuItemsContext
} from "../context/MenuItemsContext";
import {
  validatePhone,
  validateEmail,
  validateText,
  validateInput,
  validateStrictText,
  validateWholeNumber,
  validateStrictGst,
  validateStrictGstdescription
} from "../utilities";
import { submitMenuItemForm } from "../services"
import { Multiselect } from "multiselect-react-dropdown";
import { DELETE_IMG } from "../assets/images";
export default (props) => {
  return (
    <AuthContext.Consumer>
      {({ CONSTANTS, user, authToken, isSuperAdmin, logOut }) => {
        return (
          <MenuItemDetails
            {...props}
            user={user}
            authToken={authToken}
            CONSTANTS={CONSTANTS}
            isSuperAdmin={isSuperAdmin}
            logOut={logOut}
          />
        );
      }}
    </AuthContext.Consumer>
  );
};

class MenuItemDetails extends Component {
  constructor(props) {
    super(props);
    this.rFormRef = React.createRef();
  }
  static contextType = AuthContext;

  state = {
    menuItemDetails: null,
    baseUrl: "",
    Routes: this.props.CONSTANTS.Routes || [],
    foodTypes: this.props.CONSTANTS.foodTypes || [],
    aggregatorPlatforms: "",
    includedPlatforms: [],
    fullfillmentModes: "",
    itemFModes: [],
    platformImgs: [],
    platTImages: [],
    itemTags: []
  }
  componentWillMount() {
    let locationState = this.props.location.state;
    let menuItemId = locationState ? locationState.menuItemId : null;
    let platformList = [];
    let list = [];
    list = Object.keys(this.context.CONSTANTS.aggregatorPlatforms);
    for (var i = 0; i < list.length; i++) {
      platformList.push({
        label: list[i],
        value: i + 1
      });
    }
    let fModeList = [];
    let fList = [];
    fList = Object.keys(this.context.CONSTANTS.fullfillmentModes);
    for (var i = 0; i < fList.length; i++) {
      fModeList.push({
        label: fList[i],
        value: i + 1
      });
    }
    this.setState({
      menuItemId,
      baseUrl: this.props.CONSTANTS.apiBasePath.split("/api")[0],
      aggregatorPlatforms: platformList || [],
      fullfillmentModes: fModeList || [],
    });

    menuItemId && this._loadItemDetails(menuItemId);

  }
  includedPlatsCallBackFunc = (inPlats) => {
    this.setState({ includedPlatforms: inPlats })
  }
  fModesCallBackFunc = (fmode) => {
    this.setState({ itemFModes: fmode })
  }
  PlatsImgsCallBackFunc = (platImgs) => {
    this.setState({ platformImgs: platImgs })
  }
  render() {
    let editMode =
      this.props.location.state && this.props.location.state.menuItemId;
    return (
      <div className="content of-auto">
        <div className="mx-0">
          <div className="categorycol d-flex align-items-center">
            <i
              className="fa fa-long-arrow-left"
              style={{
                marginRight: "1rem",
                fontSize: "20px",
                cursor: "pointer"
              }}
              aria-hidden="true"
              onClick={() => this.props.history.goBack()}
            />
            Menu Item
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <label
                id="img_preview_label"
                htmlFor="img_preview"
                style={{
                  backgroundImage: this.state.menuItemImagePath
                    ? `url(${this.state.baseUrl +
                    this.state.menuItemImagePath})`
                    : `url(${this.state.displayImage})`
                }}
              >
                <input
                  type="file"
                  className="img_preview"
                  accept="image/*"
                  id="img_preview"
                  onChange={this.previewImage.bind(this)}
                />
                {!(
                  this.state.menuItemImagePath || this.state.displayImage
                ) && (
                    <i
                      className="fa fa-upload img-upload-icon"
                      aria-hidden="true"
                    />
                  )}
              </label>
              {this.state.menuItemId ? <img
                alt=""
                src={DELETE_IMG}
                style={{ cursor: "pointer", backgroundColor: "white", height: "25px", width: "25px" }}
                className="img-fluid"
                onClick={() => this.deleteImage()
                }
              /> : null}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6" />
            <div className="col-12 col-sm-12 col-md-6 col-lg-6" />
          </div>
          <CategoriesProvider {...this.props}>
            <SubCategoriesProvider {...this.props}>
              <ItemRoutesProvider {...this.props}>
                <CategoriesContext.Consumer>
                  {({ categories, fetchProgress, noDataMsg }) => {
                    return (
                      <SubCategoriesContext.Consumer>
                        {({ subCategories }) => (
                          <ItemRoutesContext.Consumer>
                            {({ ItemRoutes }) => (
                              <EditMenuItemModal
                                categories={categories}
                                subCategories={subCategories}
                                ItemRoutes={ItemRoutes}
                                ref={this.rFormRef}
                                setIncludedPlats={this.includedPlatsCallBackFunc.bind(this)}
                                setfullfillmentmodes={this.fModesCallBackFunc.bind(this)}
                                setPlatformTags={this.PlatsImgsCallBackFunc.bind(this)}
                                setPlatformImages={(platTagImg) => this.setState({ platTImages: platTagImg })}
                                setItemTags={(tags) => this.setState({ itemTags: tags })}
                                getFormValues={this._getFormValues.bind(this)}
                                Routes={this.state.Routes}
                                initialValues={{
                                  iName: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemTitle
                                    : "",
                                  iShortName: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemShortTitle
                                    : "",
                                  iCategory: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.categoryId
                                    : "",
                                  iSubCategory: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.subCategoryId
                                    : "",
                                  iPrice: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemPrice.toFixed(2)
                                    : "",
                                  iAggPrice: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemAggPrice
                                    : "",
                                  iCgst: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemCgst
                                    : "",
                                  iSgst: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemSgst
                                    : "",
                                  iQuantity: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemQuantity
                                    : "",
                                  iDiscount: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemDiscount
                                    : "",
                                  iFoodType: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemType
                                    : "",
                                  iRoute: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemRouteId
                                    : "",
                                  // iStatus: this.state.menuItemDetails ?
                                  //   this.state.menuItemDetails.itemState
                                  //   : "",
                                  iMarkupPrice: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemMarkupPrice
                                    : "",
                                  iWeight: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemWeight
                                    : "",
                                  iSortOrder: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemSortOrder
                                    : "",
                                  iCurrentStock: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemCurrentStock
                                    : "",
                                  iDescription: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemDescription
                                    : "",
                                  iDisableItemDiscount: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.disableItemDiscount
                                    : "",
                                  iRecommended: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemRecommended
                                    : "",
                                  iSoldAtStore: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemSoldAtStore
                                    : "",
                                  iPosStatus: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemPosStatus
                                    : "",
                                  iOnlineStatus: this.state.menuItemDetails ?
                                    this.state.menuItemDetails.itemOnlineStatus
                                    : "",
                                }}
                                foodTypes={this.state.foodTypes}
                                aggregatorPlatforms={this.state.aggregatorPlatforms}
                                includedPlatInitVal={this.state.includedPlatforms}
                                fullfillmentModes={this.state.fullfillmentModes}
                                fullfillmentModeInitVal={this.state.itemFModes}
                                platImgsList={this.state.platTImages}
                                itemTags={this.state.itemTags}
                                editMode={editMode}
                                buildClassName={this.buildClassName.bind(this)}
                              />
                            )}
                          </ItemRoutesContext.Consumer>
                        )}
                      </SubCategoriesContext.Consumer>
                    );
                  }}
                </CategoriesContext.Consumer>
              </ItemRoutesProvider>
            </SubCategoriesProvider>
          </CategoriesProvider>
          <div className="text-right">
            <button
              type="button"
              className="savebtn"
              onClick={() => this._submitMenuItemDetails(!!editMode)}
            >
              {this.state.saveProgress ||
                (this.state.uploadProgress && (
                  <i className="fa fa-spinner fa-spin fa-fw"></i>
                ))}{" "}
              {editMode ? "Save Changes" : "Save"}
            </button>
          </div>
        </div>
      </div>
    );
  }
  async _submitMenuItemDetails(edit) {
    if (this.state.saveProgress || this.state.uploadProgress) {
      return false;
    }

    this.rFormRef.current._formApi.submitForm();
    let formInValid = Object.keys(this.rFormRef.current._formApi.getState().errors).length
    if (formInValid) {
      return false;
    }
    let formValues = this.rFormRef.current._formApi.getState().values

    const { CONSTANTS, authToken, user } = this.context;
    let selectedIncludedPlats = this.state.includedPlatforms;
    let inPlatforms = []
    for (let i = 0; i < selectedIncludedPlats.length; i++) {
      if (selectedIncludedPlats[i].value) {
        inPlatforms[i] = selectedIncludedPlats[i].label;
      } else {
        inPlatforms[i] = selectedIncludedPlats[i]
      }
    }
    let selectedFullfillmentMode = this.state.itemFModes;
    let ffModes = []
    for (let i = 0; i < selectedFullfillmentMode.length; i++) {
      if (selectedFullfillmentMode[i].value) {
        ffModes[i] = selectedFullfillmentMode[i].label;
      } else {
        ffModes[i] = selectedFullfillmentMode[i]
      }
    }
    if (!ffModes.length) {
      return toast.error(`Please Select the fullfillment modes`, {
        autoClose: 4000
      })
    }
    this.setState({
      saveProgress: true
    });
    let body = {
      user_id: user.userId,
      item_image_url: this.state.menuItemImagePath,
      item_title: formValues.iName.trim(),
      item_short_title: formValues.iShortName.trim(),
      item_quantity: formValues.iQuantity.trim(),
      item_description: formValues.iDescription.trim(),
      item_type: formValues.iFoodType,
      item_price: formValues.iPrice,
      item_agg_price: formValues.iAggPrice,
      item_category_id: formValues.iCategory,
      item_sub_category_id: formValues.iSubCategory,
      // item_cgst: formValues.iCgst,
      // item_sgst: formValues.iSgst,
      // item_discount: formValues.iDiscount,
      item_markup_price: formValues.iMarkupPrice,
      item_weight: formValues.iWeight,
      item_sort_order: formValues.iSortOrder,
      item_current_stock: formValues.iCurrentStock,
      item_sold_at_store: formValues.iSoldAtStore,
      item_recommended: formValues.iRecommended,
      item_disable_discount: formValues.iDisableItemDiscount,
      item_route: formValues.iRoute,
      item_state: formValues.iStatus,
      item_id: this.state.menuItemId,
      item_pos_status: formValues.iPosStatus,
      item_online_status: formValues.iOnlineStatus,
      item_included_platforms: inPlatforms,
      item_fulfillment_modes: ffModes,
      item_old_img_url: this.state.oldImagePath,
      item_platform_images: this.state.platTImages,
      item_tags: this.state.itemTags
    }
    let apiRoute = CONSTANTS.apiRoutes.createMenuItem;
    if (edit) {
      body.item_id = this.props.location.state.menuItemId;
      apiRoute = CONSTANTS.apiRoutes.editMenuItem;
    }
    const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: edit ? "PUT" : "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));


    if (!savedResponse) {
      this.setState({
        saveProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      this.setState({
        saveProgress: false,
        msg: "An Error Occured while saving the changes"
      });
      return toast.error("An Error Occured while saving the changes");
    }
    if (!savedResponseResult.success) {
      this.setState({
        saveProgress: false,
        msg: "An Error Occured while saving the changes"
      });
      return toast.error("An Error Occured while saving the changes");

    }
    this.setState({
      saveProgress: false
    });
    toast.success(
      edit
        ? "Saved changes to " + formValues.iName + "."
        : "Added Menu Item " + formValues.iName,
      { autoClose: 3000 }
    );
    this.props.history.goBack();

  }

  previewImage(e) {
    let image = e.nativeEvent.target.files[0];
    if (image.type.split("/")[0] != "image") {
      e.target.value = null;

      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayImage: URL.createObjectURL(image)
      },
      async err => {
        //upload image
        let data = new FormData();
        data.append("file", image);
        data.append("filename", "myfile");
        let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
        this.setState({
          uploadProgress: true
        });
        let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));
        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));
        this.setState({
          uploadProgress: false
        });
        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          menuItemImagePath: response.data,
          displayImage: "",
          oldImagePath: this.state.menuItemImagePath
        });
      }
    );
  }
  _getFormValues(formName, values) {
    // this.setState({
    //   // [formName]: values
    // });
  }
  buildClassName(err, enableInput) {
    let c = "w-100 restarantInput";
    !enableInput && (c += " restarantInput--disabled");
    err && (c += " input_error");
    return c;
  }
  async _loadItemDetails(itemId) {
    if (this.state.fetchProgress) return false;
    this.setState({
      fetchProgress: true
    });
    let fetchResponse = await fetch(
      this.context.CONSTANTS.apiBasePath +
      this.context.CONSTANTS.apiRoutes.getItemDetails +
      `?item_id=${itemId}&user_id=${this.context.user.userId}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!fetchResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const itemData = await fetchResponse.json();
    //@TODO - handle error
    if (!itemData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("Could not get item details.");
    }
    let list = [];
    list = this.context.CONSTANTS.aggregatorPlatforms
    let inPlatList = itemData.data.itemIncludedPlatforms ? itemData.data.itemIncludedPlatforms.itemIncludedPlatforms : [];
    let newInPlatsList = [];
    if (inPlatList != null) {
      inPlatList.map(c => {
        newInPlatsList.push({
          label: list[c].platName,
          value: list[c].platId
        });
      }
      )
    }
    else {
      inPlatList = [];
    }
    let fList = [];
    fList = this.context.CONSTANTS.fullfillmentModes
    let fModesList = itemData.data.itemFulfillmentModes ? itemData.data.itemFulfillmentModes.itemFulfillmentModes : [];
    let newFModesList = [];
    if (fModesList != null) {
      fModesList.map(c => {
        newFModesList.push({
          label: fList[c].modeDescription,
          value: fList[c].modeId
        });
      }
      )
    }
    else {
      fModesList = [];
    }

    let pTagList = []
    pTagList = itemData.data.itemPlatformImages ? itemData.data.itemPlatformImages.itemPlatformImages : [];
    let newpTagList = [];
    if (pTagList != null) {
      pTagList.map(c => {
        newpTagList.push({
          platImgName: c.platImgName,
          value: c.value
        })
      })
    }

    //fill form values
    this.setState(
      {
        menuItemDetails: itemData.data,
        menuItemImagePath: itemData.data.itemImages[0],
        includedPlatforms: newInPlatsList,
        itemFModes: newFModesList,
        platTImages: newpTagList,
        itemTags: itemData.data.itemTags || []
      },
      err => {
        if (err) return console.error(err);
        let rData = this.state.menuItemDetails;
        //item details
        this.rFormRef.current._formApi.setValue("iName", rData.itemTitle);
        this.rFormRef.current._formApi.setValue("iShortName", rData.itemShortTitle);
        this.rFormRef.current._formApi.setValue("iCategory", rData.categoryId);
        this.rFormRef.current._formApi.setValue("iFoodType", rData.itemType);
        this.rFormRef.current._formApi.setValue("iQuantity", rData.itemQuantity);
        this.rFormRef.current._formApi.setValue("iPrice", rData.itemPrice);
        this.rFormRef.current._formApi.setValue("iAggPrice", rData.itemAggPrice);
        // this.rFormRef.current._formApi.setValue("iCgst", rData.itemCgst)
        // this.rFormRef.current._formApi.setValue("iSgst", rData.itemSgst)
        // this.rFormRef.current._formApi.setValue("iDiscount", rData.itemDiscount)
        this.rFormRef.current._formApi.setValue("iDescription", rData.itemDescription)
        this.rFormRef.current._formApi.setValue("iSubCategory", rData.subCategoryId)
        // this.rFormRef.current._formApi.setValue("iStatus", rData.itemState)
        this.rFormRef.current._formApi.setValue("iRoute", rData.itemRouteId)
        this.rFormRef.current._formApi.setValue("iMarkupPrice", rData.itemMarkupPrice)
        this.rFormRef.current._formApi.setValue("iCurrentStock", rData.itemCurrentStock)
        this.rFormRef.current._formApi.setValue("iSortOrder", rData.itemSortOrder)
        this.rFormRef.current._formApi.setValue("iWeight", rData.itemWeight)
        this.rFormRef.current._formApi.setValue("iSoldAtStore", rData.itemSoldAtStore)
        this.rFormRef.current._formApi.setValue("iRecommended", rData.itemRecommended)
        this.rFormRef.current._formApi.setValue("iPosStatus", rData.itemPosStatus)
        this.rFormRef.current._formApi.setValue("iOnlineStatus", rData.itemOnlineStatus)
        this.rFormRef.current._formApi.setValue("iDisableItemDiscount", rData.itemDisableDiscount)
      }
    );
  }
  deleteImage = async () => {
    let response = await fetch(
      this.context.CONSTANTS.apiBasePath +
      this.context.CONSTANTS.apiRoutes.deleteCategoryImage,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
          item_id: this.state.menuItemId,
          user_id: this.context.user.userId,
        })
      }
    ).catch(err => console.error(err));
    if (!response) {
      return toast.error("An Error Occured.");
    }
    const responseData = await response.json();
    if (!responseData.success) {
      return toast.error("An Error Occured.");
    }
    this.setState({
      menuItemImagePath: ""
    })
    return toast.success("successfully deleted");
  }
}

class EditMenuItemModal extends Component {
  static contextType = MenuItemsContext;
  static contextType = AuthContext;
  state = {
    categories: this.props.categories || [],
    subCategories: this.props.subCategories,
    ItemRoutes: this.props.ItemRoutes,
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues,
    files: [],
    selectedFile: "",
    platImgData: "",
    platImgName: "",
    value: "",
    platformImages: [{ platImgName: "", value: "" }]
  }

  componentDidMount() {
    this.setState({
      formValues: this.props.initialValues
    });
  }
  onSelect_includedPlats = (selectedList, selectedItem) => {

    let selectedValues_included = [];
    let i = 0;
    for (i = 0; i < selectedList.length; i++) {
      selectedValues_included.push({ label: selectedList[i].label, value: selectedList[i].value })
    }
    this.props.setIncludedPlats(selectedValues_included)
  }
  onRemove_includedPlats = (selectedList) => {
    this.props.setIncludedPlats(selectedList)
  }
  // fullmodesss
  onSelect_fModes = (selectedFList, selectedItem) => {

    let selectedValues_fModes = [];
    let i = 0;
    for (i = 0; i < selectedFList.length; i++) {
      selectedValues_fModes.push({ label: selectedFList[i].label, value: selectedFList[i].value })
    }
    this.props.setfullfillmentmodes(selectedValues_fModes)
  }
  onRemove_fModes = (selectedFList) => {
    this.props.setfullfillmentmodes(selectedFList)
  }
  onSelect_platImg = (selectedpList, selectedItem) => {

    let selectedValues_platImgs = [];
    let i = 0;
    for (i = 0; i < selectedpList.length; i++) {
      selectedValues_platImgs.push({ label: selectedpList[i].label, value: selectedpList[i].value })
    }
    this.props.setPlatformTags(selectedValues_platImgs)
  }
  onRemove_PlatsImgs = (selectedpList) => {
    this.props.setPlatformTags(selectedpList)
  }
  // 
  render() {
    let baseUrl = this.context.CONSTANTS.apiBasePath.split("/api")[0];
    return (
      <div id="menuItemDetails">
        <div className="row text-capitalize py-3 mt-2">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Item details</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {
            return (
              <>
                <div className="row text-capitalize fsize border_bottom">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="iName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iName}
                            validate={this._validateItemTitle.bind(this)}
                            id="iEdit__iName"
                            placeholder="Enter item Name"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iName,
                              this.state.enableInput
                            )}
                            validateOnBlur
                            validateOnChange
                          />
                          {formState.errors.iName && (
                            <span className="input_error">
                              {formState.errors.iName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iShortName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Short Name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="iShortName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iShortName}
                            validate={this._validateItemShortTitle.bind(this)}
                            id="iEdit__iShortName"
                            placeholder="Enter item short name"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iShortName,
                              this.state.enableInput
                            )}
                            validateOnBlur
                            validateOnChange
                          />
                          {formState.errors.iShortName && (
                            <span className="input_error">
                              {formState.errors.iShortName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iCategory"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Category <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Select
                            id="iEdit__iCategory"
                            field="iCategory"
                            validate={val =>
                              this._validateSelectInput(val, "Category")
                            }
                            disabled={!this.state.enableInput}
                            validateOnBlur
                            validateOnChange
                            initialValue={this.state.formValues.iCategory}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iCategory,
                              this.state.enableInput
                            )}
                          >
                            <option value="" disabled>
                              - Select Category -
                            </option>
                            {this.props.categories.length
                              ? this.props.categories.filter(ctg => {
                                return ctg.categoryParentId === null;
                              }).map((c, idx) => {
                                return (
                                  <option
                                    key={String(idx)}
                                    value={c.categoryId}
                                  >
                                    {c.categoryName}
                                  </option>
                                );
                              })
                              : null}
                          </Select>
                          {formState.errors.iCategory && (
                            <span className="input_error">
                              {formState.errors.iCategory}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iSubCategory"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Sub Category <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Select
                            id="iEdit__iSubCategory"
                            field="iSubCategory"
                            validate={val =>
                              this._validateSelectInput(val, "Sub Category")
                            }
                            disabled={!this.state.enableInput}
                            validateOnBlur
                            validateOnChange
                            initialValue={this.state.formValues.iSubCategory}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iSubCategory,
                              this.state.enableInput
                            )}
                          >
                            <option value="" disabled>
                              - Select sub category -
                            </option>
                            {this.props.categories.length
                              ? this.props.categories.filter(ctg => {
                                return ctg.categoryParentId !== null && ctg.categoryParentId
                              }).map((c, idx) => {
                                return (
                                  <option
                                    key={String(idx)}
                                    value={c.categoryId}
                                  >
                                    {c.categoryName}
                                  </option>
                                );
                              })
                              : null}
                          </Select>
                          {formState.errors.iSubCategory && (
                            <span className="input_error">
                              {formState.errors.iSubCategory}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iPrice"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Price <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">

                          <Text
                            validate={val => {
                              if (formState.touched.iPrice && !val) {
                                return "Please Entera valid price";
                              }
                              if (isNaN(val)) {
                                return "Please Enter a valid price.";
                              }
                              if (Number(val) > 10000) {
                                return "Price cannot be more than 10000 ";
                              }
                              let notPositive = validateWholeNumber(
                                val,
                                "Item Price"
                              );
                            }}
                            initialValue={this.state.formValues.iPrice}
                            disabled={!this.state.enableInput}
                            validateOnBlur
                            validateOnChange
                            field="iPrice"
                            type="text"
                            placeholder="Enter item price"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iPrice,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iPrice && (
                            <div className="input_error">
                              {formState.errors.iPrice}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iAggPrice"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Aggregator Price <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={val => {
                              if (val && isNaN(val)) {
                                return "Please Enter a valid Agg price.";
                              }
                              if (Number(val) > 10000) {
                                return " Agg Price cannot be more than 10000 ";
                              }
                              let notPositive = validateWholeNumber(
                                val,
                                "Item Price"
                              );
                            }}
                            initialValue={this.state.formValues.iAggPrice}
                            disabled={!this.state.enableInput}
                            validateOnBlur
                            validateOnChange
                            field="iAggPrice"
                            type="text"
                            placeholder="Enter item Aggregator price"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iAggPrice,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iAggPrice && (
                            <div className="input_error">
                              {formState.errors.iAggPrice}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iCgst"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          CGST <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={val => {
                              if (!val && !formState.touched.iCgst) {
                                return;
                              }

                            }}
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iCgst}
                            validateOnBlur
                            validateOnChange
                            field="iCgst"
                            type="text"
                            placeholder="Enter CGST tax %"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iCgst,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iCgst && (
                            <div className="input_error">
                              {formState.errors.iCgst}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iSgst"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          SGST <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={val => {
                              if (!val && !formState.touched.iSgst) {
                                return;
                              }

                            }}
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iSgst}
                            validateOnBlur
                            validateOnChange
                            field="iSgst"
                            type="text"
                            placeholder="Enter SGST tax %"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iSgst,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iSgst && (
                            <div className="input_error">
                              {formState.errors.iSgst}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iQuantity"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Quantity <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="iQuantity"
                            validate={val => {
                              if (formState.touched.iQuantity && !val) {
                                return "Please Enter quantity description / units";
                              }

                              if (
                                formState.touched.iQuantity &&
                                val.length > 30
                              ) {
                                return "Quantity description cannot be longer than 30 characters.";
                              }
                            }}
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iQuantity}
                            validateOnBlur
                            validateOnChange
                            //@todo - what is this ?
                            placeholder="Quantity (1 | Family Pack | 6pcs.)"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iQuantity,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iQuantity && (
                            <div className="input_error">
                              {formState.errors.iQuantity}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iDiscount"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Discount <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={val => {
                              if (!val && !formState.touched.iDiscount) {
                                return;
                              }

                            }}
                            validateOnBlur
                            validateOnChange
                            field="iDiscount"
                            type="text"
                            placeholder="Enter discount %"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iDiscount}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iDiscount,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iDiscount && (
                            <div className="input_error">
                              {formState.errors.iDiscount}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iFoodType"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Food Type <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Select
                            field="iFoodType"
                            validate={val =>
                              this._validateSelectInput(val, "Food Type")
                            }

                            validateOnBlur
                            validateOnChange
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iFoodType}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iFoodType,
                              this.state.enableInput
                            )}
                          >
                            <option value="" disabled>
                              - Select Food Type -
                            </option>
                            {Object.keys(this.props.foodTypes).map((c, idx) => {
                              let t = this.props.foodTypes[c];
                              return (
                                <option
                                  key={t.typeId}
                                  value={t.typeId}
                                >
                                  {t.typeTitle}
                                </option>
                              );
                            })
                            }
                          </Select>

                          {formState.errors.iFoodType && (
                            <div className="input_error">
                              {formState.errors.iFoodType}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iRoute"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Route <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Select
                            field="iRoute"
                            validate={val => {
                              return this._validateSelectInput(
                                val,
                                "item route name"
                              );
                            }}
                            validateOnBlur
                            validateOnChange
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iRoute}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iRoute,
                              this.state.enableInput
                            )}
                          >
                            <option value="">
                              - Select ItemRouteName -
                            </option>
                            {this.props.ItemRoutes.length
                              ? this.props.ItemRoutes
                                .map((c, idx) => {
                                  return (
                                    <option
                                      key={String(idx)}
                                      value={c.itemRouteId}
                                    >
                                      {c.itemRouteName}
                                    </option>
                                  );
                                })
                              : null}
                          </Select>
                          {formState.errors.iRoute && (
                            <div className="input_error">
                              {formState.errors.iRoute}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iStatus"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Status <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Select
                            field="iStatus"
                            validate={val =>
                              this._validateSelectItemState(val, "ItemState")
                            }
                            validateOnBlur
                            validateOnChange
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iStatus}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iStatus,
                              this.state.enableInput
                            )}
                          >
                            <option value="" disabled>
                              - Select Item Status -
                                </option>
                            <option >Available</option>
                            <option>Not Available</option>
                          </Select>
                          {formState.errors.iStatus && (
                            <div className="input_error">
                              {formState.errors.iStatus}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iMarkupPrice"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Markup Price <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={this.validateItemMarkUpPrice.bind(this)}
                            validateOnBlur
                            validateOnChange
                            field="iMarkupPrice"
                            type="text"
                            placeholder="Enter item markup price"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iMarkupPrice}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iMarkupPrice,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iMarkupPrice && (
                            <div className="input_error">
                              {formState.errors.iMarkupPrice}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iWeight"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Weight(gm) <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={val => {
                              if (val && isNaN(val)) return "please input a valid number";
                              if (Number(val) > 10000) {
                                return "weight cannot be more than 10000 gm ";
                              }
                              if (Number(val) < 0) {
                                return "weight cannot be lessthan 0 gm";
                              }
                              let notPositive = validateWholeNumber(
                                val,
                                "Item Weight"
                              );
                            }}
                            validateOnBlur
                            validateOnChange
                            field="iWeight"
                            type="text"
                            placeholder="Enter item Weight"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iWeight}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iWeight,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iWeight && (
                            <div className="input_error">
                              {formState.errors.iWeight}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iSortOrder"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Sort Order <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={val => {

                              if (Number(val) > 1000) {
                                return "sort order cannot be more than 1000 ";
                              }
                              if (val && !new RegExp(/^[0-9]+$/).test(val)) return "please input a valid number";
                              let notPositive = validateWholeNumber(
                                val,
                                "Item Sort Order"
                              );
                            }}
                            validateOnBlur
                            validateOnChange
                            field="iSortOrder"
                            type="text"
                            placeholder="Enter item sort order"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iSortOrder}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iSortOrder,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iSortOrder && (
                            <div className="input_error">
                              {formState.errors.iSortOrder}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iCurrentStock"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Current stock <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            validate={val => {

                              if (Number(val) > 1000) {
                                return "stock cannot be more than 1000 and cannot have more than 2 decimal points.";
                              }
                              if (val && !new RegExp(/^[0-9,-]+$/).test(val)) return "please input a valid number";
                              let notPositive = validateWholeNumber(
                                val,
                                "Item Current Stock"
                              );
                            }}
                            validateOnBlur
                            validateOnChange
                            field="iCurrentStock"
                            type="text"
                            placeholder="Enter item Current stock"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iCurrentStock}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iCurrentStock,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.iCurrentStock && (
                            <div className="input_error">
                              {formState.errors.iCurrentStock}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__idisableItemDiscount"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Disable Item Discount<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iDisableItemDiscount}
                            field="iDisableItemDiscount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iRecommended"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Recommended<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iRecommended}
                            field="iRecommended"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iSoldAtStore"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Sold At Store<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iSoldAtStore}
                            field="iSoldAtStore"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iRecommended"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Pos-status<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iSoldAtStore}
                            field="iPosStatus"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iSoldAtStore"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Online-status<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iOnlineStatus}
                            field="iOnlineStatus"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iIncludedPlatforms"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Included Platforms<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Multiselect
                            field="iIncludedPlatforms"
                            disable={!this.state.enableInput}
                            options={this.props.aggregatorPlatforms}
                            displayValue="label"
                            onSelect={this.onSelect_includedPlats}
                            onRemove={this.onRemove_includedPlats}
                            selectedValues={this.props.includedPlatInitVal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__ifullfillmentmodes"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Fullfillment Modes<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Multiselect
                            field="ifullfillmentmodes"
                            disable={!this.state.enableInput}
                            options={this.props.fullfillmentModes}
                            displayValue="label"
                            onSelect={this.onSelect_fModes}
                            onRemove={this.onRemove_fModes}
                            selectedValues={this.props.fullfillmentModeInitVal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row ">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iPlatformImages"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          platforms images <span className="">:</span>
                        </label>
                      </div>
                      <div><button className="btnaddimg" disabled={!this.state.enableInput} onClick={this._handleAddClick} >Add</button> </div>
                      {this.props.platImgsList && this.props.platImgsList.map((x, idx) => {
                        return (
                          <div className="col-6 col-md-6 col-lg-6 form-group">
                            <div
                              className="restarantInput d-flex justify-content-between align-items-center"
                            >
                              <select
                                field="iPlatformImages"
                                value={x.value}
                                onChange={(event) => this.handleTagChange(event, idx)}
                                validateOnBlur
                                validateOnChange
                                disabled={!this.state.enableInput}
                                selectedValues={this.props.includedPlatInitVal}
                                className={this.props.buildClassName.bind(this)(
                                  formState.errors.iPlatformImages,
                                  this.state.enableInput
                                )}
                              >
                                <option value="" label="" disabled>
                                  - Select tag -
                                </option>
                                {this.props.aggregatorPlatforms.length
                                  ? this.props.aggregatorPlatforms
                                    .map((c, i) => {
                                      return (
                                        <option
                                          key={String(i)}
                                          m={c.value}
                                          value={c.label}
                                        >
                                          {c.label}
                                        </option>
                                      );
                                    })
                                  : null}
                              </select>
                              <div className="col-3 col-md-3 col-lg-3 form-group" >
                                <div className="align-items-center">
                                  {x.value ? <input type="file"
                                    className={"hide", "pimg_preview", idx}
                                    accept="image/*"
                                    id={"pimg_preview", idx}
                                    onChange={(event) => this.handleImgChange(event)}
                                    disabled={!this.state.enableInput}
                                  /> : ""}
                                  {x.platImgName ?
                                    <div>
                                      <label
                                        id="pimg_preview_label"
                                        htmlFor="pimg_preview"
                                        style={{
                                          backgroundImage: x.platImgName ? `url(${baseUrl +
                                            x.platImgName})` : ""
                                        }}
                                      >
                                      </label> </div> : ""
                                  }
                                </div>
                              </div>
                              <div>
                                <button className="btnaddimg" disabled={!this.state.enableInput} onClick={() => this.handleRemoveClick(idx)}>Remove</button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row ">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iPlatformImages"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Tags <span className="">:</span>
                        </label>
                      </div>
                      <div><button className="btnaddimg" disabled={!this.state.enableInput} onClick={this._handleTagsAddClick} >Add</button> </div>
                      {this.props.itemTags && this.props.itemTags.map((x, idx) => {
                        return (
                          <div className="col-6 col-md-6 col-lg-6 form-group">
                            <div
                              className="restarantInput d-flex justify-content-between align-items-center"
                            >
                              <select
                                field="iitemTags"
                                value={x.tagName}
                                onChange={(event) => this.handleItemTagChange(event, idx)}
                                validateOnBlur
                                validateOnChange
                                disabled={!this.state.enableInput}
                                className={this.props.buildClassName.bind(this)(
                                  formState.errors.iitemTags,
                                  this.state.enableInput
                                )}
                              >
                                <option value="" label="" disabled>
                                  - Select tag -
                                </option>
                                <option value="default" label="" >
                                  default
                                </option>
                                <option value="zomato" label="" >
                                  zomato
                                </option>
                              </select>
                                <select
                                  field="iitemTagsValue"
                                  value={x.tagValue}
                                  onChange={(event) => this.handleItemTagValueChange(event, idx)}
                                  validateOnBlur
                                  validateOnChange
                                  disabled={!this.state.enableInput}
                                  className={this.props.buildClassName.bind(this)(
                                    formState.errors.iitemTagsValue,
                                    this.state.enableInput
                                  )}
                                >
                                  <option value="" label="" disabled>
                                    - Select Value -
                                  </option>
                                  <option value="packaged-good" label="" >
                                    packaged-good
                                  </option>
                                  <option value="info-mrp-item" label="" >
                                    info-mrp-item
                                  </option>
                                </select>
                                <button className="btnaddimg" disabled={!this.state.enableInput} onClick={() => this.handleRemoveItemTagClick(idx)}>Remove</button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iDescription"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Item Description <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <TextArea
                            rows={5}
                            field="iDescription"
                            validate={this._validateItemDesc.bind(this)}
                            validateOnBlur
                            validateOnChange
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.iDescription}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.iDescription,
                              this.state.enableInput
                            )}
                            placeholder="Enter item description"
                          />
                          {formState.errors.iDescription && (
                            <div className="input_error">
                              {formState.errors.iDescription}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </>

            )
          }}

        </Form>
      </div >
    )
  }
  handleTagChange(event, idx) {
    let tags = [...this.props.platImgsList]
    let findTag = tags.filter(i => i.value == event.target.value)
    if (!findTag.length) {
      tags[idx].value = (event.target.value)
    }
    this.props.setPlatformImages(tags)
  }
  handleItemTagChange(event, idx) {
    let tags = [...this.props.itemTags]
    let findTag = tags.filter(i => i.tagName == event.target.value)
    if (!findTag.length) {
      tags[idx].tagName = (event.target.value)
    }
    this.props.setItemTags(tags)
  }
  handleItemTagValueChange(event, idx) {
    let tags = [...this.props.itemTags]
    let findTag = tags.filter(i => i.tagValue == event.target.value)
    if (!findTag.length) {
      tags[idx].tagValue = (event.target.value)
    }
    this.props.setItemTags(tags)
  }

  handleImgChange(event) {
    let idx = Number(event.target.id)
    let tags = [...this.props.platImgsList]
    let selectedFile = event.target.files[0];
    if (selectedFile.type.split("/")[0] != "image") {
      event.target.value = null;
      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayImgs: URL.createObjectURL(selectedFile)
      },
      async err => {
        let data = new FormData();
        data.append("file", selectedFile);
        data.append("filename", "myfile");
        let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
        this.setState({
          uploadProgress: true
        });
        let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));
        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));
        this.setState({
          uploadProgress: false
        });
        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          platImgData: response.data,
          displayImgs: '',
        });
        tags[idx].platImgName = response.data
        this.props.setPlatformImages(tags)
      },
    )

  }
  _validateSelectItemState(val, name) {
    if (!val) {
      return `Select ${name}.`;
    }
  }
  _validateSelectInput(val, name) {
    if (!val) {
      return `Select ${name}.`;
    }
  }
  _validateItemDesc(desc) {
    // if (!desc) {
    //   //this is the first focus, do not validate.
    //   return false;
    // }
    desc = desc && desc.trim();
    if (desc && desc.length < 10) {
      return "Description must be atleast 10 Characters long";
    }
    if (desc.length > 500) {
      return "Description must not be larger than 400 characters";
    }
  }
  _validateItemTitle(title) {
    if (!title) {
      return `Enter a Item Name.`;
    }
    title = title && title.trim();
    if (!/[a-zA-Z]*/.test(title)) {
      return "Enter a valid Item Name.";
    }
    if (!title || title.length < 3) {
      return "Item name must have atleast 3 characters.";
    }

    if (!title || title.length > 30) {
      return "Item name cannot have more than 30 characters.";
    }
  }
  _validateItemShortTitle(title) {
    if (!title) {
      return `Enter a Item short Name.`;
    }
    title = title && title.trim();
    if (!/[a-zA-Z]*/.test(title)) {
      return "Enter a valid Item Name.";
    }
    if (!title || title.length < 3) {
      return "Item  short name must have atleast 3 characters.";
    }

    if (!title || title.length > 12) {
      return "Item short name cannot have more than 12 characters.";
    }
  }
  validateItemMarkUpPrice(val) {
    if (val) {
      if (isNaN(val)) return "please input a valid number";
      if (Number(val) < this._formApi.getState().values.iAggPrice) {
        return "Price Cannot be less than item Agg price";
      }
      if (Number(val) > 10000) {
        return "Price cannot be more than 10000 and cannot have more than 2 decimal points.";
      }
    }
  }
  _setFormApi(formApi) {
    this._formApi = formApi;
  }

  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("clear errors first");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }

  _handleAddClick = () => {
    let tags = [...this.props.platImgsList, { platImgName: "", value: "" }]
    this.props.setPlatformImages(tags);
  }
  _handleTagsAddClick = () => {
    let tags = [...this.props.itemTags, { tagName: "", tagValue: "" }]
    this.props.setItemTags(tags);
  }
  handleRemoveClick = (i) => {
    let tags = [...this.props.platImgsList];
    tags.splice(i, 1);
    this.props.setPlatformImages(tags)
  }
    handleRemoveItemTagClick = (i) => {
    let tags = [...this.props.itemTags];
    tags.splice(i, 1);
    this.props.setItemTags(tags)
  }

}