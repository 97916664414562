import React, { Component, PureComponent } from "react";
import { fetchSubCategories } from "../services";
import { toast } from "react-toastify";

export const SubCategoriesContext = React.createContext();
export class SubCategoriesProvider extends PureComponent {
  state = {
    subCategories: [],
  };

  
  async componentWillMount(){
    const subCategoriesResult = await fetchSubCategories({
      CONSTANTS: this.props.CONSTANTS,
      userId: this.props.user.userId,
      limit: 0,
      offset: 0,
      authToken: this.props.authToken
    })
    .catch(err => console.error(err));
    if(!subCategoriesResult){
      let msg = "An Error Occured while fetching sub-categories.";
      this.setState({
         fetchProgress: false,
         noDataMsg: msg
       });
       return toast.error(msg);
   }

   if(!subCategoriesResult.success){
     let msg = subCategoriesResult.msg;
     this.setState({
       fetchProgress: false,
       noDataMsg: msg,
       subCategories:[]
     });
     return toast.error(msg);
   }

   this.setState({
     subCategories:subCategoriesResult.data
   })
  }

  render() {
    return (
      <SubCategoriesContext.Provider value={{subCategories:this.state.subCategories,fetchSubCategories:fetchSubCategories}}>
        {this.props.children}
      </SubCategoriesContext.Provider>
    );
  }
}