import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthContext, DataContext, DataProvider } from "../contextProvider";
import { throttleTime } from "../utilities";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import "jspdf-autotable";
import * as jsPDF from 'jspdf';
import _ from 'lodash';
import ReactExport from "react-data-export";
import ReactToExcel from "react-html-table-to-excel";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class TakeAwayOrders extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      currentPageNum: 1,
      totalRecords: 0,
      recordsPerPage: 0,
      fetchProgress: false,
      records: [],
      empRoleId: null,
      empTypeTitle: "",
      empTypeTitleSingular: "",
      saveProgress: false,
      saveFailMsg: "",
      deleteProgress: false,
      deleteFailMsg: "",
      fetchFailMsg: "No records",
      selectedRestarantId: 0,
      reports: [],
      reportType: "Daily",
      value: "By Sales",
      ordertypes: [],
      selectedOrderType: "",
      byItemsSelect: "ItemsByQty",
      itemId: "",
      itemsData: [],
      SelectedList: [],
      MasterChecked: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }
  componentDidMount() {
    this._getDailyReport()
    this._loadItemsData();

  }

  async _getDailyReport() {
    await this.setState({
      startDate: new Date(),
      endDate: new Date(),
      currentPageNum: 1,
    })
    this._fetchOrdersList()
    // this._loadOrderTypes();
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  handleOrderTypeChange(event) {
    this.setState({ selectedOrderType: event.target.value });
  }
  handleItemChange(event) {
    this.setState({ itemId: event.target.value })
  }
  exportPdf = (reportHeader, reportData, paymentTypeHeader, paymentTypeData, ordertypeHeader, orderTypesData, tablesHeader, tablesData) => {
    const pdf = new jsPDF('portrait', 'pt', 'A4');
    let date = Date(Date.now())
    let dd = date.toString();
    let selectcategory = this.state.value;
    let reportType = this.state.reportType;
    let totalAmount = 0;
    let TotalDiscounts = 0;
    let TotalTaxes = 0;
    let OrderTotals = 0;
    let TotalCharges = 0
    this.state.records.forEach((o) => {
      totalAmount += o.orderSummary.merchantReceivable || (o.orderTotal - o.orderDiscount + o.orderCgst + o.orderSgst + o.orderTax + (o.orderSummary.orderIgst || 0));
      TotalDiscounts += o.orderSummary.aggDiscounts || (o.orderDiscount);
      TotalCharges += o.orderSummary.aggCharges ? o.orderSummary.aggCharges : 0
      TotalTaxes += o.orderSummary.aggTaxes || (o.orderCgst + o.orderSgst + o.orderTax + (o.orderSummary.orderIgst || 0));
      OrderTotals += (o.orderSummary.merchantReceivable - o.orderSummary.aggTaxes - o.orderSummary.aggCharges + o.orderSummary.aggDiscounts) || (o.orderTotal)
    });
    pdf.text(`${selectcategory}:${this.state.startDate.toISOString().substring(0, 10)} to ${this.state.endDate.toISOString().substring(0, 10)}`, 50, 50)
    pdf.autoTable({
      head: [reportHeader],
      body: reportData.map(body => body),
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      startY: 60,
      margin: {
        top: 60
      },
      headerStyles: {
        lineWidth: 1,
        fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold',
      },
      bodyStyles: {
        lineWidth: 1,
        lineColor: [189, 195, 199]
      },
    })
    let ordertype = this.state.value == "By Sales" ? pdf.autoTable({
      head: [ordertypeHeader],
      body: orderTypesData.map(body => body),
      headerStyles: {
        lineWidth: 1, textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255]
      },
      bodyStyles: {
        lineWidth: 1,
        lineColor: [189, 195, 199]
      },
      theme: "plain",
    }) : null
    let payment = this.state.value == "By Sales" ? (pdf.autoTable({
      head: [paymentTypeHeader],
      body: paymentTypeData.map(body => body),
      headerStyles: {
        lineWidth: 1, textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255]
      },
      bodyStyles: {
        lineWidth: 1,
        lineColor: [189, 195, 199],
        margin: { top: 100, right: 14, bottom: 50, left: 14 },
      },
      theme: "plain",
    }),
      pdf.autoTable({
        head: [tablesHeader],
        body: tablesData.map(body => body),
        headerStyles: {
          lineWidth: 1, textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255]
        },
        bodyStyles: {
          lineWidth: 1,
          lineColor: [189, 195, 199],
          margin: { top: 100, right: 14, bottom: 50, left: 14 },
        },
        theme: "plain",
      }),
      pdf.autoTable({
        headerStyles: {
          lineWidth: 1, textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255]
        },
        bodyStyles: {
          lineWidth: 1,
          lineColor: [189, 195, 199],
          margin: { top: 100, right: 14, bottom: 50, left: 14 },
        },
        theme: "plain",
        head: [['OrderTotals', 'Amount']],
        body: [["TotalDiscounts ", `${TotalDiscounts.toFixed(2)}`],
        ["TotalTaxes", `${TotalTaxes.toFixed(2)}`],
        ["Total Charges", `${TotalCharges}`],
        ["Sub Totals ", `${OrderTotals.toFixed(2)}`],
        ["TotalAmount", `${totalAmount.toFixed(2)}`]],
      })) : null

    pdf.setFontSize(12)
    pdf.setFontStyle('bold')
    pdf.setFontStyle('italic')
    pdf.save("reports" + selectcategory + dd + ".pdf");
  }
  onMasterCheck(e) {
    let tempList = this.state.records;
    tempList.map((user) => (user.orderSummary.aggregatorOrder ? null : user.selected = e.target.checked));
    this.setState({
      MasterChecked: e.target.checked,
      records: tempList,
      SelectedList: this.state.records.filter((e) => e.selected),
    });
  }
  onItemCheck(e, item) {
    let tempList = this.state.records;
    tempList.map((user) => {
      if (user.orderId === item) {
        user.selected = e.target.checked;
      }
      return user;
    });

    const totalItems = this.state.records.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      records: tempList,
      SelectedList: this.state.records.filter((e) => e.selected),
    });
  }
  render() {
    let totalAmount = 0;
    let TotalDiscounts = 0;
    let TotalTaxes = 0;
    let OrderTotals = 0;
    let TotalCharges = 0
    let records = this.state.records;
    records = this.state.selectedOrderType ? this.state.records.filter(o => { return o.orderType == this.state.selectedOrderType }) : this.state.records;
    records.forEach((o) => {
      totalAmount += o.orderSummary.merchantReceivable || (o.orderTotal - o.orderDiscount + o.orderCgst + o.orderSgst + (o.orderSummary.orderIgst || 0) + o.orderTax);
      TotalDiscounts += o.orderSummary.aggDiscounts || (o.orderDiscount);
      TotalCharges += o.orderSummary.aggCharges ? o.orderSummary.aggCharges : 0
      TotalTaxes += o.orderSummary.aggTaxes || (o.orderCgst + o.orderSgst + o.orderTax + (o.orderSummary.orderIgst || 0));
      OrderTotals += (o.orderSummary.merchantReceivable - o.orderSummary.aggTaxes - o.orderSummary.aggCharges + o.orderSummary.aggDiscounts) || (o.orderTotal);
    });
    let subTotalsExcel = [
      { name: "Total Discounts", amount: TotalDiscounts.toFixed(2) },
      { name: "Total Taxes", amount: TotalTaxes.toFixed(2) },
      { name: "Total Charges", amount: TotalCharges.toFixed(2) },
      { name: "Sub Totals", amount: OrderTotals.toFixed(2) },
      { name: "Total Amount", amount: totalAmount.toFixed(2) }

    ]
    let paymentTypeHeader = ['Type', 'Amount'];
    let paymet = _.groupBy(records, "paymentMode");
    let paymentTypeData = [];
    let paymentExcel = []
    _.forEach(paymet, (itm, key) => {
      let temp = [];
      temp.push(itm[0].paymentMode)
      let qty = _.sumBy(itm, function (o) {
        return o.orderSummary.merchantReceivable ? o.orderSummary.merchantReceivable : (o.orderTotal + o.orderTax - o.orderDiscount + o.orderSummary.orderCgst + o.orderSummary.orderSgst + (o.orderSummary.orderIgst || 0))
      });
      temp.push(qty.toFixed(2));
      paymentTypeData.push(temp);
      let excelObj = { type: itm[0].paymentMode, amount: qty }
      paymentExcel.push(excelObj);
    })
    let ordertypeHeader = ['orderType', 'Total', 'count'];
    let orderType = _.groupBy(records, "orderType");
    let orderTypesData = [];
    let orderTypeExcel = [];
    _.forEach(orderType, (itm, key) => {
      let temp = [];
      itm[0].orderType ? temp.push(itm[0].orderType) : temp.push("Not Selected");
      let qty = _.sumBy(itm, function (o) { return o.orderSummary.merchantReceivable ? o.orderSummary.merchantReceivable : (o.orderTotal + o.orderTax - o.orderDiscount + o.orderSummary.orderCgst + o.orderSummary.orderSgst + (o.orderSummary.orderIgst || 0)) });
      temp.push(qty.toFixed(2));
      temp.push(itm.length);
      orderTypesData.push(temp);
      let objExcel = { orderType: itm[0].orderType ? itm[0].orderType : "Not Selected", Total: qty.toFixed(2), count: itm.length };
      orderTypeExcel.push(objExcel)
    })
    let tablesHeader = ['Table', 'Amount', "Count"];
    let tables = _.groupBy(records, "tableNumber");
    let tablesData = [];
    let tablesExcel = [];
    _.forEach(tables, (itm, key) => {
      let temp = [];
      if (itm[0].tableNumber) {
        temp.push(itm[0].tableNumber);
        let qty = _.sumBy(itm, function (o) {
          return o.orderSummary.merchantReceivable ? o.orderSummary.merchantReceivable : (o.orderTotal + o.orderTax - o.orderDiscount + (o.orderSummary.orderCgst || 0) + (o.orderSummary.orderSgst || 0) + (o.orderSummary.orderIgst || 0))
        });
        temp.push(qty.toFixed(2));
        temp.push(itm.length);
        tablesData.push(temp);
        tablesExcel.push({ Table: itm[0].tableNumber, Amount: qty.toFixed(2), Count: itm.length })
      }
    })
    let reportHeader = [];
    let reportData = [];
    let reportPrintData = [];
    let reportPrintHeader = [];
    let sumOfItems = 0;
    let reportSalesExcel = [];
    let reportsItemsExcel = []
    if (records) {
      // this.props.receptionistPastOrders[0] ? this.props.getRestarantDetails(this.props.receptionistPastOrders[0].restarantId, false) : null;
      if (this.state.value === 'By Items') {
        if (this.state.byItemsSelect === "ItemsByQty") {
          reportHeader = ['SNO', 'Item Name', 'count', 'Total', 'order Types'];
          reportPrintHeader = ['Item Name', 'Qty', 'Total'];
          let list = [];
          records.map(o => {
            o.orderSummary.cart.map(c => {
              c.orderType = o.orderType
            })
          });
          let byitems = records.filter(function (o, k) { list.push(...o.orderSummary.cart) });
          let ite = _.groupBy(list, function (l) {
            return l.itemId ? l.itemId : l.merchant_id
          });
          let count = 0;
          _.forEach(ite, (itm, key) => {
            let temp = [];
            let printObj = {};
            let itemTotal = 0;
            _.map(itm, function (o) {
              let optionsPrice = 0;
              o.options_to_add && o.options_to_add.reduce((option, i) => {
                optionsPrice += (i.price || i.optionPrice || 0);
                return optionsPrice
              }, 0)
              itemTotal += (optionsPrice + (o.itemPrice || o.price || 0)) * (o.qty || o.quantity);
            });
            count += 1;
            temp.push(count);
            printObj.itemName = itm[0].itemTitle || itm[0].title;
            temp.push(itm[0].itemTitle || itm[0].title);
            let qty = _.sumBy(itm, function (q) {
              return q.qty ? q.qty : q.quantity;
            })
            temp.push(qty);
            printObj.qty = qty;
            temp.push(itemTotal.toFixed(2));
            let itemOrdertype = _.groupBy(itm, "orderType");
            let orderTypes = [];
            _.map(itemOrdertype, (itm, key) => { orderTypes.push(key) });
            temp.push(orderTypes.join())
            printObj.total = itemTotal;
            sumOfItems += itemTotal;
            printObj.titles = reportPrintHeader;
            reportData.push(temp);
            reportPrintData.push(printObj);
            let excelObj = {
              SNO: count,
              ItemName: itm[0].itemTitle || itm[0].title,
              count: qty,
              Total: itemTotal.toFixed(2),
              orderTypes: orderTypes.join()
            };
            reportsItemsExcel.push(excelObj);
          });
        } else {
          reportHeader = ['SNO', 'Name', 'Date', 'count', 'Total'];
          let list = [];
          records.map(o => {
            o.orderSummary.cart.map(c => {
              c.date = o.orderCreatedAt
            })
          });
          records.filter(function (o, k) { list.push(...o.orderSummary.cart) });
          let ite = _.groupBy(list, function (l) {
            return l.itemId ? l.itemId : l.merchant_id
          });
          let byItem = _.find(ite, (itm, key) => { return key == this.state.itemId });
          let count = 0;
          let itemBYDate = _.groupBy(byItem, function (i) { return i.date.split("T")[0] });
          _.forEach(itemBYDate, (itm, key) => {
            let temp = [];
            let itemTotal = 0;
            _.map(itm, function (o) {
              let optionsPrice = 0;
              o.options_to_add && o.options_to_add.reduce((option, i) => {
                optionsPrice += (i.price || i.optionPrice || 0);
                return optionsPrice
              }, 0)
              itemTotal += (optionsPrice + (o.itemPrice || o.price || 0)) * (o.qty || o.quantity);
            });
            count += 1;
            temp.push(count);
            temp.push(itm[0].itemTitle)
            temp.push(key);
            let qty = _.sumBy(itm, function (q) {
              return q.qty ? q.qty : q.quantity;
            });
            temp.push(qty);
            temp.push(itemTotal.toFixed(2));
            reportData.push(temp);
          })
        }
      } else {
        reportHeader = ["S.No", "OrderId", "Guest Name", "orderType", "Paid", "Order Date", "Order Amount", "Taxes", "Charges", "Discounts", "Total Amount"]
        let count = 0;
        _.forEach(records, (item, key) => {
          let temp = [];
          count += 1;
          temp.push(count);
          temp.push(item.orderId);
          temp.push(item.customerName)
          temp.push(item.orderType)
          temp.push(item.paymentMode)
          temp.push(moment(item.orderCreatedAt).format("DD/MM/YYYY"))
          temp.push((item.orderSummary.merchantReceivable - item.orderSummary.aggTaxes - item.orderSummary.aggCharges + item.orderSummary.aggDiscounts) || (item.orderTotal).toFixed(2))
          temp.push(item.orderSummary.aggTaxes || (item.orderCgst + item.orderSgst + item.orderTax + (item.orderIgst || 0)).toFixed(2))
          temp.push(item.orderSummary.aggCharges)
          temp.push(item.orderSummary.aggDiscounts || (item.discountItem + item.orderDiscount).toFixed(2))
          temp.push(item.orderSummary.merchantReceivable || (item.orderTotal + (item.orderIgst || 0) - item.orderDiscount + item.orderCgst + item.orderSgst + item.orderTax).toFixed(2))
          reportData.push(temp);
          let excelObj = {
            Sno: count,
            OrderId: item.orderId,
            GuestName: item.customerName,
            orderType: item.orderType,
            Paid: item.paymentMode,
            OrderDate: moment(item.orderCreatedAt).format("DD/MM/YYYY"),
            OrderAmount: (item.orderSummary.merchantReceivable - item.orderSummary.aggTaxes - item.orderSummary.aggCharges + item.orderSummary.aggDiscounts) || (item.orderTotal).toFixed(2),
            Taxes: item.orderSummary.aggTaxes || (item.orderCgst + item.orderSgst + item.orderTax + (item.orderIgst || 0)).toFixed(2),
            Charges: item.orderSummary.aggCharges,
            Discounts: item.orderSummary.aggDiscounts || (item.discountItem + item.orderDiscount).toFixed(2),
            TotalAmount: item.orderSummary.merchantReceivable || (item.orderTotal + (item.orderIgst || 0) - item.orderDiscount + item.orderCgst + item.orderSgst + item.orderTax).toFixed(2)
          };
          reportSalesExcel.push(excelObj);
        });

      }
    }

    return (
      <DataProvider
        loadRestarantsOnWillMount={this.context.isSuperAdmin}
        {...this.context}
      >
        <div className="contentvthbtn">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style={"loadingProgress"}
            />
          ) : (
            // <OrdersListUI
            //   orders={this.state.records}
            //   recordsPerPage={this.state.recordsPerPage}
            //   totalRecords={this.state.totalRecords}
            //   currentPageNum={this.state.currentPageNum}
            //   fetchOrdersList={this._fetchOrdersList.bind(this)}
            //   isSuperAdmin={this.context.isSuperAdmin}
            // />
            <div className="contentvthbtn">

              {
                <>
                  <div className="row mx-0 justify-content-between">
                    <div className="row mx-0">
                      {this.context.isSuperAdmin && (
                        <RestarantSelector
                          selectedRestarantId={this.state.selectedRestarantId}
                          filterByRestarant={this.filterByRestarant.bind(this)}
                        />
                      )}
                      <div className="d-flex align-items-center mb-3"  >
                        <select
                          className="form-control"
                          id="#filterdata"
                          value={this.state.value}
                          onChange={this.handleChange}
                        >
                          <option value="By Sales">By Sales</option>
                          <option value="By Items">By Items</option>
                        </select>
                        <label className="selectcategory"> ordertype: </label>
                        <select
                          className="form-control"
                          id="#filterdata"
                          value={this.state.selectedOrderType}
                          onChange={this.handleOrderTypeChange.bind(this)}
                        >
                          <option value="">All</option>
                          <option value="takeaway">takeaway</option>
                          <option value="dine in">dine in</option>
                          <option value="walk in">walk in</option>
                          <option value="swiggy">swiggy</option>
                          <option value="zomato">zomato</option>
                          <option value="zomato">scheduled</option>
                        </select>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <label className="selectcategory">
                          <input
                            type="radio"
                            value="Daily"
                            checked={this.state.reportType == "Daily"}
                            onChange={(e) => {
                              this.setState({
                                reportType: e.target.value
                              })
                              this._getDailyReport()
                            }}
                          />
                          Daily
                        </label>
                        <label className="selectcategory" >
                          <input
                            type="radio"
                            value="Weekly"
                            checked={this.state.reportType == "Weekly"}
                            onChange={(e) => {
                              this.setState({
                                reportType: e.target.value
                              })
                              this._getWeeklyReport()
                            }}
                          />
                          Weekly
                        </label>
                        {/* <label className="selectcategory" >
                          <input
                            type="radio"
                            value="Monthly"
                            checked={this.state.reportType === "Monthly"}
                            onChange={(e) => {
                              this.setState({
                                reportType: e.target.value
                              })
                              this._getMonthlyReport()
                            }} />
                          Monthly
                        </label> */}
                        <DateFilters
                          handleChangeEnd={this.handleChangeEnd.bind(this)}
                          handleChangeStart={this.handleChangeStart.bind(this)}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                        <button type="button" className="btndownloadpdf"
                          onClick={() => {
                            this.fetchFilteredOrders(true, this.state.startDate, this.state.endDate)
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.value === 'By Items' ?
                    <div  >
                      <input type="radio" value="ItemsByQty" onChange={this.onChangeValue} checked={this.state.byItemsSelect == "ItemsByQty"} /> Items By Qty
                      <span>  </span>
                      <input type="radio" value="ItemDateByQty" onChange={this.onChangeValue} checked={this.state.byItemsSelect == "ItemDateByQty"} /> Item Date By Qty
                      <span>  </span>
                      {this.state.byItemsSelect == "ItemDateByQty" ? <select
                        onChange={this.handleItemChange.bind(this)}
                        className="select_Restaurent mb-3"
                        Value={this.state.itemId}

                      >
                        <option value={0}>select Item</option>
                        {this.state.itemsData &&
                          this.state.itemsData.map(
                            (r, idx) =>
                              <option value={r.itemId} key={String(idx)}>
                                {r.itemTitle}
                              </option>

                          )}
                      </select> : null}
                    </div> : null
                  }
                  {/* <ReactToExcel
                    className="btndownloadpdf"
                    table="Salestable-to-xls"
                    filename={this.state.value + this.state.reportType + "Sheet"}
                    sheet="tablexls"
                    buttonText="Download Excel" /> */}

                  <ExcelFile className="btndownloadpdf">
                    <ExcelSheet data={reportSalesExcel} name="Report By Sales">
                      <ExcelColumn label="Sno" value="Sno" />
                      <ExcelColumn label="OrderId" value="OrderId" />
                      <ExcelColumn label="GuestName" value="GuestName" />
                      <ExcelColumn label="orderType" value="orderType" />
                      <ExcelColumn label="Paid" value="Paid" />
                      <ExcelColumn label="OrderDate" value="OrderDate" />
                      <ExcelColumn label="OrderAmount" value="OrderAmount" />
                      <ExcelColumn label="Taxes" value="Taxes" />
                      <ExcelColumn label="Charges" value="Charges" />
                      <ExcelColumn label="Discounts" value="Discounts" />
                      <ExcelColumn label="TotalAmount" value="TotalAmount" />
                    </ExcelSheet>
                    <ExcelSheet data={reportsItemsExcel} name="Report By Items">
                      <ExcelColumn label="SNO" value="SNO" />
                      <ExcelColumn label="ItemName" value="ItemName" />
                      <ExcelColumn label="count" value="count" />
                      <ExcelColumn label="Total" value="Total" />
                      <ExcelColumn label="orderTypes" value="orderTypes" />
                    </ExcelSheet>
                    <ExcelSheet data={paymentExcel} name="Payment Type">
                      <ExcelColumn label="type" value="type" />
                      <ExcelColumn label="amount" value="amount" />
                    </ExcelSheet>
                    <ExcelSheet data={orderTypeExcel} name="Order types">
                      <ExcelColumn label="orderType" value="orderType" />
                      <ExcelColumn label="Total" value="Total" />
                      <ExcelColumn label="count" value="count" />
                    </ExcelSheet>
                    <ExcelSheet data={tablesExcel} name="Order types">
                      <ExcelColumn label="Table" value="Table" />
                      <ExcelColumn label="Amount" value="Amount" />
                      <ExcelColumn label="Count" value="Count" />
                    </ExcelSheet>
                    <ExcelSheet data={subTotalsExcel} name="Sub Totals">
                      <ExcelColumn label="name" value="name" />
                      <ExcelColumn label="amount" value="amount" />
                    </ExcelSheet>
                  </ExcelFile>
                  <button type="button" className="btndownloadpdf"
                    onClick={() => {
                      this.exportPdf(reportHeader, reportData, paymentTypeHeader, paymentTypeData, ordertypeHeader, orderTypesData, tablesHeader, tablesData)
                    }}
                  >
                    Download Pdf
                  </button>
                  {this.context.user.restarantDeleteReports == 1 || this.context.isSuperAdmin ?
                    <button type="button" className="btndownloadpdf"
                      onClick={() => {
                        if (this.state.SelectedList.length) {
                          let ordersIds = []
                          this.state.SelectedList.map(s => {
                            ordersIds.push(s.orderId)
                          });
                          confirmAlert({
                            title: "Delete Reports?",
                            message: `Are you sure to delete the Records?`,
                            buttons: [
                              {
                                label: "Yes",
                                onClick: () => this._deleteReports(ordersIds)
                              },
                              {
                                label: "No",
                                onClick: () => null
                              }
                            ]
                          });
                        } else {
                          toast.warn("No Record Selected")
                        }
                      }}
                    >
                      Delete Reports
                    </button> : null}
                  {this.state.records.length ? (
                    <React.Fragment >
                      <div className="table-responsive mt-3 text-capitalize tableofauto" style={{ 'height': '500px', 'overflow': 'scroll', 'display': 'block' }} >
                        <table className="table" id="Salestable-to-xls" >
                          <thead id="thead" >

                            <tr>
                              {this.context.user.restarantDeleteReports == 1 || this.context.isSuperAdmin ? <th scope="col">
                                <input
                                  type="checkbox"
                                  className="text-centerimp"
                                  checked={this.state.MasterChecked}
                                  id="mastercheck"
                                  onChange={(e) => this.onMasterCheck(e)}
                                />
                              </th> : null}
                              {reportHeader.map((head, idx) => {
                                return (
                                  <th key={String(idx)} className="text-centerimp">{head}</th>)
                              })}
                            </tr>
                          </thead>
                          <tbody id="tbody">
                            {reportData.map((body, idx) => {
                              return (
                                <tr key={String(idx)}>
                                  {this.context.user.restarantDeleteReports == 1 || this.context.isSuperAdmin ? <td>
                                    <input
                                      type="checkbox"
                                      checked={this.state.records.find(r => r.orderId == body[1]).selected}
                                      className="text-centerimp"
                                      id="rowcheck{user.id}"
                                      onChange={(e) => this.onItemCheck(e, body[1])}
                                      disabled={body[3] == 'swiggy' || body[3] == 'zomato'}
                                    />
                                  </td> : null}
                                  {body.map((b, i) => {
                                    return (
                                      <td key={String(i)} className="text-center"> {b}  </td>
                                    )
                                  })}
                                </tr>)
                            })}
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="noDataMsgContainer">
                      <h5>{this.state.fetchFailMsg}</h5>
                    </div>
                  )}
                </>
              }
              {this.state.value === "By Sales" ? (
                totalAmount ?
                  <div>
                    <b className="pagination px-2">TotalAmount : {totalAmount.toFixed(2)}</b>
                  </div> : null
              ) : (
                totalAmount ?
                  <b className="pagination px-2">TotalAmount : {sumOfItems.toFixed(2)}</b> : null
              )}

              <div>
                {this.state.records.length && this.state.value === "By Sales" ? (
                  <div className="bottomTables">
                    <table className="paymentTable " id="Salestable-to-xls">
                      <thead style={{ backgroundColor: '#98a4af' }}>
                        <tr>
                          {paymentTypeHeader.map((pay, idx) => {
                            return (
                              <th key={String(idx)} className="text-centerimp" >{pay}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody id="tbody">
                        {paymentTypeData.map(pay => {
                          return (
                            <tr>
                              {pay.map((p, idx) => {
                                return (
                                  <td key={String(idx)}>{p}</td>
                                )
                              })}
                            </tr>)
                        })}
                      </tbody>
                    </table>
                    <table className=" paymentTable " id="Salestable-to-xls">
                      <thead style={{ backgroundColor: '#98a4af' }}>
                        <tr>
                          {ordertypeHeader.map((type, idx) => {
                            return (
                              <th key={String(idx)} className="text-centerimp" >{type}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody id="tbody">
                        {orderTypesData.map(type => {
                          return (
                            <tr>
                              {type.map((t, idx) => {
                                return (
                                  <td key={String(idx)}>{t}</td>
                                )
                              })}
                            </tr>)
                        })}
                      </tbody>
                    </table>
                    <table className="paymentTable " id="Salestable-to-xls">
                      <thead style={{ backgroundColor: '#98a4af' }}>
                        <tr>
                          {tablesHeader.map((t, idx) => {
                            return (
                              <th key={String(idx)} className="text-centerimp" >{t}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody id="tbody">
                        {tablesData.map((tab, idx) => {

                          return (
                            <tr key={String(idx)}>
                              {tab.map((p, i) => {
                                return (
                                  <td key={String(i)}>{p}</td>
                                )
                              })}
                            </tr>)
                        })}
                      </tbody>
                    </table>
                    <table className="paymentTable " id="Salestable-to-xls" >
                      <thead style={{ backgroundColor: '#98a4af' }} >
                        <tr>
                          <th className="text-centerimp">Name</th>
                          <th className="text-centerimp">Amount</th>
                        </tr>
                      </thead>
                      <tbody id="tbody">
                        <tr>
                          <td>TotalDiscounts</td>
                          <td>{TotalDiscounts.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>TotalTaxes</td>
                          <td>{TotalTaxes.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>TotalCharges</td>
                          <td>{TotalCharges.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Sub Totals</td>
                          <td>{OrderTotals.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>TotalAmount</td>
                          <td>{totalAmount.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
                  : null}
              </div>
            </div>
          )}
        </div>
        <div className="container-fluid">
          <div className="pagination px-2">
            {this.state.totalRecords / this.state.recordsPerPage > 1 ? (
              <ReactPaginate
                previousLabel={
                  <i className="fa fa-angle-double-left" aria-hidden="true" />
                }
                nextLabel={
                  <i className="fa fa-angle-double-right" aria-hidden="true" />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(
                  this.state.totalRecords / this.state.recordsPerPage
                )}
                marginPagesDisplayed={this.state.surroundingButtonsCount}
                pageRangeDisplayed={3}
                pageLinkClassName={"pagebtn"}
                onPageChange={data => this.getMore(data.selected + 1)}
                containerClassName={"pagination"}
                activeLinkClassName={"active"}
                forcePage={this.state.currentPageNum - 1}
              />
            ) : null}
          </div>
        </div>

      </DataProvider>
    );
  }

  onChangeValue(event) {
    this.setState({ byItemsSelect: event.target.value })
  }
  _validateSelectItemState(val, name) {
    if (!val) {
      return `Select ${name}.`;
    }
  }
  async _getWeeklyReport() {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 7)
    await this.setState({
      startDate: new Date(startDate),
      endDate: new Date(),
      currentPageNum: 1,
    })
    this._fetchOrdersList();
  }

  async _getMonthlyReport() {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 30)
    await this.setState({
      startDate: new Date(startDate),
      endDate: new Date(),
      currentPageNum: 1,
    })
    this._fetchOrdersList();
  }

  filterByRestarant(val) {
    this.setState(
      {
        selectedRestarantId: val,
        currentPageNum: 1
      },
      err => {
        this._fetchOrdersList();
        // this._loadOrderTypes();
      }
    );
  }

  getMore(pageNum) {
    this.setState(
      {
        currentPageNum: pageNum
      },
      err => {
        this._fetchOrdersList();
        // this._loadOrderTypes();
      }
    );
  }

  detailsPage(orderId) {
    let order = this.state.records.find(o => o.orderId === orderId);
    this.props.history.push("order-details/" + orderId, { order });
  }

  async _fetchOrdersList() {
    if (this.state.fetchProgress) return false;
    this.setState({
      fetchProgress: true
    });
    const filters =
      this.state.startDate && this.state.endDate
        ? { startDate: this.state.startDate, endDate: this.state.endDate }
        : null;
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
    const url =
      apiBasePath +
      apiRoutes.getRestarantTakeAwayOrders +
      `?user_id=${this.context.user.userId}&restaurant_id=${this.context.isSuperAdmin
        ? this.state.selectedRestarantId || 0
        : this.context.user.selectedRestarantId
      }&limit=${this.state.recordsPerPage}&offset=${(this.state.currentPageNum -
        1) *
      this.state.recordsPerPage}&start_date=${filters
        ? filters.startDate
          ? moment(filters.startDate)
            .utc(true)
            .startOf("day")
            .toISOString()
          : ""
        : ""
      }&end_date=${filters
        ? filters.endDate
          ? moment(filters.endDate)
            .utc(true)
            .endOf("day")
            .toISOString()
          : ""
        : ""
      }`;
    const ordersResponse = await fetch(url, {
      headers: {
        Authorization: this.context.authToken,
        "Content-type": "application/json"
      }
    }).catch(err => console.error(err));

    if (!ordersResponse) {
      this.setState({
        fetchProgress: false,
        totalRecords: 0,
        records: []
      });
      return toast.error("An Error Occured while fetching tables");
    }

    const ordersData = await ordersResponse
      .json()
      .catch(err => console.log(err));
    if (!ordersData) {
      return toast.error("An Error Occured while fetching tables");
    }
    if (ordersResponse.status == 403) {
      this.setState({
        fetchProgress: false,
        totalRecords: 0,
        records: []
      });
      toast.error(ordersData.msg, { autoClose: 8000 });
      return this.context.logOut();
    }
    if (!ordersData.success) {
      this.setState({
        fetchProgress: false,
        records: [],
        totalRecords: 0
      });
      return toast.error(ordersData.msg);
    }

    throttleTime(
      () => {
        this.setState({
          records: ordersData.data,
          fetchProgress: false,
          totalRecords: ordersData.total,
          SelectedList: []
        });
      },
      requestSendTime,
      500
    );
  }
  async _loadItemsData() {
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const itemsResponse = await fetch(
      apiBasePath +
      apiRoutes.getMenuItems +
      `?user_id=${this.context.user.userId}&limit=0&offset=0&restarant_id=${this.context.user.selectedRestarantId}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!itemsResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const itemsResponseResult = await itemsResponse.json();
    if (!itemsResponseResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!itemsResponseResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: itemsResponseResult.msg
      });
      return toast.error(itemsResponseResult.msg);
    }
    this.setState({
      itemsData: itemsResponseResult.data
    });

  }

  async _deleteReports(orderIds) {
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const deletedResponse = await fetch(
      apiBasePath + apiRoutes.deleteReports,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
          order_ids: orderIds,
          restarant_id: this.context.user.selectedRestarantId,
          is_super_admin: this.context.isSuperAdmin
        })
      }
    ).catch(err => console.error(err));
    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the records.";
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the records.";
      return toast.error(msg);
    }
    if (!deletedResponseResult.success) {
      return toast.error(deletedResponseResult.msg);
    }
    this._getDailyReport()
    this._loadItemsData();
    toast.success("Deleted the Records", { autoClose: 6000 });
  }
  async _loadOrderTypes() {
    this.setState({
      fetchProgress: true
    });
    const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
    const requestSendTime = Date.now();
    const OrderTypeResponse = await fetch(
      apiBasePath +
      apiRoutes.getOrderType +
      `?user_id=${this.context.user.userId}&limit=${this.state.recordsPerPage
      }&offset=${(this.state.currentPageNum - 1) *
      this.state.recordsPerPage}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!OrderTypeResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const OrderTypeResult = await OrderTypeResponse.json();
    if (!OrderTypeResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!OrderTypeResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: OrderTypeResult.msg
      });
      return toast.error(OrderTypeResult.msg);
    }
    throttleTime(
      () => {
        this.setState({
          ordertypes: OrderTypeResult.data,
          totalOrderTypes: OrderTypeResult.total,
          fetchProgress: false
        });
      },
      requestSendTime,
      500
    );
  }

  handleChangeStart(val) {
    this.setState(
      {
        startDate: val
      });
  }
  handleChangeEnd(val) {
    this.setState(
      {
        endDate: val
      });
  }

  fetchFilteredOrders(dateFilter, startDate, endDate) {
    //if either of them are empty - stop
    //if both of them are empty - fetch
    if (dateFilter && (!startDate || !endDate) && (startDate || endDate)) {
      return false;
    }
    this.setState(
      {
        currentPageNum: 1
      },
      err => {
        this._fetchOrdersList();
      }
    );
  }
}

function RestarantSelector({ selectedRestarantId, filterByRestarant }) {
  return (
    <DataContext.Consumer>
      {({ restarants }) => {
        return (
          <select
            onChange={e => filterByRestarant(e.nativeEvent.target.value)}
            className="select_Restaurent mb-3"
            defaultValue={selectedRestarantId}
          >
            <option value={0}>All Restaurants</option>
            {restarants &&
              restarants.map(
                (r, idx) =>
                  r.name && (
                    <option value={r.id} key={String(idx)}>
                      {r.name.length > 40
                        ? r.name.substr(0, 37) + "..."
                        : r.name}
                    </option>
                  )
              )}
          </select>
        );
      }}
    </DataContext.Consumer>
  );
}

function DateFilters({
  handleChangeEnd,
  handleChangeStart,
  startDate,
  endDate
}) {
  // let date = new Date();
  // date.setDate(1);
  // date = new Date(date.setMonth(date.getMonth() - 1));
  return (
    <>
      <DatePicker
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={new Date('10/01/2021')}
        maxDate={new Date()}
        className="form-control"
        onChange={handleChangeStart}
        isClearable={true}
        placeholderText="Starting from..."
      />
      <span style={{ textAlign: "center", width: "20px" }}>-</span>
      <DatePicker
        selected={endDate}
        selectsEnd
        minDate={startDate}
        maxDate={new Date()}
        className="form-control"
        startDate={startDate}
        endDate={endDate}
        onChange={handleChangeEnd}
        isClearable={true}
        placeholderText="...to"
      />
    </>
  );
};


