import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
export default () => {
  return (
    <AuthContext.Consumer>
      {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
        return (
          <DataContext.Consumer>
            {({ fetchCategories }) => {
              return (
                <SubCategories
                  user={user}
                  authToken={authToken}
                  CONSTANTS={CONSTANTS}
                  isSuperAdmin={isSuperAdmin}
                  fetchCategories={fetchCategories}
                />
              );
            }}
          </DataContext.Consumer>
        );
      }}
    </AuthContext.Consumer>
  );
};

class SubCategories extends Component {
  state = {
    subCategories: [],
    allCategories: [],
    totalSubCategories: 0,
    currentPageNum: 1,
    recordsPerPage: 10,
    fetchProgress: true,
    saveProgress: false,
    deleteProgress: false,
    saveFailMsg: "",
    deleteFailMsg: "",
    noDataMsg: "No Sub-Categories Yet.",
    selectedSubCategoryId: null
  };

  componentWillMount() {
    this.modalRef = React.createRef();
    this.modalUI = React.createRef();
    this._loadSubCategories();
  }

  componentDidMount() {
    this._loadCategories();
    $(this.modalUI.current).on("shown.bs.modal", () => {
      // if (this.modalRef.current.state.categories.length) return;
      // this._loadCategories();
    });

    $(this.modalUI.current).on("hidden.bs.modal", () => {
      this.modalRef.current.setState({
        editMode: false
      });
      this.setState({
        selectedSubCategoryId: null
      });
      this.modalRef.current.formApi.reset();
    });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
            <>
              <div className="row mx-0 justify-content-between align-items-center">
                <div className="">
                  <span className="categorycol">Sub-categories</span>
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btnaddcategory float-right"
                    data-toggle="modal"
                    data-target="#subCategoryModal"
                  >
                    <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                    &nbsp; Add sub-categories
                  </button>
                </div>
              </div>
              {this.state.subCategories.length ? (
                <div className="table-responsive mt-3 tableofauto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Sub-category name</th>
                        <th>Category name</th>
                        <th>Description</th>
                        <th className="actionWidth">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subCategories.map((sc, idx) => (
                        <tr key={String(idx)}>
                          <td>
                            {(this.state.currentPageNum - 1) *
                              this.state.recordsPerPage +
                              idx +
                              1}
                          </td>
                          <td>{sc.subCategoryName}</td>
                          <td>{sc.categoryName}</td>
                          <td>
                            {sc.subCategoryDescription
                              ? sc.subCategoryDescription.length <= 50
                                ? sc.subCategoryDescription
                                : sc.subCategoryDescription.substr(0, 50) +
                                  "..."
                              : ""}
                          </td>
                          <td>
                            <img
                              alt=""
                              src={EDIT_IMG}
                              data-toggle="modal"
                              data-target="#subCategoryModal"
                              className="icon"
                              onClick={() => {
                                this.setState({
                                  selectedSubCategoryId: sc.subCategoryId
                                });
                                this.modalRef.current.setState({
                                  editMode: true
                                });
                                this.modalRef.current.formApi.setValue(
                                  "subCategory__name",
                                  sc.subCategoryName
                                );
                                this.modalRef.current.formApi.setValue(
                                  "subCategory__desc",
                                  sc.subCategoryDescription
                                );
                                this.modalRef.current.formApi.setValue(
                                  "subCategory__catId",
                                  sc.categoryId
                                );
                              }}
                            />
                            <img
                              alt=""
                              src={DELETE_IMG}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.confirmAndDelete(
                                  sc.subCategoryId,
                                  sc.subCategoryName
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="noDataMsgContainer">
                  <h5>{this.state.noDataMsg}</h5>
                </div>
              )}
            </>
          )}
        </div>
        {this._pagination()}
        <SubCategoryModal
          ref={this.modalRef}
          submitSubCatForm={this._submitSubCategoryForm.bind(this)}
          modalUiRef={this.modalUI}
        />
      </>
    );
  }

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalSubCategories / this.state.recordsPerPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.state.totalSubCategories / this.state.recordsPerPage
            )}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this._getMore(data.selected + 1)}
            containerClassName={"pagination"}
            activeLinkClassName={"active"}
            forcePage={this.state.currentPageNum - 1}
          />
        ) : null}
      </div>
    );
  }

  _getMore(val) {
    this.setState(
      {
        currentPageNum: val
      },
      err => {
        this._loadSubCategories();
      }
    );
  }

  async _loadCategories() {
    let categoriesResponse = await this.props
      .fetchCategories({
        CONSTANTS: this.props.CONSTANTS,
        userId: this.props.user.userId,
        limit: 0,
        offset: 0,
        authToken: this.props.authToken
      })
      .catch(err => console.error(err));

    if (!categoriesResponse) {
      let msg = "An Error Occured while fetching Categories.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    if (!categoriesResponse.success) {
      let msg = categoriesResponse.msg;
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    this.modalRef.current.setState({
      categories: categoriesResponse.data
    });
  }

  async _loadSubCategories() {
    this.setState({
      fetchProgress: true
    });
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const requestSendTime = Date.now();
    const subCatResponse = await fetch(
      apiBasePath +
        apiRoutes.getSubCategories +
        `?user_id=${this.props.user.userId}&limit=${
          this.state.recordsPerPage
        }&offset=${(this.state.currentPageNum - 1) *
          this.state.recordsPerPage}`,
      { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!subCatResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const subCatResult = await subCatResponse.json();
    if (!subCatResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!subCatResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: subCatResult.msg
      });
      return toast.error(subCatResult.msg);
    }
    throttleTime(
      () => {
        this.setState({
          subCategories: subCatResult.data,
          totalSubCategories: subCatResult.total,
          fetchProgress: false
        });
      },
      requestSendTime,
      500
    );
  }

  async _submitSubCategoryForm(formValues) {
    if (this.state.saveProgress) return false;
    this.modalRef.current.setState({
      saveProgress: true
    });
    let subCatId = this.state.selectedSubCategoryId;
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const savedResponse = await fetch(
      apiBasePath +
        (subCatId ? apiRoutes.editSubCategory : apiRoutes.addSubCategory),
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: subCatId ? "PUT" : "POST",
        body: JSON.stringify({
          category_id: formValues.subCategory__catId,
          user_id: this.props.user.userId,
          restarant_id: this.props.user.selectedRestarantId,
          sub_cat_name: formValues.subCategory__name.trim(),
          sub_cat_desc: formValues.subCategory__desc && formValues.subCategory__desc.trim(),
          sub_cat_status: 1,
          sub_cat_id: subCatId //will be undefined in add request
        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while ${
        subCatId ? "saving the changes" : "adding the sub-category"
      }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while ${
        subCatId ? "saving the changes" : "adding the sub-category"
      }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      this.modalRef.current.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.msg
      });
      return toast.error(savedResponseResult.msg);
    }

    this.modalRef.current.setState({
      saveProgress: false,
      saveFailMsg: ""
    });
    this.setState(
      {
        currentPageNum: 1
      },
      err => {
        this._loadSubCategories();
      }
    );
    $(this.modalUI.current).modal("hide");
    toast.success(
      `Successfully ${
        subCatId ? "saved the changes" : "added " + formValues.subCategory__name
      }.`,
      { autoClose: 4000 }
    );
  }

  confirmAndDelete(subCatId, name) {
    // let subCatName = (
    //   this.state.subCategories.find(sc => sc.subCategoryId == subCatId) || {}
    // ).subCategoryName;
    confirmAlert({
      title: "Delete?",
      message: `Are you sure to delete ${name || ""}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteSubCategory(subCatId)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteSubCategory(subCatId) {
    if (this.state.deleteProgress) {
      return false;
    }

    this.setState({
      deleteProgress: true
    });
    const subCat = this.state.subCategories.find(
      i => i.subCategoryId == subCatId
    );
    const subCatName = subCat.subCategoryName;

    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const deletedResponse = await fetch(
      apiBasePath + apiRoutes.deleteSubCategory,
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: "DELETE",
        body: JSON.stringify({
          sub_cat_id: subCatId,
          user_id: this.props.user.userId,
          restarant_id: this.props.user.selectedRestarantId
        })
      }
    ).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the sub-category.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the sub-category.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    if (!deletedResponseResult.success) {
      this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });

      return toast.error(deletedResponseResult.msg);
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: "",
      currentPageNum:
        this.state.subCategories.length > 1
          ? this.state.currentPageNum
          : this.state.currentPageNum - 1 > 0
          ? this.state.currentPageNum - 1
          : 1
    });
    this._loadSubCategories();
    toast.success(`Deleted ${subCatName}`, { autoClose: 5000 });
  }
}

class SubCategoryModal extends Component {
  state = {
    editMode: false,
    categories: [],
    saveProgress: false
  };

  render() {
    const { modalUiRef, submitSubCatForm } = this.props;
    return (
      <div
        ref={modalUiRef}
        className="modal fade"
        id="subCategoryModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.editMode
                  ? "Edit sub-categories"
                  : "Add Sub-categories"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              onSubmit={submitSubCatForm}
              getApi={this._setFormApi.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row mb-sm-3">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Select
                              field="subCategory__catId"
                              validateOnChange
                              validate={this._validateCategory}
                              className="form-control mr-sm-4 py-2"
                            >
                              <option value={""} disabled>
                                - Select Category -
                              </option>
                              {this.state.categories.length
                                ? this.state.categories.map((c, idx) => {
                                    return (
                                      <option
                                        key={String(idx)}
                                        value={c.categoryId}
                                      >
                                        {c.categoryName}
                                      </option>
                                    );
                                  })
                                : null}
                            </Select>
                            {formState.errors.subCategory__catId && (
                              <div className="input_error">
                                {formState.errors.subCategory__catId}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val =>
                                validateStrictText(
                                  val,
                                  3,
                                  formState.touched.subCategory__name,
                                  "Sub-category Title",
                                  30
                                )
                              }
                              validateOnBlur
                              validateOnChange
                              field={"subCategory__name"}
                              type="text"
                              placeholder="Enter sub-category name"
                              className={
                                formState.errors.subCategory__name
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.subCategory__name && (
                              <div className="input_error">
                                {formState.errors.subCategory__name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <TextArea
                          validate={val =>
                            {

                              if (Number(val) > 500) {
                                  return "Sub Category Description cannot be more than 500 Characters.";
                              }
                          }
                            // validateText(
                            //   val,
                            //   10,
                            //   formState.touched.subCategory__desc,
                            //   "Sub-category Description",
                            //   400
                            // )
                          }
                          rows={5}
                          validateOnBlur
                          validateOnChange
                          field={"subCategory__desc"}
                          className={
                            formState.errors.subCategory__desc
                              ? "form-control input_error"
                              : "form-control"
                          }
                          placeholder="Enter item description"
                        />
                        {formState.errors.subCategory__desc && (
                          <div className="input_error">
                            {formState.errors.subCategory__desc}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.state.editMode ? "Save Changes" : "Add"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _setFormApi(formApi) {
    this.formApi = formApi;
  }

  _validateSubCatDesc(desc) {
    if (!this.formApi.getState().touched.subCategory__desc) {
      //this is the first focus, do not validate.
      return false;
    }
    desc = desc && desc.trim();
    if (!desc || desc.length < 10) {
      return "Description must be atleast 10 Characters long";
    }
    if (desc.length > 400) {
      return "Description must not be larger than 400 characters";
    }
  }

  _validateSubCatName(name) {
    if (!this.formApi.getState().touched.subCategory__name) {
      //this is the first focus, do not validate.
      return false;
    }
    name = name && name.trim();
    if (!/[a-zA-Z]*/.test(name)) {
      return "Enter a valid Sub Category Name.";
    }
    if (!name || name.length < 3) {
      return "Sub Category Name must contain atleast 3 Characters.";
    }
  }

  _validateCategory(val) {
    if (!val) return "Select Category.";
  }
}
