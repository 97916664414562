import React, { Component } from 'react';
import { toast } from "react-toastify";
import { AuthContext, DataContext } from "../contextProvider";
import { CategoriesContext, CategoriesProvider } from "../context";
import { Form, Text, TextArea, Select, Radio, RadioGroup, Checkbox } from "informed";
import $ from "jquery";
import {
    EDIT_IMG,
    DELETE_IMG,
    TEA_COFFEE_IMAGE,
    STARTERS_IMAGE,
    CURRIES_IMAGE,
    BIRYANI_IMAGE,
    SANDWICH_IMAGE,
    FRIED_RICE_IMAGE,
    COOL_DRINK_IMAGE,
    MOCK_TAILS_IMAGE,
    FRUIT_JUICES_IMAGE,
    DESSERTS_IMAGE,
    BURGER,
    NOODLES,
    SOUP,
    ICECREAM,
    INDIANBREAD,
    TANDOORIITEMS,
    SHAKES,
    BEVERGERS,
    BREAKFAST,
    LUNCH,
    ALCOHOLICBEVERAGES,
    PIZZA
} from "../assets/images";
import {
    validatePhone,
    validateEmail,
    validateText,
    validateInput,
    validateStrictText,
    validateWholeNumber,
    validateStrictGst,
    validateStrictGstdescription
} from "../utilities";
// import imageCompression from 'browser-image-compression';

export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ CONSTANTS, user, authToken, isSuperAdmin, logOut }) => {
                return (
                    <CategoryDetails
                        {...props}
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        logOut={logOut}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class CategoryDetails extends Component {
    constructor(props) {
        super(props);
        this.rFormRef = React.createRef();
    }
    static contextType = AuthContext;

    state = {
        categoryDetails: null,
        categoryImagePath: "",
        baseUrl: "",
        newImage: '',
        isSubCategories: this.props.location.pathname.indexOf('add-sub-category') > -1 ? true : false
    }
    categoryIcons = [
        {
            name: "STARTERS_IMAGE",
            id: "STARTERS_IMAGE",
            img: STARTERS_IMAGE
        },
        {
            name: "CURRIES_IMAGE",
            id: "CURRIES_IMAGE",
            img: CURRIES_IMAGE
        },
        {
            name: "BIRYANI_IMAGE",
            id: "BIRYANI_IMAGE",
            img: BIRYANI_IMAGE
        },
        {
            name: "SANDWICH_IMAGE",
            id: "SANDWICH_IMAGE",
            img: SANDWICH_IMAGE
        },
        {
            name: "FRIED_RICE_IMAGE",
            id: "FRIED_RICE_IMAGE",
            img: FRIED_RICE_IMAGE
        },
        {
            name: "COOL_DRINK_IMAGE",
            id: "COOL_DRINK_IMAGE",
            img: COOL_DRINK_IMAGE
        },
        {
            name: "MOCK_TAILS_IMAGE",
            id: "MOCK_TAILS_IMAGE",
            img: MOCK_TAILS_IMAGE
        },
        {
            name: "FRUIT_JUICES_IMAGE",
            id: "FRUIT_JUICES_IMAGE",
            img: FRUIT_JUICES_IMAGE
        },
        {
            name: "TEA_COFFEE_IMAGE",
            id: "TEA_COFFEE_IMAGE",
            img: TEA_COFFEE_IMAGE
        },
        {
            name: "BURGER",
            id: "BURGER",
            img: BURGER
        },
        {
            name: "NOODLES",
            id: "NOODLES",
            img: NOODLES
        },
        {
            name: "SOUP",
            id: "SOUP",
            img: SOUP
        },
        {
            name: "ICECREAM",
            id: "ICECREAM",
            img: ICECREAM
        },
        {
            name: "INDIANBREAD",
            id: "INDIANBREAD",
            img: INDIANBREAD
        },
        {
            name: "TANDOORIITEMS",
            id: "TANDOORIITEMS",
            img: TANDOORIITEMS
        },
        {
            name: "SHAKES",
            id: "SHAKES",
            img: SHAKES
        },
        {
            name: "BEVERGERS",
            id: "BEVERGERS",
            img: BEVERGERS
        },
        {
            name: "BREAKFAST",
            id: "BREAKFAST",
            img: BREAKFAST
        },
        {
            name: "LUNCH",
            id: "LUNCH",
            img: LUNCH
        },
        {
            name: "ALCOHOLICBEVERAGES",
            id: "ALCOHOLICBEVERAGES",
            img: ALCOHOLICBEVERAGES
        },
        {
            name: "PIZZA",
            id: "PIZZA",
            img: PIZZA
        },
    ];

    componentDidMount() {
        let locationState = this.props.location.state;
        let categoryId = null;
        let isSubCategories = null;
        if (locationState) {
            categoryId = locationState.categoryId;
            isSubCategories = locationState.locationState;
        }
        this.setState({
            categoryId,
            isSubCategories: isSubCategories || this.state.isSubCategories,
            baseUrl: this.props.CONSTANTS.apiBasePath.split("/api")[0]
        });

        categoryId && this._loadCategoryDetails(categoryId);

    }
    render() {
        let editMode =
            this.props.location.state && this.props.location.state.categoryId;
        let isSubCategories =
            this.props.location.state && this.props.location.state.isSubCategories;
        return (
            <div className="content of-auto">
                <div className="mx-0">
                    <div className="categorycol d-flex align-items-center">
                        <i
                            className="fa fa-long-arrow-left"
                            style={{
                                marginRight: "1rem",
                                fontSize: "20px",
                                cursor: "pointer"
                            }}
                            aria-hidden="true"
                            onClick={() => this.props.history.goBack()}
                        />
                        {!this.state.isSubCategories ? "category" : "Sub-category"}
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <label
                                id="img_preview_label"
                                htmlFor="img_preview"
                                style={{
                                    backgroundImage: this.state.categoryImagePath
                                        ? `url(${this.state.baseUrl +
                                        this.state.categoryImagePath})`
                                        : `url(${this.state.displayImage})`
                                }}
                            >
                                <input
                                    type="file"
                                    className="img_preview"
                                    accept="image/*"
                                    id="img_preview"
                                    onChange={this.previewImage.bind(this)}
                                />
                                {!(
                                    this.state.categoryImagePath || this.state.displayImage
                                ) && (
                                        <i
                                            className="fa fa-upload img-upload-icon"
                                            aria-hidden="true"
                                        />
                                    )}
                            </label>
                            {this.state.categoryId ? <img
                                alt=""
                                src={DELETE_IMG}
                                style={{ cursor: "pointer", backgroundColor: "white", height: "25px", width: "25px" }}
                                className="img-fluid"
                                onClick={() => this.deleteImage()
                                }
                            /> : null}
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6" />
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6" />
                    </div>
                    <CategoriesProvider {...this.props}>
                        <CategoriesContext.Consumer>
                            {({ categories, fetchProgress, noDataMsg }) => {
                                return (
                                    <EditCategoryModal
                                        ref={this.rFormRef}
                                        getFormValues={this._getFormValues.bind(this)}
                                        categoryIcons={this.categoryIcons}
                                        categories={categories}
                                        initialValues={{
                                            cName: this.state.categoryDetails ?
                                                this.state.categoryDetails.categoryName
                                                : "",
                                            cSortOrder: this.state.categoryDetails ?
                                                this.state.categoryDetails.categoryName
                                                : "",
                                            cDescription: this.state.categoryDetails ?
                                                this.state.categoryDetails.categoryDescription
                                                : "",
                                            cIcon: this.state.categoryDetails ?
                                                this.state.categoryDetails.categoryIcon
                                                : "STARTERS_IMAGE",
                                            cParentCategory: this.state.categoryDetails ?
                                                this.state.categoryDetails.categoryParentId
                                                : "",
                                            // cActive: this.state.categoryDetails ?
                                            //     this.state.categoryDetails.categoryActive
                                            //     : "",
                                            cPosStatus: this.state.categoryDetails ?
                                                this.state.categoryDetails.posActive
                                                : "",
                                            cOnlineStatus: this.state.categoryDetails ?
                                                this.state.categoryDetails.onlineActive
                                                : "",
                                            cAggStatus: this.state.categoryDetails ?
                                                this.state.categoryDetails.AggStatus
                                                : ""

                                        }}
                                        editMode={editMode}
                                        buildClassName={this.buildClassName.bind(this)}
                                        isSubCategories={this.state.isSubCategories || isSubCategories}
                                    />
                                );
                            }}
                        </CategoriesContext.Consumer>
                    </CategoriesProvider>
                    <div className="text-right">
                        <button
                            type="button"
                            className="savebtn"
                            onClick={() => this._submitCategoryDetails(!!editMode)}
                        >
                            {this.state.saveProgress ||
                                (this.state.uploadProgress && (
                                    <i className="fa fa-spinner fa-spin fa-fw"></i>
                                ))}{" "}
                            {editMode ? "Save Changes" : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    async _submitCategoryDetails(edit) {
        if (this.state.saveProgress || this.state.uploadProgress) {
            return false;
        }

        this.rFormRef.current._formApi.submitForm();
        let formInValid = Object.keys(this.rFormRef.current._formApi.getState().errors).length
        if (formInValid) {
            return false;
        }
        let formValues = this.rFormRef.current._formApi.getState().values
        this.setState({
            saveProgress: true
        });
        const { CONSTANTS, authToken, user } = this.context;
        this.setState({
            saveProgress: true
        });
        let body = {
            user_id: user.userId,
            cat_image: this.state.categoryImagePath,
            cat_name: formValues.cName.trim(),
            cat_sort_order: formValues.cSortOrder,
            cat_desc: formValues.cDescription.trim(),
            cat_status: 1,
            cat_icon: formValues.cIcon,
            cat_parent_id: formValues.cParentCategory,
            // cat_active: formValues.cOnlineStatus,
            category_id: this.state.categoryId,
            restarant_id: user.selectedRestarantId,
            cat_pos_status: formValues.cPosStatus,
            cat_online_status: formValues.cOnlineStatus,
            cat_agg_status: formValues.cAggStatus,
        }
        let apiRoute = CONSTANTS.apiRoutes.createCategory;
        if (edit) {
            body.category_id = this.props.location.state.categoryId;
            apiRoute = CONSTANTS.apiRoutes.editCategory;
        }
        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: edit ? "PUT" : "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = "An Error Occured while saving the changes.";
            this.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg, {});
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = "An Error Occured while saving the changes.";
            this.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg, {});
        }

        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.setState({
            saveProgress: false,
            saveFailMsg: ""
        });

        toast.success(
            edit
                ? "Saved changes to " + formValues.cName + "."
                : "Added Menu Item " + formValues.cName,
            { autoClose: 3000 }
        );
        this.props.history.goBack();

    }

    async previewImage(e) {
        var fileInput = false
        let image = e.nativeEvent.target.files[0];
        fileInput = true

        const options = {
            maxSizeMB: 0.01,
            maxWidthOrHeight: 1000,
            useWebWorker: true
        }
        // const resizeImage = await imageCompression(image, options)

        // if (resizeImage.size > 100000) {
        //     return toast.error("image size cannot exceed more than 100KB", {
        //         autoClose: 2000
        //     });
        // }
        // if (resizeImage.type.split("/")[0] != "image") {
        //     e.target.value = null;
        //     return toast.error("Please input a valid image file", {
        //         autoClose: 2000
        //     });
        // }
        this.setState(
            {
                displayImage: URL.createObjectURL(image)
            },
            async err => {
                //upload image
                let data = new FormData();
                data.append("file", image);
                data.append("filename", "myfile");
                let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
                this.setState({
                    uploadProgress: true
                });
                let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
                    headers: {
                        // 'Content-type':undefined
                    },
                    method: "POST",
                    body: data
                }).catch(err => console.error(err));
                let response = await uploadSuccess
                    .json()
                    .catch(err => console.error(err));
                this.setState({
                    uploadProgress: false
                });
                if (!response.success) {
                    return toast.error("Failed to upload image");
                }
                this.setState({
                    categoryImagePath: response.data,
                    displayImage: "",
                    oldImagePath: this.state.categoryImagePath
                });
            }
        );
    }
    _getFormValues(formName, values) {
        // this.setState({
        //   // [formName]: values
        // });
    }
    buildClassName(err, enableInput) {
        let c = "w-100 restarantInput";
        !enableInput && (c += " restarantInput--disabled");
        err && (c += " input_error");
        return c;
    }
    async _loadCategoryDetails(categoryId) {
        if (this.state.fetchProgress) return false;
        this.setState({
            fetchProgress: true
        });
        let fetchResponse = await fetch(
            this.context.CONSTANTS.apiBasePath +
            this.context.CONSTANTS.apiRoutes.getCategoryDetails +
            `?cat_id=${categoryId}&user_id=${this.context.user.userId}`,
            { headers: { Authorization: this.context.authToken } }
        ).catch(err => console.error(err));
        if (!fetchResponse) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const CategoryData = await fetchResponse.json();
        //@TODO - handle error
        if (!CategoryData.success) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("Could not get category details.");
        }

        //fill form values
        this.setState(
            {
                categoryDetails: CategoryData.data,
                categoryImagePath: CategoryData.data.categoryImages[0]
            },
            err => {
                if (err) return console.error(err);
                let rData = this.state.categoryDetails;
                //item details
                this.rFormRef.current._formApi.setValue("cName", rData.categoryName);
                this.rFormRef.current._formApi.setValue("cSortOrder", rData.categorySortOrder);
                this.rFormRef.current._formApi.setValue("cDescription", rData.categoryDescription);
                if (rData.categoryIcon) {
                    this.rFormRef.current._formApi.setValue("cIcon", rData.categoryIcon);
                }
                if (rData.categoryParentId) {
                    this.rFormRef.current._formApi.setValue("cParentCategory", rData.categoryParentId);
                }
                // this.rFormRef.current._formApi.setValue("cActive", rData.categoryActive);
                this.rFormRef.current._formApi.setValue("cPosStatus", rData.posActive == "33" ? true : false);
                this.rFormRef.current._formApi.setValue("cOnlineStatus", rData.onlineActive == "35" ? true : false);
                this.rFormRef.current._formApi.setValue("cAggStatus", rData.catAggStatus)
            }
        );
    }
    deleteImage = async () => {
        let response = await fetch(
            this.context.CONSTANTS.apiBasePath +
            this.context.CONSTANTS.apiRoutes.deleteCategoryImage,
            {
                headers: {
                    Authorization: this.context.authToken,
                    "Content-type": "application/json"
                },
                method: "PUT",
                body: JSON.stringify({
                    cat_id: this.state.categoryId,
                    user_id: this.context.user.userId,
                })
            }
        ).catch(err => console.error(err));
        if (!response) {
            return toast.error("An Error Occured.");
        }
        const responseData = await response.json();
        if (!responseData.success) {
            return toast.error("An Error Occured.");
        }
        this.setState({
            categoryImagePath: ""
        })
        return toast.success("successfully deleted");
    }
}

class EditCategoryModal extends Component {

    state = {
        categories: this.props.categories || [],
        editMode: this.props.editMode,
        enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
        formValues: this.props.initialValues,
        checked: false,
        isSubCategories: this.props.isSubCategories
    };
    componentDidMount() {
        this.setState({
            formValues: this.props.initialValues
        });
    }
    render() {
        return (
            <div id="categoryDetails">
                <div className="row text-capitalize py-3 mt-2">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <span className="orderhding"> {!this.state.isSubCategories ? "Category Details" : "Sub Category Details"} </span>
                        {this.state.editMode && (
                            <button
                                className="float-right rEdit__editToggle"
                                onClick={this._toggleEditMode.bind(this)}
                            >
                                <i className="fa fa-pencil" aria-hidden="true" />
                            </button>
                        )}
                    </div>
                </div>
                <Form
                    onChange={formState => this.props.getFormValues("rForm", formState)}
                    getApi={this._setFormApi.bind(this)}
                >
                    {({ formState }) => {
                        return (
                            <>
                                <div className="row text-capitalize fsize border_bottom">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cName"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    {!this.state.isSubCategories ? "Category Name" : "Sub Category Name"}    <span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-7 form-group">
                                                <div className="">
                                                    <Text
                                                        field="cName"
                                                        type="text"
                                                        disabled={!this.state.enableInput}
                                                        initialValue={this.state.formValues.cName}
                                                        validate={val =>
                                                            validateText(
                                                                val,
                                                                3,
                                                                formState.touched.cName,
                                                                !this.state.isSubCategories ? "Category Name" : "Sub Category Name",
                                                                30
                                                            )
                                                        }
                                                        id="cEdit__cName"
                                                        placeholder="Enter  Name"
                                                        className={this.props.buildClassName.bind(this)(
                                                            formState.errors.cName,
                                                            this.state.enableInput
                                                        )}
                                                        validateOnBlur
                                                        validateOnChange
                                                    />
                                                    {formState.errors.cName && (
                                                        <span className="input_error">
                                                            {formState.errors.cName}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cSortOrder"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    {!this.state.isSubCategories ? "Category Sort Order" : "Sub Category Sort Order"} <span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-7 form-group">
                                                <div className="">
                                                    <Text
                                                        validate={val => {
                                                            if (isNaN(val)) return "please input a valid number";
                                                            if (!new RegExp(/^[0-9]+$/).test(val)) return "please input a valid number";
                                                            if (Number(val) > 1000) {
                                                                return "sort order cannot be more than 1000 ";
                                                            }
                                                            if (isNaN(val)) return "please input a valid number";
                                                            let notPositive = validateWholeNumber(
                                                                val,
                                                                !this.state.isSubCategories ? "Category Sort Order" : "Sub Category Sort Order"
                                                            );
                                                        }}
                                                        validateOnBlur
                                                        validateOnChange
                                                        field="cSortOrder"
                                                        type="text"
                                                        placeholder="Enter  sort order"
                                                        disabled={!this.state.enableInput}
                                                        initialValue={this.state.formValues.cSortOrder}
                                                        className={this.props.buildClassName.bind(this)(
                                                            formState.errors.cSortOrder,
                                                            this.state.enableInput
                                                        )}
                                                    />
                                                    {formState.errors.cSortOrder && (
                                                        <div className="input_error">
                                                            {formState.errors.cSortOrder}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.isSubCategories ? <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cParentCategory"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    category parent <span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-7 form-group">
                                                <div className="">
                                                    <Select
                                                        field="cParentCategory"
                                                        validateOnChange
                                                        className="form-control mr-sm-4 py-2"
                                                    >
                                                        <option value={""} disabled>
                                                            - Select Category -
                                                        </option>
                                                        {this.props.categories.length
                                                            ? this.props.categories.filter(ctg => {
                                                                return ctg.categoryParentId == null;
                                                            }).map((c, idx) => {
                                                                return (
                                                                    <option
                                                                        key={String(idx)}
                                                                        value={c.categoryId}
                                                                    >
                                                                        {c.categoryName}
                                                                    </option>
                                                                );
                                                            })
                                                            : null}
                                                    </Select>
                                                    {formState.errors.cParentCategory && (
                                                        <div className="input_error">
                                                            {formState.errors.cParentCategory}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}
                                    {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cActive"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    Up- Active <span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-7 form-group">
                                                <div className="">
                                                    <Checkbox
                                                        disabled={!this.state.enableInput}
                                                        initialValue={this.state.formValues.cActive}
                                                        field="cActive"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group row mx-0">
                                            <label className="label-name col-4 px-0">Pos-status:
                                                <Checkbox
                                                    field="cPosStatus"
                                                    initialValue={this.state.formValues.cat__pos__status}
                                                    disabled={!this.state.enableInput}
                                                /></label>
                                            <label className="label-name col-4 px-0">Online-status:
                                                <Checkbox
                                                    field="cOnlineStatus"
                                                    initialValue={this.state.formValues.cat__online__status}
                                                    disabled={!this.state.enableInput}
                                                /></label>
                                            <label className="label-name col-4 px-0">Aggregator status:
                                                <Checkbox
                                                    field="cAggStatus"
                                                    initialValue={this.state.formValues.cat__agg__status}
                                                    disabled={!this.state.enableInput}
                                                /></label>

                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="row align-items-center">
                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cDescription"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    {!this.state.isSubCategories ? "Category Description" : "Sub Category Description"}  <span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="col-8 col-sm-8 col-md-8 col-lg-8 form-group">
                                                <div className="">
                                                    <TextArea
                                                        rows={5}
                                                        field="cDescription"
                                                        validate={val => {

                                                            if (Number(val) > 500) {
                                                                return `${!this.state.isSubCategories ? "Category " : "Sub Category "}  Description cannot be more than 500 Characters.`;
                                                            }
                                                        }
                                                            // validateText(
                                                            //     val,
                                                            //     10,
                                                            //     formState.touched.cDescription,
                                                            //     "Category Description",
                                                            //     400
                                                            // )
                                                        }
                                                        validateOnBlur
                                                        validateOnChange
                                                        disabled={!this.state.enableInput}
                                                        initialValue={this.state.formValues.cDescription}
                                                        className={this.props.buildClassName.bind(this)(
                                                            formState.errors.cDescription,
                                                            this.state.enableInput
                                                        )}
                                                        placeholder="Enter  description"
                                                    />
                                                    {formState.errors.cDescription && (
                                                        <div className="input_error">
                                                            {formState.errors.cDescription}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!this.state.isSubCategories ?
                                        <div>
                                            <div className="col-6 col-md-6 col-lg-2 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cDescription"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    category Icon <span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="form-group clearfix justify-content-between">
                                                <RadioGroup
                                                    field="cIcon"
                                                    initialValue={this.state.formValues.cIcon}
                                                >
                                                    {this.props.categoryIcons.map(
                                                        ({ name, id, img }) => (
                                                            <label
                                                                className={
                                                                    formState.values.cIcon == id
                                                                        ? "category_icon img-thumbnail selected-icon"
                                                                        : "category_icon img-thumbnail"
                                                                }
                                                                key={id}
                                                            >
                                                                <img
                                                                    src={img}
                                                                    className=""
                                                                    alt={name}
                                                                    style={{
                                                                        maxWidth: "80%",
                                                                        maxHeight: "80%"
                                                                    }}
                                                                />{" "}
                                                                <Radio value={id} style={{ opacity: 0 }} />
                                                            </label>
                                                        )
                                                    )}
                                                </RadioGroup>
                                            </div>
                                            {formState.errors.cIcon && (
                                                <div className="input_error">
                                                    {formState.errors.cIcon}
                                                </div>
                                            )}
                                        </div> : null}
                                </div>



                            </>

                        )
                    }}

                </Form>
            </div >
        )
    }
    _validateCategoryDesc(desc) {
        if (!desc) {
            //this is the first focus, do not validate.
            return false;
        }
        desc = desc && desc.trim();
        if (!desc || desc.length < 10) {
            return "Description must be atleast 10 Characters long";
        }
        if (desc.length > 400) {
            return "Description must not be larger than 400 characters";
        }
    }

    _setFormApi(formApi) {
        this._formApi = formApi;
    }

    _toggleEditMode() {
        let numErrors = 0;
        let formErrors = this._formApi.getState().errors;
        for (let i in formErrors) {
            if (formErrors[i]) {
                numErrors++;
            }
        }
        if (this.state.enableInput && numErrors) {
            return console.error("clear errors first");
        }
        this.setState({
            enableInput: !this.state.enableInput
        });
    }
}