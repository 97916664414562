//@ts-check
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Text, Form, Select, Checkbox } from "informed";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import Loader from "react-loader-spinner";
import $ from "jquery";
import { fetchCategories } from "../services";
import {
  AuthContext,
  CityListProvider,
  CityListContext
} from "../contextProvider";
import { throttleTime, validatePhone, validateEmail, validateText, validateStrictText } from "../utilities";
import moment from "moment";
import { toast } from "react-toastify";
import LocationSearchInput from "./partials/LocationSearchInput";
import optionGroups from "./optionGroups";
import QRCode from 'qrcode.react';
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);
// import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

// import { Typeahead,AsyncTypeahead } from "react-bootstrap-typeahead";

export default class AdminProfile extends Component {
  constructor(props) {
    super(props);
    this.rAdminFormRef = React.createRef();
  }
  static contextType = AuthContext;

  state = {
    // enableInput:this.props.location.state && this.props.location.state.restarantId
    adminDetails: null,
    rTimings: {},
    disableInput: true,
    imagePath: "",
    baseUrl: "",
    allCategories: [],
    charges: [],
    taxes: [],
    allOptions: [],
    allItems: [],
    optionGroups: [],
    urbanSyncDetails: []
  };

  componentWillMount() { }
  componentDidMount() {
    this._loadAdminDetails();
    if (!this.context.isSuperAdmin) {
      this._loadAllCategories()
      this._loadAllMenuItems()
      this._loadAllOptions();
      this._loadAllOptionGroups();
      this._loadTaxes();
      this._loadCharges();
      this._loadUrbanCatalogueSyncDetails();
    }
  }

  render() {
    let editMode = false;
    return (
      <div className="content of-auto">
        <div className="mx-0">
          <h3 className="order">Admin Profile</h3>
          <div className="row mx-0 my-3">
            <div className="profile-img">
              <label
                className="my-3"
                id="img_preview_label"
                htmlFor="img_preview"
                style={{
                  backgroundImage: this.state.imagePath
                    ? `url(${this.context.CONSTANTS.apiBasePath.split(
                      "/api"
                    )[0] + this.state.imagePath})`
                    : `url(${this.state.displayImage})`
                }}
              >
                <input
                  type="file"
                  className="img_preview"
                  accept="image/*"
                  id="img_preview"
                  onChange={this.previewImage.bind(this)}
                />
                {!(this.state.imagePath || this.state.displayImage) && (
                  <i
                    className="fa fa-upload img-upload-icon"
                    aria-hidden="true"
                  />
                )}
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4" >
              <h5>Feedback QrCode</h5>
              <QRCode
                id="qrCodeEl"
                size={200}
                value={`${this.context.CONSTANTS.apiBasePath.split("/api")[0]}/Feedback/${this.context.user.restarantName}/${this.context.user.encryptRestId}`}
              />
              <input
                type="button"
                className="download-btn"
                value="Download"
                onClick={this.downloadQRCode}
              />
            </div>
          </div>

          <AdminDetailsForm
            rLocationErr={this.state.rLocationErr}
            setLocationAddress={val => {
              this.setState({
                rAddress: val
              });
            }}
            setLocationCoords={val => {
              this.setState({
                rLocation: val.lat + "," + val.lng
              });
            }}
            onLocationSelected={val => {
              this.setState({ rLocation: val });
            }}
            setTimings={this.setTimings.bind(this)}
            ref={this.rAdminFormRef}
            getFormValues={this._getFormValues.bind(this)}
            initialValues={{}}
            editMode={editMode}
            buildClassName={this.buildClassName.bind(this)}
            CONSTANTS={this.context.CONSTANTS}
            user={this.context.user}
            authToken={this.context.authToken}
          />

          <div className="col-lg-12 col-xxl-8 text-right">
            <button
              type="button"
              className="savebtn"
              onClick={() => this._editProfile(!!editMode)}
            >
              Save Changes
            </button>
          </div>
          {this.context.user.urbanPiperStatus == 1 ?
            <UrbanSync
              CONSTANTS={this.context.CONSTANTS}
              user={this.context.user}
              authToken={this.context.authToken}
              categories={this.state.allCategories}
              items={this.state.allItems}
              optionGroups={this.state.optionGroups}
              options={this.state.allOptions}
              taxes={this.state.taxes}
              charges={this.state.charges}
              urbanSyncDetails={this.state.urbanSyncDetails}
            /> : null}
        </div>
      </div>
    );
  }
  downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl').toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  previewImage(e) {
    let image = e.nativeEvent.target.files[0];
    if (image.type.split("/")[0] != "image") {
      e.target.value = null;

      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayImage: URL.createObjectURL(image)
      },
      async err => {
        //upload image
        let data = new FormData();
        data.append("file", image);
        data.append("filename", "myfile");
        data.append(
          "oldPath",
          this.state.imagePath ? this.state.imagePath : ""
        );

        let { apiBasePath, apiRoutes } = this.context.CONSTANTS;
        let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));

        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));

        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          imagePath: response.data,
          displayImage: "",
          oldImagePath: this.state.imagePath
        });
      }
    );
  }

  _getFormValues(formName, values) {
    this.setState({
      // [formName]: values
    });
  }

  setTimings(obj) {
    this.setState({
      rTimings: obj
    });
  }

  _editProfile(edit) {
    this.rAdminFormRef.current._formApi.submitForm();
    // this.rHeadFormRef.current._formApi.submitForm();
    this.setState(
      {
        formInValid: Object.keys(
          this.rAdminFormRef.current._formApi.getState().errors
        ).length, //headForm is optional
        formValues: {
          ...this.rAdminFormRef.current._formApi.getState().values
        }
      },
      async () => {
        if (this.state.formInValid) {
          return false;
        }
        const { CONSTANTS, authToken, user } = this.context;
        this.setState({
          saveProgress: true
        });
        let body = {
          user_id: user.userId,
          user_name: this.state.formValues.rAName,
          user_image_url: this.state.imagePath,
          user_phone: this.state.formValues.rAPhone,
          user_email: this.state.formValues.rAEmail || "",
          user_address: this.state.formValues.rAAddress,
          old_image_url: this.state.oldImagePath
        };
        let apiRoute = CONSTANTS.apiRoutes.editAdminProfile;
        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Authorization: authToken
          },
          body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
          this.setState({
            saveProgress: false
          });
          return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
          .json()
          .catch(err => console.error(err));
        if (!savedResponseResult) {
          return this.setState({
            saveProgress: false,
            msg: "An Error Occured while saving the changes"
          });
        }
        if (!savedResponseResult.success) {
          this.setState({
            saveProgress: false
          });
          const duplicate = savedResponseResult.duplicate;

          switch (duplicate) {
            case "user_phone":
              this.rAdminFormRef.current._formApi.setError(
                "rAPhone",
                savedResponseResult.msg
              );
              break;
            case "user_email":
              this.rAdminFormRef.current._formApi.setError(
                "rAEmail",
                savedResponseResult.msg
              );
              break;

            default:
          }

          return toast.error(savedResponseResult.msg);
        }
        this.context.updateLocalProfile(body);
        toast.success("Successfully updated the profile");
      }
    );
  }

  buildClassName(err, enableInput) {
    let c = "w-100 restarantInput";
    !enableInput && (c += " restarantInput--disabled");
    err && (c += " input_error");
    return c;
  }

  async _loadAdminDetails(adminData) {
    const user = this.context.user;
    user.profilePic &&
      this.setState({
        imagePath: user.profilePic
      });
    user.userName &&
      this.rAdminFormRef.current._formApi.setValue("rAName", user.userName);
    user.userEmail &&
      this.rAdminFormRef.current._formApi.setValue("rAEmail", user.userEmail);
    user.userPhone &&
      this.rAdminFormRef.current._formApi.setValue("rAPhone", user.userPhone);
    this.rAdminFormRef.current._formApi.setValue("rAAddress", user.userAddress);
  }
  async _loadAllCategories() {
    let categoriesResponse = await fetchCategories({
      CONSTANTS: this.context.CONSTANTS,
      userId: this.context.user.userId,
      limit: 0,
      offset: 0,
      authToken: this.context.authToken
    })
      .catch(err => console.error(err));

    if (!categoriesResponse) {
      let msg = "An Error Occured while fetching Categories.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    if (!categoriesResponse.success) {
      let msg = categoriesResponse.msg;
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    this.setState({
      allCategories: categoriesResponse.data
    });
  }

  async _loadAllMenuItems() {
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const itemsResponse = await fetch(
      apiBasePath +
      apiRoutes.getMenuItems +
      `?user_id=${this.context.user.userId}&limit=0&offset=0&restarant_id=${this.context.user.selectedRestarantId}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!itemsResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const itemsResponseResult = await itemsResponse.json();
    if (!itemsResponseResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!itemsResponseResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: itemsResponseResult.msg
      });
      return toast.error(itemsResponseResult.msg);
    }
    this.setState({
      allItems: itemsResponseResult.data
    });
  }
  async _loadAllOptions() {
    this.setState({
      fetchProgress: true
    });
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const requestSendTime = Date.now();
    const optionResponseWithoutLimit = await fetch(
      apiBasePath +
      apiRoutes.getOptions +
      `?user_id=${this.context.user.userId}&limit=${0
      }&offset=${0}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!optionResponseWithoutLimit) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const allOptionsResponseResult = await optionResponseWithoutLimit.json();
    if (!allOptionsResponseResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!allOptionsResponseResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: allOptionsResponseResult.msg
      });
      return toast.error(allOptionsResponseResult.msg);
    }

    this.setState({
      allOptions: allOptionsResponseResult.data
    });

  }

  async _loadAllOptionGroups() {
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const optionGroupResponse = await fetch(
      apiBasePath +
      apiRoutes.getOptionGroups +
      `?user_id=${this.context.user.userId}&limit=${0
      }&offset=${0}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!optionGroupResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const optionGroupsResponseResult = await optionGroupResponse.json();
    if (!optionGroupsResponseResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    this.setState({
      optionGroups: optionGroupsResponseResult.data
    })

  }
  async _loadTaxes() {
    this.setState({
      fetchProgress: true
    });
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const requestSendTime = Date.now();
    const taxResponse = await fetch(
      apiBasePath +
      apiRoutes.getTaxes +
      `?user_id=${this.context.user.userId}&limit=${0
      }&offset=${0}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!taxResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const taxResponseResult = await taxResponse.json();
    if (!taxResponseResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!taxResponseResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: taxResponseResult.msg
      });
      return toast.error(taxResponseResult.msg);
    }
    throttleTime(
      () => {
        this.setState({
          taxes: taxResponseResult.data,
          totalTaxes: taxResponseResult.total,
          fetchProgress: false
        });
      },
      requestSendTime,
      500
    )

  }
  async _loadCharges() {
    this.setState({
      fetchProgress: true
    });
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const requestSendTime = Date.now();
    const chargeResponse = await fetch(
      apiBasePath +
      apiRoutes.getCharges +
      `?user_id=${this.context.user.userId}&limit=${0
      }&offset=${0}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!chargeResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const chargeResponseResult = await chargeResponse.json();
    if (!chargeResponseResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!chargeResponseResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: chargeResponseResult.msg
      });
      return toast.error(chargeResponseResult.msg);
    }
    throttleTime(
      () => {
        this.setState({
          charges: chargeResponseResult.data,
          totalCharges: chargeResponseResult.total,
          fetchProgress: false,
        });
      },
      requestSendTime,
      500
    )
  }
  async _loadUrbanCatalogueSyncDetails() {
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const urbanCatalogueSyncDetails = await fetch(
      apiBasePath +
      apiRoutes.getUrbanCatalogueSyncDetails +
      `?user_id=${this.context.user.userId}&restarant_id=${this.context.user.selectedRestarantId}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!urbanCatalogueSyncDetails) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const urbanCatalogueSyncDetailsResult = await urbanCatalogueSyncDetails.json();
    if (!urbanCatalogueSyncDetailsResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    this.setState({
      urbanSyncDetails: urbanCatalogueSyncDetailsResult.data
    })

  }
}

class AdminDetailsForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues
  };
  constructor(props) {
    super(props);
    this.changePwdModal = React.createRef();
  }
  componentDidMount() {
    $(this.changePwdModal.current).on("hidden.bs.modal", () => {
      this._changePwdForm.reset();
    });
  }

  render() {
    return (
      <div id="restarantOwnerDetails">
        <div className="row text-capitalize py-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            Restarant Name   :    <span className="orderhding">{this.props.user.restarantName}</span>
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-8" />
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Profile</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-8 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-link btn-sm"
              data-toggle="modal"
              data-target="#pwdModal"
              style={{ border: 0, textDecoration: "underline" }}
            >
              Change Password
            </button>
          </div>
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rOForm", formState)}
          getApi={formApi => this._setFormApi(formApi, "_formApi")}
        >
          {({ formState }) => {
            return (
              <>
                <div className="col-12 col-lg-12 col-xl-12 col-xxl-8 px-0">
                  <div className="row text-capitalize fsize">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="row align-items-center mx-0">
                        <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                          <div
                            htmlFor="rEdit__rOName"
                            className="row mx-0 w-100 justify-content-between"
                          >
                            Admin Name <span>:</span>
                          </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-7 form-group">
                          <div className="">
                            <Text
                              field="rAName"
                              id="rEdit__rAName"
                              type="text"
                              disabled={!this.state.enableInput}
                              initialValue={this.state.formValues.rAName}
                              className={this.props.buildClassName.bind(this)(
                                formState.errors.rAName,
                                this.state.enableInput
                              )}
                              validate={val =>
                                validateStrictText(
                                  val,
                                  4,
                                  formState.touched.rAName,
                                  "Name",
                                  30
                                )
                              }
                              validateOnBlur
                              validateOnChange
                            />
                            {formState.errors.rAName && (
                              <div className="input_error">
                                {formState.errors.rAName}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="row align-items-center mx-0">
                        <div className="col-6 col-md-6 col-lg-5 mb-3 restarant_input__labelWrapper d-flex align-items-center">
                          <div
                            htmlFor="rEdit__rAEmail"
                            className="row mx-0 justify-content-between w-100"
                          >
                            Email <span>:</span>
                          </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-7 form-group">
                          <div className="">
                            <Text
                              field="rAEmail"
                              id="rEdit__rAEmail"
                              type="text"
                              validate={val =>
                                validateEmail(val, formState.touched.rAEmail)
                              }
                              validateOnBlur
                              validateOnChange
                              disabled={!this.state.enableInput}
                              initialValue={this.state.formValues.rAEmail}
                              className={this.props.buildClassName.bind(this)(
                                formState.errors.rAEmail,
                                this.state.enableInput
                              )}
                            />
                            {formState.errors.rAEmail && (
                              <div className="input_error">
                                {formState.errors.rAEmail}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="row align-items-center mx-0">
                        <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                          <div
                            htmlFor="rEdit__rAPhone"
                            className="row mx-0 justify-content-between w-100"
                          >
                            Mobile number <span>:</span>
                          </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-7 form-group">
                          <div className="">
                            <Text
                              id="rEdit__rAPhone"
                              field="rAPhone"
                              type="text"
                              disabled={!this.state.enableInput}
                              initialValue={this.state.formValues.rOPhone}
                              className={this.props.buildClassName.bind(this)(
                                formState.errors.rAPhone,
                                this.state.enableInput
                              )}
                              validate={val =>
                                validatePhone(val, formState.touched.rAPhone)
                              }
                              validateOnBlur
                              validateOnChange
                            />
                            {formState.errors.rAPhone && (
                              <div className="input_error">
                                {formState.errors.rAPhone}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="row align-items-center mx-0">
                        <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                          <div
                            className="w-100 row mx-0 justify-content-between"
                            htmlFor="rEdit__rOAddress"
                          >
                            Address <span>:</span>
                          </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-7 form-group">
                          <div className="">
                            <Text
                              field="rAAddress"
                              id="rEdit__rAAddress"
                              type="text"
                              disabled={!this.state.enableInput}
                              initialValue={this.state.formValues.rOAddress}
                              className={this.props.buildClassName.bind(this)(
                                formState.errors.rAAddress,
                                this.state.enableInput
                              )}
                              validateOnBlur
                              validateOnChange
                              validate={val =>
                                validateText(
                                  val,
                                  10,
                                  formState.touched.rAAddress,
                                  "Address"
                                )
                              }
                            />
                            {formState.errors.rAAddress && (
                              <div className="input_error">
                                {formState.errors.rAAddress}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
        {this._pwdModal()}
      </div>
    );
  }

  basicValidation = value => {
    if (!value) return "Please Enter New Password.";
    return value.length < 8
      ? "Password must be at least eight characters"
      : undefined;
  };

  matchValidation = (value, values) => {
    if (!values.confirmPwd) return "Please Confirm Password.";
    return values.newPwd !== values.confirmPwd
      ? "Passwords donot match"
      : undefined;
  };

  passwordValidation = (value, values) => {
    return this.basicValidation(value) || this.matchValidation(value, values);
  };

  _pwdModal() {
    return (
      <div className="modal fade" id="pwdModal" ref={this.changePwdModal}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <Form
              onSubmit={formValues => this._changePassword(formValues)}
              getApi={formApi => this._setFormApi(formApi, "_changePwdForm")}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-header">
                      <h4 className="modal-title mt-0">Update Password</h4>
                      <button
                        type="button"
                        className="close float-right"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>

                    <div className="modal-body">
                      <div className="form-group">
                        <label className="d-block">
                          Old Password
                          <Text
                            field="oldPwd"
                            id="changePassword__oldPwd"
                            autocomplete="old_password"
                            type="password"
                            className="form-control"
                            placeholder="Enter Old Password"
                            validateOnBlur
                            validateOnChange
                            validate={val => {
                              if (!val && !formState.touched.oldPwd) {
                                return null;
                              }

                              if (!val)
                                return "Please Enter your current Password.";
                            }}
                          />
                          {formState.errors.oldPwd && (
                            <div className="input_error">
                              {formState.errors.oldPwd}
                            </div>
                          )}
                        </label>
                      </div>

                      <div className="form-group">
                        <label className="d-block">
                          New Password
                          <Text
                            field="newPwd"
                            autocomplete="new_password"
                            id="changePassword__newPwd"
                            type="password"
                            className="form-control"
                            placeholder="Enter New Password"
                            validateOnBlur
                            validateOnChange
                            validate={val => this.basicValidation(val)}
                          />
                          {formState.errors.newPwd && (
                            <div className="input_error">
                              {formState.errors.newPwd}
                            </div>
                          )}
                        </label>
                      </div>

                      <div className="form-group">
                        <label className="d-block">
                          Confirm Password
                          <Text
                            field="confirmPwd"
                            id="changePassword__confirmPwd"
                            type="password"
                            autocomplete="new_password"
                            className="form-control"
                            placeholder="Confirm New Password"
                            validateOnBlur
                            validateOnChange
                            validate={val => {
                              return this.matchValidation(
                                val,
                                formState.values
                              );
                            }}
                          />
                          {formState.errors.confirmPwd && (
                            <div className="input_error">
                              {formState.errors.confirmPwd}
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="change-pwd__submit"
                      >
                        Update Password
                        {this.state.updatePwdProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  async _changePassword(formValues) {
    if (this.state.updatePwdProgress) return false;
    let formState = this._changePwdForm.getState();
    if (formState.errors.oldPwd) {
      return false;
    }
    if (formState.errors.newPwd) {
      return false;
    }
    // if (!formValues.oldPwd) return false;
    // if (!formValues.newPwd) return false;//keep these separate, so that errors are shown one by one.
    const { CONSTANTS, authToken, user } = this.props;
    this.setState({
      updatePwdProgress: true
    });

    let body = {
      user_id: user.userId,
      new_pwd: formValues.newPwd,
      old_pwd: formValues.oldPwd
    };
    let apiRoute = CONSTANTS.apiRoutes.updateAdminPwd;
    const updateResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));

    if (!updateResponse) {
      this.setState({
        updatePwdProgress: false
      });
      return toast.error("An Error Occured while saving the changes");
    }
    const updateResponseResult = await updateResponse
      .json()
      .catch(err => console.error(err));
    if (!updateResponseResult) {
      return this.setState({
        updatePwdProgress: false,
        msg: "An Error Occured while saving the changes"
      });
    }
    if (!updateResponseResult.success) {
      this.setState({
        updatePwdProgress: false
      });

      return toast.error(updateResponseResult.msg);
    }

    this.setState({
      updatePwdProgress: false
    });

    $(this.changePwdModal.current).modal("hide");
    toast.success("Successfully updated the password", { autoClose: 3000 });
    this._changePwdForm.reset();
  }

  _setFormApi(formApi, formTitle) {
    this[formTitle] = formApi;
  }

  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("clear errors first");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}

class UrbanSync extends Component {
  state = {
    editMode: false,
    fCat: false,
    fItems: false,
    fOptions: false,
    fOGroups: false,
    fTaxes: false,
    fCharges: false
  };
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }
  render() {
    return (
      <div id="restarantOwnerDetails">
        <div className="row text-capitalize py-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Urban Sync</span>
          </div>
          {this.props.urbanSyncDetails.length ?
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <span className="orderhding">Errors:</span>
              <span>{this.props.urbanSyncDetails[0].errors}</span>
            </div> : null}
          {this.props.urbanSyncDetails.length ?
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <span className="orderhding">Last Sync Date:</span>
              <span>{new Date(this.props.urbanSyncDetails[0].updatedAt).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })}</span>
            </div> : null}
          <div className="col-12 col-sm-12 col-md-12 col-lg-12" />
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <label >
              <span>Flush-Categories:</span>
              <input type="checkbox" name="fCat" checked={this.state.fCat} onChange={this.handleChange.bind(this)} />
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <label style={{ flex: 1, justifyContent: 'space-evenly' }}>
              <span>Flush-Items:</span>
              <input type="checkbox" name="fItems" checked={this.state.fItems} onChange={this.handleChange.bind(this)} />
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <label style={{ flex: 1, justifyContent: 'space-evenly' }}>
              <span>Flush-Options</span>
              <input type="checkbox" name="fOptions" checked={this.state.fOptions} onChange={this.handleChange.bind(this)} />
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <label style={{ flex: 1, justifyContent: 'space-evenly' }}>
              <span>Flush-OptionGroups:</span>
              <input type="checkbox" name="fOGroups" checked={this.state.fOGroups} onChange={this.handleChange.bind(this)} />
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <label style={{ flex: 1, justifyContent: 'space-evenly' }}>
              <span>Flush-Taxes:</span>
              <input type="checkbox" name="fTaxes" checked={this.state.fTaxes} onChange={this.handleChange.bind(this)} />
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <label style={{ flex: 1, justifyContent: 'space-evenly' }}>
              <span>Flush-Charges:</span>
              <input type="checkbox" name="fCharges" checked={this.state.fCharges} onChange={this.handleChange.bind(this)} />
            </label>
          </div>
        </div>
        <div className="col-lg-12 col-xxl-8 text-right">
          <button
            type="button"
            className="savebtn"
            onClick={() => this._syncCatalogue()}
          >
            Sync Catalogue
          </button>
        </div>
      </div>
    );
  }
  async _syncCatalogue() {
    const { apiBasePath, upApiBasePath } = this.props.CONSTANTS;
    let baseUrl = apiBasePath.split("/api")[0];
    let filterCategories = this.props.categories.filter(i => { return i.catAggStatus == true });
    let cat = filterCategories.map((c, index) => {
      let ctgry = {
        ref_id: c.categoryId,
        name: c.categoryName,
        description: c.categoryDescription,
        sort_order: c.categorySortOrder,
        active: c.onlineActive == "35" ? true : false,
        img_url: c.categoryImage ? baseUrl + c.categoryImage : "",
        translations: []
      }
      if (c.categoryParentId) {
        ctgry.parent_ref_id = c.categoryParentId;
      }
      return ctgry;
    })
    let filterItems = this.props.items.filter(i => { return i.itemSoldAtStore == true });
    let filterItemPriceZero = filterItems.filter(i => { return i.itemAggPrice == 0 });
    if (filterItemPriceZero.length) {
      let itemNames = [];
      filterItemPriceZero.map(f => itemNames.push(f.itemTitle))
      return toast.error(`Some Items ${itemNames.join()} are price with zero `);
    }
    let items = filterItems.map((i, idx) => {
      let incPlatforms = i.itemIncludedPlatforms ? i.itemIncludedPlatforms.itemIncludedPlatforms : []
      let itm = {
        ref_id: i.itemId,
        title: i.itemTitle,
        price: i.itemAggPrice,
        markup_price: i.itemMarkupPrice ? i.itemMarkupPrice : Number,
        ref_title: i.itemShortTitle,
        description: i.itemDescription,
        weight: i.itemWeight ? i.itemWeight : Number,
        sold_at_store: i.itemSoldAtStore,
        available: i.onlineStatus == "30" ? true : false,
        sort_order: i.itemSortOrder ? i.itemSortOrder : 0,
        current_stock: i.itemCurrentStock ? i.itemCurrentStock : Number,
        category_ref_ids: [String(i.subCategoryId)],
        food_type: String(i.itemType),
        recommended: i.itemRecommended,
        fulfillment_modes: i.itemFulfillmentModes.itemFulfillmentModes,
        translations: [],
        included_platforms: incPlatforms,

      }
      if (i.itemImage) {
        itm.img_url = baseUrl + i.itemImage;
      }
      if (i.itemPlatformImages.itemPlatformImages.length) {
        let images = [];
        i.itemPlatformImages.itemPlatformImages.map(i => {
          images.push({ tag: i.value, url: "https://buvvasmini.com" + i.platImgName });
        });
        itm.images = images;
      }
      if(i.itemTags && i.itemTags.length) {
        let tags  = {};
        i.itemTags.map(i =>{
          let r = {[i.tagName]: [i.tagValue]}
           Object.assign(tags, r);
        });
        itm.tags =  tags
      }
      return itm;
    });
    let optionGroups = this.props.optionGroups.filter(og => { return og.optionGroupOnlineActive == true });
    let oGroups = optionGroups.map((o, index) => {
      return {
        ref_id: String(o.optionGroupId),
        title: o.optionGroupTitle,
        ref_title: o.optionGroupRefTitle,
        description: o.optionGroupDescription,
        min_selectable: o.optionGroupMinSelectable,
        max_selectable: o.optionGroupMaxSelectable,
        sort_order: o.optionGroupSortOrder,
        active: o.optionGroupOnlineActive,
        display_inline: o.optionGroupDisplayInline,
        item_ref_ids: JSON.parse(o.optionGroupItemRefIds),
        translations: []
      }
    });
    let options = this.props.options.filter(o => { return o.optionOnlineActive == true });
    let opt = options.map((opt, index) => {
      return {
        ref_id: String(opt.optionId),
        title: opt.optionTitle,
        ref_title: opt.optionRefTitle,
        description: opt.optionDescription,
        weight: opt.optionWeight,
        available: opt.optionOnlineActive,
        recommended: opt.optionRecommended,
        price: opt.optionAggPrice,
        sold_at_store: opt.optionSoldAtStore,
        sort_order: opt.optionSortOrder,
        opt_grp_ref_ids: JSON.parse(opt.optionGroupRefIds),
        nested_opt_grps: [],
        food_type: opt.optionFoodType,
        translations: []
      }
    })
    let taxes = this.props.taxes.map((t, index) => {
      return {
        code: t.taxCode,
        title: t.taxTitle,
        description: t.taxDescription,
        active: t.taxOnlineActive,
        structure: {
          value: t.taxStructureValue
        },
        item_ref_ids: t.taxItemRefIds.taxItemRefIds
      }
    })
    let charges = this.props.charges.map((c, index) => {
      return {
        code: c.chargeCode,
        title: c.chargeTitle,
        description: c.chargeDescription,
        active: c.chargeActive,
        structure: {
          applicable_on: c.chargeStructureApplicableOn,
          value: c.chargeStructureValue
        },
        fulfillment_modes: c.chargeFullfillmentMode.chargeFullfillmentMode,
        excluded_platforms: c.chargeExcludedPlatforms.chargeExcludedPlatforms,
        item_ref_ids: c.chargeItemRefIds ? c.chargeItemRefIds.chargeItemRefIds : []
      }
    })
    this.setState({
      saveProgress: true
    });
    let body = {
      flush_categories: this.state.fCat,
      categories: cat,
      flush_items: this.state.fItems,
      items: items,
      flush_option_groups: this.state.fOGroups,
      option_groups: oGroups,
      flush_options: this.state.fOptions,
      options: opt,
      flush_taxes: this.state.fTaxes,
      taxes: taxes,
      flush_charges: this.state.fCharges,
      charges: charges
    }
    const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/locations/" + this.props.user.restarantId + "/", {
      // " + this.props.user.restarantId + "
      // async: true,
      // crossDomain: true,
      method: "POST",
      headers: {
        Authorization: this.props.user.urbanAuthorization,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body),
      retries: 3,
      retryDelay: 1000,
      retryOn: [500, 503]
    }
    )
    if (!savedResponse) {
      let msg = "An error Occurred"
      this.setState({
        saveProgress: false
      });
      return toast.error(msg)
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (savedResponseResult.status == "error") {
      this.setState({
        saveProgress: false
      });
      return toast.error(savedResponseResult.message)
    }
    if (savedResponseResult.status == "success") {
      this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference)
      setTimeout(() => {
        toast.success(
          "Successfully synched"
        );
      }, 1500);
      return toast.success(savedResponseResult.message);
    }

  }

  async saveWebhookResponse(restId, referenceId) {
    const { CONSTANTS, authToken } = this.props
    let body = {
      restaurant_id: restId,
      reference_id: referenceId,
    }

    let apiRoute = CONSTANTS.apiRoutes.UrbanCatalogueResponse;

    const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));

    if (!savedResponse) {
      this.setState({
        saveProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      this.setState({
        saveProgress: false,

      });
      return toast.error("An error occurred while storing the response.")
    }
    if (!savedResponseResult.success) {
      toast.error(savedResponseResult.msg, { autoClose: 8000 });
      return this.setState({
        saveProgress: false
      });
    }
    this.setState({
      saveProgress: false
    });
  }
}
