import React, { Component } from "react";
import { throttleTime } from "../utilities";
import { AuthContext } from "../contextProvider";
import moment from "moment";
import { Link } from "react-router-dom";
import $ from "jquery";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";

export default class GuestDetails extends Component {
  state = {
    guest: this.props.location.state.guest,
    surroundingButtonsCount:3,
    pastOrders: [],
    fetchProgress: true,
    fetchFailMsg: "",
    currentPageNum: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    setGuestStatus: this.props.location.state.guest.guestStatus
  };

  static contextType = AuthContext;

  componentWillMount() {
    this._fetchPastOrders();
  }

  componentDidMount() {
    this.cModal = React.createRef();
  }

  render() {
    return (
      <>
        <div className="contentvthbtn">
          <div className="row mx-0">
            <div className="col-6 col-sm-5 col-md-5 col-lg-5 pl-0 d-flex align-items-center display-3 categorycol">
              <i
                className="fa fa-long-arrow-left"
                aria-hidden="true"
                style={{
                  marginRight: "2rem",
                  fontSize: "2rem",
                  cursor: "pointer"
                }}
                onClick={this.props.history.goBack}
              />
              <span className="">Guest Details</span>
            </div>
            <div className="col-6 col-sm-7 col-md-7 col-lg-7 pr-0" />
          </div>
          <div className="table-responsive mt-3 text-capitalize">
            <table className="table">
              <thead>
                <tr>
                  <th />
                  <th>guest name</th>

                  <th>contact number</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td>{this.state.guest.guestName}</td>

                  <td>{this.state.guest.guestPhone}</td>
                  <td>
                    <select
                      onChange={e =>
                        this.setState(
                          {
                            setGuestStatus: e.nativeEvent.target.value
                          },
                          (err, result) => {
                            if (err) return console.error(err);
                            this.confirmAndUpdateGuestStatus();
                          }
                        )
                      }
                      value={this.state.guest.guestStatus}
                      className="blckactive"
                    >
                      <option value="1">Active</option>
                      <option value="0">Block</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row mx-0">
            <div className="col-6 col-sm-5 col-md-5 col-lg-5 pl-0">
              <span className="categorycol">History</span>
            </div>
            <div className="col-6 col-sm-7 col-md-7 col-lg-7 pr-0" />
          </div>
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : this.state.pastOrders.length ? (
            <div className="table-responsive mt-3 text-capitalize tableofauto1">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>date</th>
                    <th>invoice ID</th>
                    <th>restaurant name</th>
                    {/* <th>Location</th> */}
                    <th>contact number</th>
                    <th>view Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.pastOrders.map((o, idx) => {
                    return (
                      <tr key={"pastOrder" + idx}>
                        <td>
                          {(this.state.currentPageNum - 1) *
                            this.state.recordsPerPage +
                            (idx + 1)}
                        </td>
                        <td>{moment(o.orderCreatedAt).format("L")}</td>
                        <td>{o.invoiceId || "N/A"}</td>
                        <td>{o.restarantName}</td>
                        {/* <td>{o.restarantLocation}</td> */}
                        <td>{o.restarantPhone}</td>
                        <td>
                          {o.invoiceLink ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                this.context.CONSTANTS.apiBasePath.split(
                                  "/api"
                                )[0] + o.invoiceLink
                              }
                            >
                              View
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="noDataMsgContainer">
              <h5>{this.state.noDataMsg}</h5>
            </div>
          )}
        </div>
        {this._pagination()}
        {this.confirmationModal()}
      </>
    );
  }

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalRecords / this.state.recordsPerPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.state.totalRecords / this.state.recordsPerPage
            )}
            // pageCount={10}
            marginPagesDisplayed={this.state.surroundingButtonsCount}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this.getMore(data.selected + 1)}
            containerClassName={"pagination"}
            // subContainerClassName={''}
            activeLinkClassName={"active"}
          />
        ) : null}
      </div>
    );
  }

  getMore(pageNum){
    this.setState({
      currentPageNum:pageNum
    },(err,result)=>{
      if(err) return console.error(err)
      this._fetchPastOrders()
    })
  }

  showStatusChangeModal(val) {
    this.setState({
      setGuestStatus: val
    });
    $(this.cModal.current).modal("show");
  }

  confirmationModal() {
    return (
      <div
        ref={this.cModal}
        className="modal fade"
        id="confirmationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.setGuestStatus == 1 ? "Un-Block" : "Block"} the
                User?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body" />
            <div className="modal-footer clearfix d-block">
              <button
                type="submit"
                className="btnmodaladd text-uppercase float-right"
                onClick={this.updateGuestStatus.bind(this)}
              >
                {this.state.setGuestStatus ? "Save Changes" : "..."}
                {this.state.updateStatusProgress && (
                  <Loader
                    type="ThreeDots"
                    color="#fff"
                    height={20}
                    width={30}
                    style="loaderInsideBtn"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  confirmAndUpdateGuestStatus() {
    confirmAlert({
      title:
        this.state.setGuestStatus == 1
          ? "UnBlock the User?"
          : "Block the user?",
      message: `Do you want to ${
        this.state.setGuestStatus == 1 ? "unblock" : "block"
      } ${this.state.guest.guestName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.updateGuestStatus()
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async updateGuestStatus() {
    this.setState({
      statusUpdateProgress: true
    });
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const savedResponse = await fetch(
      apiBasePath + apiRoutes.updateGuestStatus,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
          user_id: this.context.user.userId,
          guest_id: this.state.guest.guestId,
          status: this.state.setGuestStatus
        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while ${
        this.setGuestStatus == 1 ? "Unblocking" : "Blocking"
      } the guest.`;
      this.setState({
        updateFailMsg: msg,
        statusUpdateProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while ${
        this.setGuestStatus == 1 ? "Unblocking" : "Blocking"
      } the guest.`;
      this.setState({
        updateFailMsg: msg,
        statusUpdateProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      this.setState({
        updateFailMsg: savedResponseResult.msg,
        statusUpdateProgress: false
      });
      return toast.error(savedResponseResult.msg);
    }

    this.setState({
      updateFailMsg: "",
      statusUpdateProgress: true,
      guest: { ...this.state.guest, guestStatus: this.state.setGuestStatus }
    });
    let statusText = this.state.setGuestStatus == 1 ? "Unblocked" : "Blocked";
    toast.success("Successfully " + statusText + " the user", {});
    $(this.cModal.current).modal("hide");
  }

  async _fetchPastOrders() {
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
    const pastOrdersResponse = await fetch(
      apiBasePath +
        apiRoutes.guestPastOrders +
        `?user_id=${this.context.user.userId}&restarant_id=${
          this.context.user.selectedRestarantId
        }&limit=${this.state.recordsPerPage}&offset=${(this.state
          .currentPageNum -
          1) *
          this.state.recordsPerPage}&guest_id=${this.state.guest.guestId}`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));

    if (!pastOrdersResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching tables");
    }

    const pastOrdersData = await pastOrdersResponse
      .json()
      .catch(err => console.log(err));
    if (!pastOrdersData) {
      return toast.error("An Error Occured while fetching tables");
    }
    if (!pastOrdersData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error(pastOrdersData.msg);
    }
    throttleTime(
      () => {
        this.setState({
          pastOrders: pastOrdersData.data,
          fetchProgress: false,
          totalRecords: pastOrdersData.total
        });
      },
      requestSendTime,
      500
    );
  }
}
