import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Text, Form, Select, Radio, RadioGroup, Checkbox, TextArea } from "informed";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import {
  AuthContext,
  CityListProvider,
  CityListContext
} from "../contextProvider";
import {
  validatePhone,
  validateEmail,
  validateText,
  validateInput,
  validateStrictText,
  validateStrictGst,
  validateStrictGstdescription,
} from "../utilities";
import moment from "moment";
import { toast } from "react-toastify";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LocationSearchInput from "./partials/LocationSearchInput";
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');

// import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

// import { Typeahead,AsyncTypeahead } from "react-bootstrap-typeahead";
export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ CONSTANTS, user, authToken, isSuperAdmin, logOut }) => {
                return (
                    <CustomerPortalDetails
                        {...props}
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        logOut={logOut}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};
class CustomerPortalDetails extends Component {
  constructor(props) {
    super(props);
    this.rFormRef = React.createRef();
    this.rUserAppFormRef = React.createRef();
  }
  static contextType = AuthContext;

  state = {
    //enableInput:this.props.location.state && this.props.location.state.restarantId,
    enableInput:this.props.user.restarantId,
    restarantDetails: null,
    restarantImagePath: "",
    disableInput:
    this.props.user.restarantId, 
    baseUrl: "",
    zipCodes: '',
    files: [],
    rAbout: "",
    rOffers: [],
    rTimings: [],
    locationsFromGoogleApi: [],
    rLocationErr: "",
    rLocation: [],
    rFeatures: [],
    superAdminAuth:"eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyTmFtZSI6IlN1cGVyIEFkbWluIiwidXNlclJvbGVzIjpbMjU1XSwiaXNTdXBlckFkbWluIjp0cnVlLCJ1c2VyUGljIjoiL3VwbG9hZHMvMTU1NzM4MDU2MTk4Ny1UYWouanBnIiwidXNlclJlc3RhcmFudElkcyI6ZmFsc2V9.fafuH5KsYdILsg7kcS3jRB2GyN5p7aEgRoGEegqcbU0"
  };


  componentDidMount(){
 
    let restarantId=this.context.user.restarantId;
    this.setState({
      restarantId,
      baseUrl: this.props.CONSTANTS.apiBasePath.split("/api")[0],
    
    });
    
    restarantId && this._loadRestarantDetails(restarantId);

    if (!window.google) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = `https://maps.googleapis.com/maps/api/js?key=${this.context.locationAPIKey}&libraries=places`;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener("load", e => {
        // this.onScriptLoad();
        this.rFormRef.current.setState({ mapsApiReady: true });
      });
    } else {
      // this.onScriptLoad();
      // this.rFormRef.current && this.rFormRef.current.setState({ mapsApiReady: true });
    }
  }

 
  render() {
    let editMode = this.context.user.restarantId;
    return (
      <div className="content of-auto">
        <div className="mx-0">
        <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <label
                id="img_preview_label"
                htmlFor="img_preview"
                style={{
                  backgroundImage: this.state.restarantImagePath
                    ? `url(${this.state.baseUrl +
                    this.state.restarantImagePath})`
                    : `url(${this.state.displayImage})`
                }}
              >
                <input
                  type="file"
                  className="img_preview"
                  accept="image/*"
                  id="img_preview"
                  onChange={this.previewImage.bind(this)}
                />
                {!(
                  this.state.restarantImagePath || this.state.displayImage
                ) && (
                    <i
                      className="fa fa-upload img-upload-icon"
                      aria-hidden="true"
                    />
                  )}
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4" />
            <div className="col-12 col-sm-12 col-md-4 col-lg-4" />
          </div>
          <CityListProvider
            apiBasePath={this.context.CONSTANTS.apiBasePath}
            apiRoutes={this.context.CONSTANTS.apiRoutes}
            user={this.context.user}
            authToken={this.state.superAdminAuth}
          >
            <RestarantDetailsForm
              rLocationErr={this.state.rLocationErr}
              setLocationAddress={val => {
                this.setState({
                  rAddress: val
                });
              }}
              restarantId={
                this.props.user 
                  ? this.props.user.restarantId
                  : null
              }
              initialAddress={this.state.rAddress}
              setLocationCoords={val => {
                this.setState({
                  rLocation: val.lat + "," + val.lng
                });
              }}
              // onLocationSelected={val => {
              //   this.setState({ rLocation: val });
              // }}
              // setTimings={this.setTimings.bind(this)}
              setTimingSlots={(timingSlotsarr) => { this.setState({ rTimings: timingSlotsarr }) }}
              setSwitch={(checked) => { this.setState({ switchChecked: checked }) }}
              SwitchChecked={this.state.switchChecked}
              ref={this.rFormRef}
              getFormValues={this._getFormValues.bind(this)}
              initialValues={{
                // rLocation: this.state.restarantDetails
                //   ? this.state.restarantDetails.rLocation
                //   : "",
                rName: this.state.restarantDetails
                  ? this.state.restarantDetails.rName
                  : "",
                rShortName: this.state.restarantDetails
                  ? this.state.restarantDetails.rShortName
                  : "",
                rPhone: this.state.restarantDetails
                  ? this.state.restarantDetails.rPhone
                  : "",
                rOwner: this.state.restarantDetails
                  ? this.state.restarantDetails.rOwner
                  : "",
                rTimings: this.state.restarantDetails
                  ? this.state.restarantDetails.rTimings
                  : "",
                rNearLocation: this.state.restarantDetails
                  ? this.state.restarantDetails.rNearLocation
                  : "",
                rStatus: this.state.restarantDetails
                  ? this.state.restarantDetails.rStatus
                  : this.context.CONSTANTS.statusCodes["RESTAURANT_ACTIVE"],
                rGst: this.state.restarantDetails
                  ? this.state.restarantDetails.rGst
                  : "",
                rGstdescription: this.state.restarantDetails
                  ? this.state.restarantDetails.rGstdescription
                  : "",
                rCurrency: this.state.restarantDetails
                  ? this.state.restarantDetails.rCurrency
                  : "",
                // rKitchen: this.state.restarantDetails
                //   ? this.state.restarantDetails.rKitchen
                //   : "",
                // rCAP: this.state.restarantDetails
                //   ? this.state.restarantDetails.rCAP
                //   : "",
                rUrbanpiper: this.state.restarantDetails
                  ? this.state.restarantDetails.rUrbanpiper
                  : "",
                rFeedback: this.state.restarantDetails
                  ? this.state.restarantDetails.rFeedback
                  : ""
              }}
              editMode={editMode}
              buildClassName={this.buildClassName.bind(this)}
              inputList={this.state.rTimings}
            />
          </CityListProvider>
          <RestarantUserAppForm
            ref={this.rUserAppFormRef}
            apiBasePath={this.context.CONSTANTS.apiBasePath}
            apiRoutes={this.context.CONSTANTS.apiRoutes}
            getFormValues={this._getFormValues.bind(this)}
            initialValues={
              {
                rLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rLink
                  : "",
                rAbout: this.state.restarantDetails
                  ? this.state.restarantDetails.rAbout
                  : "",
                rFBLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rFBLink
                  : "",
                rInstaLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rInstaLink
                  : "",
                rTwiterLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rTwiterLink
                  : "",
                rTagLine: this.state.restarantDetails
                  ? this.state.restarantDetails.rTagLine
                  : "",
                rUserappTemplete: this.state.restarantDetails
                  ? this.state.restarantDetails.rUserappTemplete
                  : "",
              }
            }
            setRestAbout={(restAbout) => { this.setState({ rAbout: restAbout }) }}
            rAboutInitVal={this.state.rAbout}
            editMode={editMode}
            buildClassName={this.buildClassName.bind(this)}
            offersList={this.state.rOffers}
            setOffers={(restOffers)=>{this.setState({rOffers:restOffers})}}
            setFeatures={(features) => { this.setState({ rFeatures: features }) }}
            featuresList={this.state.rFeatures}
          >
          </RestarantUserAppForm>
        
          
        </div>
        <div className="text-right">
                        <button
                            type="button"
                            className="savebtn"
                            onClick={() => this._submitRestarantWebsiteDetails(!!editMode)}
                        >
                            {this.state.saveProgress ||
                                (this.state.uploadProgress && (
                                    <i className="fa fa-spinner fa-spin fa-fw"></i>
                                ))}{" "}
                            {editMode ? "Save Changes" : "Save"}
                        </button>
                    </div>
      </div>
    );
  }

  previewImage(e) {
    let image = e.nativeEvent.target.files[0];
    if (image.type.split("/")[0] != "image") {
      e.target.value = null;

      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayImage: URL.createObjectURL(image)
      },
      async err => {
        //upload image
        let data = new FormData();
        data.append("file", image);
        data.append("filename", "myfile");
        let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
        this.setState({
          uploadProgress: true
        });
        let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));
        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));
        this.setState({
          uploadProgress: false
        });
        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          restarantImagePath: response.data,
          displayImage: "",
          oldImagePath: this.state.restarantImagePath
        });
      }
    );
  }

  _getFormValues(formName, values) {
    // this.setState({
    //   // [formName]: values
    // });
  }

  isFormEdited(formValues) {
    // let formValues = this._formApi.getState().values;
    if (!Object.keys(formValues).length) return false;
    let values = [];
    for (let fieldName in formValues) {
      // debugger;
      if (!!formValues[fieldName]) values.push(formValues[fieldName]);
    }
    return !!values.length;
  }


  buildClassName(err, enableInput) {
    let c = "w-100 restarantInput";
    !enableInput && (c += " restarantInput--disabled");
    err && (c += " input_error");
    return c;
  }

  async _loadRestarantDetails(restarantId) {
    if (this.state.fetchProgress) return false;
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    let fetchResponse = await fetch(
      this.context.CONSTANTS.apiBasePath +
      this.context.CONSTANTS.apiRoutes.getRestarantDetails +
      `?restarant_id=${restarantId}&user_id=${this.context.user.userId}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!fetchResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const restarantData = await fetchResponse.json(); //@TODO - handle error
    if (!restarantData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("Could not get restaurant details.");
    }

    function testTimingsData(timingsData) {
      try {
        JSON.parse(timingsData);
        return true;
      } catch (e) {
        return false;
      }

    }
   
  
    this.setState(
      {
        restarantDetails: restarantData.data,
        restarantImagePath: restarantData.data.restarantImages[0],
      },
      err => {
        if (err) return console.error(err);
        let rData = this.state.restarantDetails;
        let isTimingsParsable = testTimingsData(rData.rTimings);
        let timingSlotsData = isTimingsParsable ? JSON.parse(rData.rTimings) : []
        //restaramt details
        this.rFormRef.current._formApi.setValue("rName", rData.rName);
        this.rFormRef.current._formApi.setValue("rShortName", rData.rShortName);
      
        // this.rFormRef.current._formApi.setValue("rLocation", rData.rAddress);
        let restOffers=restarantData.data.rOffers ? JSON.parse(restarantData.data.rOffers) : [];
        let restFeatures = restarantData.data.rFeatures ? JSON.parse(restarantData.data.rFeatures) : [];
        
        this.setState({
          rAbout: rData.rAbout,
          rOffers:restOffers.restaurant_offers ? restOffers.restaurant_offers : [],
          rAddress: rData.rAddress,
          rLocation: rData.rLocation,
          oldImagePath: null, //this.state.restarantImagePath
          rTimings: timingSlotsData ? timingSlotsData.restaurant_timings : [],
          rFeatures: restFeatures.restaurant_features ? restFeatures.restaurant_features : []

        });
        this.rFormRef.current._formApi.setValue("rNearLocation", rData.rNearLocation);
        this.rFormRef.current._formApi.setValue("rPhone", rData.rPhone);
        this.rFormRef.current._formApi.setValue("rCity", rData.rCity);
        this.rFormRef.current._formApi.setValue("rGst", rData.rGst);
        this.rFormRef.current._formApi.setValue("rGstdescription", rData.rGstdescription);
        this.rFormRef.current._formApi.setValue("rCurrency", rData.rCurrency);
        // this.rFormRef.current._formApi.setValue("rKitchen", rData.rKitchen)
        // this.rFormRef.current._formApi.setValue("rCAP", rData.rCAP)
        this.rFormRef.current._formApi.setValue("rFeedback", rData.rFeedback)
        this.rFormRef.current._formApi.setValue("rStatus", rData.rStatus);

        //User App Form Details
        this.rUserAppFormRef.current._formApi.setValue("rLink", rData.rYoutubeLink);
        this.rUserAppFormRef.current._formApi.setValue("rFBLink", rData.rFblink)
        this.rUserAppFormRef.current._formApi.setValue("rTwiterLink", rData.rTwitterLink)
        this.rUserAppFormRef.current._formApi.setValue("rInstaLink", rData.rInstaLink)
        this.rUserAppFormRef.current._formApi.setValue("rTagLine", rData.rTagline)
        this.rUserAppFormRef.current._formApi.setValue("rUserappTemplete", rData.rUserappTemplete)
        this.rUserAppFormRef.current._formApi.setValue("rSpeciality", rData.rSpeciality);
        this.rUserAppFormRef.current._formApi.setValue("rPureVeg", rData.rPureVeg);

      }
    );
  }
  
  _submitRestarantWebsiteDetails(edit) {
  
  
    if (this.state.saveProgress || this.state.uploadProgress) {
      return false;
    }
    if (!this.state.restarantImagePath) {
      return toast.error(`Please upload a restaurant image`, {
        autoClose: 4000
      });
    }
  
    this.rFormRef.current._formApi.submitForm();
    this.rUserAppFormRef.current._formApi.submitForm();
  
    this.setState(
      {
        formInValid:
        Object.keys(this.rFormRef.current._formApi.getState().errors)
        .length ||
          Object.keys(this.rUserAppFormRef.current._formApi.getState().errors)
            .length,
        formValues: {
          ...this.rFormRef.current._formApi.getState().values,
          ...this.rUserAppFormRef.current._formApi.getState().values
        }
      },
      async () => {
        if (!this.state.rLocation) {
          return this.setState({
            rLocationErr: "Please select location"
          });
        }
        if (this.state.formInValid) {
          return false;
        }
        if (!this.state.rAddress) {
          this.setState({ rAddressErr: "Please Enter restaurant Address" });
          return toast.error("Please enter restaurant address");
        }
      
        const { CONSTANTS, authToken, user } = this.context;
        this.setState({
          saveProgress: true
        });
     
        let body = {
          user_id: user.userId,
          restaurant_image_url: this.state.restarantImagePath,
          restaurant_name: this.state.formValues.rName,
          restaurant_short_name: this.state.formValues.rShortName,
          restaurant_location: this.state.rLocation || this.state.rAddress,
          restaurant_near_location: this.state.formValues.rNearLocation,
          restaurant_address: this.state.rAddress,
          restaurant_city_id: this.state.formValues.rCity,
          restaurant_phone: this.state.formValues.rPhone,
          restaurant_status: this.state.formValues.rStatus,
          restaurant_gst: this.state.formValues.rGst,
          restaurant_gstdescription: this.state.formValues.rGstdescription,
          restaurant_currency: this.state.formValues.rCurrency,
          restaurant_kitchen_status: this.state.formValues.rKitchen,
          restaurant_cap: this.state.formValues.rCAP,
          restaurant_feedback: this.state.formValues.rFeedback,
          restaurant_timings: this.state.rTimings,
          restaurant_fbLink: this.state.formValues.rFBLink,
          restaurant_instaLink: this.state.formValues.rInstaLink,
          restaurant_twitterLink: this.state.formValues.rTwiterLink,
          restaurant_youtubeLink: this.state.formValues.rLink,
          restaurant_tagLine: this.state.formValues.rTagLine,
          restaurant_about: this.state.rAbout,
          restaurant_userapp_template: this.state.formValues.rUserappTemplete,
          restaurant_offers:this.state.rOffers,
          restaurant_speciality: this.state.formValues.rSpeciality,
          restaurant_pureVeg: this.state.formValues.rPureVeg,
          restaurant_features: this.state.rFeatures,
        };
        let apiRoute;
        if (edit) {
          body.restarant_id = this.context.user.restarantId;
          apiRoute = CONSTANTS.apiRoutes.updateCustomerPortalDetails;
        }
        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
          method: edit ? "PUT" : "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: authToken
          },
          body: JSON.stringify(body)
        }).catch(err => console.error(err));
  
        if (!savedResponse) {
          this.setState({
            saveProgress: false
          });
          return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
          .json()
          .catch(err => console.error(err));
        if (!savedResponseResult) {
          return this.setState({
            saveProgress: false,
            msg: "An Error Occured while saving the changes"
          });
        }
        if (!savedResponseResult.success) {
          this.setState({
            saveProgress: false
          });
         
        }
  
        this.setState({
          saveProgress: false
        });
        toast.success(
          `Successfully ${edit ? "edited" : "added"} the details.`,
          { autoClose: 3000 }
        );
       
  
      }
    );
  }

}


class RestarantDetailsForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues,
    rTimings: {},
    mapsApiReady: window.google && window.google.maps,
  };
  componentDidMount() {
    this.setState({
      formValues: this.props.initialValues,

    });
  }
  handleSwitchChange(checked) {
    this.props.setSwitch(checked)
  }

  //timings and day slots testing code

  handleInputChange(e, index, str) {
    const list = [...this.props.inputList];

    if (str == "day") {
      let findDay = list.filter(i => i.day == e.target.value)
      if (!findDay.length) list[index].day = e.target.value;
    }
    else if (str == "sTime") {
      const stime = e.format("HH:mm:ss");
      list[index].slots[0].start_time = stime;
    }
    else if (str == "eTime") {
      const etime = e.format("HH:mm:ss");
      list[index].slots[0].end_time = etime;
    }
    this.props.setTimingSlots(list);
  }
  handleRemoveClick(e, i) {
    const list = [...this.props.inputList];
    list.splice(i, 1);
    this.props.setTimingSlots(list);
  }

  handleAddClick() {
    const list = [...this.props.inputList, { day: "", slots: [{ start_time: "", end_time: "" }] }];
    this.props.setTimingSlots(list);
  }
  render() {
    return (
      <div id="restarantDetails">
        <div className="row text-capitalize py-3 mt-2">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Restaurant details</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
          <div
            className={
              this.state.editMode
                ? "col-12 col-sm-12 col-md-8 col-lg-8 text-right"
                : "d-none"
            }
          >
            <Link
              to={"tables-list/" + this.props.restarantId}
              className="view_tables"
            >
              view tables
            </Link>
          </div>
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {
            return (
              <>
                <div className="row text-capitalize fsize border_bottom">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rName}
                            validate={val =>
                              validateStrictText(
                                val,
                                2,
                                formState.touched.rName,
                                "Restaurant Name",
                                30
                              )
                            }
                            id="rEdit__rName"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rName,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rName && (
                            <span className="input_error">
                              {formState.errors.rName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rShortName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant short name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rShortName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rShortName}
                            validate={val =>
                              validateStrictText(
                                val,
                                3,
                                formState.touched.rShortName,
                                "Restaurant Short Name",
                                15
                              )
                            }
                            id="rEdit__rShortName"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rShortName,
                              this.state.enableInput
                            )}
                            validateOnBlur
                            validateOnChange
                          />
                          {formState.errors.rShortName && (
                            <span className="input_error">
                              {formState.errors.rShortName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rPhone"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Landline number <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rPhone"
                            type="text"
                            initialValue={this.state.formValues.rPhone}
                            disabled={!this.state.enableInput}
                            validateonblur="true"
                            validateonchange="true"
                            validate={val =>
                              validatePhone(
                                val && val.trim(),
                                formState.touched.rPhone
                              )
                            }
                            id="rEdit__rPhone"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rPhone,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.rPhone && (
                            <span className="input_error">
                              {formState.errors.rPhone}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rCity"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          City <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <CityListContext.Consumer>
                          {({ records: cities }) => {
                            return (
                              <div className="">
                                <Select
                                  id="rEdit__rCity"
                                  field="rCity"
                                  type="text"
                                  validateonblur="true"
                                  validateonchange="true"
                                  validate={val => {
                                    if (!val) return "Please select city.";
                                  }}
                                  disabled={!this.state.enableInput}
                                  initialValue={this.state.formValues.rCity}
                                  className={
                                    this.state.enableInput
                                      ? "w-100 restarantInput"
                                      : "w-100 restarantInput restarantInput--disabled"
                                  }
                                  value={this.state.formValues.rStatus}
                                >
                                  <option value="">-select city-</option>
                                  {cities.map(city => (
                                    <option
                                      value={city.cityId}
                                      key={city.cityId}
                                    >
                                      {city.cityName}
                                    </option>
                                  ))}
                                </Select>
                                {formState.errors.rCity && (
                                  <span className="input_error">
                                    {formState.errors.rCity}
                                  </span>
                                )}
                              </div>
                            );
                          }}
                        </CityListContext.Consumer>
                      </div>
                    </div>
                  </div>
                
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rLocation"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Address <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          {this.state.mapsApiReady && (
                            <LocationSearchInput
                              setLocationAddress={this.props.setLocationAddress}
                              address={this.props.initialAddress}
                              disabled={!this.state.enableInput}
                              setLocationCoords={this.props.setLocationCoords}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* area name of reatauant */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rNearLocation"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Area name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rNearLocation"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rNearLocation}
                            validate={val =>
                              validateInput(
                                val,
                                3,
                                formState.touched.rNearLocation,
                                "Area Name",
                                30
                              )
                            }
                            id="rEdit__rNearLocation"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rNearLocation,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rNearLocation && (
                            <span className="input_error">
                              {formState.errors.rNearLocation}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rStatus"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Status <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rStatus"
                          disabled={!this.state.enableInput}
                          field="rStatus"
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rStatus}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="5">Active</option>
                          <option value="6">Inactive</option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGst"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          GST<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rGst"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rGst}
                            validate={val =>
                              validateStrictGst(
                                val,
                                2,
                                formState.touched.rGst,
                                "Restaurant Gst",
                                15
                              )
                            }
                            id="rEdit__rGst"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rGst,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rGst && (
                            <span className="input_error">
                              {formState.errors.rGst}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGstdescription"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          GST description <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rGstdescription"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rGstdescription}
                            validate={val =>
                              validateStrictGstdescription(
                                val,
                                5,
                                formState.touched.rGstdescription,
                                "Restaurant Gstdescription",
                                50
                              )
                            }
                            id="rEdit__rGstdescription"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rGstdescription,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rGstdescription && (
                            <span className="input_error">
                              {formState.errors.rGstdescription}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rCurrency"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Currency <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rCurrency"
                          disabled={!this.state.enableInput}
                          field="rCurrency"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select restaurant currency.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rCurrency}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option >-select currency-</option>
                          <option >Rs</option>
                        </Select>
                        {formState.errors.rCurrency && (
                          <span className="input_error">
                            {formState.errors.rCurrency}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rKitchen"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Kitchen <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rKitchen"
                          disabled={!this.state.enableInput}
                          field="rKitchen"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Kitchen status.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rKitchen}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          <option value="">select Kitchen status</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Select>
                        {formState.errors.rKitchen && (
                          <span className="input_error">
                            {formState.errors.rKitchen}
                          </span>
                        )}
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGstdescription"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          CAP <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rCAP"
                          disabled={!this.state.enableInput}
                          field="rCAP"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Cancel After Payment.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rCAP}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          <option value="">select CAP</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Select>
                        {formState.errors.rCAP && (
                          <span className="input_error">
                            {formState.errors.rCAP}
                          </span>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFeedback"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Feedback <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rFeedback"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rFeedback}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rTimings"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Timings <span className="">:</span>
                        </label>
                      </div>
                      <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddClick()}>Add</button></div>
                      {
                        this.props.inputList && this.props.inputList.map((x, i) => {

                          return (

                            <div className="col-6 col-md-6 col-lg-6 form-group">
                              <div
                                className="restarantInput d-flex justify-content-between align-items-center"
                                style={{ padding: "6px" }}
                              >
                                <select
                                  style={{ marginInlineStart: "8px" }}
                                  disabled={!this.state.enableInput}
                                  validateonblur="true"
                                  validateonchange="true"
                                  className={
                                    this.state.enableInput
                                      ? "w-100 restarantInput"
                                      : "w-100 restarantInput restarantInput--disabled"
                                  }
                                  value={x.day}
                                  onChange={obj => this.handleInputChange(obj, i, "day")}
                                >
                                  {/* @todo - dynamic values */}
                                  <option value="">select day</option>
                                  <option value="monday">monday</option>
                                  <option value="tuesday">tuesday</option>
                                  <option value="wednesday">wednesday</option>
                                  <option value="thursday">thursday</option>
                                  <option value="friday">friday</option>
                                  <option value="saturday">saturday</option>
                                  <option value="sunday">sunday</option>
                                </select>
                                <TimePicker
                                  placeholder="HH:MM:ss"
                                  disabled={!this.state.enableInput}
                                  value={x.slots[0].start_time ? moment(x.slots[0].start_time, "HH:mm:ss") : null}
                                  hideDisabledOptions
                                  clearIcon={this.state.enableInput ? null : <i />}
                                  onChange={momentDateObj => {
                                    this.handleInputChange(momentDateObj, i, "sTime")
                                  }}
                                  showSecond={true}
                                />
                                <span style={{ width: "40px", textAlign: "center" }}>
                                  {" "}
                            To{" "}
                                </span>
                                <TimePicker
                                  placeholder="HH:MM:ss"
                                  disabled={!this.state.enableInput}
                                  showSecond={true}
                                  value={x.slots[0].end_time ? moment(x.slots[0].end_time, "HH:mm:ss") : null}
                                  clearIcon={this.state.enableInput ? null : <i />}
                                  hideDisabledOptions={true}
                                  onChange={momentDateObj => {
                                    this.handleInputChange(momentDateObj, i, "eTime")
                                  }}

                                />

                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handleRemoveClick(obj, i)}>Remove</button></div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    );
  }

  _setFormApi(formApi) {
    this._formApi = formApi;
  }

  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("clear errors first");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}


class RestarantUserAppForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues
  };

  handleCkEditorState = (event, editor) => {
    const data = editor.getData();
    this.props.setRestAbout(data);
  }
  //add offers
  handleInputChange(e, index) {
    const list = [...this.props.offersList];
      let findOffer = list.filter(i => i.offers == e.target.value)
       if (!findOffer.length) list[index].offers = e.target.value;
   
    this.props.setOffers(list);
  }
  handleOfferImgChange(event) {
    let idx = Number(event.target.id)
    let offList = [...this.props.offersList]
    let selectedFile = event.target.files[0];
    if (selectedFile.type.split("/")[0] != "image") {
      event.target.value = null;
      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayImgs: URL.createObjectURL(selectedFile)
      },
      async err => {
        let data = new FormData();
        data.append("file", selectedFile);
        data.append("filename", "myfile");
        //let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
        this.setState({
          uploadProgress: true
        });
        let uploadSuccess = await fetch(this.props.apiBasePath + this.props.apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));
        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));
        this.setState({
          uploadProgress: false
        });
        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          displayImgs: ''
        });
        offList[idx].offerImg = response.data
        this.props.setOffers(offList)
      },
    )

  }
  handleRemoveClick(e, i) {
    const list = [...this.props.offersList];
    list.splice(i, 1);
    this.props.setOffers(list);
  }

  handleAddOffersClick() {
    const list = [...this.props.offersList,{offers:"",offerImg:""}];
    this.props.setOffers(list)
  //  this.props.setTimingSlots(list);
  }
  handleAddFeaturesClick() {
    const list = [...this.props.featuresList, { feature: "", featureAvailable: "" }];
    this.props.setFeatures(list)
  }
  handleFeatureInputChange(e, index) {
    const list = [...this.props.featuresList];
    list[index].feature = e.target.value;
    this.props.setFeatures(list);
  }
  handleFeatureAvailableChange(e, index) {
    const list = [...this.props.featuresList];
    list[index].featureAvailable = e.target.value;
    this.props.setFeatures(list);
  }
  handleFeatureRemoveClick(e, i) {
    const list = [...this.props.featuresList];
    list.splice(i, 1);
    this.props.setFeatures(list);
  }
  render() {
    let baseUrl=this.props.apiBasePath.split("/api")[0];
    return (
      <div id="restarantDetails">
        <div className="row text-capitalize py-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Restaurant Website Details</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
          <div
            className={
              this.state.editMode
                ? "col-12 col-sm-12 col-md-8 col-lg-8 text-right"
                : "d-none"
            }
          >

          </div>
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {

            return (
              <>
                <div className="row text-capitalize fsize border_bottom">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          YouTube Link <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rLink}
                            /*  validate={val =>
                               validateInput(
                                 val,
                                 2,
                                 formState.touched.rLink,
                                 "Restaurant YouTube Link",
                                 500
                               )
                             } */
                            id="rEdit__rLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rLink && (
                            <span className="input_error">
                              {formState.errors.rLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFBLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Facebook <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rFBLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rFBLink}
                            /* validate={val =>
                              validateInput(
                                val,
                                2,
                                formState.touched.rFBLink,
                                "Restaurant FaceBook Link",
                                100
                              )
                            } */
                            id="rEdit__rFBLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rFBLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rFBLink && (
                            <span className="input_error">
                              {formState.errors.rFBLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rInstaLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Instagram <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rInstaLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rInstaLink}
                            /* validate={val =>
                              validateInput(
                                val,
                                2,
                                formState.touched.rInstaLink,
                                "Restaurant Instagram Link",
                                100
                              )
                            } */
                            id="rEdit__rInstaLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rInstaLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rInstaLink && (
                            <span className="input_error">
                              {formState.errors.rInstaLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rTwiterLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Twitter <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rTwiterLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rTwiterLink}
                            /*  validate={val =>
                               validateInput(
                                 val,
                                 2,
                                 formState.touched.rTwiterLink,
                                 "Restaurant Twitter Link",
                                 100
                               )
                             } */
                            id="rEdit__rTwiterLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rTwiterLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rTwiterLink && (
                            <span className="input_error">
                              {formState.errors.rTwiterLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4" >
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rPureVeg"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Pure Veg <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rPureVeg"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rPureVeg}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4" >
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rSpeciality"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Speciality<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rSpeciality"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rSpeciality}
                            validate={val =>
                              validateStrictText(
                                val,
                                2,
                                formState.touched.rSpeciality,
                                "Restaurant Speciality",
                                30
                              )
                            }
                            id="rEdit__rSpeciality"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rSpeciality,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rSpeciality && (
                            <span className="input_error">
                              {formState.errors.rSpeciality}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rAbout"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          About <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-10 col-md-10 col-lg-10 form-group">
                        <div className="">

                          <CKEditor
                            editor={ClassicEditor}
                            disabled={!this.state.enableInput}
                            field="rAbout"
                            data={this.props.rAboutInitVal}
                            onChange={this.handleCkEditorState.bind(this)}

                          />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rOffers"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant Offers <span className="">:</span>
                        </label>
                      </div>
                      <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddOffersClick()}>Add</button></div>
                      {
                       
                        this.props.offersList && this.props.offersList.map((x, i) => {
                        
                          return (
                           
                            <div className="col-6 col-md-6 col-lg-6 form-group">
                             
                              <div
                                className="restarantInput d-flex justify-content-between align-items-center"
                                style={{ padding: "6px" }}
                              >
                                
                                 <input
                                 style={{width:"-webkit-fill-available"}}
                            field="rOffers"
                            type="text"
                            disabled={!this.state.enableInput}
                           // initialValue={x}
                            id="rEdit__rOffers"
                            onChange={val => {
                              this.handleInputChange(val, i)
                            }}
                            value={x.offers}
                          />
                               <div className="col-3 col-md-3 col-lg-3 form-group" >
                                <div className="align-items-center">
                                  {<input type="file"
                                    className={"hide", "pimg_preview", i}
                                    accept="image/*"
                                    id={"pimg_preview", i}
                                    onChange={(event) => this.handleOfferImgChange(event)}
                                    disabled={!this.state.enableInput}
                                  />}
                                  {x.offerImg ?
                                    <div>
                                      <label
                                        id="pimg_preview_label"
                                        htmlFor="pimg_preview"
                                        style={{
                                          backgroundImage: x.offerImg ? `url(${baseUrl +
                                            x.offerImg})` : ""
                                        }}
                                      >
                                      </label> </div> : ""
                                  }
                                </div>
                              </div>
                              
                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handleRemoveClick(obj, i)}>Remove</button></div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rTagLine"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Tag Line <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-10 col-md-10 col-lg-10 form-group">
                        <div className="">
                          <Text
                            field="rTagLine"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rTagLine}
                            validate={val =>
                              validateInput(
                                val,
                                2,
                                formState.touched.rTagLine,
                                "Restaurant Tag Line",
                                500
                              )
                            }
                            id="rEdit__rTagLine"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rTagLine,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rTagLine && (
                            <span className="input_error">
                              {formState.errors.rTagLine}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* choose template  */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUserappTemplete"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Choose Theme <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rUserappTemplete"
                          disabled={!this.state.enableInput}
                          field="rUserappTemplete"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Theme to your restaurant Template .";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rUserappTemplete}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="">select Template </option>
                          <option value="Template1">Template1</option>
                          <option value="Template2">Template2</option>
                        </Select>
                        {formState.errors.rUserappTemplete && (
                          <span className="input_error">
                            {formState.errors.rUserappTemplete}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFeatures"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant Features <span className="">:</span>
                        </label>
                      </div>
                      <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddFeaturesClick()}>Add</button></div>
                      {

                        this.props.featuresList && this.props.featuresList.map((x, i) => {

                          return (

                            <div className="col-6 col-md-6 col-lg-6 form-group">

                              <div
                                className="restarantInput d-flex justify-content-between align-items-center"
                                style={{ padding: "6px" }}
                              >

                                <input
                                  // style={{ width: "-webkit-fill-available" }}
                                  field="rFeatures"
                                  type="text"
                                  disabled={!this.state.enableInput}
                                  // initialValue={x}
                                  id="rEdit__rFeatures"
                                  onChange={val => {
                                    this.handleFeatureInputChange(val, i)
                                  }}
                                  value={x.feature}
                                />
                                <select
                                  style={{ marginInlineStart: "8px" }}
                                  disabled={!this.state.enableInput}
                                  validateonblur="true"
                                  validateonchange="true"
                                  className={
                                    this.state.enableInput
                                      ? "w-100 restarantInput"
                                      : "w-100 restarantInput restarantInput--disabled"
                                  }
                                  value={x.featureAvailable}
                                  onChange={obj => this.handleFeatureAvailableChange(obj, i)}
                                >
                                  {/* @todo - dynamic values */}
                                  <option value="">select Available</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handleFeatureRemoveClick(obj, i)}>Remove</button></div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>

                </div>
              
              </>
            );
          }}
        </Form>
      </div>
    );
  }
 
  _setFormApi(formApi) {
    this._formApi = formApi;
  }
  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("clear errors first");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}
