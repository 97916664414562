import React, { Component } from "react";
import { AuthContext } from "../contextProvider";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, TextArea, Select } from "informed";
import { toast } from "react-toastify";
import $ from "jquery";
import Loader from "react-loader-spinner";
import { throttleTime } from "../utilities";
import { confirmAlert } from "react-confirm-alert";

export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <FeedBack
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class FeedBack extends Component {
    state = {
        feedBacks: [],
        editMode: false,
        saveProgress: false,
        fetchProgress: false,
        deleteProgress: false
    }
    async componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        await this._loadFeedBacks();
    }

    componentDidMount() {
        $(this.modalUI.current).on("shown.bs.modal", () => {
            // if (this.modalRef.current.state.categories.length) return;
            // this._loadCategories();
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedFeedBackId: null
            });
            this.modalRef.current.formApi.reset();
        });
    }
    render() {
        return (
            <>
                <div className="content">
                    <div className="row mx-0 justify-content-between align-items-center">
                        <div className="">
                            <span className="categorycol">FeedBack</span>
                        </div>
                        <div className="">
                            <button
                                type="button"
                                className="btnaddcategory float-right"
                                data-toggle="modal"
                                data-target="#feedBackModal"
                            >
                                <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                    &nbsp; Add FeedBack
                  </button>
                        </div>
                        <div className="table-responsive mt-3 tableofauto">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>title</th>
                                        <th>Type</th>
                                        <th className="actionWidth">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.feedBacks.map((f, idx) => (
                                        <tr key={String(idx)}>
                                            <td>{idx + 1}</td>
                                            <td>{f.feedbackTitle}</td>
                                            <td>{f.feedbackTitleType}</td>
                                            <td>
                                                <img
                                                    alt=""
                                                    src={EDIT_IMG}
                                                    data-toggle="modal"
                                                    data-target="#feedBackModal"
                                                    className="icon"
                                                    onClick={() => {
                                                        this.setState({
                                                            selectedFeedBackId: f.feedbackId,
                                                        });
                                                        this.modalRef.current.setState({
                                                            editMode: true
                                                        });
                                                        this.modalRef.current.formApi.setValue(
                                                            "feedBack_title",
                                                            f.feedbackTitle
                                                        );
                                                        this.modalRef.current.formApi.setValue(
                                                            "feedback_question_type",
                                                            f.feedbackTitleType
                                                        );
                                                    }}
                                                />
                                                <img
                                                    alt=""
                                                    src={DELETE_IMG}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        this.confirmAndDelete(
                                                            f.feedbackId,
                                                            f.feedbackTitle
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <FeedBackModal
                    ref={this.modalRef}
                    submitFeedBackForm={this._submitFeedBackForm.bind(this)}
                    modalUiRef={this.modalUI}
                />
            </>
        )
    }
    async _submitFeedBackForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let feedBackId = this.state.selectedFeedBackId;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath +
            (feedBackId ? apiRoutes.editFeedBack : apiRoutes.createFeedBack),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: feedBackId ? "PUT" : "POST",
                body: JSON.stringify({
                    feed_back_id: feedBackId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId,
                    feed_back_title: formValues.feedBack_title,
                    feed_back_question_type: formValues.feedback_question_type
                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${feedBackId ? "saving the changes" : "adding the FeedBack "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${feedBackId ? "saving the changes" : "adding the Feed Back "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1,
            },
            err => {
                this._loadFeedBacks();
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${feedBackId ? "saved the changes" : "added " + formValues.feedBack_title
            }.`,
            { autoClose: 4000 }
        );
    }

    async _loadFeedBacks() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const feedBackResponse = await fetch(
            apiBasePath +
            apiRoutes.getFeedBacks +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}&restaurant_id=${this.props.user.restarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!feedBackResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const feedBackResponseResult = await feedBackResponse.json();
        if (!feedBackResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!feedBackResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: feedBackResponseResult.msg
            });
            return toast.error(feedBackResponseResult.msg);
        }
        throttleTime(
            () => {
                this.setState({
                    feedBacks: feedBackResponseResult.data,
                    fetchProgress: false,
                });
            },
            requestSendTime,
            500
        );

    }
    confirmAndDelete(Id, Title) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${Title || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteFeedBack(Id, Title)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }

    async _deleteFeedBack(Id, Title) {
        if (this.state.deleteProgress) {
            return false;
        }
        this.setState({
            deleteProgress: true
        });
        const feedBack = this.state.feedBacks.find(
            i => i.feedbackId == Id
        );
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deleteFeedBack,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "DELETE",
                body: JSON.stringify({
                    feedback_id: Id,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the feedBack.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the feedBack.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: ""
        });
        this._loadFeedBacks();
        toast.success(` successfully Deleted  ${Title}`, { autoClose: 5000 });
    }

}
class FeedBackModal extends Component {
    state = {
        editMode: false,
        saveProgress: false,
    };

    render() {
        const { modalUiRef, submitFeedBackForm } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="feedBackModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit FeedBack "
                                    : "Add FeedBack "}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitFeedBackForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {

                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <TextArea
                                                    validate={val => {
                                                        if (!val) return "please enter a feedback title"
                                                        if (Number(val) > 70) {
                                                            return "feed back Title cannot be more than 70 Characters.";
                                                        }
                                                    }
                                                    }
                                                    rows={4}
                                                    validateOnBlur
                                                    validateOnChange
                                                    field={"feedBack_title"}
                                                    className={
                                                        formState.errors.feedBack_title
                                                            ? "form-control input_error"
                                                            : "form-control"
                                                    }
                                                    placeholder="Enter  Title"
                                                />
                                                {formState.errors.feedBack_title && (
                                                    <div className="input_error">
                                                        {formState.errors.feedBack_title}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Feedback Question Type
                                                            <Select
                                                        field={"feedback_question_type"}
                                                        validate={val => {
                                                            if (!val) return "please enter a Feedback Question Type";
                                                        }}
                                                        validateOnBlur
                                                        validateOnChange
                                                        className={
                                                            formState.errors.feedback_question_type
                                                                ? "form-control input_error"
                                                                : "form-control"
                                                        }
                                                    >
                                                        <option value=""> - Select Feedback Question Type - </option>
                                                        <option value="Star" >Star</option>
                                                        <option value="textbox" >TextBox</option>
                                                        <option value="Yes/No" >Yes/No</option>
                                                        <option value="Options">Options</option>
                                                    </Select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                )
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
    _setFormApi(formApi) {
        this.formApi = formApi;
    }
}