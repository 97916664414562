import React, { Component } from "react";
import { FB, INSTA, TWITTER, LINKEDIN } from "../assets/images/";
import { AuthContext } from "../contextProvider";
import { toast } from "react-toastify";
import { CONSTANTS } from "../contextProvider";
import StarRatings from 'react-star-ratings';
import { Checkbox } from "@material-ui/core";
import { Text, Form, Select, Radio, RadioGroup, TextArea } from "informed";


export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
                return (
                    <GenericFeedback
                        {...props}
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                    />

                );
            }}
        </AuthContext.Consumer>
    );
};

class GenericFeedback extends Component {
    state = {
        feedBacks: [],
        fetchProgress: false,
        orderId: "",
        name: "",
        phone: "",
        email: "",
        DOB: "",
        submitFeedback: false
    }
    async componentDidMount() {
        await this._loadFeedbacks(this.props.match.params.id);
    }
    changeRating(newRating, name) {
        let feedBack = [...this.state.feedBacks]
        feedBack[Number(name)].feedBackAnswer = newRating;
        this.setState({
            feedBacks: feedBack
        });
    }
    orderIdChange(event) {
        this.setState({
            orderId: event.target.value
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container loginBg">
                    <div className="row">
                        <div className="col-20 col-lg-15 col-xxl-12 bg-white rounded mx-auto shadow-sm p-3 py-lg-5">
                            <div className="text-center">
                                {!this.state.submitFeedback ?
                                    <div>
                                        <h3 style={{ color: 'grey', textDecorationLine: 'underline' }}><b> Provide  Feedback</b></h3>
                                        <input
                                            onChange={this.orderIdChange.bind(this)}
                                            value={this.state.orderId}
                                            placeholder="Enter Order Id(optional)"
                                            style={{ border: "1px solid black", height: 30 }} />
                                        {
                                            this.state.feedBacks.map((f, idx) => {
                                                return <div key={String(f.feedbackId)}>
                                                    <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: 10 }}>{f.feedbackTitle}</div>
                                                    {f.feedbackTitleType == "Star" ? <StarRatings
                                                        rating={f.feedBackAnswer}
                                                        starRatedColor="#1f74f4"
                                                        changeRating={this.changeRating.bind(this)}
                                                        numberOfStars={5}
                                                        name={String(idx)}
                                                        starDimension='40px'
                                                        starSpacing='8px'
                                                        starHoverColor='green'
                                                    /> : null}
                                                    {f.feedbackTitleType == "textbox" ? <textarea
                                                        value={f.feedBackAnswer}
                                                        onChange={text => {
                                                            let feedBack = [...this.state.feedBacks]
                                                            feedBack[idx].feedBackAnswer = text.target.value;
                                                            this.setState({
                                                                feedBacks: feedBack
                                                            });
                                                        }}
                                                        rows={5}
                                                        style={{ width: "80%", marginTop: 20 }}
                                                        placeholder="Enter Comment..."
                                                    /> : null}
                                                    {f.feedbackTitleType == "Yes/No" ?
                                                        <div>
                                                            <Checkbox onClick={() => {
                                                                let feedBack = [...this.state.feedBacks]
                                                                feedBack[idx].feedBackAnswer = "YES";
                                                                this.setState({
                                                                    feedBacks: feedBack
                                                                });
                                                            }} checked={f.feedBackAnswer == "YES"} /><label> Yes</label><Checkbox onClick={() => {
                                                                let feedBack = [...this.state.feedBacks]
                                                                feedBack[idx].feedBackAnswer = "NO";
                                                                this.setState({
                                                                    feedBacks: feedBack
                                                                });
                                                            }} checked={f.feedBackAnswer == "NO"} /><label> No</label> </div> : null}
                                                    {f.feedbackTitleType == "Options" ?
                                                        <div>
                                                            <Checkbox onClick={() => {
                                                                let feedBack = [...this.state.feedBacks]
                                                                feedBack[idx].feedBackAnswer = "Excellent";
                                                                this.setState({
                                                                    feedBacks: feedBack
                                                                });
                                                            }} checked={f.feedBackAnswer == "Excellent"} /><label> Excellent</label>
                                                            <Checkbox onClick={() => {
                                                                let feedBack = [...this.state.feedBacks]
                                                                feedBack[idx].feedBackAnswer = "Good";
                                                                this.setState({
                                                                    feedBacks: feedBack
                                                                });
                                                            }} checked={f.feedBackAnswer == "Good"} /><label> Good</label>
                                                            <Checkbox onClick={() => {
                                                                let feedBack = [...this.state.feedBacks]
                                                                feedBack[idx].feedBackAnswer = "Average";
                                                                this.setState({
                                                                    feedBacks: feedBack
                                                                });
                                                            }} checked={f.feedBackAnswer == "Average"} /><label> Average</label>
                                                            <Checkbox onClick={() => {
                                                                let feedBack = [...this.state.feedBacks]
                                                                feedBack[idx].feedBackAnswer = "Dissatisfied";
                                                                this.setState({
                                                                    feedBacks: feedBack
                                                                });
                                                            }} checked={f.feedBackAnswer == "Dissatisfied"} /><label> Dissatisfied</label>
                                                        </div> : null}
                                                </div>
                                            })
                                        }

                                        <h3 style={{ color: 'grey', textDecorationLine: 'underline' }}><b>Customer Details</b></h3>
                                        <div>
                                            <input
                                                onChange={(event) => this.setState({ name: event.target.value })}
                                                value={this.state.name}
                                                placeholder="Enter Name"
                                                style={{ border: "1px solid black", height: 40, width: 300, marginBottom: 15 }} />
                                        </div>
                                        <div>

                                            <input
                                                onChange={(event) => {
                                                   if(event.target.value && !new RegExp(/^[0-9]+$/).test(event.target.value)) return false;
                                                    this.setState({ phone: event.target.value })
                                                }}
                                                value={this.state.phone}
                                                placeholder="Enter Phone Number"
                                                style={{ border: "1px solid black", height: 40, width: 300, marginBottom: 15 }}
                                                type="text"
                                                maxLength={12} 
                                                />
                                        </div>
                                        <div>

                                            <input
                                                onChange={(event) => this.setState({ email: event.target.value })}
                                                value={this.state.email}
                                                placeholder="Enter Email"
                                                style={{ border: "1px solid black", height: 40, width: 300, marginBottom: 15 }}
                                                type="email" />
                                        </div>
                                            DOB :  
                                            <input
                                                onChange={(event) => this.setState({ DOB: event.target.value })}
                                                value={this.state.DOB}
                                                placeholder="DOB"
                                                style={{ border: "1px solid black", height: 45, width: 250, marginBottom: 15,marginLeft:10 }}
                                                type="date"
                                            />

                                        <div>
                                            <button style={{ width: '80%', height: 40, backgroundColor: '#1f74f4', border: "1px solid black", color: '#fff', borderRadius: 8, fontWeight: 'bold' }}
                                                onClick={() => {
                                                    let feedBack = [];
                                                    this.state.feedBacks.map(f => {
                                                        if (f.feedBackAnswer) {
                                                            feedBack.push(f.feedbackId)
                                                        }
                                                    })
                                                    if (!feedBack.length) {
                                                        return toast.error("Please Select One Option")
                                                    }
                                                    this.onSubmitFeedback(this.props.match.params.id)
                                                }}>Submit Feedback</button>
                                        </div>
                                    </div> : <div className="text-center">
                                        <p>Thank you for taking time out to give us your valuable feedback.</p>
                                        <p> Have a Nice day.</p>
                                        <p><b> {this.props.match.params.name}</b></p>
                                        <p><b>Powered By Buvvas Business Apps.</b></p>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    async onSubmitFeedback(id) {
        if (this.state.saveProgress) return false;
        const { apiBasePath, apiRoutes } = CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath + apiRoutes.saveGenericFeedBack,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    order_id: this.state.orderId,
                    restarant_id: id,
                    fb_response_rating: this.state.feedBacks,
                    fb_response_comment: true,
                    customer_details: {
                        name: this.state.name,
                        phone: this.state.phone,
                        email: this.state.email,
                        DOB: this.state.DOB,
                        altPhone: ""
                    },
                    rest_name: ""
                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while submitting the FeedBack "`;
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while Submitting the Feed Back "`;
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            return toast.error(savedResponseResult.msg);
        }
        this.setState({
            submitFeedback: true
        })
        toast.success(
            `Successfully Submitted the feedback.`,
            { autoClose: 4000 }
        );
    }

    async _loadFeedbacks(restId) {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = CONSTANTS;
        const feedBackResponse = await fetch(
            apiBasePath +
            apiRoutes.getFeedBacks +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}&restaurant_id=${restId}&genericFeedBack=${true}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!feedBackResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const feedBackResponseResult = await feedBackResponse.json();
        if (!feedBackResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!feedBackResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: feedBackResponseResult.msg
            });
            return toast.error(feedBackResponseResult.msg);
        }
        this.setState({
            feedBacks: feedBackResponseResult.data,
            fetchProgress: false,
        });


    }
}