import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
export default () => {
  return (
    <AuthContext.Consumer>
      {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
        return (
          <DataContext.Consumer>
            {({ fetchItemRoutes }) => {
              return (
                <ItemRoutes
                  user={user}
                  authToken={authToken}
                  CONSTANTS={CONSTANTS}
                  isSuperAdmin={isSuperAdmin}
                  fetchItemRoutes={fetchItemRoutes}
                />
              )
            }}
          </DataContext.Consumer>
        );
      }}
    </AuthContext.Consumer>
  );
};

class ItemRoutes extends Component {
  state = {
    itemRoutes: [],
    totalItemRoutes: 0,
    currentPageNum: 1,
    recordsPerPage: 10,
    fetchProgress: true,
    saveProgress: false,
    deleteProgress: false,
    saveFailMsg: "",
    deleteFailMsg: "",
    noDataMsg: "No Item-Routes Yet.",
    selectedItemRoutesId: null
  };

  componentWillMount() {
    this.modalRef = React.createRef();
    this.modalUI = React.createRef();
    this._loadItemRoutes();
  }

  componentDidMount() {
    $(this.modalUI.current).on("shown.bs.modal", () => {
      // if (this.modalRef.current.state.categories.length) return;
      // this._loadCategories();
    });

    $(this.modalUI.current).on("hidden.bs.modal", () => {
      this.modalRef.current.setState({
        editMode: false
      });
      this.setState({
        selectedItemRoutesId: null
      });
      this.modalRef.current.formApi.reset();
    });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
              <>
                <div className="row mx-0 justify-content-between align-items-center">
                  <div className="">
                    <span className="categorycol">Item Routes</span>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btnaddcategory float-right"
                      data-toggle="modal"
                      data-target="#itemRoutesModal"
                    >
                      <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                      &nbsp; Add Item Route
                  </button>
                  </div>
                </div>
                {this.state.itemRoutes.length ? (
                  <div className="table-responsive mt-3 tableofauto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Item Route Name</th>
                          <th className="actionWidth">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.itemRoutes.map((ir, idx) => (
                          <tr key={String(idx)}>
                            <td>
                              {(this.state.currentPageNum - 1) *
                                this.state.recordsPerPage +
                                idx +
                                1}
                            </td>
                            <td>{ir.itemRouteName}</td>
                            <td>
                              <img
                                alt=""
                                src={EDIT_IMG}
                                data-toggle="modal"
                                data-target="#itemRoutesModal"
                                className="icon"
                                onClick={() => {
                                  this.setState({
                                    selectedItemRoutesId: ir.itemRouteId
                                  });
                                  this.modalRef.current.setState({
                                    editMode: true
                                  });
                                  this.modalRef.current.formApi.setValue(
                                    "itemRoute__name",
                                    ir.itemRouteName
                                  );
                                }}
                              />
                              <img
                                alt=""
                                src={DELETE_IMG}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.confirmAndDelete(
                                    ir.itemRouteId,
                                    ir.itemRouteName
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                    <div className="noDataMsgContainer">
                      <h5>{this.state.noDataMsg}</h5>
                    </div>
                  )}
              </>
            )}
        </div>
        {this._pagination()}
        <ItemRoutesModal
          ref={this.modalRef}
          submitItemRoutesForm={this._submitItemRoutesForm.bind(this)}
          modalUiRef={this.modalUI}
        />
      </>
    );
  }

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalItemRoutes / this.state.recordsPerPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.state.totalItemRoutes / this.state.recordsPerPage
            )}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this._getMore(data.selected + 1)}
            containerClassName={"pagination"}
            activeLinkClassName={"active"}
            forcePage={this.state.currentPageNum - 1}
          />
        ) : null}
      </div>
    );
  }

  _getMore(val) {
    this.setState(
      {
        currentPageNum: val
      },
      err => {
        this._loadItemRoutes();
      }
    );
  }

  async _loadItemRoutes() {
    this.setState({
      fetchProgress: true
    });
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const requestSendTime = Date.now();
    const itemRouteResponse = await fetch(
      apiBasePath +
      apiRoutes.getItemRoute +
      `?user_id=${this.props.user.userId}&limit=${
      this.state.recordsPerPage
      }&offset=${(this.state.currentPageNum - 1) *
      this.state.recordsPerPage}`,
      { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!itemRouteResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const itemRouteResult = await itemRouteResponse.json();
    if (!itemRouteResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!itemRouteResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: itemRouteResult.msg
      });
      return toast.error(itemRouteResult.msg);
    }
    throttleTime(
      () => {
        this.setState({
          itemRoutes: itemRouteResult.data,
          totalItemRoutes: itemRouteResult.total,
          fetchProgress: false
        });
      },
      requestSendTime,
      500
    );
  }

  async _submitItemRoutesForm(formValues) {
    if (this.state.saveProgress) return false;
    this.modalRef.current.setState({
      saveProgress: true
    });
    let routeId = this.state.selectedItemRoutesId;
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const savedResponse = await fetch(
      apiBasePath +
      (routeId ? apiRoutes.editItemRoute : apiRoutes.createItemRoute),
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: routeId ? "PUT" : "POST",
        body: JSON.stringify({
          user_id: this.props.user.userId,
          restarant_id: this.props.user.selectedRestarantId,
          item_route_name: formValues.itemRoute__name.trim(),
          item_route_id: routeId //will be undefined in add request
        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while ${
        routeId ? "saving the changes" : "adding the item-Route"
        }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while ${
        routeId ? "saving the changes" : "adding the item-Route"
        }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      this.modalRef.current.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.msg
      });
      return toast.error(savedResponseResult.msg);
    }

    this.modalRef.current.setState({
      saveProgress: false,
      saveFailMsg: ""
    });
    this.setState(
      {
        currentPageNum: 1
      },
      err => {
        this._loadItemRoutes();
      }
    );
    $(this.modalUI.current).modal("hide");
    toast.success(
      `Successfully ${
      routeId ? "saved the changes" : "added " + formValues.itemRoute__name
      }.`,
      { autoClose: 4000 }
    );
  }

  confirmAndDelete(routeId, name) {
    confirmAlert({
      title: "Delete?",
      message: `Are you sure to delete ${name || ""}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteItemRoute(routeId)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteItemRoute(routeId) {
    if (this.state.deleteProgress) {
      return false;
    }

    this.setState({
      deleteProgress: true
    });
    const itemRoute = this.state.itemRoutes.find(
      i => i.itemRouteId == routeId
    );
    const itemRouteName = itemRoute.itemRouteName;

    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const deletedResponse = await fetch(
      apiBasePath + apiRoutes.deleteItemRoute,
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: "DELETE",
        body: JSON.stringify({
          item_route_id: routeId,
          user_id: this.props.user.userId,
          restarant_id: this.props.user.selectedRestarantId
        })
      }
    ).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the item-route.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the item-route.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    if (!deletedResponseResult.success) {
      this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });

      return toast.error(deletedResponseResult.msg);
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: "",
      currentPageNum:
        this.state.itemRoutes.length > 1
          ? this.state.currentPageNum
          : this.state.currentPageNum - 1 > 0
            ? this.state.currentPageNum - 1
            : 1
    });
    this._loadItemRoutes();
    toast.success(`Deleted ${itemRouteName}`, { autoClose: 5000 });
  }
}

class ItemRoutesModal extends Component {
  state = {
    editMode: false,
    saveProgress: false
  };

  render() {
    const { modalUiRef, submitItemRoutesForm } = this.props;
    return (
      <div
        ref={modalUiRef}
        className="modal fade"
        id="itemRoutesModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.editMode
                  ? "Edit item-routes"
                  : "Add item-routes"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              onSubmit={submitItemRoutesForm}
              getApi={this._setFormApi.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row mb-sm-3">

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!formState.touched.itemRoute__name) {
                                  return false;
                                }
                                val = val && val.trim();
                                if (!val) return `Item route name cannot be empty`;
                                if (!val || val.length < 3 || val.length > 30) {
                                  return `${val} must contain 3 to 30 Characters.`;
                                }
                              }}
                              validateOnBlur
                              validateOnChange
                              field={"itemRoute__name"}
                              type="text"
                              placeholder="Enter Item-Route name"
                              className={
                                formState.errors.itemRoute__name
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.itemRoute__name && (
                              <div className="input_error">
                                {formState.errors.itemRoute__name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.state.editMode ? "Save Changes" : "Add"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _setFormApi(formApi) {
    this.formApi = formApi;
  }

}
