import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import {throttleTime} from '../utilities';
import { AuthContext,DataContext,DataProvider } from "../contextProvider";
import ReactPaginate from 'react-paginate';
import { toast } from "react-toastify";
export default class Guests extends Component {
  static contextType = AuthContext;
  
  state = {
    currentPageNum: 1,
    recordsPerPage: 10,
    fetchProgress: true,
    fetchFailMsg: "No Records Found",
    records: [],
    selectedRestarantId:0
  };

  componentWillMount(){
    this._fetchGuests()
  }

  render() {
    return (
       <DataProvider loadRestarantsOnWillMount={this.context.isSuperAdmin} {...this.context}>
      
        <div className="contentvthbtn">
        {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
            <>
          <div className="row mx-0 justify-content-between">
            <div className="">
              <span className="categorycol">Guests</span>
            </div>
            <div className="" >
                    <DataContext.Consumer>
                      {({restarants}) => {
                        return (
                          <select 
                            onChange={e => this.filterByRestarant(e.nativeEvent.target.value)}
                            className="select_Restaurent "
                            defaultValue={this.state.selectedRestarantId}
                          >
                            <option value={0}>All Restaurants</option>
                            {restarants && restarants.map((r,idx) => (
                                 r.name && (
                                  <option value={r.id} key={String(idx)}>
                                    {r.name.length > 40
                                      ? r.name.substr(0, 37) + "..."
                                      : r.name}
                                  </option>
                                )
                            ))}
                          </select>
                        );
                      }}
                    </DataContext.Consumer>
                  </div>
          </div>
          {this.state.records.length ? (
          <div className="table-responsive mt-3 text-capitalize tableofauto">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>guest name</th>
                  <th>contact number</th>
                  <th>status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
               {this.state.records.map((guest,idx)=>{
                 return  <tr key={'guest-'+idx}>
                 <td>{((this.state.currentPageNum - 1) * this.state.recordsPerPage) + (idx + 1)}</td>
                 <td>{guest.guestName}</td>

                 <td>{guest.guestPhone}</td>
                 <td>
                   <span>{guest.guestStatus !== 0 ? "Active" : "Blocked"}</span>
                   {/* <select className="blckactive"> 
                      <option>Block</option>
                     <option>Active</option>
                   </select> */}
                 </td>
                 <td><button className="btn btn-link" style={{border:0,padding:0,color:"#19d5ff"}} onClick={()=>this._toGuestDetails(guest.guestId)}>View</button></td>
               </tr>
              
               })}
              </tbody>
            </table>
          </div>):(<div className="noDataMsgContainer">
                  <h5>{this.state.fetchFailMsg}</h5>
                </div>)}
          </>
            )}
        </div>
        <div className="container-fluid">
        {Math.floor(this.state.totalRecords / this.state.recordsPerPage) > 0 ? (
            <ReactPaginate
              previousLabel={
                <i className="fa fa-angle-double-left" aria-hidden="true" />
              }
              nextLabel={
                <i className="fa fa-angle-double-right" aria-hidden="true" />
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(this.state.totalRecords / this.state.recordsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              pageLinkClassName={"pagebtn"}
              onPageChange={(data)=>this.getMore(data.selected + 1)}
              containerClassName={"pagination"}
              activeLinkClassName={"active"}
            />
          ) : null}
        </div>
         </DataProvider>
        
    );
  }

filterByRestarant(val){
  this.setState({
    selectedRestarantId:val,
    currentPageNum:1
  },(err)=>{
    this._fetchGuests();
  })
}
  getMore(pageNum){
    this.setState({
      currentPageNum:pageNum
    },(err)=>{
      this._fetchGuests()
    })
  }

  _toGuestDetails(guestId){
    let guest = this.state.records.find(r=>r.guestId == guestId);
    this.props.history.push('guest-details/'+guestId,{guest})
  }

  async _fetchGuests(){
    
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
    const guestsResponse = await fetch(
      apiBasePath +
        apiRoutes.fetchGuestsList +
        `?user_id=${this.context.user.userId}&restarant_id=${
       this.context.isSuperAdmin ? this.state.selectedRestarantId :    this.context.user.selectedRestarantId
        }&limit=${this.state.recordsPerPage}&offset=${(this.state.currentPageNum - 1) *
          this.state.recordsPerPage}`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));
    if (!guestsResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching guests list");
    }

    const guestsData = await guestsResponse
      .json()
      .catch(err => console.log(err));
    if (!guestsData) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching guests list");
    }
    
    if (!guestsData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error(guestsData.msg);
    }
    throttleTime(
      () => {
        this.setState({
          records: guestsData.data,
          fetchProgress: false,
          totalRecords:guestsData.total
        });
      },
      requestSendTime,
      500
    );
  }
}
