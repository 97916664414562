import React, { Component } from 'react';
import { toast } from "react-toastify";
import { AuthContext, DataContext } from "../contextProvider";
import { CategoriesContext, CategoriesProvider } from "../context";
import { Form, Text } from "informed";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import {
    validateText
} from "../utilities";
import ReactSearchBox from 'react-search-box';
import { Multiselect } from "multiselect-react-dropdown";

export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ CONSTANTS, user, authToken, isSuperAdmin, logOut }) => {
                return (
                    <DataContext.Consumer>
                        {({ fetchCategories }) => {
                            return (
                                <CategoryTimingGroupDetails
                                    {...props}
                                    user={user}
                                    authToken={authToken}
                                    CONSTANTS={CONSTANTS}
                                    isSuperAdmin={isSuperAdmin}
                                    logOut={logOut}
                                    fetchCategories={fetchCategories}
                                />
                            );
                        }}
                    </DataContext.Consumer>
                );
            }}
        </AuthContext.Consumer>
    );
};

class CategoryTimingGroupDetails extends Component {
    constructor(props) {
        super(props);
        this.rFormRef = React.createRef();
    }
    static contextType = AuthContext;
    state = {
        catTimingGroupDetails: null,
        rTimings: [],
        categories: [],
        catIds: [],
        displayCategoryNames: '',
        selectedCategories: []
    }

    async componentWillMount() {
        let locationState = this.props.location.state;
        let catTimingGroupId = locationState ? locationState.catTimingGroupId : null;
        this.setState({
            catTimingGroupId,
        });
        await this._loadCategories();
        catTimingGroupId && this._loadCatTimingGroupDetails(catTimingGroupId);

    }
    setCategoriesCallback = (cat) => {
        this.setState({ selectedCategories: cat })
    }
    render() {
        let editMode =
            this.props.location.state && this.props.location.state.catTimingGroupId;
        return (
            <div className="content of-auto">
                <div className="mx-0">
                    <div className="categorycol d-flex align-items-center">
                        <i
                            className="fa fa-long-arrow-left"
                            style={{
                                marginRight: "1rem",
                                fontSize: "20px",
                                cursor: "pointer"
                            }}
                            aria-hidden="true"
                            onClick={() => this.props.history.goBack()}
                        />
                          category Timing Groups
                    </div>
                    <EditCatTimingGroupModal
                        ref={this.rFormRef}
                        getFormValues={this._getFormValues.bind(this)}
                        setTimingSlots={(timingSlotsarr) => { this.setState({ rTimings: timingSlotsarr }) }}
                        inputList={this.state.rTimings}
                        initialValues={{
                            cName: this.state.catTimingGroupDetails ?
                                this.state.catTimingGroupDetails.catTimingGroupTitle
                                : "",

                        }}
                        editMode={editMode}
                        buildClassName={this.buildClassName.bind(this)}
                        categories={this.state.categories}
                        // selectedCategoryIds={(catIds)=>{this.setState({catIds: catIds})}}
                        // selectedCategoryNames={(catNames)=>{this.setState({displayCategoryNames: catNames})}}
                        // displayCatNames={this.state.displayCategoryNames}
                        // displayCatIds={this.state.catIds}
                        setCategories={this.setCategoriesCallback.bind(this)}
                        selectedCategories={this.state.selectedCategories}
                    />
                    <div className="text-right">
                        <button
                            type="button"
                            className="savebtn"
                            onClick={() => this._submitCatTimingGroupDetails(!!editMode)}
                        >
                            {this.state.saveProgress ||
                                (this.state.uploadProgress && (
                                    <i className="fa fa-spinner fa-spin fa-fw"></i>
                                ))}{" "}
                            {editMode ? "Save Changes" : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    async _submitCatTimingGroupDetails(edit) {
        if (this.state.saveProgress) {
            return false;
        }

        this.rFormRef.current._formApi.submitForm();
        let formInValid = Object.keys(this.rFormRef.current._formApi.getState().errors).length
        if (formInValid) {
            return false;
        }
        let formValues = this.rFormRef.current._formApi.getState().values
        const { CONSTANTS, authToken, user } = this.context;
        this.setState({
            saveProgress: true
        });
        let categoryIds = this.state.selectedCategories;
        let selectCatIds = []
        for (let i = 0; i < categoryIds.length; i++) {
            selectCatIds[i] = String(categoryIds[i].value);
        }
        if (!formValues.cName || !this.state.rTimings.length || !selectCatIds.length) {
            this.setState({
                saveProgress: false
            });
            let msg = "Please Enter Required Fields";
            return toast.error(msg, {});
        }
        let body = {
            user_id: user.userId,
            restarant_id: user.selectedRestarantId,
            cat_timing_title: formValues.cName,
            cat_timing_id: this.state.catTimingGroupId,
            cat_day_slots: this.state.rTimings,
            cat_ref_ids: selectCatIds
        }
        let apiRoute = CONSTANTS.apiRoutes.createCategoryTimingGroup;
        if (edit) {
            body.cat_timing_id = this.props.location.state.catTimingGroupId;
            apiRoute = CONSTANTS.apiRoutes.editCategoryTimingGroup;
        }
        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: edit ? "PUT" : "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = "An Error Occured while saving the changes.";
            this.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg, {});
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = "An Error Occured while saving the changes.";
            this.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg, {});
        }

        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.setState({
            saveProgress: false,
            saveFailMsg: ""
        });

        toast.success(
            edit
                ? "Saved changes to " + formValues.cName + "."
                : "Added Menu Item " + formValues.cName,
            { autoClose: 3000 }
        );
        this.props.history.goBack();
    }

    _getFormValues(formName, values) {
        // this.setState({
        //   // [formName]: values
        // });
    }
    buildClassName(err, enableInput) {
        let c = "w-100 restarantInput";
        !enableInput && (c += " restarantInput--disabled");
        err && (c += " input_error");
        return c;
    }
    async _loadCatTimingGroupDetails(catTimingGroupId) {
        if (this.state.fetchProgress) return false;
        this.setState({
            fetchProgress: true
        });
        let fetchResponse = await fetch(
            this.context.CONSTANTS.apiBasePath +
            this.context.CONSTANTS.apiRoutes.getCatTimingGroupDetails +
            `?cat_timing_id=${catTimingGroupId}&user_id=${this.context.user.userId}`,
            { headers: { Authorization: this.context.authToken } }
        ).catch(err => console.error(err));
        if (!fetchResponse) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const catTimingGroupData = await fetchResponse.json();
        //@TODO - handle error
        if (!catTimingGroupData.success) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("Could not get cat timing group details.");
        }
        function testTimingsData(timingsData) {
            try {
                JSON.parse(timingsData);
                return true;
            } catch (e) {
                return false;
            }

        }

        //fill form values
        this.setState(
            {
                catTimingGroupDetails: catTimingGroupData.data
            },
            err => {
                if (err) return console.error(err);
                let rData = this.state.catTimingGroupDetails;
                let isTimingsParsable = testTimingsData(rData.catTimingDaySlots);
                let timingSlotsData = isTimingsParsable ? JSON.parse(rData.catTimingDaySlots) : []
                let catIdFromDB = rData.categoryRefIds ? JSON.parse(rData.categoryRefIds).cat_ref_ids : []
                let selectedcatIds = []
                catIdFromDB.map((i, idx) => {
                    let catId = this.state.categories.find((id) => id.value == Number(catIdFromDB[idx]))
                    if (catId)
                        selectedcatIds.push(catId)
                })
                this.setState({
                    selectedCategories: selectedcatIds,
                })
                //item details
                this.rFormRef.current._formApi.setValue("cName", rData.catTimingGroupTitle);
                this.setState({
                    rTimings: timingSlotsData ? timingSlotsData.cat_day_slots : []
                });
            }
        );
    }
    async _loadCategories() {
        let categoriesResponse = await this.props
            .fetchCategories({
                CONSTANTS: this.props.CONSTANTS,
                userId: this.props.user.userId,
                limit: 0,
                offset: 0,
                authToken: this.props.authToken
            })
            .catch(err => console.error(err));

        if (!categoriesResponse) {
            let msg = "An Error Occured while fetching Categories.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        if (!categoriesResponse.success) {
            let msg = categoriesResponse.msg;
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        let categoriesList = categoriesResponse.data.map(c => {
            return {
                value: c.categoryId,
                label: c.categoryName
            };
        });
        this.setState({
            fetchProgress: false,
            categories: categoriesList
        })
    }

}

class EditCatTimingGroupModal extends Component {
    state = {
        editMode: this.props.editMode,
        enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
        formValues: this.props.initialValues,
    };
    componentDidMount() {
        this.setState({
            formValues: this.props.initialValues
        });
    }

    handleInputChange(e, index, str, l) {
        const list = [...this.props.inputList];

        if (str == "day") {
            list[index].day = e.target.value;
        }
        else if (str == "sTime") {
            const stime = e ? e.format("HH:mm") : ""
            list[index].slots[l].start_time = stime;
        }
        else if (str == "eTime") {
            const etime = e ? e.format("HH:mm") : ""
            list[index].slots[l].end_time = etime;
        }
        this.props.setTimingSlots(list);
    }
    handleRemoveClick(e, i) {
        const list = [...this.props.inputList];
        list.splice(i, 1);
        this.props.setTimingSlots(list);
    }
    handleTimeRemoveClick(i, l) {
        const list = [...this.props.inputList]
        if (list[i].slots.length != 1)
            list[i].slots.splice(l, 1)
        this.props.setTimingSlots(list);
    }
    handletimeAddClick(i) {
        const list = [...this.props.inputList]
        list[i].slots = [...list[i].slots, { start_time: "", end_time: "" }]
        this.props.setTimingSlots(list);
    }
    handleAddClick() {
        const list = [...this.props.inputList, { day: "", slots: [{ start_time: "", end_time: "" }] }];
        this.props.setTimingSlots(list);
    }
    // handleOnCategorySelect = item => {
    //     const listNames=[...this.props.displayCatNames]
    //     const listIds = [...this.props.displayCatIds]
    //     let selectedId= listIds.find(c=>c==item.key);
    //     if(!selectedId){
    //     listIds.push(String(item.key));
    //     listNames.push(item.value)
    //    }
    //   this.props.selectedCategoryIds(listIds)
    //   this.props.selectedCategoryNames(listNames)
    //   }
    //   handleOnCategoryRemove = item => {
    //     const listNames=[...this.props.displayCatNames]
    //     const listIds = [...this.props.displayCatIds]
    //     let selectedId= listIds.find(c=>c==item.key);
    //     if(selectedId){
    //        for(let i=0;i<listIds.length;i++) {
    //         if(listIds[i]==item.key){
    //             listIds.splice(i,1)
    //                    }
    //        }
    //        for(let i=0;i<listNames.length;i++) {
    //         if(listNames[i]==item.value){
    //             listNames.splice(i,1)
    //                    }
    //        }
    //        this.props.selectedCategoryIds(listIds)
    //        this.props.selectedCategoryNames(listNames)
    //     }

    //   }
    onSelect_categories = (selectedList, selectedItem) => {
        let selectedCategories = [];
        let i = 0;
        for (i = 0; i < selectedList.length; i++) {
            selectedCategories.push({ label: selectedList[i].label, value: selectedList[i].value })
        }
        this.props.setCategories(selectedCategories)
    }

    onRemove_categories = (selectedList) => {
        this.props.setCategories(selectedList)
    }
    render() {
        return (
            <div id="categoryDetails">
                <div className="row text-capitalize py-3 mt-2">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        {/* <span className="orderhding">Category Timing group details</span> */}
                        {this.state.editMode && (
                            <button
                                className="float-right rEdit__editToggle"
                                onClick={this._toggleEditMode.bind(this)}
                            >
                                <i className="fa fa-pencil" aria-hidden="true" />
                            </button>
                        )}
                    </div>
                </div>
                <Form
                    onChange={formState => this.props.getFormValues("rForm", formState)}
                    getApi={this._setFormApi.bind(this)}
                >
                    {({ formState }) => {
                        return (
                            <>
                                <div className="row text-capitalize fsize border_bottom">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="row align-items-center">
                                            <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cName"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    Timing Group Title <span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-7 form-group">
                                                <div className="">
                                                    <Text
                                                        field="cName"
                                                        type="text"
                                                        disabled={!this.state.enableInput}
                                                        initialValue={this.state.formValues.cName}
                                                        validate={val =>
                                                            validateText(
                                                                val,
                                                                3,
                                                                formState.touched.cName,
                                                                "Cat Timing group title",
                                                                30
                                                            )
                                                        }
                                                        id="cEdit__cName"
                                                        placeholder="Enter timing group title"
                                                        className={this.props.buildClassName.bind(this)(
                                                            formState.errors.cName,
                                                            this.state.enableInput
                                                        )}
                                                        validateOnBlur
                                                        validateOnChange
                                                    />
                                                    {formState.errors.cName && (
                                                        <span className="input_error">
                                                            {formState.errors.cName}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6" />
                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Add Categories Ids
                                                            <ReactSearchBox
                                                    data={this.props.categories}
                                                    onSelect={this.handleOnCategorySelect}
                                                    placeholder="Type to Search"

                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Remove Categories Ids
                                                            <ReactSearchBox
                                                    data={this.props.categories}
                                                    onSelect={this.handleOnCategoryRemove}
                                                    placeholder="Type to Search"

                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label>Selected Categories ids:
                                                    <textarea
                                                    value={this.props.displayCatNames}
                                                    disabled={true}
                                                    placeholder="Selected Option Groups"
                                                    style={{ width: "1000px" }}
                                                    rows={4}
                                                />
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="row align-items-center">
                                            <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="rEdit__cCategoriesIds"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    Select Categories<span className="">:</span>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-7 form-group">
                                                <div className="">
                                                    <Multiselect
                                                        field="cCategoriesIds"
                                                        options={this.props.categories}
                                                        displayValue="label"
                                                        onSelect={this.onSelect_categories}
                                                        onRemove={this.onRemove_categories}
                                                        selectedValues={this.props.selectedCategories}
                                                        disable={!this.state.enableInput}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="row align-items-center">
                                            <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                                                <label
                                                    htmlFor="cEdit__cTimings"
                                                    className="row mx-0 w-100 justify-content-between"
                                                >
                                                    Timings <span className="">:</span>
                                                </label>
                                            </div>
                                            <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddClick()}>Add</button></div>
                                            {
                                                this.props.inputList && this.props.inputList.map((x, i) => {
                                                    return (
                                                        <div className="col-6 col-md-6 col-lg-6 form-group">
                                                            <div
                                                                className="restarantInput d-flex justify-content-between align-items-center"
                                                                style={{ padding: "10px" }}
                                                            >
                                                                <select
                                                                    style={{ marginInlineStart: "8px" }}
                                                                    disabled={!this.state.enableInput}
                                                                    validateOnBlur
                                                                    validateOnChange
                                                                    className={
                                                                        this.state.enableInput
                                                                            ? "w-100 restarantInput"
                                                                            : "w-100 restarantInput restarantInput--disabled"
                                                                    }
                                                                    value={x.day}
                                                                    onChange={obj => this.handleInputChange(obj, i, "day", "")}
                                                                >
                                                                    {/* @todo - dynamic values */}
                                                                    <option value="">select day</option>
                                                                    <option value="monday">monday</option>
                                                                    <option value="tuesday">tuesday</option>
                                                                    <option value="wednesday">wednesday</option>
                                                                    <option value="thursday">thursday</option>
                                                                    <option value="friday">friday</option>
                                                                    <option value="saturday">saturday</option>
                                                                    <option value="sunday">sunday</option>
                                                                </select>
                                                                <div style={{ lineHeight: "10px", marginInlineStart: "6px" }}><button disabled={!this.state.enableInput} style={{ fontSize: 25 }} className="btnaddcategory" onClick={() => this.handletimeAddClick(i)}>+</button></div>
                                                                <div>
                                                                    {x.slots.map((y, l) => {
                                                                        return (
                                                                            <div className="restarantInput d-flex justify-content-between align-items-center"
                                                                                style={{ padding: "4px" }}>
                                                                                <TimePicker
                                                                                    placeholder="HH:MM"
                                                                                    disabled={!this.state.enableInput}
                                                                                    value={y.start_time ? moment(y.start_time, "HH:mm") : null}
                                                                                    hideDisabledOptions
                                                                                    clearIcon={this.state.enableInput ? null : <i />}
                                                                                    onChange={momentDateObj => {
                                                                                        this.handleInputChange(momentDateObj, i, "sTime", l)
                                                                                    }}
                                                                                    showSecond={false}
                                                                                />
                                                                                <span style={{ width: "40px", textAlign: "center" }}>
                                                                                    {" "}
                            To{" "}
                                                                                </span>
                                                                                <TimePicker
                                                                                    placeholder="HH:MM"
                                                                                    disabled={!this.state.enableInput}
                                                                                    showSecond={false}
                                                                                    value={y.end_time ? moment(y.end_time, "HH:mm") : null}
                                                                                    clearIcon={this.state.enableInput ? null : <i />}
                                                                                    hideDisabledOptions={true}
                                                                                    onChange={momentDateObj => {
                                                                                        this.handleInputChange(momentDateObj, i, "eTime", l)
                                                                                    }}

                                                                                />
                                                                                <div style={{ lineHeight: "10px", marginInlineStart: "6px" }} ><button disabled={!this.state.enableInput} style={{ fontSize: 25 }} className="btnaddcategory" onClick={() => this.handleTimeRemoveClick(i, l)}>-</button></div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handleRemoveClick(obj, i)}>Remove</button></div>

                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }}

                </Form>
            </div >
        )
    }

    _setFormApi(formApi) {
        this._formApi = formApi;
    }

    _toggleEditMode() {
        let numErrors = 0;
        let formErrors = this._formApi.getState().errors;
        for (let i in formErrors) {
            if (formErrors[i]) {
                numErrors++;
            }
        }
        if (this.state.enableInput && numErrors) {
            return console.error("clear errors first");
        }
        this.setState({
            enableInput: !this.state.enableInput
        });
    }
}