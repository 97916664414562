import React, { Component } from "react";
import { FB, INSTA, TWITTER, LINKEDIN } from "../assets/images/";
import { AuthContext } from "../contextProvider";
import { toast } from "react-toastify";
import { CONSTANTS } from "../contextProvider";
import StarRatings from 'react-star-ratings';
import { Checkbox } from "@material-ui/core";


export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
                return (
                    <OrderDetails
                        {...props}
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                    />

                );
            }}
        </AuthContext.Consumer>
    );
};

class OrderDetails extends Component {
    state = {
        fetchProgress: true,
        smsOrderDetails: {},
        rating: 2,
        feedBacks: [],
        textAreaValue: "",
        submitFeedBack: false,
        name:'',
        phone:'',
        email:'',
        DOB:''
    };

    async componentDidMount() {
        await this._loadorderDetails(this.props.match.params.id);
    }
    changeRating(newRating, name) {
        let feedBack = [...this.state.feedBacks]
        feedBack[Number(name)].feedBackAnswer = newRating;
        this.setState({
            feedBacks: feedBack
        });
    }
    render() {
        let order = this.state.smsOrderDetails;
        const ordersum = order.orderSummary;
        let cart = ordersum ? ordersum.cart : [];
        return (
            <div>
                <div className="bg-white rounded mx-auto shadow-sm p-3 py-lg-5">
                    {order.restarantFeedback && !order.feedbackStatus ? <button
                        type="button"
                        className="btnaddcategory float"
                        data-toggle="modal"
                        data-target="#feedBackModal"
                    >
                        <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                        &nbsp; Give FeedBack
                    </button> : null}
                    <div className="text-center">
                        <div style={{ fontWeight: 'bold' }}>
                            <div style={{ fontSize: 30 }}>   {order.restarantName}</div>
                            <div> {order.restarantAdress}</div>
                            <div>  phone :  {order.restarantPhone}</div>
                            {order.restarantGst ? <div>  GSTIN :  {order.restarantGst}</div> : null}
                            {order.restarantGstDesc ? <div>   GST Description :   {order.restarantGstDesc}</div> : null}
                        </div>
                        <hr />
                        {order ? (
                            <div style={{ marginTop: "2%", fontWeight: 'bold' }}>
                                <div >Order Id :{order.orderId}</div>
                                {order.customerName ? <div >Name : {order.customerName}</div> : null}
                                {order.customerPhone ? <div >PhoneNumber : {order.customerPhone}</div> : null}
                                {order.orderType ? <div >Order Type : {order.orderType}</div> : null}
                            </div>) : null}
                        <hr />
                        <div style={{ marginTop: "2%", display: 'flex', justifyContent: 'center' }}>

                            <table border="2px " cellPadding="5" cellSpacing="5">

                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                {ordersum ? (
                                    <tbody>
                                        {cart.map((item, idx) => {
                                            let optionsPrice = 0;
                                            let options = []
                                            if (item.options_to_add) {
                                                item.options_to_add.reduce((option, i) => {
                                                    optionsPrice += (i.optionPrice || 0)
                                                    options.push(i.optionTitle)
                                                    return optionsPrice
                                                }, 0)
                                            }
                                            return <tr key={String(idx)}>
                                                <td>{item.itemTitle}{options.length ? "(" + options.join() + ")" : null}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.itemPrice + optionsPrice}</td>
                                                <td>{(item.itemPrice + optionsPrice) * item.qty}</td>
                                            </tr>
                                        })}

                                        <tr>
                                            <th colSpan="3">Order Total</th>

                                            <th>{ordersum.orderTotal.toFixed(2)}</th>
                                        </tr>


                                        {ordersum.orderCgst ? <tr>
                                            <td colSpan="3">CGST({ordersum.CGST}%)</td>

                                            <td>{ordersum.orderCgst.toFixed(2)}</td>
                                        </tr> : null}
                                        {ordersum.orderSgst ? <tr>
                                            <td colSpan="3">SGST({ordersum.SGST}%)</td>

                                            <td>{ordersum.orderSgst.toFixed(2)}</td>
                                        </tr> : null}
                                        {ordersum.orderIgst ? <tr>
                                            <td colSpan="3">IGST({ordersum.IGST}%)</td>

                                            <td>{ordersum.orderSgst.toFixed(2)}</td>
                                        </tr> : null}
                                        {/* {ordersum.discountItem ? <tr>
                                            <td colspan="3">Item Discount</td>

                                            <td>{ordersum.discountItem}</td>
                                        </tr> : null} */}
                                        {order.orderDiscount ? <tr>
                                            <td colSpan="3">Order Discount</td>

                                            <td>{order.orderDiscount.toFixed(2)}</td>
                                        </tr> : null}
                                        <tr>
                                            <th colSpan="3">Grand Total</th>

                                            <th>{(ordersum.orderTotal + ordersum.orderCgst + ordersum.orderSgst + (ordersum.orderIgst || 0) - order.orderDiscount).toFixed(2)} {order.restarantCurrency}</th>
                                        </tr>
                                    </tbody>) : null}
                            </table>


                        </div>
                    </div>
                </div  >

                <div style={{ backgroundColor: '#49565A', height: 120, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <p ><a href="https://www.buvvas.com" target="blank">Powered by Buvvas Business Apps</a> </p>
                    <p ><a href="https://www.buvvas.com" target="blank">info@buvvas.com</a></p>
                    <div >
                        <a href="https://www.facebook.com/buvvas" target="blank"><img src={FB} className="img-fluid" alt="Buvvas" /></a>
                        <a href="https://www.instagram.com/buvvas_business/" target="blank"><img src={INSTA} className="img-fluid" alt="Buvvas" style={{ marginLeft: 10 }} /></a>
                        <a href="https://twitter.com/Buvvas_Business" target="blank"><img src={TWITTER} className="img-fluid" alt="Buvvas" style={{ marginLeft: 10 }} /></a>
                        <a href="https://www.linkedin.com/company/buvvas/" target="blank"><img src={LINKEDIN} className="img-fluid" alt="Buvvas" style={{ marginLeft: 10 }} /></a>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="feedBackModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Provide Us Feedback
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span className="closeicon" aria-hidden="true">
                                        &times;
                                    </span>
                                </button>
                            </div>
                            {!this.state.submitFeedBack ?
                                <div className="modal-body">
                                    {
                                        this.state.feedBacks.map((f, idx) => {
                                            return <div key={String(f.feedbackId)}>
                                                <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: 10 }}>{f.feedbackTitle}</div>
                                                {f.feedbackTitleType == "Star" ? <StarRatings
                                                    rating={f.feedBackAnswer}
                                                    starRatedColor="#1f74f4"
                                                    changeRating={this.changeRating.bind(this)}
                                                    numberOfStars={5}
                                                    name={String(idx)}
                                                    starDimension='40px'
                                                    starSpacing='8px'
                                                    starHoverColor='green'
                                                /> : null}
                                                {f.feedbackTitleType == "textbox" ? <textarea
                                                    value={f.feedBackAnswer}
                                                    onChange={text => {
                                                        let feedBack = [...this.state.feedBacks]
                                                        feedBack[idx].feedBackAnswer = text.target.value;
                                                        this.setState({
                                                            feedBacks: feedBack
                                                        });
                                                    }}
                                                    rows={5}
                                                    style={{ width: "100%", marginTop: 20 }}
                                                    placeholder="Enter Comment..."
                                                /> : null}
                                                {f.feedbackTitleType == "Yes/No" ?
                                                    <div>
                                                        <Checkbox onClick={() => {
                                                            let feedBack = [...this.state.feedBacks]
                                                            feedBack[idx].feedBackAnswer = "YES";
                                                            this.setState({
                                                                feedBacks: feedBack
                                                            });
                                                        }} checked={f.feedBackAnswer == "YES"} /><label> Yes</label><Checkbox onClick={() => {
                                                            let feedBack = [...this.state.feedBacks]
                                                            feedBack[idx].feedBackAnswer = "NO";
                                                            this.setState({
                                                                feedBacks: feedBack
                                                            });
                                                        }} checked={f.feedBackAnswer == "NO"} /><label> No</label> </div> : null}
                                                {f.feedbackTitleType == "Options" ?
                                                    <div>
                                                        <Checkbox onClick={() => {
                                                            let feedBack = [...this.state.feedBacks]
                                                            feedBack[idx].feedBackAnswer = "Excellent";
                                                            this.setState({
                                                                feedBacks: feedBack
                                                            });
                                                        }} checked={f.feedBackAnswer == "Excellent"} /><label> Excellent</label>
                                                        <Checkbox onClick={() => {
                                                            let feedBack = [...this.state.feedBacks]
                                                            feedBack[idx].feedBackAnswer = "Good";
                                                            this.setState({
                                                                feedBacks: feedBack
                                                            });
                                                        }} checked={f.feedBackAnswer == "Good"} /><label> Good</label>
                                                        <Checkbox onClick={() => {
                                                            let feedBack = [...this.state.feedBacks]
                                                            feedBack[idx].feedBackAnswer = "Average";
                                                            this.setState({
                                                                feedBacks: feedBack
                                                            });
                                                        }} checked={f.feedBackAnswer == "Average"} /><label> Average</label>
                                                        <Checkbox onClick={() => {
                                                            let feedBack = [...this.state.feedBacks]
                                                            feedBack[idx].feedBackAnswer = "Dissatisfied";
                                                            this.setState({
                                                                feedBacks: feedBack
                                                            });
                                                        }} checked={f.feedBackAnswer == "Dissatisfied"} /><label> Dissatisfied</label>
                                                    </div> : null}
                                            </div>
                                        })
                                    }
                                      <h3 style={{ color: 'grey', textDecorationLine: 'underline' }}><b>Customer Details</b></h3>
                                        <div>
                                            <input
                                                onChange={(event) => this.setState({ name: event.target.value })}
                                                value={order.customerName || this.state.name}
                                                placeholder="Enter Name"
                                                style={{ border: "1px solid black", height: 40, width: 300, marginBottom: 15 }} />
                                        </div>
                                        <div>

                                            <input
                                                onChange={(event) => {
                                                   if(event.target.value && !new RegExp(/^[0-9]+$/).test(event.target.value)) return false;
                                                    this.setState({ phone: event.target.value })
                                                }}
                                                value={order.customerPhone || this.state.phone}
                                                placeholder="Enter Phone Number"
                                                style={{ border: "1px solid black", height: 40, width: 300, marginBottom: 15 }}
                                                type="text"
                                                maxLength={12} 
                                                />
                                        </div>
                                        <div>

                                            <input
                                                onChange={(event) => this.setState({ email: event.target.value })}
                                                value={this.state.email}
                                                placeholder="Enter Email"
                                                style={{ border: "1px solid black", height: 40, width: 300, marginBottom: 15 }}
                                                type="email" />
                                        </div>
                                            DOB :  
                                            <input
                                                onChange={(event) => this.setState({ DOB: event.target.value })}
                                                value={this.state.DOB}
                                                placeholder="DOB"
                                                style={{ border: "1px solid black", height: 45, width: 250, marginBottom: 15,marginLeft:10 }}
                                                type="date"
                                            />

                                </div> :
                                <div className="modal-body">
                                    <h5> Thanks for Providing feedback</h5>
                                    <h5>Have a nice day.</h5>
                                </div>}
                            {!this.state.submitFeedBack ? <div className="modal-footer d-block clearfix">
                                <button
                                    type="submit"
                                    className="btnmodaladd text-uppercase float-right"
                                    onClick={() => {
                                        let feedBack = [];
                                        this.state.feedBacks.map(f => {
                                            if (f.feedBackAnswer) {
                                                feedBack.push(f.feedbackId)
                                            }
                                        })
                                        if (!feedBack.length) {
                                            return toast.error("Please Select One Option")
                                        }
                                        this.submitFeedBackForm(this.props.match.params.id, order)
                                    }}
                                >
                                    Submit
                                </button>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>

        );
    }


    async _loadorderDetails(id) {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = CONSTANTS;
        const requestSendTime = Date.now();
        const SmsOrderResponse = await fetch(
            apiBasePath +
            apiRoutes.getOrderDetails +
            `?order_id=${id}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!SmsOrderResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        const SmsOrderResult = await SmsOrderResponse.json();
        if (!SmsOrderResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!SmsOrderResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: SmsOrderResult.msg
            });
            return toast.error(SmsOrderResult.msg);
        }
        this.setState({
            smsOrderDetails: SmsOrderResult.data,
            fetchProgress: false,
            feedBacks: SmsOrderResult.data.feedBacks
        });
    }


    async submitFeedBackForm(orderId, order) {
        if (this.state.saveProgress) return false;
        const { apiBasePath, apiRoutes } = CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath + apiRoutes.saveFeedBack,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    order_id: orderId,
                    restarant_id: order.restarantId,
                    fb_response_rating: this.state.feedBacks,
                    fb_response_comment: true,
                    customer_details: {
                        name: this.state.name || order.customerName,
                        phone: this.state.phone || order.customerPhone,
                        email: this.state.email,
                        DOB: this.state.DOB,
                        altPhone: order.customerPhone
                    },
                    rest_name: order.restarantName
                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while submitting the FeedBack "`;
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while Submitting the Feed Back "`;
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            return toast.error(savedResponseResult.msg);
        }
        this.setState({
            submitFeedBack: true
        })
        toast.success(
            `Successfully Submitted the feedback.`,
            { autoClose: 4000 }
        );
    }
}