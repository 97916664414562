import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Text, Form, Select, Radio, RadioGroup, Checkbox, TextArea } from "informed";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import {
  AuthContext,
  CityListProvider,
  CityListContext
} from "../contextProvider";
import {
  validatePhone,
  validateEmail,
  validateText,
  validateInput,
  validateStrictText,
  validateStrictGst,
  validateStrictGstdescription,
} from "../utilities";
import moment from "moment";
import { toast } from "react-toastify";
import LocationSearchInput from "./partials/LocationSearchInput";
import { Multiselect } from "multiselect-react-dropdown";
import Switch from 'react-switch';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Slider } from "@material-ui/core";
import { DELETE_IMG } from "../assets/images";
import QRCode from 'qrcode.react';
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetchRetry = require('fetch-retry')(originalFetch);
// import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

// import { Typeahead,AsyncTypeahead } from "react-bootstrap-typeahead";

export default class RestarantDetails extends Component {
  constructor(props) {
    super(props);
    this.rFormRef = React.createRef();
    this.rOwnerFormRef = React.createRef();
    this.rHeadFormRef = React.createRef();
    this.rUrbanFormRef = React.createRef();
    this.rUserAppFormRef = React.createRef();
  }
  static contextType = AuthContext;

  state = {
    // enableInput:this.props.location.state && this.props.location.state.restarantId
    restarantDetails: null,
    rTimings: [],
    disableInput:
      this.props.location.state && this.props.location.state.restarantId,
    restarantImagePath: "",
    baseUrl: "",
    locationsFromGoogleApi: [],
    rLocationErr: "",
    rLocation: [],
    aggregatorPlatforms: "",
    includedPlatforms: [],
    excludedPlatforms: [],
    switchChecked: false,
    zipCodes: '',
    files: [],
    rAbout: "",
    synchError: false,
    rOffers: [],
    rFeatures: [],
    userPickUpTime: [0, 0],
    userDeliveryTime: [0, 0],
    rDeliveryPartners: [],
    rOrderingContactNos: '',
    restarantLogoImagePath: '',
    platformsData: []
  };

  componentWillMount() {
    let locationState = this.props.location.state;
    let restarantId = locationState ? locationState.restarantId : null;
    let platformList = [];
    let list = [];
    list = Object.keys(this.context.CONSTANTS.aggregatorPlatforms);
    for (var i = 0; i < list.length; i++) {
      platformList.push({
        label: list[i],
        value: i + 1
      });
    }
    this.setState({
      restarantId,
      baseUrl: this.context.CONSTANTS.apiBasePath.split("/api")[0],
      aggregatorPlatforms: platformList || []
    });
    //@todo - support passing restarntId with url also,
    // const segmentsReversed = this.props.location.pathname.split('/').reverse();

    // if(segmentsReversed[1] === this.props.currentPath){
    //   restarantId = segmentsReversed[0];
    // }
    restarantId && this._loadRestarantDetails(restarantId);
    if (!window.google) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = `https://maps.googleapis.com/maps/api/js?key=${this.context.locationAPIKey}&libraries=places`;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener("load", e => {
        // this.onScriptLoad();
        this.rFormRef.current.setState({ mapsApiReady: true });
      });
    } else {
      // this.onScriptLoad();
      // this.rFormRef.current && this.rFormRef.current.setState({ mapsApiReady: true });
    }
  }

  includedPlatsCallBackFunc = (inPlats) => {
    this.setState({ includedPlatforms: inPlats })
  }
  excludedPlatsCallbackFunc = (exPlats) => {
    this.setState({ excludedPlatforms: exPlats })
  }

  render() {
    let editMode =
      this.props.location.state && this.props.location.state.restarantId;
    return (
      <div className="content of-auto">
        <div className="mx-0">
          <div className="categorycol d-flex align-items-center">
            <i
              className="fa fa-long-arrow-left"
              style={{
                marginRight: "1rem",
                fontSize: "20px",
                cursor: "pointer"
              }}
              aria-hidden="true"
              onClick={() => this.props.history.goBack()}
            />
            Restaurants
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <label
                id="img_preview_label"
                htmlFor="img_preview"
                style={{
                  backgroundImage: this.state.restarantImagePath
                    ? `url(${this.state.baseUrl +
                    this.state.restarantImagePath})`
                    : `url(${this.state.displayImage})`
                }}
              >
                <input
                  type="file"
                  className="img_preview"
                  accept="image/*"
                  id="img_preview"
                  onChange={this.previewImage.bind(this)}
                />
                {!(
                  this.state.restarantImagePath || this.state.displayImage
                ) && (
                    <i
                      className="fa fa-upload img-upload-icon"
                      aria-hidden="true"
                    />
                  )}
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <label
                id="img_preview_label"
                htmlFor="img_logo_preview"
                style={{
                  backgroundImage: this.state.restarantLogoImagePath
                    ? `url(${this.state.baseUrl +
                    this.state.restarantLogoImagePath})`
                    : `url(${this.state.displayLogoImage})`
                }}
              >
                <input
                  type="file"
                  className="img_preview"
                  accept="image/*"
                  id="img_logo_preview"
                  onChange={this.previewLogoImage.bind(this)}
                />
                {!(
                  this.state.restarantLogoImagePath || this.state.displayLogoImage
                ) && (
                    <i
                      className="fa fa-upload img-upload-icon"
                      aria-hidden="true"
                    />
                  )}
              </label>
              {/* <img
                alt=""
                src={DELETE_IMG}
                style={{ cursor: "pointer", backgroundColor: "white", height: "25px", width: "25px" }}
                className="img-fluid"
                // onClick={() => this.deleteImage(i)
                // }
              /> */}
            </div>
            {this.props.location.state ? <div className="col-12 col-sm-12 col-md-4 col-lg-4" >
              <h5>Feedback QrCode</h5>
              <QRCode
                id="qrCodeEl"
                size={200}
                value={`${this.context.CONSTANTS.apiBasePath.split("/api")[0]}/Feedback/${this.props.location.state.restarantName}/${this.props.location.state.restEncryptId}`}
              />
              <input
                type="button"
                className="download-btn"
                value="Download"
                onClick={this.downloadQRCode}
              />
            </div> : null}
          </div>
          <CityListProvider
            apiBasePath={this.context.CONSTANTS.apiBasePath}
            apiRoutes={this.context.CONSTANTS.apiRoutes}
            user={this.context.user}
            authToken={this.context.authToken}
          >
            <RestarantDetailsForm
              rLocationErr={this.state.rLocationErr}
              setLocationAddress={val => {
                this.setState({
                  rAddress: val
                });
              }}
              restarantId={
                this.props.location && this.props.location.state
                  ? this.props.location.state.restarantId
                  : null
              }
              initialAddress={this.state.rAddress}
              setLocationCoords={val => {
                this.setState({
                  rLocation: val.lat + "," + val.lng
                });
              }}
              // onLocationSelected={val => {
              //   this.setState({ rLocation: val });
              // }}
              // setTimings={this.setTimings.bind(this)}
              setTimingSlots={(timingSlotsarr) => { this.setState({ rTimings: timingSlotsarr }) }}
              setSwitch={(checked) => { this.setState({ switchChecked: checked }) }}
              SwitchChecked={this.state.switchChecked}
              ref={this.rFormRef}
              getFormValues={this._getFormValues.bind(this)}
              initialValues={{
                // rLocation: this.state.restarantDetails
                //   ? this.state.restarantDetails.rLocation
                //   : "",
                rName: this.state.restarantDetails
                  ? this.state.restarantDetails.rName
                  : "",
                rShortName: this.state.restarantDetails
                  ? this.state.restarantDetails.rShortName
                  : "",
                rPhone: this.state.restarantDetails
                  ? this.state.restarantDetails.rPhone
                  : "",
                rOwner: this.state.restarantDetails
                  ? this.state.restarantDetails.rOwner
                  : "",
                rTimings: this.state.restarantDetails
                  ? this.state.restarantDetails.rTimings
                  : "",
                rNearLocation: this.state.restarantDetails
                  ? this.state.restarantDetails.rNearLocation
                  : "",
                rStatus: this.state.restarantDetails
                  ? this.state.restarantDetails.rStatus
                  : this.context.CONSTANTS.statusCodes["RESTAURANT_ACTIVE"],
                rGst: this.state.restarantDetails
                  ? this.state.restarantDetails.rGst
                  : "",
                rGstdescription: this.state.restarantDetails
                  ? this.state.restarantDetails.rGstdescription
                  : "",
                rFSSAI: this.state.restarantDetails
                  ? this.state.restarantDetails.rFSSAI
                  : "",
                rCurrency: this.state.restarantDetails
                  ? this.state.restarantDetails.rCurrency
                  : "",
                rKitchen: this.state.restarantDetails
                  ? this.state.restarantDetails.rKitchen
                  : "",
                rCAP: this.state.restarantDetails
                  ? this.state.restarantDetails.rCAP
                  : "",
                rMAP: this.state.restarantDetails
                  ? this.state.restarantDetails.rMAP
                  : "",
                rMAPType: this.state.restarantDetails
                  ? this.state.restarantDetails.rMAPType
                  : "",
                rMapPhone: this.state.restarantDetails
                  ? this.state.restarantDetails.rMapPhone
                  : "",
                rUrbanpiper: this.state.restarantDetails
                  ? this.state.restarantDetails.rUrbanpiper
                  : "",
                rFeedback: this.state.restarantDetails
                  ? this.state.restarantDetails.rFeedback
                  : "",
                rRecReports: this.state.restarantDetails
                  ? this.state.restarantDetails.rRecReports
                  : "",
                rScheduledOrder: this.state.restarantDetails
                  ? this.state.restarantDetails.rScheduledOrder
                  : "",
                rPrintBeforeInvoice: this.state.restarantDetails
                  ? this.state.restarantDetails.rPrintBeforeInvoice
                  : "",
                rDeleteReports: this.state.restarantDetails
                  ? this.state.restarantDetails.rDeleteReports
                  : "",
                rOnboardingDate: this.state.restarantDetails
                  ? this.state.restarantDetails.rOnboardingDate
                  : "",
                rOnboardingValidity: this.state.restarantDetails
                  ? this.state.restarantDetails.rOnboardingValidity
                  : "",
                rMenuDelete: this.state.restarantDetails
                  ? this.state.restarantDetails.rMenuDelete
                  : "",
              }}
              editMode={editMode}
              buildClassName={this.buildClassName.bind(this)}
              inputList={this.state.rTimings}
            />
          </CityListProvider>
          {this.props.location.state ? <div>
            MAP Password :
            <input
              onChange={(event) => this.setState({ mapPassword: event.target.value })}
              value={this.state.mapPassword}
              placeholder="Enter the password"
              style={{ border: "1px solid black", height: 45, width: 250, marginBottom: 15, marginLeft: 10 }}
              type="password"
            />
            <button
              type="button"
              className="savebtn"
              onClick={() => this._updateMapPassword()}
              style={{ marginLeft: 20 }}
            >
              Submit
            </button>
            <hr />
          </div> : null}
          <UrbanPiperDetailsForm
            setIncludedPlats={this.includedPlatsCallBackFunc.bind(this)}
            setExcludedPlats={this.excludedPlatsCallbackFunc.bind(this)}
            ref={this.rUrbanFormRef}
            getFormValues={this._getFormValues.bind(this)}
            setSwitch={(checked) => { this.setState({ switchChecked: checked }) }}
            platformsData={this.state.platformsData}
            setPlatformsData={(data) => { this.setState({ platformsData: data }) }}
            SwitchChecked={this.state.switchChecked}
            initialValues={{
              rUMinPickupTime: this.state.restarantDetails
                ? this.state.restarantDetails.rMinPickupTime
                : "",
              rUMinDeliveryTime: this.state.restarantDetails
                ? this.state.restarantDetails.rMinDeliveryTime
                : "",
              rUMinOrderValue: this.state.restarantDetails
                ? this.state.restarantDetails.rMinOrderValue
                : "",
              rUHidefromUI: this.state.restarantDetails
                ? this.state.restarantDetails.rHidefromUI
                : "",
              rUOrderingEnabled: this.state.restarantDetails
                ? this.state.restarantDetails.rOrderingEnabled
                : "",
              rUZipCodes: this.state.restarantDetails
                ? this.state.zipCodes
                : ""

            }}
            editMode={editMode}
            buildClassName={this.buildClassName.bind(this)}
            aggregatorPlatforms={this.state.aggregatorPlatforms}
            includedPlatInitVal={this.state.includedPlatforms}
            excludedPlatInitVal={this.state.excludedPlatforms}
          />
          <RestarantOwnerEditForm
            ref={this.rOwnerFormRef}
            getFormValues={this._getFormValues.bind(this)}
            initialValues={{
              rOName: this.state.restarantDetails
                ? this.state.restarantDetails.rOName
                : "",
              rOPhone: this.state.restarantDetails
                ? this.state.restarantDetails.rOPhone
                : "",
              rOAddress: this.state.restarantDetails
                ? this.state.restarantDetails.rOAddress
                : ""
            }}
            editMode={editMode}
            buildClassName={this.buildClassName.bind(this)}
          />
          <RestarantHeadEditForm
            ref={this.rHeadFormRef}
            getFormValues={this._getFormValues.bind(this)}
            initialValues={{
              rHName: this.state.restarantDetails
                ? this.state.restarantDetails.rHName
                : "",
              rHAddress: this.state.restarantDetails
                ? this.state.restarantDetails.rHAddress
                : "",
              rHPhone: this.state.restarantDetails
                ? this.state.restarantDetails.rHPhone
                : ""
            }}
            editMode={editMode}
            buildClassName={this.buildClassName.bind(this)}
          />
          <RestarantUserAppForm
            ref={this.rUserAppFormRef}
            apiBasePath={this.context.CONSTANTS.apiBasePath}
            apiRoutes={this.context.CONSTANTS.apiRoutes}
            getFormValues={this._getFormValues.bind(this)}
            initialValues={
              {
                rLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rLink
                  : "",
                rAbout: this.state.restarantDetails
                  ? this.state.restarantDetails.rAbout
                  : "",
                rFBLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rFBLink
                  : "",
                rInstaLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rInstaLink
                  : "",
                rTwiterLink: this.state.restarantDetails
                  ? this.state.restarantDetails.rTwiterLink
                  : "",
                rTagLine: this.state.restarantDetails
                  ? this.state.restarantDetails.rTagLine
                  : "",
                rUserappTemplete: this.state.restarantDetails
                  ? this.state.restarantDetails.rUserappTemplete
                  : "",
                rSpeciality: this.state.restarantDetails
                  ? this.state.restarantDetails.rSpeciality
                  : "",
                rPureVeg: this.state.restarantDetails
                  ? this.state.restarantDetails.rPureVeg
                  : "",
                rEmail: this.state.restarantDetails
                  ? this.state.restarantDetails.rEmail
                  : "",
                rOrderingContactNos: this.state.restarantDetails
                  ? this.state.rOrderingContactNos
                  : ""
              }
            }
            setRestAbout={(restAbout) => { this.setState({ rAbout: restAbout }) }}
            rAboutInitVal={this.state.rAbout}
            editMode={editMode}
            buildClassName={this.buildClassName.bind(this)}
            offersList={this.state.rOffers}
            setOffers={(restOffers) => { this.setState({ rOffers: restOffers }) }}
            setFeatures={(features) => { this.setState({ rFeatures: features }) }}
            featuresList={this.state.rFeatures}
            setUserPickuptime={(data) => { this.setState({ userPickUpTime: data }) }}
            userPickupTime={this.state.userPickUpTime}
            setUserDeliverytime={(data) => { this.setState({ userDeliveryTime: data }) }}
            userDeliveryTime={this.state.userDeliveryTime}
            setPartners={(data) => { this.setState({ rDeliveryPartners: data }) }}
            partnersList={this.state.rDeliveryPartners}
          >
          </RestarantUserAppForm>

          <div className="text-right">
            <CityListProvider
              apiBasePath={this.context.CONSTANTS.apiBasePath}
              apiRoutes={this.context.CONSTANTS.apiRoutes}
              user={this.context.user}
              authToken={this.context.authToken}
            >
              <CityListContext.Consumer>
                {({ records }) => {
                  return (
                    <button
                      type="button"
                      className="savebtn"
                      onClick={() => this._submitRestarantDetails(!!editMode, records)}
                    >
                      {this.state.saveProgress ||
                        (this.state.uploadProgress && (
                          <i className="fa fa-spinner fa-spin fa-fw"></i>
                        ))}{" "}
                      {editMode ? "Save Changes" : "Save"}
                    </button>
                  )
                }}
              </CityListContext.Consumer>
            </CityListProvider>
          </div>
        </div>
      </div >
    );
  }

  async _updateMapPassword() {
    if (!this.state.mapPassword) return false;
    const { CONSTANTS, authToken, user } = this.context;
    let apiRoute = CONSTANTS.apiRoutes.updateMapPassword;
    let body = {
      user_id: user.userId,
      restaurant_id: this.state.restarantId,
      map_password: this.state.mapPassword
    }
    const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));

    if (!savedResponse) {
      return toast.error("An Error Occured.");
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      return toast.error("An Error Occured while saving the changes");
    }
    toast.success("successfullY Updated")
  }
  downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  previewImage(e) {
    let image = e.nativeEvent.target.files[0];
    if (image.type.split("/")[0] != "image") {
      e.target.value = null;

      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayImage: URL.createObjectURL(image)
      },
      async err => {
        //upload image
        let data = new FormData();
        data.append("file", image);
        data.append("filename", "myfile");
        let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
        this.setState({
          uploadProgress: true
        });
        let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));
        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));
        this.setState({
          uploadProgress: false
        });
        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          restarantImagePath: response.data,
          displayImage: "",
          oldImagePath: this.state.restarantImagePath
        });
      }
    );
  }
  async previewLogoImage(e) {
    let image = e.nativeEvent.target.files[0];
    if (image.type.split("/")[0] != "image") {
      e.target.value = null;

      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayLogoImage: URL.createObjectURL(image)
      },
      async err => {
        //upload image
        let data = new FormData();
        data.append("file", image);
        data.append("filename", "myfile");
        let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
        this.setState({
          uploadProgress: true
        });
        let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));
        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));
        this.setState({
          uploadProgress: false
        });
        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          restarantLogoImagePath: response.data,
          displayLogoImage: "",
        });
      }
    );
  }
  _getFormValues(formName, values) {
    // this.setState({
    //   // [formName]: values
    // });
  }

  setTimings(obj) {
    this.setState({
      rTimings: obj
    });
  }
  isFormEdited(formValues) {
    // let formValues = this._formApi.getState().values;
    if (!Object.keys(formValues).length) return false;
    let values = [];
    for (let fieldName in formValues) {
      // debugger;
      if (!!formValues[fieldName]) values.push(formValues[fieldName]);
    }
    return !!values.length;
  }

  _submitRestarantDetails(edit, cities) {
    if (this.state.saveProgress || this.state.uploadProgress) {
      return false;
    }

    if (!this.state.restarantImagePath) {
      return toast.error(`Please upload a restaurant image`, {
        autoClose: 4000
      });
    }

    this.rFormRef.current._formApi.submitForm();
    this.rOwnerFormRef.current._formApi.submitForm();
    let headDetailsEntered = this.isFormEdited(
      this.rHeadFormRef.current._formApi.getState().values
    );
    headDetailsEntered && this.rHeadFormRef.current._formApi.submitForm();
    this.rUserAppFormRef.current._formApi.submitForm();
    let urbanPiperDetailsEntered = this.isFormEdited(
      this.rUrbanFormRef.current._formApi.getState().values
    )
    urbanPiperDetailsEntered && this.rUrbanFormRef.current._formApi.submitForm();
    this.setState(
      {
        formInValid:
          Object.keys(this.rFormRef.current._formApi.getState().errors)
            .length ||
          Object.keys(this.rOwnerFormRef.current._formApi.getState().errors)
            .length ||
          (headDetailsEntered &&
            Object.keys(this.rHeadFormRef.current._formApi.getState().errors)
              .length) ||
          (urbanPiperDetailsEntered &&
            Object.keys(this.rUrbanFormRef.current._formApi.getState().errors).length) ||
          Object.keys(this.rUserAppFormRef.current._formApi.getState().errors)
            .length, //headForm is optional,but should validate if filled.
        // rForm: this.rFormRef.current._formApi.getState().values,
        // rOForm: this.rHeadFormRef.current._formApi.getState().values,
        // rHForm: this.rOwnerFormRef.current._formApi.getState().values,
        formValues: {
          ...this.rFormRef.current._formApi.getState().values,
          ...this.rHeadFormRef.current._formApi.getState().values,
          ...this.rOwnerFormRef.current._formApi.getState().values,
          ... this.rUrbanFormRef.current._formApi.getState().values,
          ...this.rUserAppFormRef.current._formApi.getState().values
        }
      },
      async () => {
        if (!this.state.rLocation) {
          return this.setState({
            rLocationErr: "Please select location"
          });
        }
        if (this.state.formInValid) {
          return false;
        }
        if (!this.state.rAddress) {
          this.setState({ rAddressErr: "Please Enter restaurant Address" });
          return toast.error("Please enter restaurant address");
        }
        if (this.state.formValues.rFSSAI && !new RegExp(/^[0-9]+$/).test(this.state.formValues.rFSSAI)) {
          return toast.error("Please enter valid FSSAI Number");
        }
        /*  if (!this.state.rTimings) {
           return toast.error("Please Enter restaurant Timings.");
         }
         if (!this.state.rTimings.openTime) {
           return toast.error("Please Enter restaurant opening time.");
         }
 
         if (!this.state.rTimings.closeTime) {
           return toast.error("Please Enter restaurant closing time.");
         }
         if (
           this.state.rTimings.openTime.isSame(this.state.rTimings.closeTime)
         ) {
           return toast.error("Open Time and close Time cannot be same.");
         }
 
         if (
           this.state.rTimings.openTime.isAfter(this.state.rTimings.closeTime)
         ) {
           return toast.error("Closing Time cannot be before Opening time.");
         }
  */
        const { CONSTANTS, authToken, user } = this.context;
        this.setState({
          saveProgress: true
        });
        let selectedexcludedPlats = this.state.excludedPlatforms;
        let exPlatforms = []
        for (let i = 0; i < selectedexcludedPlats.length; i++) {
          exPlatforms[i] = selectedexcludedPlats[i].label;
        }
        let selectedIncludedPlats = this.state.includedPlatforms;
        let inPlatforms = []
        for (let i = 0; i < selectedIncludedPlats.length; i++) {
          inPlatforms[i] = selectedIncludedPlats[i].label;
        }
        if (this.state.switchChecked) {
          await this._onSyncRestaurant(cities, inPlatforms, exPlatforms)
        }
        if (this.state.synchError) {
          return false;
        }
        let body = {
          user_id: user.userId,
          restaurant_id: this.state.restarantId,
          restaurant_admin_id: this.state.restarantDetails
            ? this.state.restarantDetails.rHId
            : null,
          restaurant_owner_id: this.state.restarantDetails
            ? this.state.restarantDetails.rOId
            : null,
          restaurant_image_url: this.state.restarantImagePath,
          restaurant_name: this.state.formValues.rName,
          restaurant_short_name: this.state.formValues.rShortName,
          restaurant_location: this.state.rLocation || this.state.rAddress,
          restaurant_near_location: this.state.formValues.rNearLocation,
          restaurant_address: this.state.rAddress,
          restaurant_city_id: this.state.formValues.rCity,
          restaurant_phone: this.state.formValues.rPhone,
          restaurant_admin_name: this.state.formValues.rHName,
          restaurant_status: this.state.formValues.rStatus,
          restaurant_gst: this.state.formValues.rGst,
          restaurant_near_location: this.state.formValues.rNearLocation,
          restaurant_gstdescription: this.state.formValues.rGstdescription,
          restaurant_fssai: this.state.formValues.rFSSAI,
          restaurant_currency: this.state.formValues.rCurrency,
          restaurant_kitchen_status: this.state.formValues.rKitchen,
          restaurant_cap: this.state.formValues.rCAP,
          restaurant_map: this.state.formValues.rMAP,
          restaurant_map_type: this.state.formValues.rMAPType,
          restaurant_map_phone: this.state.formValues.rMapPhone,
          // restaurant_map_password: this.state.formValues.rMapPassword,
          restaurant_min_pickup_time: this.state.formValues.rUMinPickupTime,
          restaurant_min_delivery_time: this.state.formValues.rUMinDeliveryTime,
          restaurant_min_order_value: this.state.formValues.rUMinOrderValue,
          restaurant_hide_from_ui: this.state.formValues.rUHidefromUI,
          restaurant_ordering_enabled: this.state.formValues.rUOrderingEnabled,
          restaurant_included_platforms: inPlatforms,
          restaurant_excluded_platforms: exPlatforms,
          restaurant_delivery_zipcodes: this.state.formValues.rUZipCodes ? this.state.formValues.rUZipCodes.split(",") : "",
          restaurant_urban_api_key: this.state.formValues.rUApiKey,
          restaurant_urban_satellite_username: this.state.formValues.rUSatelliteUsername,
          restaurant_admin_phone: this.state.formValues.rHPhone,
          restaurant_admin_email: this.state.formValues.rHEmail || "",
          restaurant_admin_address: this.state.formValues.rHAddress,
          restaurant_owner_name: this.state.formValues.rOName,
          restaurant_owner_phone: this.state.formValues.rOPhone,
          restaurant_owner_email: this.state.formValues.rOEmail || "",
          restaurant_owner_address: this.state.formValues.rOAddress,
          restaurant_timings: this.state.rTimings,
          restaurant_urbanpiper: this.state.switchChecked,
          restaurant_fbLink: this.state.formValues.rFBLink,
          restaurant_instaLink: this.state.formValues.rInstaLink,
          restaurant_twitterLink: this.state.formValues.rTwiterLink,
          restaurant_youtubeLink: this.state.formValues.rLink,
          restaurant_tagLine: this.state.formValues.rTagLine,
          restaurant_about: this.state.rAbout,
          restaurant_userapp_template: this.state.formValues.rUserappTemplete,
          restaurant_offers: this.state.rOffers,
          restaurant_speciality: this.state.formValues.rSpeciality,
          restaurant_pureVeg: this.state.formValues.rPureVeg,
          restaurant_features: this.state.rFeatures,
          restaurant_feedback: this.state.formValues.rFeedback,
          restaurant_recp_reports: this.state.formValues.rRecReports,
          restaurant_scheduled_order: this.state.formValues.rScheduledOrder,
          restaurant_print_before_invoice: this.state.formValues.rPrintBeforeInvoice,
          restaurant_user_pickup_time: this.state.userPickUpTime,
          restaurant_user_delivery_time: this.state.userDeliveryTime,
          restaurant_deliveryPartners: this.state.rDeliveryPartners,
          restaurant_email: this.state.formValues.rEmail,
          restaurant_ordering_contactNos: this.state.formValues.rOrderingContactNos ? this.state.formValues.rOrderingContactNos.split(",") : "",
          restaurant_logo_image_url: this.state.restarantLogoImagePath,
          restaurant_platforms_data: this.state.platformsData,
          restaurant_delete_reports: this.state.formValues.rDeleteReports,
          restaurant_onboarding_date: this.state.formValues.rOnboardingDate,
          restaurant_onboarding_validity: this.state.formValues.rOnboardingValidity,
          restaurant_menu_delete: this.state.formValues.rMenuDelete
          /*  (this.state.rTimings.openTime
             ? this.state.rTimings.openTime.format("HH:mm")
             : "") +
           "-" +
           (this.state.rTimings.closeTime
             ? this.state.rTimings.closeTime.format("HH:mm")
             : ""),
         restaurant_old_img_url: this.state.oldImagePath */
        };
        let apiRoute = CONSTANTS.apiRoutes.createRestaurant;
        if (edit) {
          body.restarant_id = this.props.location.state.restarantId;
          apiRoute = CONSTANTS.apiRoutes.adminEditRestaurant;
        }
        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
          method: edit ? "PUT" : "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: authToken
          },
          body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
          this.setState({
            saveProgress: false
          });
          return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
          .json()
          .catch(err => console.error(err));
        if (!savedResponseResult) {
          return this.setState({
            saveProgress: false,
            msg: "An Error Occured while saving the changes"
          });
        }
        if (!savedResponseResult.success) {
          this.setState({
            saveProgress: false
          });
          const duplicate = savedResponseResult.duplicate;

          switch (duplicate) {
            case "restaurant_admin_phone":
              this.rHeadFormRef.current._formApi.setError(
                "rHPhone",
                savedResponseResult.msg
              );
              break;
            case "restaurant_admin_email":
              this.rHeadFormRef.current._formApi.setError(
                "rHEmail",
                savedResponseResult.msg
              );
              break;
            case "restaurant_owner_phone":
              this.rOwnerFormRef.current._formApi.setError(
                "rOPhone",
                savedResponseResult.msg
              );
              break;
            case "restaurant_owner_email":
              this.rOwnerFormRef.current._formApi.setError(
                "rOEmail",
                savedResponseResult.msg
              );
              break;
            default:
          }
          !duplicate && toast.error(savedResponseResult.msg);
          return false;
        }


        toast.success(
          `Successfully ${edit ? "edited" : "added"} the restaurant.`,
          { autoClose: 3000 }
        );
        !edit &&
          setTimeout(() => {
            toast.success(
              `Successfully sent email to ${this.state.formValues.rHName ? "admins" : "admin"
              }.`,
              { autoClose: 3000 }
            );
          }, 1500);
        edit &&
          savedResponseResult.addedAdmin &&
          setTimeout(() => {
            toast.success(`Successfully sent email to admin.`, {
              autoClose: 3000
            });
          }, 1500);

        this.props.history.goBack();
      }
    );
  }

  buildClassName(err, enableInput) {
    let c = "w-100 restarantInput";
    !enableInput && (c += " restarantInput--disabled");
    err && (c += " input_error");
    return c;
  }


  async _loadRestarantDetails(restarantId) {
    if (this.state.fetchProgress) return false;
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    let fetchResponse = await fetch(
      this.context.CONSTANTS.apiBasePath +
      this.context.CONSTANTS.apiRoutes.getRestarantDetails +
      `?restarant_id=${restarantId}&user_id=${this.context.user.userId}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!fetchResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const restarantData = await fetchResponse.json(); //@TODO - handle error
    if (!restarantData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("Could not get restaurant details.");
    }

    //fill form values
    let list = [];
    list = this.context.CONSTANTS.aggregatorPlatforms

    //to update included pplatforms
    let inPlatList = restarantData.data.rIncludedPlatforms ? restarantData.data.rIncludedPlatforms.rIncludedPlatforms : [];
    let newInPlatsList = [];
    if (inPlatList != null) {
      inPlatList.map(c => {
        newInPlatsList.push({
          label: list[c].platName,
          value: list[c].platId
        });
      }
      )
    }
    else {
      inPlatList = [];
    }

    //to set excluded platforms
    let exPlatList = restarantData.data.rExcludedPlatforms ? restarantData.data.rExcludedPlatforms.rExcludedPlatforms : [];
    let newExPlatsList = [];
    if (exPlatList != null) {
      exPlatList.map(c => {
        newExPlatsList.push({
          label: list[c].platName,
          value: list[c].platId
        });
      }
      )
    } else {
      exPlatList = [];
    }
    //to set zipcodes
    let zipcodeList = restarantData.data.rDeliverableZipCodes ? restarantData.data.rDeliverableZipCodes.rDeliverableZipCodes : [];
    //let zipCodesStr = "";
    let zipCodesStr = zipcodeList ? zipcodeList.join() : "";

    function testTimingsData(timingsData) {
      try {
        JSON.parse(timingsData);
        return true;
      } catch (e) {
        return false;
      }

    }

    let orderingContacts = restarantData.data.rOrderingContactNos ? JSON.parse(restarantData.data.rOrderingContactNos) : "";
    orderingContacts = orderingContacts.rOrderingContactNos ? orderingContacts.rOrderingContactNos.join() : ""

    this.setState(
      {
        restarantDetails: restarantData.data,
        restarantImagePath: restarantData.data.restarantImages[0],
        restarantLogoImagePath: restarantData.data.rLogoImage[0],
        includedPlatforms: newInPlatsList,
        excludedPlatforms: newExPlatsList,
        zipCodes: zipCodesStr,
        platformsData: restarantData.data.rPlatformsData
        // displayImage:this.context.CONSTANTS.apiBasePath + restarantData.data.restarantImage
      },
      err => {
        if (err) return console.error(err);
        let rData = this.state.restarantDetails;


        let isTimingsParsable = testTimingsData(rData.rTimings);
        let timingSlotsData = isTimingsParsable ? JSON.parse(rData.rTimings) : []
        //restaramt details
        this.rFormRef.current._formApi.setValue("rName", rData.rName);
        this.rFormRef.current._formApi.setValue("rShortName", rData.rShortName);
        // this.rFormRef.current._formApi.setValue("rLocation", rData.rAddress);
        let restOffers = restarantData.data.rOffers ? JSON.parse(restarantData.data.rOffers) : [];
        let restFeatures = restarantData.data.rFeatures ? JSON.parse(restarantData.data.rFeatures) : [];
        let userPickupTime = restarantData.data.rUserPickupTime ? JSON.parse(restarantData.data.rUserPickupTime) : [0, 0];
        let userDeliveryTime = restarantData.data.rUserDeliveryTime ? JSON.parse(restarantData.data.rUserDeliveryTime) : [0, 0];
        let partnersData = restarantData.data.rDeliveryPartners ? JSON.parse(restarantData.data.rDeliveryPartners) : [];
        this.setState({
          rAddress: rData.rAddress,
          rLocation: rData.rLocation,
          oldImagePath: null, //this.state.restarantImagePath
          rTimings: timingSlotsData ? timingSlotsData.restaurant_timings : [],
          switchChecked: rData.rUrbanpiper,
          rAbout: rData.rAbout,
          rOffers: restOffers.restaurant_offers ? restOffers.restaurant_offers : [],
          rFeatures: restFeatures.restaurant_features ? restFeatures.restaurant_features : [],
          userPickUpTime: userPickupTime,
          userDeliveryTime: userDeliveryTime,
          rDeliveryPartners: partnersData.restaurant_deliveryPartners ? partnersData.restaurant_deliveryPartners : []
        });

        this.rFormRef.current._formApi.setValue("rNearLocation", rData.rNearLocation);
        this.rFormRef.current._formApi.setValue("rPhone", rData.rPhone);
        this.rFormRef.current._formApi.setValue("rCity", rData.rCity);
        this.rFormRef.current._formApi.setValue("rGst", rData.rGst);
        this.rFormRef.current._formApi.setValue("rGstdescription", rData.rGstdescription);
        this.rFormRef.current._formApi.setValue("rFSSAI", rData.rFSSAI);
        this.rFormRef.current._formApi.setValue("rCurrency", rData.rCurrency);
        this.rFormRef.current._formApi.setValue("rKitchen", rData.rKitchen)
        this.rFormRef.current._formApi.setValue("rCAP", rData.rCAP);
        this.rFormRef.current._formApi.setValue("rMAP", rData.rMAP);
        this.rFormRef.current._formApi.setValue("rMAPType", rData.rMAPType);
        this.rFormRef.current._formApi.setValue("rMapPhone", rData.rMapPhone);
        this.rFormRef.current._formApi.setValue("rFeedback", rData.rFeedback);
        this.rFormRef.current._formApi.setValue("rRecReports", rData.rRecReports);
        this.rFormRef.current._formApi.setValue("rScheduledOrder", rData.rScheduledOrder);
        this.rFormRef.current._formApi.setValue("rPrintBeforeInvoice", rData.rPrintBeforeInvoice);
        this.rUrbanFormRef.current._formApi.setValue("rUMinPickupTime", rData.rMinPickupTime);
        this.rUrbanFormRef.current._formApi.setValue("rUMinDeliveryTime", rData.rMinDeliveryTime);
        this.rUrbanFormRef.current._formApi.setValue("rUMinOrderValue", rData.rMinOrderValue);
        this.rUrbanFormRef.current._formApi.setValue("rUHidefromUI", rData.rHidefromUI);
        this.rUrbanFormRef.current._formApi.setValue("rUOrderingEnabled", rData.rOrderingEnabled);
        this.rUrbanFormRef.current._formApi.setValue("rUZipCodes", zipCodesStr);
        this.rUrbanFormRef.current._formApi.setValue("rUApiKey", rData.rUrbanApiKey);
        this.rUrbanFormRef.current._formApi.setValue("rUSatelliteUsername", rData.rUrbanSatelliteUsername);
        this.rUserAppFormRef.current._formApi.setValue("rSpeciality", rData.rSpeciality);
        this.rUserAppFormRef.current._formApi.setValue("rPureVeg", rData.rPureVeg);
        this.rFormRef.current._formApi.setValue("rDeleteReports", rData.rDeleteReports);
        this.rFormRef.current._formApi.setValue("rOnboardingDate", rData.rOnboardingDate);
        this.rFormRef.current._formApi.setValue("rOnboardingValidity", rData.rOnboardingValidity);
        this.rFormRef.current._formApi.setValue("rMenuDelete", rData.rMenuDelete);
        /* let openHrs = rData.rTimings.split("-")[0] || undefined;
        let closeHrs = rData.rTimings.split("-")[1] || undefined;
        this.setState({
          rTimings: {
            openTime: openHrs ? moment(openHrs, "HH:mm") : null,
            closeTime: closeHrs ? moment(closeHrs, "HH:mm") : null
          }
        });
        this.rFormRef.current.setState({
          formValues: {
            ...this.rFormRef.current.formValues,
            rTimings: {
              openTime: openHrs ? moment(openHrs, "HH:mm") : null,
              closeTime: closeHrs ? moment(closeHrs, "HH:mm") : null
            }
          }
        }); */
        this.rFormRef.current._formApi.setValue("rStatus", rData.rStatus);

        //owner details
        this.rOwnerFormRef.current._formApi.setValue("rOName", rData.rOName);
        this.rOwnerFormRef.current._formApi.setValue("rOEmail", rData.rOEmail);
        this.rOwnerFormRef.current._formApi.setValue("rOPhone", rData.rOPhone);
        this.rOwnerFormRef.current._formApi.setValue(
          "rOAddress",
          rData.rOAddress
        );

        //head details
        rData.rHName &&
          this.rHeadFormRef.current._formApi.setValue("rHName", rData.rHName);
        rData.rHEmail &&
          this.rHeadFormRef.current._formApi.setValue("rHEmail", rData.rHEmail);
        rData.rHPhone &&
          this.rHeadFormRef.current._formApi.setValue("rHPhone", rData.rHPhone);
        this.rHeadFormRef.current._formApi.setValue(
          "rHAddress",
          rData.rHAddress
        );
        //User App Form Details
        this.rUserAppFormRef.current._formApi.setValue("rLink", rData.rYoutubeLink);
        this.rUserAppFormRef.current._formApi.setValue("rFBLink", rData.rFblink)
        this.rUserAppFormRef.current._formApi.setValue("rTwiterLink", rData.rTwitterLink)
        this.rUserAppFormRef.current._formApi.setValue("rInstaLink", rData.rInstaLink)
        this.rUserAppFormRef.current._formApi.setValue("rTagLine", rData.rTagline)
        this.rUserAppFormRef.current._formApi.setValue("rUserappTemplete", rData.rUserappTemplete)
        this.rUserAppFormRef.current._formApi.setValue("rEmail", rData.rEmail);
        this.rUserAppFormRef.current._formApi.setValue("rOrderingContactNos", orderingContacts);

      }
    );
  }

  async _onSyncRestaurant(cities, inPlats, exPlats) {
    const { CONSTANTS } = this.context;
    // if (!r.name || !r.cityId || !r.id) {
    //   return toast.error(`please fill the required fields`, {
    //     autoClose: 4000
    //   });
    // }
    const city = cities.find(
      c => c.cityId == this.state.formValues.rCity
    );
    const cityName = city.cityName;
    let excludedPlatforms = exPlats;
    let includedPlatforms = inPlats;
    let zipCodes = this.state.formValues.rUZipCodes ? this.state.formValues.rUZipCodes.split(",") : [];
    const timings = this.state.rTimings;
    const location = this.state.rLocation.split(",");
    const latitude = location[0];
    const longitude = location[1];
    this.setState({
      saveProgress: true
    });
    let storeInfo = {
      city: cityName,
      name: this.state.formValues.rName,
      min_pickup_time: this.state.formValues.rUMinPickupTime,
      min_delivery_time: this.state.formValues.rUMinDeliveryTime,
      contact_phone: this.state.formValues.rPhone,
      notification_phones: [
        this.state.formValues.rHPhone || '',
        this.state.formValues.rOPhone || ''
      ],
      ref_id: String(this.state.restarantId),
      min_order_value: this.state.formValues.rUMinOrderValue,
      hide_from_ui: this.state.formValues.rUHidefromUI,
      address: this.state.rAddress,
      notification_emails: [
        this.state.formValues.rHEmail || '',
        this.state.formValues.rOEmail || ''
      ],
      zip_codes: zipCodes,
      geo_longitude: longitude,
      active: this.state.switchChecked,
      geo_latitude: latitude,
      ordering_enabled: this.state.formValues.rUOrderingEnabled,
      translations: [],
      included_platforms: includedPlatforms,
      timings: timings,
    };
    if (excludedPlatforms.length) {
      storeInfo.excluded_platforms = excludedPlatforms;
    }
    if (this.state.platformsData.length) {
      storeInfo.platform_data = this.state.platformsData
    }
    let body = {
      stores: [
        storeInfo
      ]
    }

    const savedResponse = await fetchRetry(CONSTANTS.upApiBasePath + "/external/api/v1/stores/", {
      async: true,
      crossDomain: true,
      method: "POST",
      headers: {
        Authorization: this.state.formValues.rUApiKey,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body),
      retries: 3,
      retryDelay: 1000,
      retryOn: [500, 503]
    }
    )
    if (!savedResponse) {
      let msg = "An error Occurred"
      this.setState({
        saveProgress: false
      });
      return toast.error(msg)
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (savedResponseResult) {

      if (savedResponseResult.status == "error") {
        this.setState({
          saveProgress: false,
          synchError: true
        });
        return toast.error(savedResponseResult.message)
      }
      if (savedResponseResult.status == "success") {
        this.setState({
          saveProgress: false,
          synchError: false
        })
        this.saveWebhookResponse(this.state.restarantId, savedResponseResult.reference)
        setTimeout(() => {
          toast.success(
            "Successfully synched"
          );
        }, 1500);
        this._setupWebhooks()
        return toast.success(savedResponseResult.message);
      }
    }
    else {
      this.setState({
        saveProgress: false,
        synchError: true
      })
      return toast.error("An Error Occurred While synching")

    }
  }

  async saveWebhookResponse(restId, referenceId) {
    const { CONSTANTS, authToken } = this.context
    let body = {
      restaurant_id: restId,
      reference_id: referenceId,
    }

    let apiRoute = CONSTANTS.apiRoutes.UrbanStoreAddUpdateResponse;

    const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));

    if (!savedResponse) {
      this.setState({
        saveProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      this.setState({
        saveProgress: false,

      });
      return toast.error("An error occurred while storing the response.")
    }
    if (!savedResponseResult.success) {
      toast.error(savedResponseResult.msg, { autoClose: 8000 });
      return this.setState({
        saveProgress: false
      });
    }
    this.setState({
      saveProgress: false
    });
  }

  _webhookCallback(responses) {
    const sucRes = responses.filter((res) => {
      return res.status === 200;
    });
    if (sucRes.length > 3) {
      toast.success("Successfully created webhooks.");
    } else {
      // toast.error("An Error Occured while creating webhooks.");
    }
  }

  _setupWebhooks() {
    let { apiRoutes } = this.context.CONSTANTS
    Promise.all(
      [
        this._callWebhook("store_creation", apiRoutes.urbanStoreAddUpdate),
        this._callWebhook("store_action", apiRoutes.urbanStoreActions),
        this._callWebhook("order_placed", apiRoutes.urbanOrderRelay),
        this._callWebhook("order_status_update", apiRoutes.urbanOrderStatusChange),
      ]
    ).then(this._webhookCallback);

    setTimeout(() => {
      Promise.all(
        [
          this._callWebhook("rider_status_update", apiRoutes.urbanRiderStatusChange),
          this._callWebhook("inventory_update", apiRoutes.urbanCatalogueIngestion),
          this._callWebhook("item_state_toggle", apiRoutes.urbanItemActions),
          this._callWebhook("option_state_toggle", apiRoutes.urbanOptionActions),
          this._callWebhook("catalogue_timing_grp", apiRoutes.urbanCategoryTimingGroups),
        ]
      ).then(this._webhookCallback);

    }, 60000); //Limitation at urban only 5 can process at once in 1 min.


  }
  _callWebhook(eventName, urlPath) {
    const urlPathList = urlPath.split(':');
    urlPath = urlPathList[0] + this.state.restarantId;
    let body = {
      active: true,
      event_type: eventName,
      retrial_interval_units: "seconds",
      url: this.context.CONSTANTS.apiBasePath + urlPath
    }
    return fetch(this.context.CONSTANTS.upApiBasePath + "/external/api/v1/webhooks/", {
      async: true,
      crossDomain: true,
      method: "POST",
      headers: {
        Authorization: this.state.formValues.rUApiKey,
        "Content-Type": "application/json"
      },
      processData: false,
      body: JSON.stringify(body)
    }).catch(err => console.error(err));
  }
}

class RestarantDetailsForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues,
    rTimings: {},
    mapsApiReady: window.google && window.google.maps,
  };
  componentDidMount() {
    this.setState({
      formValues: this.props.initialValues,

    });
  }
  handleSwitchChange(checked) {
    this.props.setSwitch(checked)
  }

  //timings and day slots testing code

  handleInputChange(e, index, str) {
    const list = [...this.props.inputList];

    if (str == "day") {
      let findDay = list.filter(i => i.day == e.target.value)
      if (!findDay.length) list[index].day = e.target.value;
    }
    else if (str == "sTime") {
      const stime = e.format("HH:mm:ss");
      list[index].slots[0].start_time = stime;
    }
    else if (str == "eTime") {
      const etime = e.format("HH:mm:ss");
      list[index].slots[0].end_time = etime;
    }
    this.props.setTimingSlots(list);
  }
  handleRemoveClick(e, i) {
    const list = [...this.props.inputList];
    list.splice(i, 1);
    this.props.setTimingSlots(list);
  }

  handleAddClick() {
    const list = [...this.props.inputList, { day: "", slots: [{ start_time: "", end_time: "" }] }];
    this.props.setTimingSlots(list);
  }
  render() {
    return (
      <div id="restarantDetails">
        <div className="row text-capitalize py-3 mt-2">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Restaurant details</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
          <div
            className={
              this.state.editMode
                ? "col-12 col-sm-12 col-md-8 col-lg-8 text-right"
                : "d-none"
            }
          >
            <Link
              to={"tables-list/" + this.props.restarantId}
              className="view_tables"
            >
              view tables
            </Link>
          </div>
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {
            return (
              <>
                <div className="row text-capitalize fsize border_bottom">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rName}
                            validate={val => {
                              // validateStrictText(
                              //   val,
                              //   2,
                              //   formState.touched.rName,
                              //   "Restaurant Name",
                              //   30
                              // )
                              let minLength = 2;
                              let touched = formState.touched.rName;
                              let name = "Restaurant Name";
                              let maxLength = 45;
                              if (
                                typeof val == "undefined" ||
                                typeof minLength == "undefined" ||
                                typeof touched == "undefined" ||
                                typeof name == "undefined"
                              ) {
                                // console.warn("missing arguments ", val, touched, name);
                              }

                              if (!name) name = "value";
                              if (typeof minLength == "undefined") minLength = 4;
                              if (!touched) {
                                //this is the first focus, do not validate.
                                return false;
                              }
                              val = val && val.trim();
                              // if (!/^[ A-Za-z@$&_']*$/.test(val)) {
                              //   return `Enter a valid ${name}.`;
                              // }
                              if (!val) return `${name} cannot be empty`;
                              if (!val || val.length < minLength || val.length > maxLength) {
                                return `${name} must contain ${minLength} to ${maxLength} Characters.`;
                              }
                            }
                            }
                            id="rEdit__rName"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rName,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rName && (
                            <span className="input_error">
                              {formState.errors.rName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rShortName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant short name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rShortName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rShortName}
                            validate={val =>
                              validateStrictText(
                                val,
                                3,
                                formState.touched.rShortName,
                                "Restaurant Short Name",
                                15
                              )
                            }
                            id="rEdit__rShortName"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rShortName,
                              this.state.enableInput
                            )}
                            validateOnBlur
                            validateOnChange
                          />
                          {formState.errors.rShortName && (
                            <span className="input_error">
                              {formState.errors.rShortName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rPhone"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Landline number <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rPhone"
                            type="text"
                            initialValue={this.state.formValues.rPhone}
                            disabled={!this.state.enableInput}
                            validateonblur="true"
                            validateonchange="true"
                            validate={val =>
                              validatePhone(
                                val && val.trim(),
                                formState.touched.rPhone
                              )
                            }
                            id="rEdit__rPhone"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rPhone,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.rPhone && (
                            <span className="input_error">
                              {formState.errors.rPhone}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rCity"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          City <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <CityListContext.Consumer>
                          {({ records: cities }) => {
                            return (
                              <div className="">
                                <Select
                                  id="rEdit__rCity"
                                  field="rCity"
                                  type="text"
                                  validateonblur="true"
                                  validateonchange="true"
                                  validate={val => {
                                    if (!val) return "Please select city.";
                                  }}
                                  disabled={!this.state.enableInput}
                                  initialValue={this.state.formValues.rCity}
                                  className={
                                    this.state.enableInput
                                      ? "w-100 restarantInput"
                                      : "w-100 restarantInput restarantInput--disabled"
                                  }
                                  value={this.state.formValues.rStatus}
                                >
                                  <option value="">-select city-</option>
                                  {cities.map(city => (
                                    <option
                                      value={city.cityId}
                                      key={city.cityId}
                                    >
                                      {city.cityName}
                                    </option>
                                  ))}
                                </Select>
                                {formState.errors.rCity && (
                                  <span className="input_error">
                                    {formState.errors.rCity}
                                  </span>
                                )}
                              </div>
                            );
                          }}
                        </CityListContext.Consumer>
                      </div>
                    </div>
                  </div>
                  {/*  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rTimings"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Timings <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">

                        <div
                          className="restarantInput d-flex justify-content-between align-items-center"
                          style={{ padding: "6px" }}
                        >
                          <TimePicker
                            placeholder="HH:MM"
                            disabled={!this.state.enableInput}
                            value={
                              this.state.formValues &&
                                this.state.formValues.rTimings
                                ? this.state.formValues.rTimings.openTime
                                  ? this.state.formValues.rTimings.openTime
                                  : null //moment()
                                : null
                            }
                            hideDisabledOptions
                            clearIcon={this.state.enableInput ? null : <i />}
                            onChange={momentDateObj => {
                              this.setState(
                                {
                                  formValues: {
                                    ...this.state.formValues,
                                    rTimings: {
                                      ...this.state.formValues.rTimings,
                                      openTime: momentDateObj
                                    }
                                  }
                                },
                                err => {
                                  this.props.setTimings({
                                    openTime: momentDateObj,
                                    closeTime: this.state.formValues.rTimings
                                      .closeTime
                                  });
                                }
                              );
                            }}
                            showSecond={false}
                          />
                          <span style={{ width: "40px", textAlign: "center" }}>
                            {" "}
                            To{" "}
                          </span>
                          <TimePicker
                            placeholder="HH:MM"
                            disabled={!this.state.enableInput}
                            showSecond={false}
                            value={
                              this.state.formValues &&
                                this.state.formValues.rTimings
                                ? this.state.formValues.rTimings.closeTime
                                  ? this.state.formValues.rTimings.closeTime
                                  : null
                                : null
                              // moment()
                            }
                            clearIcon={this.state.enableInput ? null : <i />}
                            hideDisabledOptions={true}
                            onChange={momentDateObj => {
                              this.setState(
                                {
                                  formValues: {
                                    ...this.state.formValues,
                                    rTimings: {
                                      ...this.state.formValues.rTimings,
                                      closeTime: momentDateObj
                                    }
                                  }
                                },
                                err => {
                                  this.props.setTimings({
                                    openTime: this.state.formValues.rTimings
                                      .openTime,
                                    closeTime: momentDateObj
                                  });
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rLocation"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Address <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          {/* <Text
                            id="rEdit__rLocation"
                            field="rLocation"
                            type="text"
                            validateonblur="true"
                            validateonchange="true"
                            validate={val =>
                              validateText(
                                val,
                                10,
                                formState.touched.rLocation,
                                "Restaurant Location"
                              )
                            }
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rLocation}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rLocation,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.rLocation && (
                            <span className="input_error">
                              {formState.errors.rLocation}
                            </span>
                          )} */}

                          {this.state.mapsApiReady && (
                            <LocationSearchInput
                              setLocationAddress={this.props.setLocationAddress}
                              address={this.props.initialAddress}
                              disabled={!this.state.enableInput}
                              setLocationCoords={this.props.setLocationCoords}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* area name of reatauant */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rNearLocation"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Area name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rNearLocation"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rNearLocation}
                            validate={val =>
                              validateInput(
                                val,
                                3,
                                formState.touched.rNearLocation,
                                "Area Name",
                                30
                              )
                            }
                            id="rEdit__rNearLocation"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rNearLocation,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rNearLocation && (
                            <span className="input_error">
                              {formState.errors.rNearLocation}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rStatus"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Status <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rStatus"
                          disabled={!this.state.enableInput}
                          field="rStatus"
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rStatus}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="5">Active</option>
                          <option value="6">Inactive</option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGst"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          GST<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rGst"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rGst}
                            validate={val =>
                              validateStrictGst(
                                val,
                                2,
                                formState.touched.rGst,
                                "Restaurant Gst",
                                15
                              )
                            }
                            id="rEdit__rGst"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rGst,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rGst && (
                            <span className="input_error">
                              {formState.errors.rGst}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGstdescription"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          GST description <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rGstdescription"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rGstdescription}
                            validate={val =>
                              validateStrictGstdescription(
                                val,
                                5,
                                formState.touched.rGstdescription,
                                "Restaurant Gstdescription",
                                50
                              )
                            }
                            id="rEdit__rGstdescription"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rGstdescription,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rGstdescription && (
                            <span className="input_error">
                              {formState.errors.rGstdescription}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFSSAI"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          FSSAI<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rFSSAI"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rFSSAI}
                            validate={val => {
                              if (val && (val.length > 14 || val.length < 14))
                                return "Enter a valid Fssai Number"
                            }
                            }
                            maxLength={14}
                            id="rEdit__rFSSAI"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rFSSAI,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rFSSAI && (
                            <span className="input_error">
                              {formState.errors.rFSSAI}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rCurrency"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Currency <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rCurrency"
                          disabled={!this.state.enableInput}
                          field="rCurrency"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select restaurant currency.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rCurrency}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option >-select currency-</option>
                          <option >Rs</option>
                        </Select>
                        {formState.errors.rCurrency && (
                          <span className="input_error">
                            {formState.errors.rCurrency}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rKitchen"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Kitchen <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rKitchen"
                          disabled={!this.state.enableInput}
                          field="rKitchen"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Kitchen status.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rKitchen}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="">select Kitchen status</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Select>
                        {formState.errors.rKitchen && (
                          <span className="input_error">
                            {formState.errors.rKitchen}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGstdescription"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          CAP <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rCAP"
                          disabled={!this.state.enableInput}
                          field="rCAP"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Cancel After Payment.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rCAP}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="">select CAP</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Select>
                        {formState.errors.rCAP && (
                          <span className="input_error">
                            {formState.errors.rCAP}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGstdescription"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          MAP <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rMAP"
                          disabled={!this.state.enableInput}
                          field="rMAP"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Modify After Payment.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rMAP}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="">select MAP</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Select>
                        {formState.errors.rMAP && (
                          <span className="input_error">
                            {formState.errors.rMAP}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rGstdescription"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          MAP Type <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rMAPType"
                          disabled={!this.state.enableInput}
                          field="rMAPType"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Modify After Payment Type.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rMAPType}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="">select MAP Type</option>
                          <option value="password">Password</option>
                          <option value="otp">Otp</option>
                        </Select>
                        {formState.errors.rMAPType && (
                          <span className="input_error">
                            {formState.errors.rMAPType}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rMapPassword"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          MAP Password <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Text
                          field="rMapPassword"
                          type="password"
                          autoComplete="new-password"
                          placeholder="Enter password"
                          className={
                            formState.errors.rMapPassword
                              ? "form-control mr-sm-4 input_error"
                              : "form-control mr-sm-4"
                          }
                          //mandatory only in add mode
                          validate={val =>
                            val
                              ? validateInput(
                                val,
                                6,
                                formState.touched.rMapPassword,
                                "Password"
                              )
                              : null
                          }
                          validateOnBlur
                          validateOnChange
                        />
                        {formState.errors.rMapPassword && (
                          <span className="input_error">
                            {formState.errors.rMapPassword}
                          </span>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rMapPhone"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Map Phone <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rMapPhone"
                            type="text"
                            initialValue={this.state.formValues.rMapPhone}
                            disabled={!this.state.enableInput}
                            validateonblur="true"
                            validateonchange="true"
                            validate={val =>
                              validatePhone(
                                val && val.trim(),
                                formState.touched.rMapPhone
                              )
                            }
                            id="rEdit__rMapPhone"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rMapPhone,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.rMapPhone && (
                            <span className="input_error">
                              {formState.errors.rMapPhone}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFeedback"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Feedback <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rFeedback"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rFeedback}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rRecReports"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Recp Reports <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rRecReports"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rRecReports}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rscheduledOrder"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          scheduled order <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rScheduledOrder"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rScheduledOrder}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rPrintBeforeInvoice"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Print Before Invoice <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rPrintBeforeInvoice"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rPrintBeforeInvoice}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rDeleteReports"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Delete Reports <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rDeleteReports"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rDeleteReports}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rDeleteReports"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          onboarding Date <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                        <Text
                            field="rOnboardingDate"
                            type="date"
                            initialValue={this.state.formValues.rOnboardingDate}
                            disabled={!this.state.enableInput}
                            validateonblur="true"
                            validateonchange="true"
                            id="rEdit__rOnboardingDate"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rOnboardingDate,
                              this.state.enableInput
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rDeleteReports"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          onboarding Validity  <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                        <Select
                          id="rEdit__rOnboardingValidity"
                          disabled={!this.state.enableInput}
                          field="rOnboardingValidity"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            // if (!val) return "Please select Modify After Payment Type.";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rOnboardingValidity}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="">Select Validity</option>
                          <option value="1">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="6">6 Months</option>
                          <option value="12">1 Year</option>
                          <option value="18">1.6 Year</option>
                          <option value="24">2 Years</option>
                          <option value="36">3 Years</option>
                        </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rMenuDelete"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Delete Menu <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rMenuDelete"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rMenuDelete}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUrbanpiper"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Urbanpiper<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Switch
                          onColor="#1f74f4"
                          onChange={this.handleSwitchChange.bind(this)}
                          checked={this.props.SwitchChecked}
                          uncheckedIcon=""
                          checkedIcon=""
                          disabled={!this.state.enableInput}
                          id="rEdit__rUrbanpiper"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rTimings"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Timings <span className="">:</span>
                        </label>
                      </div>
                      <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddClick()}>Add</button></div>
                      {
                        this.props.inputList && this.props.inputList.map((x, i) => {

                          return (

                            <div className="col-6 col-md-6 col-lg-6 form-group">
                              <div
                                className="restarantInput d-flex justify-content-between align-items-center"
                                style={{ padding: "6px" }}
                              >
                                <select
                                  style={{ marginInlineStart: "8px" }}
                                  disabled={!this.state.enableInput}
                                  validateonblur="true"
                                  validateonchange="true"
                                  className={
                                    this.state.enableInput
                                      ? "w-100 restarantInput"
                                      : "w-100 restarantInput restarantInput--disabled"
                                  }
                                  value={x.day}
                                  onChange={obj => this.handleInputChange(obj, i, "day")}
                                >
                                  {/* @todo - dynamic values */}
                                  <option value="">select day</option>
                                  <option value="monday">monday</option>
                                  <option value="tuesday">tuesday</option>
                                  <option value="wednesday">wednesday</option>
                                  <option value="thursday">thursday</option>
                                  <option value="friday">friday</option>
                                  <option value="saturday">saturday</option>
                                  <option value="sunday">sunday</option>
                                </select>
                                <TimePicker
                                  placeholder="HH:MM:ss"
                                  disabled={!this.state.enableInput}
                                  value={x.slots[0].start_time ? moment(x.slots[0].start_time, "HH:mm:ss") : null}
                                  hideDisabledOptions
                                  clearIcon={this.state.enableInput ? null : <i />}
                                  onChange={momentDateObj => {
                                    this.handleInputChange(momentDateObj, i, "sTime")
                                  }}
                                  showSecond={true}
                                />
                                <span style={{ width: "40px", textAlign: "center" }}>
                                  {" "}
                                  To{" "}
                                </span>
                                <TimePicker
                                  placeholder="HH:MM:ss"
                                  disabled={!this.state.enableInput}
                                  showSecond={true}
                                  value={x.slots[0].end_time ? moment(x.slots[0].end_time, "HH:mm:ss") : null}
                                  clearIcon={this.state.enableInput ? null : <i />}
                                  hideDisabledOptions={true}
                                  onChange={momentDateObj => {
                                    this.handleInputChange(momentDateObj, i, "eTime")
                                  }}

                                />

                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handleRemoveClick(obj, i)}>Remove</button></div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    );
  }

  _setFormApi(formApi) {
    this._formApi = formApi;
  }

  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("clear errors first");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}
class UrbanPiperDetailsForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues
  };
  onSelect_includedPlats = (selectedList, selectedItem) => {

    let selectedValues_included = [];
    let i = 0;
    for (i = 0; i < selectedList.length; i++) {
      selectedValues_included.push({ label: selectedList[i].label, value: selectedList[i].value })
    }
    this.props.setIncludedPlats(selectedValues_included)
  }

  onSelect_excludedPlats = (selectedList, selectedItem) => {
    let selectedValues_excluded = [];
    let i = 0;
    for (i = 0; i < selectedList.length; i++) {
      selectedValues_excluded.push({ label: selectedList[i].label, value: selectedList[i].value })
    }
    this.props.setExcludedPlats(selectedValues_excluded)
  }

  onRemove_excludedPlats = (selectedList) => {
    this.props.setExcludedPlats(selectedList)
  }

  onRemove_includedPlats = (selectedList) => {
    this.props.setIncludedPlats(selectedList)
  }
  handleSwitchChange(checked) {
    this.props.setSwitch(checked)
  }
  render() {
    return (
      <div id="restarantDetails">
        <div className="row text-capitalize py-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding"> UrbanPiper details</span>{" "}
            {this.props.SwitchChecked ?
              this.state.editMode && (
                <button
                  className="float-right rEdit__editToggle"
                  onClick={this._toggleEditMode.bind(this)}
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                </button>
              ) : null}
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4" />
          <div className="col-12 col-sm-12 col-md-4 col-lg-4" >
            <div className="row align-items-center">
              <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                <label
                  htmlFor="rEdit__rUrbanpiper"
                  className="row mx-0 w-100 justify-content-between"
                >
                  Urbanpiper<span className="">:</span>
                </label>
              </div>

              <Switch
                onColor="#1f74f4"
                onChange={this.handleSwitchChange.bind(this)}
                checked={this.props.SwitchChecked}
                uncheckedIcon={null}
                checkedIcon={null}
                id="rEdit__rUrbanpiper"
              />
            </div>
          </div>
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rUForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {
            return (
              <>
                <div className="row text-capitalize fsize">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUMinPickupTime"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Min Pickup Time(sec) <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Text
                          field="rUMinPickupTime"
                          type="text"
                          placeholder="enter a min pickup time(in seconds)"
                          disabled={!this.state.enableInput}
                          initialValue={this.state.formValues.rUMinPickupTime}
                          validate={val => {
                            if (!new RegExp(/^[0-9]+$/).test(val))
                              return "Enter a valid pickup time"
                          }}
                          id="rEdit__rUMinPickupTime"
                          className={this.props.buildClassName.bind(this)(
                            formState.errors.rUMinPickupTime,
                            this.state.enableInput
                          )}
                          validateonblur="true"
                          validateonchange="true"
                        />
                        {formState.errors.rUMinPickupTime && (
                          <span className="input_error">
                            {formState.errors.rUMinPickupTime}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUMinDeliveryTime"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Min Delivery Time(sec) <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rUMinDeliveryTime"
                            type="text"
                            placeholder="enter a min delivery time(in seconds)"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rUMinDeliveryTime}
                            validate={val => {
                              if (!new RegExp(/^[0-9]+$/).test(val))
                                return "Enter a valid delivery time"
                            }}
                            id="rEdit__rUMinDeliveryTime"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rUMinDeliveryTime,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rUMinDeliveryTime && (
                            <span className="input_error">
                              {formState.errors.rUMinDeliveryTime}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUMinOrderValue"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Min Order Value <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rUMinOrderValue"
                            type="text"
                            disabled={!this.state.enableInput}
                            placeholder="enter a min order Value"
                            initialValue={this.state.formValues.rUMinOrderValue}
                            validate={val => {
                              if (!new RegExp(/^[0-9]+$/).test(val))
                                return "Enter a valid order value"
                            }}
                            id="rEdit__rUMinOrderValue"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rUMinOrderValue,
                              this.state.rUMinOrderValue
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rUMinOrderValue && (
                            <span className="input_error">
                              {formState.errors.rUMinOrderValue}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUHidefromUI"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Hide Rest Name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rUHidefromUI"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rUHidefromUI}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUOrderingEnabled"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Ordering Enabled<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rUOrderingEnabled"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rUOrderingEnabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUIncludedPlatforms"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Included Platforms<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Multiselect
                            field="rUIncludedPlatforms"
                            disable={!this.state.enableInput || this.props.excludedPlatInitVal.length}
                            options={this.props.aggregatorPlatforms}
                            displayValue="label"
                            onSelect={this.onSelect_includedPlats}
                            onRemove={this.onRemove_includedPlats}
                            selectedValues={this.props.includedPlatInitVal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUIncludedPlatforms"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Excluded Platforms<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Multiselect
                            field="rUExcludedPlatforms"
                            disable={!this.state.enableInput || this.props.includedPlatInitVal.length}
                            options={this.props.aggregatorPlatforms}
                            displayValue="label"
                            onSelect={this.onSelect_excludedPlats}
                            onRemove={this.onRemove_excludedPlats}
                            selectedValues={this.props.excludedPlatInitVal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUZipCodes"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Deliverable Zip Codes<span className="" >:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <TextArea
                            field="rUZipCodes"
                            disabled={!this.state.enableInput}
                            placeholder="Enter deliverable pin codes.For ex:530012,500038,123456"
                            initialValue={this.state.formValues.rUZipCodes}
                            validate={val => {
                              if (!new RegExp(/^[0-9,]+$/).test(val))
                                return "Enter valid zip codes seperated by comas(,)"
                            }}
                            id="rEdit__rUZipCodes"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rUZipCodes,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rUZipCodes && (
                            <span className="input_error">
                              {formState.errors.rUZipCodes}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUApiKey"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Api Key <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rUApiKey"
                            type="text"
                            disabled={!this.state.enableInput}
                            placeholder="enter a Api Key"
                            initialValue={this.state.formValues.rUApiKey}
                            id="rUApiKey"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rUApiKey,
                              this.state.rUApiKey
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rUApiKey && (
                            <span className="input_error">
                              {formState.errors.rUApiKey}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUSatelliteUsername"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Satellite User Name <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rUSatelliteUsername"
                            type="text"
                            disabled={!this.state.enableInput}
                            placeholder="enter a Satellite User Name"
                            initialValue={this.state.formValues.rUSatelliteUsername}
                            id="rUSatelliteUsername"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rUSatelliteUsername,
                              this.state.rUSatelliteUsername
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rUSatelliteUsername && (
                            <span className="input_error">
                              {formState.errors.rUSatelliteUsername}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row ">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="iEdit__iPlatformData"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Platforms Data <span className="">:</span>
                        </label>
                      </div>
                      <div><button className="btnaddimg" disabled={!this.state.enableInput} onClick={this._handlePlatformsAddClick} >Add</button> </div>
                      {this.props.platformsData && this.props.platformsData.map((x, idx) => {
                        return (
                          <div className="col-6 col-md-6 col-lg-6 form-group">
                            <div
                              className="restarantInput d-flex justify-content-between align-items-center"
                            >
                              <select
                                field="iPlatformsdata"
                                value={x.name}
                                onChange={(event) => this.handlePlatformTagChange(event, idx)}
                                validateOnBlur
                                validateOnChange
                                disabled={!this.state.enableInput}
                                className={this.props.buildClassName.bind(this)(
                                  formState.errors.iPlatformsdata,
                                  this.state.enableInput
                                )}
                              >
                                <option value="" label="" disabled>
                                  - Select tag -
                                </option>
                                {this.props.aggregatorPlatforms.length
                                  ? this.props.aggregatorPlatforms
                                    .map((c, i) => {
                                      return (
                                        <option
                                          key={String(i)}
                                          // m={c.value}
                                          value={c.label}
                                        >
                                          {c.label}
                                        </option>
                                      );
                                    })
                                  : null}
                              </select>
                              <input
                                onChange={(event) => this.handlePlatformUrlChange(event, idx)}
                                value={x.url}
                                placeholder="Enter url"
                                style={{ border: "1px solid ", height: 35, marginLeft: 4, borderRadius: 5 }}
                                type="text"
                              />
                              <input
                                onChange={(event) => this.handlePlatformStoreIdChange(event, idx)}
                                value={x.platform_store_id}
                                placeholder="Enter store id"
                                style={{ border: "1px solid ", height: 35, marginLeft: 4, borderRadius: 5 }}
                                type="text"
                              />
                              <button className="btnaddimg" disabled={!this.state.enableInput} onClick={() => this.handleRemoveItemTagClick(idx)}>Remove</button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    );
  }
  handleRemoveItemTagClick = (i) => {
    let tags = [...this.props.platformsData];
    tags.splice(i, 1);
    this.props.setPlatformsData(tags)
  }
  _handlePlatformsAddClick = () => {
    let tags = [...this.props.platformsData, { name: "", url: "", platform_store_id: '' }]
    this.props.setPlatformsData(tags);
  }
  handlePlatformTagChange(event, idx) {
    let tags = [...this.props.platformsData]
    let findTag = tags.filter(i => i.name == event.target.value)
    if (!findTag.length) {
      tags[idx].name = (event.target.value)
    }
    this.props.setPlatformsData(tags)
  }
  handlePlatformUrlChange(event, idx) {
    let tags = [...this.props.platformsData]
    let findTag = tags.filter(i => i.url == event.target.value)
    if (!findTag.length) {
      tags[idx].url = (event.target.value)
    }
    this.props.setPlatformsData(tags)
  }
  handlePlatformStoreIdChange(event, idx) {
    let tags = [...this.props.platformsData]
    let findTag = tags.filter(i => i.platform_store_id == event.target.value)
    if (!findTag.length) {
      tags[idx].platform_store_id = (event.target.value)
    }
    this.props.setPlatformsData(tags)
  }
  _setFormApi(formApi) {
    this._formApi = formApi;
  }

  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("Please clear errors");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}


class RestarantOwnerEditForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues
  };

  render() {
    return (
      <div id="restarantOwnerDetails">
        <div className="row text-capitalize py-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Restaurant owner details</span>{" "}
            <span className="text-muted small">(Required)</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-8" />
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rOForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {
            return (
              <>
                <div className="row text-capitalize fsize">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rOName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant owner <span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rOName"
                            id="rEdit__rOName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rOName}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rOName,
                              this.state.enableInput
                            )}
                            validate={val =>
                              validateStrictText(
                                val,
                                4,
                                formState.touched.rOName,
                                "Owner Name",
                                300
                              )
                            }
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rOName && (
                            <span className="input_error">
                              {formState.errors.rOName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rOEmail"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Email <span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rOEmail"
                            id="rEdit__rOEmail"
                            type="text"
                            validate={val =>
                              validateEmail(
                                val && val.trim(),
                                formState.touched.rOEmail
                              )
                            }
                            validateonblur="true"
                            validateonchange="true"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rOEmail}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rOEmail,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.rOEmail && (
                            <span className="input_error">
                              {formState.errors.rOEmail}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row text-capitalize fsize border_bottom">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rOPhone"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Mobile number <span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            id="rEdit__rOPhone"
                            field="rOPhone"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rOPhone}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rOPhone,
                              this.state.enableInput
                            )}
                            validate={val =>
                              validatePhone(
                                val && val.trim(),
                                formState.touched.rOPhone
                              )
                            }
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rOPhone && (
                            <span className="input_error">
                              {formState.errors.rOPhone}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rOAddress"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Address <span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="formf-group">
                          <Text
                            field="rOAddress"
                            id="rEdit__rOAddress"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rOAddress}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rOAddress,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                            validate={val =>
                              validateText(
                                val,
                                10,
                                formState.touched.rOAddress,
                                "Address"
                              )
                            }
                          />
                          {formState.errors.rOAddress && (
                            <span className="input_error">
                              {formState.errors.rOAddress}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    );
  }

  _setFormApi(formApi) {
    this._formApi = formApi;
  }

  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("Please clear errors");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}

class RestarantHeadEditForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues
  };

  render() {
    return (
      <div id="restaurentHeadDetails">
        <div className="row text-capitalize py-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">Restaurant head details</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-8" />
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {
            return (
              <>
                <div className="row text-capitalize fsize">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rHName"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant head <span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rHName"
                            id="rEdit__rHName"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rHName}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rHName,
                              this.state.enableInput
                            )}
                            validate={val =>
                              validateText(
                                val,
                                4,
                                formState.touched.rHName,
                                "Head Name"
                              )
                            }
                            // validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rHName && (
                            <span className="input_error">
                              {formState.errors.rHName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rHEmail"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Email <span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rHEmail"
                            id="rEdit__rHEmail"
                            type="text"
                            validate={val =>
                              validateEmail(
                                val && val.trim(),
                                formState.touched.rHEmail
                              )
                            }
                            // validateonblur="true"//optional value
                            validateonchange="true"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rHEmail}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rHEmail,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.rHEmail && (
                            <span className="input_error">
                              {formState.errors.rHEmail}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row text-capitalize fsize">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rHPhone"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Mobile number <span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rHPhone"
                            id="rEdit__rHPhone"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rHPhone}
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rHPhone,
                              this.state.enableInput
                            )}
                            validate={val =>
                              validatePhone(
                                val && val.trim(),
                                formState.touched.rHPhone
                              )
                            }
                            // validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rHPhone && (
                            <span className="input_error">
                              {formState.errors.rHPhone}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rHAddress"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Address<span>:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rHAddress"
                            id="rEdit__rHAddress"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rHAddress}
                            validate={val =>
                              validateText(
                                val,
                                10,
                                formState.touched.rHAddress,
                                "Address"
                              )
                            }
                            // validateonblur="true"
                            validateonchange="true"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rHAddress,
                              this.state.enableInput
                            )}
                          />
                          {formState.errors.rHAddress && (
                            <span className="input_error">
                              {formState.errors.rHAddress}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    );
  }

  _setFormApi(formApi) {
    this._formApi = formApi;
  }

  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("clear errors first");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}


class RestarantUserAppForm extends Component {
  state = {
    editMode: this.props.editMode,
    enableInput: !this.props.editMode || false, //disable inputs only if this is editmode.
    formValues: this.props.initialValues
  };

  handleCkEditorState = (event, editor) => {
    const data = editor.getData();
    this.props.setRestAbout(data);
  }
  //add offers
  handleInputChange(e, index) {
    const list = [...this.props.offersList];
    let findOffer = list.filter(i => i.offers == e.target.value)
    if (!findOffer.length) list[index].offers = e.target.value;

    this.props.setOffers(list);
  }
  handleOfferImgChange(event) {
    let idx = Number(event.target.id)
    let offList = [...this.props.offersList]
    let selectedFile = event.target.files[0];
    if (selectedFile.type.split("/")[0] != "image") {
      event.target.value = null;
      return toast.error("Please input a valid image file", {
        autoClose: 2000
      });
    }
    this.setState(
      {
        displayImgs: URL.createObjectURL(selectedFile)
      },
      async err => {
        let data = new FormData();
        data.append("file", selectedFile);
        data.append("filename", "myfile");
        //let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
        this.setState({
          uploadProgress: true
        });
        let uploadSuccess = await fetch(this.props.apiBasePath + this.props.apiRoutes.uploadFile, {
          headers: {
            // 'Content-type':undefined
          },
          method: "POST",
          body: data
        }).catch(err => console.error(err));
        let response = await uploadSuccess
          .json()
          .catch(err => console.error(err));
        this.setState({
          uploadProgress: false
        });
        if (!response.success) {
          return toast.error("Failed to upload image");
        }
        this.setState({
          displayImgs: ''
        });
        offList[idx].offerImg = response.data
        this.props.setOffers(offList)
      },
    )

  }
  handleRemoveClick(e, i) {
    const list = [...this.props.offersList];
    list.splice(i, 1);
    this.props.setOffers(list);
  }

  handleAddOffersClick() {
    const list = [...this.props.offersList, { offers: "", offerImg: "" }];
    this.props.setOffers(list)
    //  this.props.setTimingSlots(list);
  }
  handleAddFeaturesClick() {
    const list = [...this.props.featuresList, { feature: "", featureAvailable: "" }];
    this.props.setFeatures(list)
  }
  handleFeatureInputChange(e, index) {
    const list = [...this.props.featuresList];
    list[index].feature = e.target.value;
    this.props.setFeatures(list);
  }
  handleFeatureAvailableChange(e, index) {
    const list = [...this.props.featuresList];
    list[index].featureAvailable = e.target.value;
    this.props.setFeatures(list);
  }
  handleFeatureRemoveClick(e, i) {
    const list = [...this.props.featuresList];
    list.splice(i, 1);
    this.props.setFeatures(list);
  }

  //Delivery Partners
  handleAddPartnersClick() {
    const list = [...this.props.partnersList, { link: "", partner: "" }];
    this.props.setPartners(list)
  }
  handlePartnerLinkChange(e, index) {
    const list = [...this.props.partnersList];
    list[index].link = e.target.value;
    this.props.setPartners(list);
  }
  handlePartnerChange(e, index) {
    const list = [...this.props.partnersList];
    list[index].partner = e.target.value;
    this.props.setPartners(list);
  }
  handlePartnerRemoveClick(e, i) {
    const list = [...this.props.partnersList];
    list.splice(i, 1);
    this.props.setPartners(list);
  }

  render() {
    let baseUrl = this.props.apiBasePath.split("/api")[0];
    const pickUpValues = [
      {
        value: 0,
        label: 0,
      },
      {
        value: 50,
        label: 50,
      },
      {
        value: 100,
        label: 100,
      },
    ];
    return (
      <div id="restarantDetails">
        <div className="row text-capitalize py-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <span className="orderhding">User Web Portal Details</span>
            {this.state.editMode && (
              <button
                className="float-right rEdit__editToggle"
                onClick={this._toggleEditMode.bind(this)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
          </div>
          <div
            className={
              this.state.editMode
                ? "col-12 col-sm-12 col-md-8 col-lg-8 text-right"
                : "d-none"
            }
          >

          </div>
        </div>
        <Form
          onChange={formState => this.props.getFormValues("rForm", formState)}
          getApi={this._setFormApi.bind(this)}
        >
          {({ formState }) => {

            return (
              <>
                <div className="row text-capitalize fsize border_bottom">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          YouTube Link <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rLink}
                            /*  validate={val =>
                               validateInput(
                                 val,
                                 2,
                                 formState.touched.rLink,
                                 "Restaurant YouTube Link",
                                 500
                               )
                             } */
                            id="rEdit__rLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rLink && (
                            <span className="input_error">
                              {formState.errors.rLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFBLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Facebook <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rFBLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rFBLink}
                            /* validate={val =>
                              validateInput(
                                val,
                                2,
                                formState.touched.rFBLink,
                                "Restaurant FaceBook Link",
                                100
                              )
                            } */
                            id="rEdit__rFBLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rFBLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rFBLink && (
                            <span className="input_error">
                              {formState.errors.rFBLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rInstaLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Instagram <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rInstaLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rInstaLink}
                            /* validate={val =>
                              validateInput(
                                val,
                                2,
                                formState.touched.rInstaLink,
                                "Restaurant Instagram Link",
                                100
                              )
                            } */
                            id="rEdit__rInstaLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rInstaLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rInstaLink && (
                            <span className="input_error">
                              {formState.errors.rInstaLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-3 col-md-3 col-lg-3 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rTwiterLink"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Twitter <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-9 col-md-9 col-lg-9 form-group">
                        <div className="">
                          <Text
                            field="rTwiterLink"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rTwiterLink}
                            /*  validate={val =>
                               validateInput(
                                 val,
                                 2,
                                 formState.touched.rTwiterLink,
                                 "Restaurant Twitter Link",
                                 100
                               )
                             } */
                            id="rEdit__rTwiterLink"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rTwiterLink,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rTwiterLink && (
                            <span className="input_error">
                              {formState.errors.rTwiterLink}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4" >
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rPureVeg"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Pure Veg <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Checkbox
                            field="rPureVeg"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rPureVeg}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4" >
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rSpeciality"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Speciality<span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rSpeciality"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rSpeciality}
                            validate={val => {
                              if (val) {
                                let minLength = 2;
                                let touched = formState.touched.rSpeciality;
                                let name = "Restaurant Speciality";
                                let maxLength = 30;
                                if (typeof minLength == "undefined") minLength = 4;
                                if (!touched) {
                                  //this is the first focus, do not validate.
                                  return false;
                                }
                                val = val && val.trim();
                                if (!/^[ A-Za-z@$&_']*$/.test(val)) {
                                  return `Enter a valid ${name}.`;
                                }
                                if (!val) return `${name} cannot be empty`;
                                if (!val || val.length < minLength || val.length > maxLength) {
                                  return `${name} must contain ${minLength} to ${maxLength} Characters.`;
                                }
                              }
                            }
                            }
                            id="rEdit__rSpeciality"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rSpeciality,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rSpeciality && (
                            <span className="input_error">
                              {formState.errors.rSpeciality}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rAbout"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          About <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-10 col-md-10 col-lg-10 form-group">
                        <div className="">

                          <CKEditor
                            editor={ClassicEditor}
                            disabled={!this.state.enableInput}
                            field="rAbout"
                            data={this.props.rAboutInitVal}
                            onChange={this.handleCkEditorState.bind(this)}

                          />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rOffers"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant Offers <span className="">:</span>
                        </label>
                      </div>
                      <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddOffersClick()}>Add</button></div>
                      {

                        this.props.offersList && this.props.offersList.map((x, i) => {

                          return (

                            <div className="col-6 col-md-6 col-lg-6 form-group">

                              <div
                                className="restarantInput d-flex justify-content-between align-items-center"
                                style={{ padding: "6px" }}
                              >

                                <input
                                  style={{ width: "-webkit-fill-available" }}
                                  field="rOffers"
                                  type="text"
                                  disabled={!this.state.enableInput}
                                  // initialValue={x}
                                  id="rEdit__rOffers"
                                  onChange={val => {
                                    this.handleInputChange(val, i)
                                  }}
                                  value={x.offers}
                                />
                                <div className="col-3 col-md-3 col-lg-3 form-group" >
                                  <div className="align-items-center">
                                    {<input type="file"
                                      className={"hide", "pimg_preview", i}
                                      accept="image/*"
                                      id={"pimg_preview", i}
                                      onChange={(event) => this.handleOfferImgChange(event)}
                                      disabled={!this.state.enableInput}
                                    />}
                                    {x.offerImg ?
                                      <div>
                                        <label
                                          id="pimg_preview_label"
                                          htmlFor="pimg_preview"
                                          style={{
                                            backgroundImage: x.offerImg ? `url(${baseUrl +
                                              x.offerImg})` : ""
                                          }}
                                        >
                                        </label> </div> : ""
                                    }
                                  </div>
                                </div>

                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handleRemoveClick(obj, i)}>Remove</button></div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rTagLine"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Tag Line <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-10 col-md-10 col-lg-10 form-group">
                        <div className="">
                          <Text
                            field="rTagLine"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rTagLine}
                            validate={val => {
                              if (val) {
                                let minLength = 2;
                                let touched = formState.touched.rTagLine;
                                let name = "Restaurant Tag line";
                                let maxLength = 30;
                                if (typeof minLength == "undefined") minLength = 4;
                                if (!touched) {
                                  //this is the first focus, do not validate.
                                  return false;
                                }
                                val = val && val.trim();
                                if (!/^[ A-Za-z@$&_']*$/.test(val)) {
                                  return `Enter a valid ${name}.`;
                                }
                                if (!val) return `${name} cannot be empty`;
                                if (!val || val.length < minLength || val.length > maxLength) {
                                  return `${name} must contain ${minLength} to ${maxLength} Characters.`;
                                }
                              }
                            }
                            }
                            id="rEdit__rTagLine"
                            className={
                              this.props.buildClassName.bind(this)(
                                formState.errors.rTagLine,
                                this.state.enableInput
                              )
                            }
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {
                            formState.errors.rTagLine && (
                              <span className="input_error">
                                {formState.errors.rTagLine}
                              </span>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* choose template  */}
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rUserappTemplete"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Choose Theme <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <Select
                          id="rEdit__rUserappTemplete"
                          disabled={!this.state.enableInput}
                          field="rUserappTemplete"
                          validateonblur="true"
                          validateonchange="true"
                          validate={val => {
                            if (!val) return "Please select Theme to your restaurant Template .";
                          }}
                          className={
                            this.state.enableInput
                              ? "w-100 restarantInput"
                              : "w-100 restarantInput restarantInput--disabled"
                          }
                          value={this.state.formValues.rUserappTemplete}
                        // validateonblur="true"
                        // validateOnSelect
                        >
                          {/* @todo - dynamic values */}
                          <option value="">select Template </option>
                          <option value="Template1">Template1</option>
                          <option value="Template2">Template2</option>
                        </Select>
                        {formState.errors.rUserappTemplete && (
                          <span className="input_error">
                            {formState.errors.rUserappTemplete}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFeatures"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant Features <span className="">:</span>
                        </label>
                      </div>
                      <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddFeaturesClick()}>Add</button></div>
                      {

                        this.props.featuresList && this.props.featuresList.map((x, i) => {

                          return (

                            <div className="col-6 col-md-6 col-lg-6 form-group">

                              <div
                                className="restarantInput d-flex justify-content-between align-items-center"
                                style={{ padding: "6px" }}
                              >

                                <input
                                  // style={{ width: "-webkit-fill-available" }}
                                  field="rFeatures"
                                  type="text"
                                  disabled={!this.state.enableInput}
                                  // initialValue={x}
                                  id="rEdit__rFeatures"
                                  onChange={val => {
                                    this.handleFeatureInputChange(val, i)
                                  }}
                                  value={x.feature}
                                />
                                <select
                                  style={{ marginInlineStart: "8px" }}
                                  disabled={!this.state.enableInput}
                                  validateonblur="true"
                                  validateonchange="true"
                                  className={
                                    this.state.enableInput
                                      ? "w-100 restarantInput"
                                      : "w-100 restarantInput restarantInput--disabled"
                                  }
                                  value={x.featureAvailable}
                                  onChange={obj => this.handleFeatureAvailableChange(obj, i)}
                                >
                                  {/* @todo - dynamic values */}
                                  <option value="">select Available</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handleFeatureRemoveClick(obj, i)}>Remove</button></div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFeatures"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          pick up Time <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-6 form-group">
                        <Slider value={this.props.userPickupTime} onChange={this.updateRange} marks={pickUpValues} />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 form-group">
                        <span style={{ fontWeight: 'bold' }}>{this.props.userPickupTime[0]} - {this.props.userPickupTime[1]}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFeatures"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          delivery Time <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-6 form-group">
                        <Slider value={this.props.userDeliveryTime} onChange={this.updateDeliveryRange} marks={pickUpValues} />
                      </div>
                      <div className="col-2 col-md-2 col-lg-2 form-group">
                        <span style={{ fontWeight: 'bold' }}>{this.props.userDeliveryTime[0]} - {this.props.userDeliveryTime[1]}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rFeatures"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Delivery Partners <span className="">:</span>
                        </label>
                      </div>
                      <div><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={() => this.handleAddPartnersClick()}>Add</button></div>
                      {

                        this.props.partnersList && this.props.partnersList.map((x, i) => {

                          return (

                            <div className="col-6 col-md-6 col-lg-6 form-group">

                              <div
                                className="restarantInput d-flex justify-content-between align-items-center"
                                style={{ padding: "6px" }}
                              >

                                <input
                                  // style={{ width: "-webkit-fill-available" }}
                                  field="rPartners"
                                  type="text"
                                  placeholder="Enter delivery partner link"
                                  disabled={!this.state.enableInput}
                                  // initialValue={x}
                                  id="rEdit__rFeatures"
                                  onChange={val => {
                                    this.handlePartnerLinkChange(val, i)
                                  }}
                                  value={x.link}
                                />
                                <select
                                  style={{ marginInlineStart: "8px" }}
                                  disabled={!this.state.enableInput}
                                  validateonblur="true"
                                  validateonchange="true"
                                  className={
                                    this.state.enableInput
                                      ? "w-100 restarantInput"
                                      : "w-100 restarantInput restarantInput--disabled"
                                  }
                                  value={x.partner}
                                  onChange={obj => this.handlePartnerChange(obj, i)}
                                >
                                  {/* @todo - dynamic values */}
                                  <option value="">select Devilery Partner</option>
                                  <option value="zomato">zomato</option>
                                  <option value="swiggy">swiggy</option>
                                  <option value="scootsy">scootsy</option>
                                  <option value="ubereats">ubereats</option>
                                  <option value="dunzo">dunzo</option>
                                  <option value="dotpe">dotpe</option>
                                  <option value="foodpanda">foodpanda</option>
                                  <option value="amazon">amazon</option>
                                  <option value="talabat">talabat</option>
                                  <option value="deliveroo">deliveroo</option>
                                  <option value="jahez">jahez</option>
                                  <option value="magicpin">magicpin</option>
                                  <option value="eazydiner">eazydiner</option>
                                  <option value="swiggystore">swiggystore</option>
                                  <option value="zomatomarket">zomatomarket</option>
                                </select>
                                <div style={{ lineHeight: "10px", marginInlineStart: "8px" }} ><button disabled={!this.state.enableInput} className="btnaddcategory" onClick={(obj) => this.handlePartnerRemoveClick(obj, i)}>Remove</button></div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rEmail"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Restaurant Email <span className="">:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <Text
                            field="rEmail"
                            type="text"
                            disabled={!this.state.enableInput}
                            initialValue={this.state.formValues.rEmail}
                            /*  validate={val =>
                               validateInput(
                                 val,
                                 2,
                                 formState.touched.rLink,
                                 "Restaurant YouTube Link",
                                 500
                               )
                             } */
                            id="rEdit__rEmail"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rEmail,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rEmail && (
                            <span className="input_error">
                              {formState.errors.rEmail}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="row align-items-center">
                      <div className="col-4 col-md-4 col-lg-4 restarant_input__labelWrapper mb-3">
                        <label
                          htmlFor="rEdit__rOrderingContactNos"
                          className="row mx-0 w-100 justify-content-between"
                        >
                          Ordering Contact Nos<span className="" >:</span>
                        </label>
                      </div>
                      <div className="col-6 col-md-6 col-lg-7 form-group">
                        <div className="">
                          <TextArea
                            field="rOrderingContactNos"
                            disabled={!this.state.enableInput}
                            placeholder="Enter Contact No(s) for online Orders.For ex:123456778,9876543210"
                            initialValue={this.state.formValues.rOrderingContactNos}
                            validate={val => {
                              if (val && !new RegExp(/^[0-9,]+$/).test(val))
                                return "Enter valid Nos seperated by comas(,)"
                            }}
                            id="rEdit__rOrderingContactNos"
                            className={this.props.buildClassName.bind(this)(
                              formState.errors.rOrderingContactNos,
                              this.state.enableInput
                            )}
                            validateonblur="true"
                            validateonchange="true"
                          />
                          {formState.errors.rOrderingContactNos && (
                            <span className="input_error">
                              {formState.errors.rOrderingContactNos}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    );
  }
  updateRange = (e, data) => {
    this.props.setUserPickuptime(data);
  };
  updateDeliveryRange = (e, data) => {
    this.props.setUserDeliverytime(data);
  }
  _setFormApi(formApi) {
    this._formApi = formApi;
  }
  _toggleEditMode() {
    let numErrors = 0;
    let formErrors = this._formApi.getState().errors;
    for (let i in formErrors) {
      if (formErrors[i]) {
        numErrors++;
      }
    }
    if (this.state.enableInput && numErrors) {
      return console.error("clear errors first");
    }
    this.setState({
      enableInput: !this.state.enableInput
    });
  }
}
