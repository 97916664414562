import React, { Component } from "react";
import { AuthContext } from "../contextProvider";
import { throttleTime } from "../utilities";
import ReactPaginate from "react-paginate";
import Loader from 'react-loader-spinner';
import { toast } from "react-toastify";
export default class AssignedTablesList extends Component {
  static contextType = AuthContext;

  state = {
    currentPageNum: 1,
    recordsPerPage: 10,
    fetchProgress: false,
    fetchFailMsg: "",
    records: [],
    totalRecords: 0,
    noDataMsg:"No Records."
  };

  componentWillMount() {
    this._fetchAssignedTables();
  }

  render() {
    return (
      <>
        <div className="contentvthbtn">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
            <>
              <div className="row mx-0 justify-content-between">
                <div className="">
                  <span className="categorycol text-capitalized pl-0">
                    Assigned tables
                  </span>
                </div>
                <div className="">
                  {/* <select className="select_Restaurent m-0">
                            <option value='' disabled>Select Restaurant</option>
                        </select> */}
                </div>
              </div>
              {this.state.records.length ? (
                <div className="table-responsive mt-3 text-capitalize tableofauto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>table number</th>
                        <th>Bearer name</th>
                        <th className="text-center">Seating capacity</th>
                        {/* <th>status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.records.map((t, idx) => {
                        return (
                          <tr key={String(idx)}>
                            <td>
                              {(this.state.currentPageNum - 1) *
                                this.state.recordsPerPage +
                                (idx + 1)}
                            </td>
                            <td>table {t.tableNum}</td>
                            <td>{t.bearerName}</td>
                            <td className="text-center">{t.tableCapacity}</td>
                            {/* <td className="activecol">active</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="noDataMsgContainer">
                  <h5>{this.state.noDataMsg}</h5>
                </div>
              )}
            </>
          )}
        </div>
        <div className="container-fluid">
          {this.state.totalRecords / this.state.recordsPerPage > 1 ? (
            <ReactPaginate
              previousLabel={
                <i className="fa fa-angle-double-left" aria-hidden="true" />
              }
              nextLabel={
                <i className="fa fa-angle-double-right" aria-hidden="true" />
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(
                this.state.totalRecords / this.state.recordsPerPage
              )}
              marginPagesDisplayed={this.state.surroundingButtonsCount}
              pageRangeDisplayed={3}
              pageLinkClassName={"pagebtn"}
              onPageChange={data => this.getMore(data.selected + 1)}
              containerClassName={"pagination"}
              activeLinkClassName={"active"}
            />
          ) : null}
        </div>
      </>
    );
  }

  getMore(pageNum) {
    this.setState(
      {
        currentPageNum: pageNum
      },
      err => {
        err && console.error(err);
        this._fetchAssignedTables();
      }
    );
  }

  async _fetchAssignedTables() {
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath } = this.context.CONSTANTS;
    const tablesResponse = await fetch(
      apiBasePath +
        apiRoutes.getBearerTableAssignments +
        `?user_id=${this.context.user.userId}&restarant_id=${
          this.context.user.selectedRestarantId
        }&limit=${this.state.recordsPerPage}&offset=${(this.state
          .currentPageNum - 1) *
          this.state.recordsPerPage}`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));

    if (!tablesResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching tables");
    }

    const tablesData = await tablesResponse
      .json()
      .catch(err => console.error(err));
    if (!tablesData) {
      return toast.error("An Error Occured while fetching tables");
    }
    if (!tablesData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error(tablesData.msg);
    }
    throttleTime(
      () => {
        this.setState({
          records: tablesData.data,
          fetchProgress: false,
          totalRecords: tablesData.total
        });
      },
      requestSendTime,
      500
    );
  }
}
