import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Checkbox } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import moment from 'moment';
export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <CategoryTimingGroups
                        {...props}
                        user={user}
                        authToken={authToken}
                        upAuthToken={up_Authorization}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class CategoryTimingGroups extends Component {
    state = {
        categoryTimingGroups: [],
        totalCatTimingGroups: 0,
        currentPageNum: 1,
        recordsPerPage: 10,
        fetchProgress: true,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        noDataMsg: "No category timing groups Yet.",
        selectedCategoryTimingGroupId: null,
        allcategoryTimingGroups: [],
        urbanCatTimingGroupSyncDetails: []
    };

    async componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        await this._loadUrbanCatTimingGroupSyncDetails();
        this._loadCategoryTimingGroups(true);

    }

    componentDidMount() {
        $(this.modalUI.current).on("shown.bs.modal", () => {
            // if (this.modalRef.current.state.categories.length) return;
            // this._loadCategories();
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedCategoryTimingGroupId: null
            });
            this.modalRef.current.formApi.reset();
        });
    }

    componentWillUnmount() {
        toast.dismiss();
    }

    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                        <>
                            <div className="row mx-0 justify-content-between align-items-center">
                                <div className="">
                                    <span className="categorycol">categoryTimingGroups</span>
                                </div>
                                {this.state.urbanCatTimingGroupSyncDetails.length?<div>
                                    {"errors : " + this.state.urbanCatTimingGroupSyncDetails[0].errors.length}
                                    <div>
                                    {"last Synched : " +moment(this.state.urbanCatTimingGroupSyncDetails[0].lastSynched).format("DD/MM/YYYY HH:mm:ss")}
                                    </div>
                                </div>:null}
                                <div>

                                    <button
                                        type="button"
                                        className="btnaddcategory"
                                        onClick={() => {
                                            this._onSyncCatTimingGroups()
                                        }}
                                    >
                                        Sync cat Timing Groups
                                        </button>
                                </div>
                                <div className="">
                                    <button
                                        type="button"
                                        className="btnaddcategory float-right"
                                        // data-toggle="modal"
                                        // data-target="#catTimingGroupModal"
                                        onClick={() => {
                                            this.props.history.push("add-category-timing-group")
                                        }}
                                    >
                                        <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                       &nbsp; Add cat timing group
                                        </button>
                                </div>
                            </div>
                            {this.state.categoryTimingGroups.length ? (
                                <div className="table-responsive mt-3 tableofauto">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>title</th>
                                                <th className="actionWidth">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.categoryTimingGroups.map((c, idx) => (
                                                <tr key={String(idx)}>
                                                    <td>
                                                        {(this.state.currentPageNum - 1) *
                                                            this.state.recordsPerPage +
                                                            idx +
                                                            1}
                                                    </td>
                                                    <td>{c.catTimingGroupTitle}</td>
                                                    <td>
                                                        <img
                                                            alt=""
                                                            src={EDIT_IMG}
                                                            className="icon"
                                                            onClick={() => {
                                                                this.props.history.push("category-timing-group-details", { catTimingGroupId: c.catTimingGroupId })
                                                            }}
                                                        />
                                                        {/* <img
                                                                alt=""
                                                                src={DELETE_IMG}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() =>
                                                                    this.confirmAndDelete(
                                                                        o.optionId,
                                                                        o.optionTitle
                                                                    )
                                                                }
                                                            /> */}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="noDataMsgContainer">
                                    <h5>{this.state.noDataMsg}</h5>
                                </div>
                            )}
                        </>
                    )}
                </div>
                {this._pagination()}
            </>
        );
    }

    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totalCatTimingGroups / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totalCatTimingGroups / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadCategoryTimingGroups(true);
            }
        );
    }


    async _loadCategoryTimingGroups(limit) {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const catTimingGroupResponse = await fetch(
            apiBasePath +
            apiRoutes.getCategoryTimingGroups +
            `?user_id=${this.props.user.userId}&limit=${limit ? this.state.recordsPerPage : 0
            }&offset=${limit ? (this.state.currentPageNum - 1) *
                this.state.recordsPerPage : 0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!catTimingGroupResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const catTimingGroupResponseResult = await catTimingGroupResponse.json();
        if (!catTimingGroupResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!catTimingGroupResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: catTimingGroupResponseResult.msg
            });
            return toast.error(catTimingGroupResponseResult.msg);
        }
        limit ?
            throttleTime(
                () => {
                    this.setState({
                        categoryTimingGroups: catTimingGroupResponseResult.data,
                        totalCatTimingGroups: catTimingGroupResponseResult.total,
                        fetchProgress: false
                    });
                },
                requestSendTime,
                500
            ) :
            this.setState({
                fetchProgress: false,
                allcategoryTimingGroups: catTimingGroupResponseResult.data
            })
    }

    async _onSyncCatTimingGroups() {
        await this._loadCategoryTimingGroups(false)
        if (this.state.saveProgress) {
            return false;
        }
        const { upApiBasePath } = this.props.CONSTANTS;
        let catTimingGroups = this.state.allcategoryTimingGroups.map((c, i) => {
            let daySlots = c.catTimingDaySlots ? JSON.parse(c.catTimingDaySlots).cat_day_slots : [];
            let catRefIds = c.categoryRefIds ? JSON.parse(c.categoryRefIds).cat_ref_ids : []
            return {
                title: c.catTimingGroupTitle,
                category_ref_ids: catRefIds,
                day_slots: daySlots
            }
        })
        this.setState({
            saveProgress: true
        });
        let body = {
            timing_groups: catTimingGroups
        }
        const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/categories/timing-groups/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (savedResponseResult) {
            if (savedResponseResult.status == "error") {
                this.setState({
                    saveProgress: false
                });
                return toast.error(savedResponseResult.message)
            }
            if (savedResponseResult.status == "success") {
                this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference_id)
                this.setState({
                    saveProgress: false
                });
                setTimeout(() => {
                    toast.success(
                        "Successfully synched"
                    );
                }, 1500);
                return toast.success(savedResponseResult.message);
            }
        }
    }


    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: restId,
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanCatTimingGroupResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while saving the webhook response"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
    }

    async _loadUrbanCatTimingGroupSyncDetails() {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const urbanCatTimingGroupSyncDetails = await fetch(
            apiBasePath +
            apiRoutes.getUrbanCatTimingGroupSyncDetails +
            `?user_id=${this.props.user.userId}&restarant_id=${this.props.user.selectedRestarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!urbanCatTimingGroupSyncDetails) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const urbanCatTimingGroupSyncDetailsResult = await urbanCatTimingGroupSyncDetails.json();
        if (!urbanCatTimingGroupSyncDetailsResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        this.setState({
            urbanCatTimingGroupSyncDetails: urbanCatTimingGroupSyncDetailsResult.data
        })

    }
}

