import React, { Component } from "react";
import { AuthContext, DataContext, DataProvider } from "../contextProvider";
import _ from 'lodash';
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "react-loader-spinner";


export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <DaywiseSaleReport
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class DaywiseSaleReport extends Component {
    state = {
        records: [],
        selectedRestarantId: 0,
        fetchProgress: false
    }
    componentDidMount() {
        if (!this.props.isSuperAdmin) this._fetchOrdersList();
    }
    render() {
        let records = _.groupBy(this.state.records, function (o) {
            return o.orderUpdatedAt.substring(0, 10)
        });
        let salesRecords = [];
        _.forEach(records, (itm, key) => {
            let takeaway = itm.filter(i => i.orderType == 'takeaway');
            let dinein = itm.filter(i => i.orderType == 'dine in');
            let walkin = itm.filter(i => i.orderType == 'walk in');
            let scheduled = itm.filter(i => i.orderType == 'scheduled');
            let swiggy = itm.filter(i => i.orderType == 'swiggy');
            let zomato = itm.filter(i => i.orderType == 'zomato');
            salesRecords.push({
                date: key,
                Takeaway: _.sumBy(takeaway, function (o) { return o.orderTotal + o.orderTax - o.orderDiscount + o.orderSummary.orderCgst + o.orderSummary.orderSgst + (o.orderSummary.orderIgst || 0) }),
                dinein: _.sumBy(dinein, function (o) { return o.orderTotal + o.orderTax - o.orderDiscount + o.orderSummary.orderCgst + o.orderSummary.orderSgst + (o.orderSummary.orderIgst || 0) }),
                walkin: _.sumBy(walkin, function (o) { return o.orderTotal + o.orderTax - o.orderDiscount + o.orderSummary.orderCgst + o.orderSummary.orderSgst + (o.orderSummary.orderIgst || 0) }),
                scheduled: _.sumBy(scheduled, function (o) { return o.orderTotal + o.orderTax - o.orderDiscount + o.orderSummary.orderCgst + o.orderSummary.orderSgst + (o.orderSummary.orderIgst || 0) }),
                swiggy: _.sumBy(swiggy, function (o) { return o.orderSummary.merchantReceivable }),
                zomato: _.sumBy(zomato, function (o) { return o.orderSummary.merchantReceivable }),
                total: _.sumBy(itm, function (o) { return o.orderSummary.merchantReceivable || (o.orderTotal + o.orderTax - o.orderDiscount + o.orderSummary.orderCgst + o.orderSummary.orderSgst + (o.orderSummary.orderIgst || 0)) })
            });
        });
        return (
            <DataProvider
                loadRestarantsOnWillMount={this.props.isSuperAdmin}
                {...this.props}
            >
                <div className="content">
                    <div className="row mx-0 justify-content-between align-items-center">
                        <div className="">
                            <span className="categorycol">Day Wise Sale</span>
                        </div>
                        {this.props.isSuperAdmin && (
                            <RestarantSelector
                                selectedRestarantId={this.state.selectedRestarantId}
                                filterByRestarant={this.filterByRestarant.bind(this)}
                            />

                        )}
                    </div>
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style={"loadingProgress"}
                        />
                    ):(
                        < div className="table-responsive mt-3 tableofauto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th class="text-center">Takeaway</th>
                                <th class="text-center">Dine in</th>
                                <th class="text-center">Walk in</th>
                                <th class="text-center">Scheduled</th>
                                <th class="text-center">Swiggy</th>
                                <th class="text-center">Zomato</th>
                                <th class="text-center">Total</th>
                            </tr>
                        </thead>
                       {salesRecords.length? <tbody>
                            {
                                salesRecords.map((sr, idx) => (
                                    <tr key={String(idx)}>
                                        <td>{sr.date}</td>
                                        <td class="text-center">{sr.Takeaway}</td>
                                        <td class="text-center">{sr.dinein}</td>
                                        <td class="text-center">{sr.walkin}</td>
                                        <td class="text-center">{sr.scheduled}</td>
                                        <td class="text-center">{sr.swiggy}</td>
                                        <td class="text-center">{sr.zomato}</td>
                                        <td class="text-center">{sr.total}</td>
                                    </tr>
                                ))
                            }
                        </tbody>:<p>No Records</p>}
                    </table>
                </div>
                    )}
            </div>
            </DataProvider >
        )
    }

    filterByRestarant(val) {
        this.setState(
            {
                selectedRestarantId: val
            },
            err => {
                this._fetchOrdersList();
            }
        );
    }
    async _fetchOrdersList() {
        if (this.state.fetchProgress) return false;
        this.setState({ fetchProgress: true });
        let start_date = new Date();
        start_date.setDate(start_date.getDate() - 30);
        const filters = { startDate: start_date, endDate: new Date() }
        const { apiRoutes, apiBasePath, userRoles } = this.props.CONSTANTS;
        const url =
            apiBasePath +
            apiRoutes.getRestarantTakeAwayOrders +
            `?user_id=${this.props.user.userId}&restaurant_id=${this.props.isSuperAdmin
                ? this.state.selectedRestarantId || 0
                : this.props.user.selectedRestarantId
            }&limit=${0}&offset=${0}&start_date=${filters
                ? filters.startDate
                    ? moment(filters.startDate)
                        .utc(true)
                        .startOf("day")
                        .toISOString()
                    : ""
                : ""
            }&end_date=${filters
                ? filters.endDate
                    ? moment(filters.endDate)
                        .utc(true)
                        .endOf("day")
                        .toISOString()
                    : ""
                : ""
            }`;
        const ordersResponse = await fetch(url, {
            headers: {
                Authorization: this.props.authToken,
                "Content-type": "application/json"
            }
        }).catch(err => console.error(err));

        if (!ordersResponse) {
            this.setState({
                records: [],
                fetchProgress: false
            });
            return toast.error("An Error Occured while fetching orders");
        }

        const ordersData = await ordersResponse
            .json()
            .catch(err => console.log(err));
        if (!ordersData) {
            return toast.error("An Error Occured while fetching orders");
        }
        if (ordersResponse.status == 403) {
            this.setState({
                records: [],
                fetchProgress: false

            });
            toast.error(ordersData.msg, { autoClose: 8000 });
            return this.context.logOut();
        }
        if (!ordersData.success) {
            this.setState({
                records: [],
                fetchProgress: false
            });
            return toast.error(ordersData.msg);
        }

        this.setState({
            records: ordersData.data,
            fetchProgress: false
        });
    }
}

function RestarantSelector({ selectedRestarantId, filterByRestarant }) {
    return (
        <DataContext.Consumer>
            {({ restarants }) => {
                return (
                    <select
                        onChange={e => filterByRestarant(e.nativeEvent.target.value)}
                        className="select_Restaurent mb-3"
                        defaultValue={selectedRestarantId}
                    >
                        <option value={0}>All Restaurants</option>
                        {restarants &&
                            restarants.map(
                                (r, idx) =>
                                    r.name && (
                                        <option value={r.id} key={String(idx)}>
                                            {r.name.length > 40
                                                ? r.name.substr(0, 37) + "..."
                                                : r.name}
                                        </option>
                                    )
                            )}
                    </select>
                );
            }}
        </DataContext.Consumer>
    );
}