import React, { Component } from "react";
import Chart from "chart.js";
import { DataContext, DataProvider, AuthContext } from "../contextProvider";
import { fetchFilteredOrders, fetchFilteredTakeAwayOrders } from "../services";
import { enumerateDaysBetweenDates } from "../utilities";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Loader from "react-loader-spinner";
import TakeAwayOrders from "./takeaway";

// import { XYPlot,
//   XAxis,
//   YAxis,
//   VerticalGridLines,
//   HorizontalGridLines,
//   VerticalBarSeries,
//   VerticalBarSeriesCanvas,
//   LabelSeries} from 'react-vis';
const moment = require("moment");
export default class Reports extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.pieChartCanvasRef = React.createRef();
    this.barChartCanvasRef = React.createRef();
  }

  state = {
    activeButton: "ALL",
    reportStartingDate: null,
    reportEndingDate: null,
    chartColors: [
      "rgb(255, 99, 132)",
      "rgb(181, 52, 113)",
      "rgb(255, 159, 64)",
      "rgb(75, 192, 192)",
      "rgb(149, 165, 166)",
      "rgb(60, 99, 130)",
      // "rgb(255, 121, 121)",
      "rgb(249, 202, 36)",
      "rgb(0, 98, 102)",
      "rgb(142, 68, 173)",
      "rgb(39, 174, 96)",
      "rgb(22, 160, 133)",
      "rgb(41, 128, 185)"
    ],
    barChartTitle: "Weekly Report",
    barChartConfig: {
      labels: ["jan", "feb", "march"],
      datasets: [
        {
          label: "Non-veg",
          backgroundColor: "rgb(255, 99, 132)",

          data: [900, 650, 375]
        },
        {
          label: "veg",
          backgroundColor: "rgb(255, 159, 64)",

          data: [350, 500, 425]
        },
        {
          label: "starters",
          backgroundColor: "rgb(255, 205, 86)",

          data: [550, 700, 550]
        },
        {
          label: "Cool drinks",
          backgroundColor: "rgb(75, 192, 192)",

          data: [625, 575, 425]
        }
      ]
    },
    pieChartConfig: {
      datasets: [
        {
          data: [59, 18, 10, 13],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(126, 214, 223)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "#7ed6df"
          ],
          label: "Category wise orders"
        }
      ],
      labels: ["Non-veg", "Veg", "starters", "Cool drinks"]
    },
    value: "Numbers"
  };
  componentDidMount() {
    //monthly vs yearly
    //categories per day-> items | amount | margin?
    //---------------
    //orders vs dates
    //categories vs dates -> per day | month | custom_period

    // this.drawCharts(null, null);

    // window.addEventListener("resize",  this.drawCharts.bind(this));
  }

  async drawCharts(startingDate, endingDate) {
    //get orders for dates in state,
    // pass the values
    //     !this.context.isSuperAdmin && this.drawCategoriesChart();
    //     this.barChartForPeriod();

    this.setState({
      chartLoading: true
    });
    //last 1 week,
    let startDate =
      startingDate ||
      moment()
        .subtract(1, "week")
        .startOf("day");
    let endDate = endingDate || moment().endOf("day");
    let orders = await this.fetchOrdersByDate(
      {
        startDate: moment(startDate)
          .utc(true)
          .toISOString(), //consider the given time to be utc.
        endDate: moment(endDate)
          .utc(true)
          .toISOString()
      },
      this.state.activeButton
    ).catch(err => console.error(err));

    if (!orders) {
      orders = [];
    }
    this.barChartForPeriod(false, orders, startDate, endDate);

    (!this.context.isSuperAdmin || this.state.selectedRestarantId) &&
      this.drawCategoriesChart(false, orders);
  }

  componentWillUnmount() {
    // window.removeEventListener("resize",  this.drawCharts.bind(this));
  }
  previewImage(e) {
    let value = e.target.value;
    console.log(this.setState({ value: value }));
  }

  render() {
    const greenData = [{ x: "A", y: 10 }, { x: "B", y: 5 }, { x: "C", y: 15 }];
    const labelData = greenData.map((d, idx) => ({
      x: d.x,
      y: Math.max(greenData[idx].y, 0)
    }));
    return (
      <div className="content content--reports">
        <div className="row mx-0 justify-content-between">
          <div className="mb-3">
            <span className="categorycol">Reports</span>
            {/* <input type="button" value="Charts" className="btndownloadpdf"
              onClick={
                this.previewImage.bind(this)
              }
            />
            <input type="button" value="Numbers" className="btndownloadpdf"
              onClick={
                this.previewImage.bind(this)
              }
            /> */}
          </div>
        </div>
        {this.state.value === 'Numbers' ?
          <TakeAwayOrders />
          :
          (
            <React.Fragment >
              <div className="row mx-0 justify-content-end flex-fill">
                <div
                  className={`d-flex align-items-center justify-content-between mb-3 mr-2 ${
                    this.context.isSuperAdmin ? "" : ""
                    }`}
                >
                  {/* <div className="align-items-center pr-2 fb">
                select date range
              </div> */}
                  {/* {this.context.isSuperAdmin ? null : this._datePicker()} */}
                  {this._datePicker()}
                </div>
                {this.context.isSuperAdmin && (
                  <div className="mb-3">
                    <DataProvider loadRestarantsOnWillMount {...this.context}>
                      <DataContext.Consumer>
                        {({ restarants }) => {
                          return (
                            <select
                              onChange={e =>
                                this.filterByRestarant(e.nativeEvent.target.value)
                              }
                              className="select_Restaurent mr-0"
                              defaultValue=""
                            >
                              <option value={""}>All Restaurants</option>
                              {restarants &&
                                restarants.map(
                                  (r, idx) =>
                                    r.name && (
                                      <option value={r.id} key={String(idx)}>
                                        {r.name.length > 40
                                          ? r.name.substr(0, 37) + "..."
                                          : r.name}
                                      </option>
                                    )
                                )}
                            </select>
                          );
                        }}
                      </DataContext.Consumer>
                    </DataProvider>
                  </div>
                )}
              </div>
              <div className="row" style={{ position: "relative" }}>
                {this.state.chartLoading && (
                  <Loader
                    type="Triangle"
                    // type="Plane"
                    color="rgb(255, 99, 132)"
                    height="100"
                    width="100"
                    style="reportsLoadingProgress"
                  />
                )}
                <div
                  className={
                    this.context.isSuperAdmin && !this.state.selectedRestarantId
                      ? `col-12 col-sm-8 col-md-8 reportmargin m-auto py-5`
                      : `col-12 col-sm-12 col-md-12 reportmargin col-lg-6`
                  }
                >
                  <div
                    id="canvas-holder"
                  // style={{ maxHeight: "70vh", position: "relative" }}
                  >
                    <canvas id="chart-area" ref={this.barChartCanvasRef} />
                  </div>

                  {/* <XYPlot xType="ordinal" width={300} height={300} xDistance={100}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          <VerticalBarSeries className="bar-chart-bars" data={greenData} />
          <LabelSeries data={labelData} getLabel={d => d.x} />
        </XYPlot> */}
                </div>
                <div
                  className={`col-12 col-sm-12 col-md-12 reportmargin ${
                    this.context.isSuperAdmin && !this.state.selectedRestarantId
                      ? "d-none"
                      : "col-lg-6"
                    }`}
                >
                  <div id="barChartContainer">
                    <canvas id="canvas" ref={this.pieChartCanvasRef} />
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    className={
                      this.state.activeButton == "DINE_IN"
                        ? "btn btn-secondary btn-sm active"
                        : "btn btn-secondary btn-sm"
                    }
                    onClick={() =>
                      this.setState(
                        {
                          activeButton: "DINE_IN"
                        },
                        err => {
                          this.drawCharts(
                            this.state.reportStartingDate,
                            this.state.reportEndingDate
                          );
                        }
                      )
                    }
                  >
                    Dine In
            </button>
                  <button
                    type="button"
                    className={
                      this.state.activeButton == "ALL"
                        ? "btn btn-secondary btn-sm active"
                        : "btn btn-secondary btn-sm"
                    }
                    onClick={() =>
                      this.setState(
                        {
                          activeButton: "ALL"
                        },
                        err => {
                          this.drawCharts(
                            this.state.reportStartingDate,
                            this.state.reportEndingDate
                          );
                        }
                      )
                    }
                  >
                    All Orders
            </button>
                  <button
                    type="button"
                    className={
                      this.state.activeButton == "TAKE_AWAY"
                        ? "btn btn-secondary btn-sm active"
                        : "btn btn-secondary btn-sm"
                    }
                    onClick={() =>
                      this.setState(
                        {
                          activeButton: "TAKE_AWAY"
                        },
                        err => {
                          this.drawCharts(
                            this.state.reportStartingDate,
                            this.state.reportEndingDate
                          );
                        }
                      )
                    }
                  >
                    Take Away
            </button>
                </div>
              </div>
            </React.Fragment>
          )
        }
      </div>
    );
  }

  filterByRestarant(val) {
    this.setState(
      {
        selectedRestarantId: val
      },
      () =>
        this.drawCharts(
          this.state.reportStartingDate,
          this.state.reportEndingDate
        )
    );
  }

  drawPieChart() {
    const config = {
      type: "pie",
      data: this.state.pieChartConfig,
      options: {
        responsive: true,
        legend: {
          position: "bottom"
        },
        tooltips: {
          // enabled:false,
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = Number(
                ((currentValue / total) * 100).toFixed(1)
              );
              // return currentValue + " (" + percentage + "%)";
              return percentage + "%";
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        }
      }
    };
    var piechart = this.pieChartCanvasRef.current.getContext("2d");

    piechart.clearRect(0, 0, piechart.width, piechart.height);
    this.state.pieChart && this.state.pieChart.destroy();
    // piechart.clearRect(0, 0, piechart.width, piechart.height);
    this.setState({
      pieChart: new Chart(piechart, config)
    });
  }

  removeData(chart) {
    chart.data.labels.pop();
    chart.data.datasets.forEach(dataset => {
      dataset.data.pop();
    });
    // chart.update();
  }

  addData(chart, labels, data) {
    labels.forEach(label => chart.data.labels.push(label));
    chart.data.datasets.forEach(dataset => {
      dataset.data.push(data);
    });
    // chart.update();
  }

  drawBarChart(screenWidth, max) {
    var barChartConfig = this.state.barChartConfig;
    max = Math.max(max, 100);
    var chartOptions = {
      responsive: true,
      // maintainAspectRatio: false,
      legend: {
        position: "bottom"
      },
      title: {
        display: true,
        text: this.state.barChartTitle
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: max,
              stepSize: screenWidth > 576 ? max / 10 : max / 4
            }
          }
        ]
      }
    };

    // if (initialDraw) {
    var barchart = this.barChartCanvasRef.current.getContext("2d");
    this.state.barChart && this.state.barChart.destroy();
    barchart.clearRect(0, 0, barchart.width, barchart.height);
    this.setState({
      barChart: new Chart(barchart, {
        type: "bar",
        data: barChartConfig,
        options: chartOptions
      })
    });
    // } else {
    //   this.removeData(this.state.barChart);
    //   this.state.barchart.options = chartOptions;
    // this.addData(this.state.barChart,barChartConfig.labels,barChartConfig.data)

    //   this.state.barChart.update();
    // }
  }

  async weeklyOrdersReport(initialDraw, isSuperAdmin) {
    this.drawCharts(null, null);
  }

  async barChartForPeriod(initialDraw, orders, startDate, endDate) {
    //orders to ordersByDate
    let labels = enumerateDaysBetweenDates(
      moment(startDate).startOf("day"),
      moment(endDate).endOf("day")
    );
    let ordersPerDayList = [];
    let maxValue = 0;
    orders.forEach(o => {
      let orderDate = moment(o.orderDate || o.orderCreatedAt).format(
        "D/M/YYYY"
      );
      let indexOfOrderDateInLabels = labels.indexOf(orderDate);
      ordersPerDayList[indexOfOrderDateInLabels] = ordersPerDayList[
        indexOfOrderDateInLabels
      ]
        ? ordersPerDayList[indexOfOrderDateInLabels] + 1
        : 1;
      maxValue = Math.max(
        ordersPerDayList[indexOfOrderDateInLabels] + 1,
        maxValue
      );
    });
    //map orders to data
    this.setState(
      {
        barChartConfig: {
          labels,
          datasets: [
            {
              label: "Orders",
              backgroundColor: "rgb(255, 99, 132)",
              data: ordersPerDayList
            }
          ]
        },
        barChartTitle:
          this.state.reportStartingDate && this.state.reportEndingDate
            ? `${moment(this.state.reportStartingDate).format(
              "D/M/YYYY"
            )} - ${moment(this.state.reportEndingDate).format("D/M/YYYY")}`
            : "Weekly Report"
      },
      err => {
        if (err) return console.error(err);
        this.drawBarChart(window.screen.width, maxValue);
        this.setState({
          chartLoading: false
        });
      }
    );
  }

  async drawCategoriesChart(initialDraw, orders) {
    //extract data

    let totalItems = orders.reduce((cart, order) => {
      let orderCart =
        typeof order.orderSummary === "string"
          ? JSON.parse(order.orderSummary).cart
          : order.orderSummary.cart;
      return cart.concat(orderCart);
    }, []);
    let totalItemsCount = 0;
    let categoriesAndValues = {};
    totalItems.forEach(item => {
      if (!categoriesAndValues[item.categoryId]) {
        categoriesAndValues[item.categoryId] = {
          orderCount: item.qty,
          categoryTitle: item.categoryName
        };
      } else {
        categoriesAndValues[item.categoryId]["orderCount"] += item.qty;
      }
      totalItemsCount += item.qty;
    });
    let categoryPercents = [],
      categoryTitles = [];
    for (let catId in categoriesAndValues) {
      categoryPercents.push(
        (categoriesAndValues[catId]["orderCount"] / totalItemsCount) * 100
      );
      categoryTitles.push(categoriesAndValues[catId]["categoryTitle"]);
    }
    this.setState(
      {
        pieChartConfig: {
          datasets: [
            {
              data: categoryPercents,
              backgroundColor: this.state.chartColors,
              label: "Category wise orders"
            }
          ],
          labels: categoryTitles
        }
      },
      err => {
        if (err) return console.error(err);
        this.drawPieChart(initialDraw);
      }
    );
  }

  _datePicker() {
    return (
      <>
        <DatePicker
          selected={this.state.dateFiltersStartDate}
          selectsStart
          startDate={this.state.dateFiltersStartDate}
          endDate={this.state.dateFiltersEndDate}
          maxDate={new Date()}
          className="form-control"
          onChange={this.handleChangeStart.bind(this)}
          isClearable={true}
          placeholderText="Starting from..."
        />
        <span style={{ textAlign: "center", width: "20px" }}>-</span>
        <DatePicker
          selected={this.state.dateFiltersEndDate}
          selectsEnd
          minDate={this.state.dateFiltersStartDate}
          maxDate={new Date()}
          className="form-control"
          startDate={this.state.dateFiltersStartDate}
          endDate={this.state.dateFiltersEndDate}
          onChange={this.handleChangeEnd.bind(this)}
          isClearable={true}
          placeholderText="...to"
        />
      </>
    );
  }

  handleChangeStart(val) {
    this.setState(
      {
        reportStartingDate: moment(val)
          .startOf("day")
          .format(), //moment js format for sending to server
        dateFiltersStartDate: val //js format for date picker
      },
      () => {
        if (!this.state.reportEndingDate && !this.state.reportStartingDate) {
          this.weeklyOrdersReport();
        }
        if (!this.state.reportEndingDate || !this.state.reportStartingDate) {
          return false;
        }
        console.group("start date changed");
        console.log("statring date", this.state.reportStartingDate);
        this.drawCharts(
          this.state.reportStartingDate,
          this.state.reportEndingDate
        );
      }
    );
  }

  handleChangeEnd(val) {
    this.setState(
      {
        reportEndingDate: moment(val)
          .endOf("day")
          .format(),
        dateFiltersEndDate: val
      },
      err => {
        if (!this.state.reportEndingDate && !this.state.reportStartingDate) {
          this.weeklyOrdersReport();
        }
        if (!this.state.reportEndingDate || !this.state.reportStartingDate) {
          return false;
        }
        this.drawCharts(
          this.state.reportStartingDate,
          this.state.reportEndingDate
        );
      }
    );
  }

  async fetchOrdersByDate(dates, type) {
    //if either of them are empty - stop
    //if both of them are empty - fetch
    if (this.state.fetchProgress) return false;
    this.setState({
      fetchProgress: true
    });
    if (!dates.startDate || !dates.endDate) {
      console.error("one or both dates mising for fetchorderbydate");
      return [];
    }

    let { user, isSuperAdmin, authToken, CONSTANTS } = this.context;

    let ordersResponse = await fetchFilteredOrders({
      filters: {
        startDate: dates.startDate,
        endDate: dates.endDate
      },
      CONSTANTS,
      user,
      isSuperAdmin,
      authToken,
      restarantId: isSuperAdmin
        ? this.state.selectedRestarantId || 0
        : user.selectedRestarantId
    }).catch(err => console.error(err));

    if (!ordersResponse) {
      return toast.error("An Error Occured while generating Reports.");
    }
    let orders = ordersResponse.data || [];

    let takeAwayOrdersResponse = await fetchFilteredTakeAwayOrders({
      filters: {
        startDate: dates.startDate,
        endDate: dates.endDate
      },
      CONSTANTS,
      user,
      isSuperAdmin,
      authToken,
      restarantId: isSuperAdmin
        ? this.state.selectedRestarantId || 0
        : user.selectedRestarantId
    }).catch(err => console.error(err));

    this.setState({
      fetchProgress: false
    });
    if (!takeAwayOrdersResponse || !takeAwayOrdersResponse.success) {
      // toast.error("An Error Occured while generating Reports.");
      return orders;
    }

    let takeAwayOrders = takeAwayOrdersResponse.data;
    switch (type) {
      case "DINE_IN":
        return orders;
      case "TAKE_AWAY":
        return takeAwayOrders;
      case "ALL":
        return orders.concat(takeAwayOrders);
      default:
        return orders.concat(takeAwayOrders);
    }
  }
}
