import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: this.props.address || "" };
  }

  // static getDerivedStateFromProps(props,state){
  //   return {address:props.address}
  // }

  handleChange = address => {
    this.props.setLocationAddress(address)

    // this.setState({ address });
  };

  handleSelect = address => {
    this.props.setLocationAddress(address)
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.props.setLocationCoords(latLng))
      .catch(error => console.error("Error", error));
  };


  onError = (status, clearFunc) => {
    console.error(status)
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.address || ""}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              className={"w-100 restarantInput"}
              {...getInputProps({
                disabled: this.props.disabled,
                placeholder: "Search Places ...",
                className: "location-search-input w-100 restarantInput"
              })}
            // value={suggestion.descriptions}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
