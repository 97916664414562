import React, { Component } from 'react';
import { DELETE_IMG, EDIT_IMG } from '../assets/images';
import {EditReceptionistModal} from './partials/modals';
import EmployeesList from './employees.js';

export default () => {
    return <EmployeesList
    empTypeTitle = "Receptionists"
       roleName="RECEPTIONIST"
       empTypeTitleSingular="Receptionist"
    />

}