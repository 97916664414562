import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthContext, DataContext, DataProvider } from "../contextProvider";
import { throttleTime } from "../utilities";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
export default class Orders extends Component {
  static contextType = AuthContext;
  state = {
    currentPageNum: 1,
    totalRecords: 0,
    recordsPerPage: 10,
    fetchProgress: false,
    records: [],
    empRoleId: null,
    empTypeTitle: "",
    empTypeTitleSingular: "",
    saveProgress: false,
    saveFailMsg: "",
    deleteProgress: false,
    deleteFailMsg: "",
    fetchFailMsg: "No records",
    selectedRestarantId: 0,
    showRecords: true
  };

  componentWillMount() {
    this._fetchOrdersList();
  }

  render() {
    return (
      <DataProvider
        loadRestarantsOnWillMount={this.context.isSuperAdmin}
        {...this.context}
      >
        <div className="contentvthbtn">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
            <>
              <div className="row mx-0 justify-content-between">
                <div className="">
                  <span className="categorycol">orders</span>
                </div>
                <div className="row mx-0">
                  {this.context.isSuperAdmin && (
                    <DataContext.Consumer>
                      {({ restarants }) => {
                        return (
                          <select
                            onChange={e =>
                              this.filterByRestarant(e.nativeEvent.target.value)
                            }
                            className="select_Restaurent mb-3"
                            defaultValue={this.state.selectedRestarantId}
                          >
                            <option value={0}>All Restaurants</option>
                            {restarants &&
                              restarants.map(
                                (r, idx) =>
                                  r.name && (
                                    <option value={r.id} key={String(idx)}>
                                      {r.name.length > 40
                                        ? r.name.substr(0, 37) + "..."
                                        : r.name}
                                    </option>
                                  )
                              )}
                          </select>
                        );
                      }}
                    </DataContext.Consumer>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    {this._datePicker()}
                  </div>
                  <div>
                  <select
                    onChange={e =>
                      this.loadRecordsPerPage(e.nativeEvent.target.value)
                    }
                    className="select_Restaurent ml-3"
                    defaultValue={this.state.recordsPerPage}
                  >
                    <option value={10}>10 per Page</option>
                    <option value={25}>25 per Page</option>
                    <option value={50}>50 per Page</option>
                    <option value={75}>75 per Page</option>
                    <option value={100}>100 per Page</option>
                  </select>
                  </div>
                </div>
              </div>
              {this.state.records.length ? (
                <div className="table-responsive mt-3 text-capitalize tableofauto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>guest name</th>
                        <th className="text-center">Order Id</th>
                        <th>date of Order</th>
                        <th className="text-center">Invoice Id</th>
                        {/* <th>date of invoice</th> */}
                        <th className="text-centerimp">items</th>
                        <th className="text-centerimp">amount</th>
                        <th className="text-centerimp">Paid</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.records.map((o, idx) => (
                        <tr
                          key={String(idx)}
                          className={
                            !o.invoiceGeneratedAt ? "clickable-row" : ""
                          }
                        >
                          <td>
                            {(this.state.currentPageNum - 1) *
                              this.state.recordsPerPage +
                              (idx + 1)}
                          </td>
                          <td>{o.guestName}</td>
                          <td className="text-center">{o.orderId}</td>
                          {/* <td>{moment(o.invoiceConfirmedAt).format("DD/MM/YYYY")}</td> */}
                          <td>{moment(o.orderDate).format("DD/MM/YYYY")}</td>
                          <td className="text-center">{o.invoiceId}</td>

                          {/* <td>{moment(o.orderDate).format("L")}</td> */}
                          <td className="text-center">
                            {JSON.parse(o.orderSummary).cart.length}
                          </td>
                          <td className="text-center">
                            {(o.orderTotal + o.orderTax).toFixed(2)}
                          </td>
                          <td className="text-center">
                            {o.paymentMode || "Not Paid"}
                          </td>
                          <td
                            onClick={() => this.detailsPage(o.orderId)}
                            style={{ cursor: "pointer" }}
                          >
                            {o.invoiceGeneratedAt ? (
                              <>
                                {" "}
                                <i
                                  className="fa fa-eye icon d-inline"
                                  aria-hidden="true"
                                />
                                <span>View</span>
                              </>
                            ) : (
                              <button
                                className={"btn btn-xs btn-success"}
                                style={{ borderWidth: "1px" }}
                              >
                                Generate Invoice
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="noDataMsgContainer">
                  <h5>{this.state.fetchFailMsg}</h5>
                </div>
              )}
            </>
          )}
        </div>
        <div className="container-fluid">
          <div className="pagination px-2">
            {this.state.showRecords && (this.state.totalRecords / this.state.recordsPerPage > 1) ? (
              <ReactPaginate
                previousLabel={
                  <i className="fa fa-angle-double-left" aria-hidden="true" />
                }
                nextLabel={
                  <i className="fa fa-angle-double-right" aria-hidden="true" />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(
                  this.state.totalRecords / this.state.recordsPerPage
                )}
                marginPagesDisplayed={this.state.surroundingButtonsCount}
                pageRangeDisplayed={3}
                pageLinkClassName={"pagebtn"}
                onPageChange={data => this.getMore(data.selected + 1)}
                containerClassName={"pagination"}
                activeLinkClassName={"active"}
                forcePage={this.state.currentPageNum - 1}
              />
            ) : null}
          </div>
        </div>
      </DataProvider>
    );
  }

  loadRecordsPerPage(val) {
    this.setState({showRecords: false});
    this.getMore(1);
    this.setState({recordsPerPage: val});
    this.setState({showRecords: true});
  }

  filterByRestarant(val) {
    this.setState(
      {
        selectedRestarantId: val,
        currentPageNum: 1
      },
      err => {
        this._fetchOrdersList();
      }
    );
  }

  getMore(pageNum) {
    this.setState(
      {
        currentPageNum: pageNum
      },
      err => {
        this._fetchOrdersList();
      }
    );
  }

  detailsPage(orderId) {
    let order = this.state.records.find(o => o.orderId === orderId);
    this.props.history.push("order-details/" + orderId, { order });
  }

  async _fetchOrdersList() {
    if (this.state.fetchProgress) return false;
    this.setState({
      fetchProgress: true
    });
    const filters =
      this.state.startDate && this.state.endDate
        ? { startDate: this.state.startDate, endDate: this.state.endDate }
        : null;
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
    const url =
      apiBasePath +
      apiRoutes.getRestarantCompletedOrders +
      `?user_id=${this.context.user.userId}&restaurant_id=${
        this.context.isSuperAdmin
          ? this.state.selectedRestarantId || 0
          : this.context.user.selectedRestarantId
      }&limit=${this.state.recordsPerPage}&offset=${(this.state.currentPageNum -
        1) *
        this.state.recordsPerPage}&start_date=${
        filters
          ? filters.startDate
            ? moment(filters.startDate)
                .utc(true)
                .startOf("day")
                .toISOString()
            : ""
          : ""
      }&end_date=${
        filters
          ? filters.endDate
            ? moment(filters.endDate)
                .utc(true)
                .endOf("day")
                .toISOString()
            : ""
          : ""
      }`;
    const ordersResponse = await fetch(url, {
      headers: {
        Authorization: this.context.authToken,
        "Content-type": "application/json"
      }
    }).catch(err => console.error(err));

    if (!ordersResponse) {
      this.setState({
        fetchProgress: false,
        totalRecords: 0,
        records: []
      });
      return toast.error("An Error Occured while fetching tables");
    }

    const ordersData = await ordersResponse
      .json()
      .catch(err => console.log(err));
    if (!ordersData) {
      return toast.error("An Error Occured while fetching tables");
    }
    if (ordersResponse.status == 403) {
      this.setState({
        fetchProgress: false,
        totalRecords: 0,
        records: []
      });
      toast.error(ordersData.msg, { autoClose: 8000 });
      return this.context.logOut();
    }
    if (!ordersData.success) {
      this.setState({
        fetchProgress: false,
        records: [],
        totalRecords: 0
      });
      return toast.error(ordersData.msg);
    }

    throttleTime(
      () => {
        this.setState({
          records: ordersData.data,
          fetchProgress: false,
          totalRecords: ordersData.total
        });
      },
      requestSendTime,
      500
    );
  }

  _datePicker() {
    return (
      <>
        <DatePicker
          selected={this.state.startDate}
          selectsStart
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          maxDate={new Date()}
          className="form-control"
          onChange={this.handleChangeStart.bind(this)}
          isClearable={true}
          placeholderText="Starting from..."
        />
        <span style={{ textAlign: "center", width: "20px" }}>-</span>
        <DatePicker
          selected={this.state.endDate}
          selectsEnd
          minDate={this.state.startDate}
          maxDate={new Date()}
          className="form-control"
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChangeEnd.bind(this)}
          isClearable={true}
          placeholderText="...to"
        />
      </>
    );
  }

  handleChangeStart(val) {
    this.setState(
      {
        startDate: val
      },
      () => this.fetchFilteredOrders(true)
    );
  }
  handleChangeEnd(val) {
    this.setState(
      {
        endDate: val
      },
      err => {
        this.fetchFilteredOrders(true);
      }
    );
  }

  fetchFilteredOrders(dateFilter) {
    //if either of them are empty - stop
    //if both of them are empty - fetch
    if (
      dateFilter &&
      (!this.state.startDate || !this.state.endDate) &&
      (this.state.startDate || this.state.endDate)
    ) {
      return false;
    }
    this.setState(
      {
        currentPageNum: 1
      },
      err => {
        this._fetchOrdersList();
      }
    );
  }
}
