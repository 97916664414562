import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./AuthPage.css";
import { BUVVAS_LOGO } from "../assets/images/";
import { AuthContext } from "../contextProvider";
import { validateEmail } from "../utilities";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";

export default class AuthPage extends Component {
  state = {
    phone: "",
    pwd: "",
    phoneError: "",
    pwdError: "",
    loginError: "",
    emailTouched: false,
    pwdTouched: false,
    forgotPassword: false,
    // resetPwd:true,
  };

  render() {
    let submitBtnText = "Login";
    let submitBtnAction = this._login;
    switch (true) {
      case this.state.forgotPassword:
        submitBtnText = "Get OTP";
        submitBtnAction = this._getOtp;
        break;
      case this.state.verifyOtp:
        submitBtnText = "Verify OTP";
        submitBtnAction = this._verifyOtp;
        break;
      case this.state.resetPwd:
        submitBtnText = "Reset Password";
        submitBtnAction = this._resetPwd;
        break;
      default:
        submitBtnText = "LOGIN";
    }
    return (
      <AuthContext.Consumer>
        {({ login, getOtp, submitOtp, resetPwd, loading }) => (
          <div className="container-fluid">
            <div className="container loginBg">
              <div className="row">
                <div className="loginForm py-3">
                  <div className="col-12 col-lg-10 col-xxl-7 bg-white rounded mx-auto shadow-sm p-3 py-lg-5">
                    <div className="text-center">
                      <img
                        src={BUVVAS_LOGO}
                        className="img-fluid"
                        alt="Buvvas"
                      />
                    </div>
                    {/* <form> */}
                    <div className="p-3 p-md-4">
                      {this.state.verifyOtp ? (
                        <div className="row align-items-center mb-1">
                          <div className="col-12 col-sm-12 col-md-5 col-lg-5 mb-3">
                            <div className="row mx-0 justify-content-between font-bold">
                              Enter the OTP
                              <span className="d-none d-sm-block">:</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-7 col-lg-7 mb-3">
                            <input
                              type="text"
                              placeholder="OTP"
                              className={
                                this.state.otpError
                                  ? "loginput input_error"
                                  : "loginput"
                              }
                              onChange={e => {
                                let value = e.target.value;
                                this.setState({
                                  otp: value,
                                  otpError:
                                    this.state.otpTouched && !this.state.otp
                                      ? "Please Enter OTP"
                                      : ""
                                });
                              }}
                              value={this.state.otp}
                              onBlur={e => {
                                let value = e.target.value;

                                if (!value) {
                                  this.setState({
                                    otpTouched: true,
                                    otpError: "Please Enter OTP"
                                  });
                                } else {
                                  this.setState({
                                    otpTouched: false,
                                    otpError: ""
                                  });
                                }
                              }}
                              onKeyUp={e => {
                                if (e.nativeEvent.keyCode == 13) {
                                  submitBtnAction.bind(this)({
                                    login,
                                    getOtp,
                                    resetPwd,
                                    submitOtp
                                  });
                                }
                              }}
                            />
                            {this.state.otpError && (
                              <div className="input_error">
                                {this.state.otpError}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="row align-items-center">
                          {!this.state.resetPwd && (
                            <>
                              <div className="col-12 col-sm-12 col-md-5 col-lg-5 mb-3">
                                <div className="row mx-0 justify-content-between font-bold">
                                  Enter your Email
                                  <span className="d-none d-sm-block">:</span>
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-7 col-lg-7 mb-3">
                                <input
                                  type="email"
                                  placeholder="Email"
                                  className={
                                    this.state.emailError
                                      ? "loginput input_error"
                                      : "loginput"
                                  }
                                  value={this.state.email}
                                  onChange={e =>
                                    this._onEmailChange(e.target.value)
                                  }
                                  onBlur={e => {
                                    let value = e.target.value;
                                    this.setState(
                                      { emailTouched: true },
                                      err => {
                                        this._onEmailChange(value);
                                      }
                                    );
                                  }}
                                  onKeyUp={e => {
                                    if (
                                      e.keyCode == 13 &&
                                      this.state.forgotPassword
                                    ) {
                                      submitBtnAction.bind(this)({
                                        login,
                                        getOtp,
                                        resetPwd,
                                        submitOtp
                                      });
                                    }
                                  }}
                                />
                                {this.state.emailError && (
                                  <div className="input_error">
                                    {this.state.emailError}
                                  </div>
                                )}
                              </div>

                              {!this.state.forgotPassword ? (
                                <>
                                  <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                    <div className="row mx-0 justify-content-between font-bold">
                                      Enter your Password
                                      <span className="d-none d-sm-block">
                                        :
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                    <input
                                      type="password"
                                      placeholder="password"
                                      // autoComplete="new-password"
                                      className={
                                        this.state.pwdError
                                          ? "loginput input_error"
                                          : "loginput"
                                      }
                                      value={this.state.pwd}
                                      onChange={e =>
                                        this._onPwdChange(e.target.value)
                                      }
                                      onBlur={() => {
                                        this.setState({ pwdTouched: true });
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.nativeEvent.keyCode == 13 &&
                                          !this.state.forgotPassword
                                        ) {
                                          submitBtnAction.bind(this)({
                                            login,
                                            getOtp,
                                            resetPwd,
                                            submitOtp
                                          });
                                        }
                                      }}
                                    />
                                    {this.state.pwdError && (
                                      <div className="input_error">
                                        {this.state.pwdError}
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                          {!this.state.forgotPassword && !this.state.resetPwd && (
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <button
                                tabIndex="-1"
                                to="/"
                                onClick={() =>
                                  this.setState({ forgotPassword: true })
                                }
                                className="colred float-right text-capitalize font-italic btn btn-link "
                                style={{ border: "none" }}
                              >
                                forgot password?
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.resetPwd ? (
                        <>
                          <div className="row mb-2">
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 d-flex align-items-center">
                              <div className="row mx-0 font-bold flex-fill justify-content-between">
                                Enter New password
                                <span className="d-none d-sm-block">:</span>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <input
                                type="password"
                                placeholder="password"
                                className={
                                  this.state.newPwdError
                                    ? "loginput input_error"
                                    : "loginput"
                                }
                                value={this.state.newPwd}
                                onChange={e =>
                                  this._onNewPwdChange(e.target.value)
                                }
                                autocomplete="new-password"
                                onBlur={e => {
                                  let value = e.target.value;
                                  this.setState(
                                    {
                                      newPwdTouched: true
                                    },
                                    err => {
                                      if (err) console.error(err);
                                      this._onNewPwdChange(value);
                                    }
                                  );
                                }}
                              />
                              {this.state.newPwdError && (
                                <div className="input_error">
                                  {this.state.newPwdError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 d-flex align-items-center">
                              <div className="row mx-0 justify-content-between font-bold  flex-fill justify-content-between">
                                Confirm password <span className="d-none d-sm-block">:</span>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <input
                                type="password"
                                placeholder="password"
                                className={
                                  this.state.confirmPwdError
                                    ? "loginput input_error"
                                    : "loginput"
                                }
                                value={this.state.confirmPwd}
                                onChange={e =>
                                  this._onConfirmPwdChange(e.target.value)
                                }
                                onBlur={e => {
                                  let value = e.target.value;
                                  this.setState(
                                    { confirmPwdTouched: true },
                                    err => {
                                      if (err) console.error(err);
                                      this._onConfirmPwdChange(value);
                                    }
                                  );
                                }}
                                onKeyUp={e => {
                                  if (e.nativeEvent.keyCode == 13) {
                                    submitBtnAction.bind(this)({
                                      login,
                                      getOtp,
                                      resetPwd,
                                      submitOtp
                                    });
                                  }
                                }}
                              />
                              {this.state.confirmPwdError && (
                                <div className="input_error">
                                  {this.state.confirmPwdError}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right px-0">
                        <button
                          onClick={() => {
                            if (loading) return false;
                            submitBtnAction.bind(this)({
                              login,
                              getOtp,
                              resetPwd,
                              submitOtp
                            });
                          }}
                          type="button"
                          className="loginBtn text-uppercase my-3"
                        >
                          {submitBtnText}
                          {loading && (
                            <Loader
                              type="ThreeDots"
                              color="#fff"
                              height={20}
                              width={30}
                              style="loaderInsideBtn"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthContext.Consumer>
    );
  }

  _login({ login }) {
    if(!this.state.email) return this.setState({
      emailError:"Please Enter your email"
    })
    if(!this.state.pwd) return this.setState({
      pwdError:"Please Enter your password"
          })
    this.setState(
      {
        emailTouched: true,
        pwdTouched: true
      },
      err => {
        this.state.email &&
          this.state.pwd &&
          !this.state.pwdError &&
          !validateEmail(this.state.email, true) &&
          login(this.state.email, this.state.pwd);
      }
    );
  }

  //submits email and gets otpId in response and an otp to the email .
  async _getOtp({ getOtp }) {
    if(!this.state.email) return this.setState({
      emailError:"Please Enter your email"
    })
    const response = await getOtp(this.state.email);
    if (!response.success) {
      return this.setState({
        emailError: response.msg
      });
    }
    this.setState({
      forgotPassword: false,
      verifyOtp: true,
      otpId: response.data,
      emailError: ""
    });
  }

  //submits otp and gets a temporary token that can be sent with new pwd.
  async _verifyOtp({ submitOtp }) {
    if(!this.state.otp) return this.setState({
      otpError :"Please enter your OTP."
    })
    const response = await submitOtp(this.state.otpId, this.state.otp);
    if (!response.success) {
      return this.setState({
        otpError: response.msg
      });
    }
    this.setState({
      verifyOtp: false,
      resetPwd: true,
      otp: "",
      otpError: false,
      resetToken: response.data
    });
  }

  async _resetPwd({ resetPwd }) {
    let err = "";
    if (!this.state.newPwd) {
      err = "Please Enter a new password.";
      return this.setState({
        newPwdError: err
      });
    }

    if (this.state.newPwd != this.state.confirmPwd) {
      err = "Passwords Donot match.";
      return this.setState({
        confirmPwdError: err
      });
    }else{
      this.setState({
        confirmPwdError:null,
        newPwdError:null
      })
    }

    if (this.state.confirmPwdError || this.state.newPwdError || err) {
      return false;
    }

    const response = await resetPwd(this.state.resetToken, this.state.newPwd);
    if (!response.success)
      return toast.error(response.msg, { autoClose: 5000 });
    this.setState({
      resetPwd: false,
      newPwd: "",
      confirmPwd: ""
    });
  }

  _onEmailChange(email) {
    let emailError = validateEmail(email, this.state.emailTouched);
    this.setState({
      email: email,
      emailError
    });
  }

  _onPwdChange(pwd) {
    this.setState({
      pwd: pwd,
      pwdError: pwd.length === 0 ? "Please Input password" : ""
    });
  }

  _onConfirmPwdChange(pwd) {
    let err =
      this.state.confirmPwdTouched && pwd != this.state.newPwd
        ? "Passwords Donot match."
        : "";
    err =
      pwd.length == 0 && this.state.confirmPwdTouched
        ? "please confirm your password"
        : "";

    this.setState({
      confirmPwd: pwd,
      confirmPwdError: err
    });
  }

  _onNewPwdChange(pwd) {
    this.setState({
      newPwd: pwd,
      newPwdError:
        pwd.length == 0 && this.state.newPwdTouched
          ? "please input your password"
          : ""
    });
    if(pwd.length && pwd == this.state.confirmPwd){
return this.setState({
  confirmPwdError:null
})
    }
  }

  validateNewPwd(pwd) {}
}
