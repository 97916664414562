import React, { Component, PureComponent } from "react";
import { fetchItemRoutes } from "../services";
import { toast } from "react-toastify";

export const ItemRoutesContext = React.createContext();
export class ItemRoutesProvider extends PureComponent {
  state = {
    ItemRoutes: [],
  };

  
  async componentWillMount(){
    const itemRoutesResult = await fetchItemRoutes({
      CONSTANTS: this.props.CONSTANTS,
      userId: this.props.user.userId,
      limit: 0,
      offset: 0,
      authToken: this.props.authToken
    })
    .catch(err => console.error(err));
    if(!itemRoutesResult){
      let msg = "An Error Occured while fetching itemsroutes.";
      this.setState({
         fetchProgress: false,
         noDataMsg: msg
       });
       return toast.error(msg);
   }

   if(!itemRoutesResult.success){
     let msg = itemRoutesResult.msg;
     this.setState({
       fetchProgress: false,
       noDataMsg: msg,
       ItemRoutes:[]
     });
     return toast.error(msg);
   }

   this.setState({
    ItemRoutes:itemRoutesResult.data
   })
  }

  render() {
    return (
      <ItemRoutesContext.Provider value={{ItemRoutes:this.state.ItemRoutes,fetchItemRoutes:fetchItemRoutes}}>
        {this.props.children}
      </ItemRoutesContext.Provider>
    );
  }
}