import React, { Component } from "react";
import { Link, Route, Switch, Redirect } from "react-router-dom";
import { EDIT_IMG, USER_ICON, DELETE_IMG } from "../assets/images";
import { toast, ToastContainer } from "react-toastify";
import Categories from "./categories";
import CategoryDetails from "./categoriesDetails";
import CategoryTimingGroups from "./categoryTimingGroups";
import CategoryTimingGroupDetails from "./categoryTimingGroupsDetails";
import SubCategories from "./sub-categories";
import MenuItems from "./menu-items";
import MenuItemDetails from "./menu-item-details";
import OptionGroups from "./optionGroups";
import Options from "./options";
import Taxes from "./taxes";
import Charges from "./charges";
import PaytmIntegration from "./paytmIntegration";
import Discounts from "./discounts";
import TablesList from "./tables";
import AssignedTablesList from "./assignedTables";
import StoreActions from "./storeActions";
import SatelliteOrders from "./satelliteOrders";
// import Chefs from "./chefs";
import Kiosks from "./Kiosk"
// import Bearers from "./Bearers";
import ItemRoutes from "./itemRoutes.js";
import OrderType from "./orderType";
import CustomMessages from "./customMessages"
import Orders from "./Orders";
import OrderDetails from "./orderDetails";
import Reports from "./Reports";
import Restarants from "./restarants";
import RestarantDetails from "./restarantDetails";
import Guests from "./guests";
import GuestDetails from "./guestDetails";
import Cities from "./cities";
import Sidebar from "./partials/Sidebar";
import Receptionists from "./receptionists";
import AdminProfile from "./adminProfile";
import TakeAwayOrders from "./takeaway";
import { AuthContext } from "../contextProvider";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { capitalize } from "../utilities";
import ItemActions from "./itemActions.js";
import RestaurantImages from "./restaurantImages";
import OptionActions from "./optionActions";
import OrdersCountReports from "./ordersCountReports";
import FeedBack from "./Feedback";
import FeedbackResponses from "./FeedbackResponses";
import FeedbackRating from "./FeedbackRating";
import CustomerPortalDetails from "./customerPortaldetails"
import GenericFeedbackResponses from "./genericFeedbackResponses";
import DaywiseSaleReport from "./DaywiseSaleReport";
import UrbanSyncCatalogueDetails from "./urbanSyncCatalogueDetails";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    let SUPER_ADMIN = this.props.CONSTANTS.userRoles["SUPER_ADMIN"];
    let ADMIN = this.props.CONSTANTS.userRoles["ADMIN"];
    let OWNER = this.props.CONSTANTS.userRoles["OWNER"];
    let urbanPiper = this.props.user.urbanPiperStatus == 1 ? true : false;

    this.state = {
      navOpen: false,
      showMenu: false,
      routes: [
        {
          route: "/restaurants",
          component: Restarants,
          roles: [SUPER_ADMIN],
          title: "Restaurants"
        },
        {
          route: "/cities",
          component: Cities,
          roles: [SUPER_ADMIN],
          title: "Cities"
        },
        {
          route: "/add-restarant",
          component: RestarantDetails,
          roles: [SUPER_ADMIN],
          title: "Add Restaurant",
          noSidebarEntry: true
        },
        {
          route: "/restaurant-details",
          component: RestarantDetails,
          roles: [SUPER_ADMIN],
          key: "add-restarant",
          title: "Add restaurant",
          noSidebarEntry: true
        },
        {
          route: "/categories",
          component: Categories,
          roles: [ADMIN, OWNER],
          title: "Categories"
        },
        {
          route: "/add-category",
          component: CategoryDetails,
          roles: [ADMIN, OWNER],
          title: "Add Category",
          noSidebarEntry: true
        },
        {
          route: "/add-sub-category",
          component: CategoryDetails,
          roles: [ADMIN, OWNER],
          title: "Add Sub Category",
          noSidebarEntry: true
        },
        {
          route: "/category-details",
          component: CategoryDetails,
          roles: [ADMIN, OWNER],
          key: "add-category",
          title: "Add category",
          noSidebarEntry: true
        },
        {
          route: "/categoryTimingGroups",
          component: CategoryTimingGroups,
          roles: [ADMIN, OWNER],
          title: "Categorytimings",
          noUrbanPiper: !urbanPiper
        },
        {
          route: "/add-category-timing-group",
          component: CategoryTimingGroupDetails,
          roles: [ADMIN, OWNER],
          title: "Add Category timing group",
          noSidebarEntry: true
        },
        {
          route: "/category-timing-group-details",
          component: CategoryTimingGroupDetails,
          roles: [ADMIN, OWNER],
          key: "add-category-timing-group",
          title: "Add category timing group",
          noSidebarEntry: true
        },
        {
          route: "/sub-categories",
          component: Categories,
          roles: [ADMIN, OWNER],
          title: "Sub Categories"
        },
        {
          route: "/item-Route",
          component: ItemRoutes,
          roles: [ADMIN, OWNER],
          title: "Item Routes"
        },
        {
          route: "/menu-items",
          component: MenuItems,
          roles: [ADMIN, OWNER],
          title: "Items"
        },
        {
          route: "/add-menu-item",
          component: MenuItemDetails,
          roles: [ADMIN, OWNER],
          title: "Add Menu Item",
          noSidebarEntry: true
        },
        {
          route: "/menu-item-details",
          component: MenuItemDetails,
          roles: [ADMIN, OWNER],
          key: "add-menu-item",
          title: "Add menu item",
          noSidebarEntry: true
        },
        {
          route: "/option-groups",
          component: OptionGroups,
          roles: [ADMIN, OWNER],
          title: "Option Groups",
          // noUrbanPiper: !urbanPiper
        },
        {
          route: "/options",
          component: Options,
          roles: [ADMIN, OWNER],
          title: "Options",
          // noUrbanPiper: !urbanPiper
        },
        {
          route: "/taxes",
          component: Taxes,
          roles: [ADMIN, OWNER],
          title: "Taxes",
          // noUrbanPiper: !urbanPiper
        },
        {
          route: "/charges",
          component: Charges,
          roles: [ADMIN, OWNER],
          title: "Charges",
          noUrbanPiper: !urbanPiper
        },
        {
          route: "/tables-list/:rId",
          component: TablesList,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Tables",
          noSidebarEntry: true
        },
        {
          route: "/tables-list",
          component: TablesList,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Tables"
        },
        // {
        //   route: "/assigned-tables-list",
        //   component: AssignedTablesList,
        //   roles: [ADMIN, OWNER],
        //   title: "Assigned Tables"
        // },
        {
          route: "/receptionists",
          component: Receptionists,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Receptionists"
        },
        {
          route: "/item-actions",
          component: ItemActions,
          roles: [ADMIN, OWNER],
          title: "Item Actions",
          // noUrbanPiper: !urbanPiper
        },
        {
          route: "/option-actions",
          component: OptionActions,
          roles: [ADMIN, OWNER],
          title: "Option Actions",
          // noUrbanPiper: !urbanPiper
        },
        {
          route: "/ordertype",
          component: OrderType,
          roles: [ADMIN, OWNER],
          title: "OrderType"
        },
        // {
        //   route: "/chefs",
        //   component: Chefs,
        //   roles: [ADMIN, OWNER, SUPER_ADMIN],
        //   title: "Chefs"
        // },
        {
          route: "/kiosk",
          component: Kiosks,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Kiosks"
        },

        // {
        //   route: "/bearers",
        //   component: Bearers,
        //   roles: [ADMIN, OWNER, SUPER_ADMIN],
        //   title: "Bearers"
        // },
        // {
        //   route: "/orders",
        //   component: Orders,
        //   roles: [ADMIN, OWNER, SUPER_ADMIN],
        //   title: "Orders"
        // },
        {
          route: "/takeaway-orders",
          component: TakeAwayOrders,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Take Away",
          noSidebarEntry: true
        },
        {
          route: "/custom-Messages",
          component: CustomMessages,
          roles: [ADMIN, OWNER],
          title: "Custom-Messages"
        },

        {
          route: "/discounts",
          component: Discounts,
          roles: [ADMIN, OWNER],
          title: "Discounts/Coupons"
        },
        {
          route: "/paytm-integration",
          component: PaytmIntegration,
          roles: [ADMIN, OWNER],
          title: "Paytm Integration"
        },
        {
          route: "/order-details",
          component: OrderDetails,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Order Details",
          noSidebarEntry: true
        },
        {
          route: "/guests",
          component: Guests,
          roles: [SUPER_ADMIN],
          title: "Guests"
        },
        {
          route: "/guest-details/:guestId",
          component: GuestDetails,
          roles: [SUPER_ADMIN],
          title: "Guest Details",
          noSidebarEntry: true
        },
        {
          route: "/reports/sales",
          component: Reports,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Reports",
          subMenu: [
            {
              route: "/reports/sales",
              component: Reports,
              roles: [ADMIN, OWNER, SUPER_ADMIN],
              title: "Orders",
            },
            {
              route: "/reports/DaywiseSaleReport",
              component: DaywiseSaleReport,
              roles: [ADMIN, OWNER, SUPER_ADMIN],
              title: "Daywise Sale",
            },
          ],
        },
        {
          route: "/ordersCount",
          component: OrdersCountReports,
          roles: [SUPER_ADMIN],
          title: "Orders Count Reports"
        },
        {
          route: "/syncdetails",
          component: UrbanSyncCatalogueDetails,
          roles: [SUPER_ADMIN],
          title: "Sync Catalogue Details"
        },
        {
          route: "/store-actions",
          component: StoreActions,
          roles: [ADMIN, OWNER],
          title: "Store Actions",
          noUrbanPiper: !urbanPiper
        },
        {
          route: "/satellite-orders",
          component: SatelliteOrders,
          roles: [ADMIN, OWNER],
          title: "Satellite Orders",
          noUrbanPiper: !urbanPiper
        },
        {
          route: "/feedback/Question",
          roles: [ADMIN, OWNER],
          title: "Feedback",
          subMenu: [
            {
              route: "/feedback/Question",
              component: FeedBack,
              roles: [ADMIN, OWNER],
              title: "Question",
            },
            {
              route: "/feedback/response",
              component: FeedbackResponses,
              roles: [ADMIN, OWNER],
              title: "Response",
            },
            {
              route: "/generic-feedback/response",
              component: GenericFeedbackResponses,
              roles: [ADMIN, OWNER],
              title: "GenericResponse",
            },
            {
              route: "/feedback/rating",
              component: FeedbackRating,
              roles: [ADMIN, OWNER],
              title: "Rating",
            }
          ],
        },
        {
          route: "/admin-profile",
          component: AdminProfile,
          roles: [ADMIN, OWNER, SUPER_ADMIN],
          title: "Profile",
          noSidebarEntry: true
        },
        {
          route: "/restaurant-images",
          component: RestaurantImages,
          roles: [ADMIN, OWNER],
          title: "Restaurant Images"

        },
        {
          route: "/customerPortal-details",
          component: CustomerPortalDetails,
          roles: [ADMIN, OWNER],
          title: "Restaurant website Details"
        },


      ].filter(r => {
        let pass = false;
        for (let i in r.roles) {
          if (this.props.user.userRoles.includes(r.roles[i])) {
            pass = true;
            return true;
          }
        }
        return pass;
      })
    };
  }

  toggleMobileSidebar(bool) {
    this.setState({
      navOpen: bool
    });
  }
  componentDidUpdate(){
    if(this.props.user && this.props.user.restarantStatus)
     this._getRestarantDetails();
  }
  render() {
    let path = this.props.location.pathname;
    let SUPER_ADMIN_CODE = this.props.CONSTANTS.userRoles["SUPER_ADMIN"];
    let isSuperAdmin = this.props.user.userRoles.includes(SUPER_ADMIN_CODE);
    return (
      <div className={`wrapper ${this.state.navOpen ? "nav-open" : ""}`}>
        <Sidebar
          parentRoute="/Dashboard"
          active={this.props.location.pathname.split("/")[2]}
          match={this.props.match}
          routes={this.state.routes
            .filter(r => !r.noSidebarEntry && (!r.noUrbanPiper))
            .map(r => ({ segment: r.route, title: r.title, subMenu: r.subMenu }))}
        />
        <div className="main-panel">
          <nav
            className="navbar navbar-expand-lg fixed-top"
            color-on-scroll="500"
          >
            <div className=" container-fluid justify">
              <div className="textright position-relative">
                <button
                  className="dropdown-toggle profilePicDropDown d-flex align-items-center"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={
                      this.props.user.profilePic
                        ? this.props.CONSTANTS.apiBasePath.split("/api")[0] +
                        this.props.user.profilePic
                        : USER_ICON
                    }
                    className="img-dp mx-2"
                    alt={capitalize(this.props.user.userName)}
                  />
                  <span className="userName">
                    {capitalize(this.props.user.userName)}
                  </span>
                </button>

                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{ width: "100%" }}
                >
                  <Link className="dropdown-item" to="admin-profile">
                    profile
                  </Link>

                  <span onClick={this.props.logOut} className="dropdown-item">
                    logout
                  </span>
                </div>
              </div>

              <button
                href=""
                className="navbar-toggler navbar-toggler-right"
                onClick={() => this.toggleMobileSidebar(!this.state.navOpen)}
                type="button"
                data-toggle="collapse"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-bar burger-lines" />
                <span className="navbar-toggler-bar burger-lines" />
                <span className="navbar-toggler-bar burger-lines" />
              </button>
            </div>
          </nav>

          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return isSuperAdmin ? (
                  <Redirect to={this.props.match.path + "/restaurants"} />
                ) : (
                  <Redirect to={this.props.match.path + "/categories"} />
                );
              }}
            />

            {this.state.routes.map((r, idx) => {
              if (r.subMenu) {
                return r.subMenu.map((s, idx) => {
                  return (
                    <Route
                      key={String(idx)}
                      path={this.props.match.path + s.route}
                      component={s.component}
                    />
                  )
                })
              } else {
                return (
                  <Route
                    key={String(idx)}
                    path={this.props.match.path + r.route}
                    component={r.component}
                  />
                );
              }
            })}
            <Redirect
              to={
                isSuperAdmin
                  ? this.props.match.path + "/restaurants"
                  : this.props.match.path + "/categories"
              }
            />
          </Switch>
        </div>
      </div>
    );
  }

  async _getRestarantDetails(){
    let fetchResponse = await fetch(
      this.props.CONSTANTS.apiBasePath +
      this.props.CONSTANTS.apiRoutes.getRestarantDetails +
      `?restarant_id=${this.props.user.restarantId}&user_id=${this.props.user.restarantId}`,
      { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!fetchResponse) {
      return false
    }
    const restarantData = await fetchResponse.json(); //@TODO - handle error
    if (!restarantData.success) {
      return false
    }
    if(restarantData.data.rStatus == 6){
      toast.error("The Account is Deactivated. Please Activate your account.");
      this.props.logOut();
    }
  }
}
