import React, { Component } from 'react';
import EmployeesList from './employees.js';

export default () => {
    return <EmployeesList
    empTypeTitle = "Kiosks"
       roleName="KIOSK"
       empTypeTitleSingular="Kiosk"
    />

}