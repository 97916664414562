//@ts-check
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Text, Form, Select } from "informed";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import Loader from "react-loader-spinner";
import $ from "jquery";

import {
    AuthContext
   
} from "../contextProvider";
import { validatePhone, validateEmail, validateText, validateStrictText } from "../utilities";
import moment from "moment";
import { toast } from "react-toastify";
import LocationSearchInput from "./partials/LocationSearchInput";
import { DELETE_IMG } from "../assets/images";
// import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

// import { Typeahead,AsyncTypeahead } from "react-bootstrap-typeahead";

export default class RestaurantImages extends Component {
   
    static contextType = AuthContext;

    state = {
        // enableInput:this.props.location.state && this.props.location.state.restarantId
        adminDetails: null,
        rTimings: {},
        disableInput: true,
        imagePath: "",
        baseUrl: "",
        file: [],
        displayImage: "",
        restBackgroundImage:"",
        restGalleryImages:[],
        restaurantImages: {rBackgroundImg:"",rGalleryImages:[]}
    };

    componentWillMount() {
        this._loadRestImages();
    }
    componentDidMount() {
        this.setState({
            baseUrl: this.context.CONSTANTS.apiBasePath.split("/api")[0]
        });
    }
 
    deleteImage = (index) => {
      const imagesList=[...this.state.restGalleryImages]
      imagesList.splice(index,1);
      this.setState({
        restGalleryImages:imagesList
      })
    }

    render() {
       
        return (
            <div className="content of-auto">
                <div className="mx-0">
                    <h3 className="order">Upload Restaurant images</h3>
                    <h5>Gallery Images:</h5> 
                   <div className="row mx-0 my-3">
                      <div className="col-lg-4">    <input type="file" className="form-control" onChange={this.previewGalleryImage.bind(this)} multiple />  </div>
                    </div> 
                    {/* <div className="row form-group">
                            
                    </div> */}
                    <div className="row mx-0 my-3">
                       
                        {this.state.restGalleryImages.map((url,i) => (
                            <div className="col-lg-4">
                                {/* <img src={`url(${this.state.baseUrl +url})`} alt="..." height="100px" width="100px" /> */}
                                <div className="show-image">
                                <label
                                    id="img_preview_label"
                                    htmlFor="img_preview_gallery"
                                    style={{
                                        backgroundImage:
                                            `url(${this.state.baseUrl + url})`
                                    }}
                                ></label>
                              
                                <img
                                  alt=""
                                  src={DELETE_IMG}
                                  style={{ cursor: "pointer",backgroundColor:"white",height:"25px",width:"25px" }}
                                  className="img-fluid"
                                  onClick={()=> this.deleteImage(i)
                                  }
                                />
                                </div>
                            </div>
                        ))}


                      
                   
                    </div>
                
                    <h5>Background Image:</h5>
                    <div className="row mx-0 my-3">
                        
                      
                        <div className="col-lg-4">
                            {/* <img src={`url(${this.state.baseUrl +url})`} alt="..." height="100px" width="100px" /> */}
                            <div>
                                <label
                                    id="img_preview_label"
                                    htmlFor="img_preview"
                                    style={{
                                        backgroundImage: this.state.restBackgroundImage
                                            ? `url(${this.state.baseUrl +
                                            this.state.restBackgroundImage})`
                                            : `url(${this.state.displayImage})`
                                    }}
                                >
                                    <input
                                        type="file"
                                        className="img_preview"
                                        accept="image/*"
                                        id="img_preview"
                                        onChange={this.previewBackgroundImage.bind(this)}
                                    />
                                    {!(
                                        this.state.restBackgroundImage || this.state.displayImage
                                    ) && (
                                            <i
                                                className="fa fa-upload img-upload-icon"
                                                aria-hidden="true"
                                            />
                                        )}
                                </label>
                                </div>
                            </div>
                    
                    </div>



                    <div className="col-lg-12 col-xxl-8 text-right">
                        <button
                            type="button"
                            className="savebtn"
                            onClick={() => this.onClickSaveChanges()}
                        >
                            Save Changes
            </button>
                    </div>
                </div>
            </div>
        );
    }

    previewGalleryImage = (e) => {
        let rImages = this.state.restGalleryImages;
        let fileObject = [];
        let imgArray = []
        fileObject.push(e.target.files)
        for (let i = 0; i < fileObject[0].length; i++) {
            let image = fileObject[0][i];
            if (image.type.split("/")[0] != "image") {
                e.target.value = null;
                return toast.error("Please input a valid image file", {
                    autoClose: 2000
                });
            }
            else {
                this.setState(
                    {
                        displayImage: ""
                    },
                    async err => {
                        //upload image
                        let data = new FormData();
                        data.append("file", image);
                        data.append("filename", "myfile");
                        data.append(
                            "oldPath",
                            this.state.imagePath ? this.state.imagePath : ""
                        );

                        let { apiBasePath, apiRoutes } = this.context.CONSTANTS;
                        let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
                            headers: {
                                // 'Content-type':undefined
                            },
                            method: "POST",
                            body: data
                        }).catch(err => console.error(err));

                        let response = await uploadSuccess
                            .json()
                            .catch(err => console.error(err));

                        if (!response.success) {
                            return toast.error("Failed to upload image");
                         }
                        this.setState({
                            restGalleryImages: [...this.state.restGalleryImages, response.data]
                        })
                        rImages.push(response.data)
                        imgArray.push(response.data)
                        
                        /*   this.setState({
                              imagePath: response.data,
                              displayImage: "",
                              oldImagePath: this.state.imagePath
                          }); */
                    }
                         
                )
                

            }

        }
        this.setState({
            file: imgArray,

        })

        //==================================


    }
    previewBackgroundImage=(e)=>{
            let image = e.nativeEvent.target.files[0];
            if (image.type.split("/")[0] != "image") {
              e.target.value = null;
        
              return toast.error("Please input a valid image file", {
                autoClose: 2000
              });
            }
            this.setState(
              {
                displayImage: URL.createObjectURL(image)
              },
              async err => {
                //upload image
                let data = new FormData();
                data.append("file", image);
                data.append("filename", "myfile");
                let { apiBasePath, apiRoutes, serverBasePath } = this.context.CONSTANTS;
                this.setState({
                  uploadProgress: true
                });
                let uploadSuccess = await fetch(apiBasePath + apiRoutes.uploadFile, {
                  headers: {
                    // 'Content-type':undefined
                  },
                  method: "POST",
                  body: data
                }).catch(err => console.error(err));
                let response = await uploadSuccess
                  .json()
                  .catch(err => console.error(err));
                this.setState({
                  uploadProgress: false
                });
                if (!response.success) {
                  return toast.error("Failed to upload image");
                }
                this.setState({
                  restBackgroundImage: response.data,
                  displayImage: "",
                });
              }
            );
          

    }
    onClickSaveChanges() {
      /*   this.state.restaurantImages.map(imgPath => {
            this._submitRestaurantImages(imgPath)
        }) */
       this._submitRestaurantImages();
    }
    async _submitRestaurantImages() {
        let  restaurantImages = {rBackgroundImg:"",rGalleryImages:[]}
        restaurantImages.rBackgroundImg=this.state.restBackgroundImage
        restaurantImages.rGalleryImages=[...this.state.restGalleryImages]
        let { CONSTANTS, authToken } = this.context
        let body = {
            
            restarant_id: this.context.user.restarantId,
            restarantSubImages: restaurantImages
        }
       // let apiRoute = CONSTANTS.apiRoutes.addRestaurantSubImages
       let apiRoute=CONSTANTS.apiRoutes.updateRestarantSubImages
        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "PUT",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while saving the changes"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
           
        }
        else if(savedResponseResult.success){
            return toast.success("Succesfully saved images.");
        }

    }


    async _loadRestImages() {
        if (this.state.fetchProgress) return false;
        this.setState({
            fetchProgress: true,
            baseUrl: this.context.CONSTANTS.apiBasePath.split("/api")[0]
        });
        const requestSendTime = Date.now();
        let fetchResponse = await fetch(
            this.context.CONSTANTS.apiBasePath +
            this.context.CONSTANTS.apiRoutes.getRestarantDetails +
            `?restarant_id=${this.context.user.restarantId}&user_id=${this.context.user.userId}`,
            { headers: { Authorization: this.context.authToken } }
        ).catch(err => console.error(err));
        if (!fetchResponse) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const restarantData = await fetchResponse.json(); //@TODO - handle error
        if (!restarantData.success) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("Could not load restaurant images.");
        }
        let rBackgroundImgData=restarantData.data.rSubImages.rBackgroundImg ? restarantData.data.rSubImages.rBackgroundImg : "";
        let rGalleryImgData=restarantData.data.rSubImages.rGalleryImages ? restarantData.data.rSubImages.rGalleryImages : []
        this.setState(
            {
                restBackgroundImage:rBackgroundImgData,
                restGalleryImages:rGalleryImgData
                // displayImage:this.context.CONSTANTS.apiBasePath + restarantData.data.restarantImage
            },

        );
  
    }

}
