import React, { Component } from "react";
import { AuthContext } from "../contextProvider";
import { toast } from "react-toastify";
import StarRatings from 'react-star-ratings';
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";
export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <FeedbackRating
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};
class FeedbackRating extends Component {
    state = {
        feedBackResponses: [],
        feedBacks: []
    }
    async componentDidMount() {
        await this.setState({
            startDate: new Date(),
            endDate: new Date()
        })
        await this._loadFeedBacks();
        await this._loadFeedbackResponses();
    }
    render() {
        let list = [];
        this.state.feedBackResponses.filter(function (f, k) { list.push(...f.fb_response_rating) });
        let responses = _.groupBy(list, "feedbackId");
        return (
            <div className="content">
                <div className="row mx-0 justify-content-between align-items-center">
                    <div className="">
                        <span className="categorycol">FeedBack Rating</span>
                    </div>
                    <div className="contentvthbtn">
                        <div className="row mx-0 justify-content-between">
                            <DateFilters
                                handleChangeEnd={this.handleChangeEnd.bind(this)}
                                handleChangeStart={this.handleChangeStart.bind(this)}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                            />
                        </div>
                    </div>
                    <div className="table-responsive mt-3 tableofauto">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>title</th>
                                    <th>overAll Rating</th>
                                    <th>count</th>
                                    <th>Total Responses</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.feedBacks.filter(f=> f.feedbackTitleType == 'Star')
                                .map((f, idx) => {
                                    let ratingList = 0;
                                    let countRes = 0
                                    _.forEach(responses, res => {
                                        if (res[0].feedbackId == f.feedbackId) {
                                            let rating = _.sumBy(res.filter(r => { return r.feedbackTitleType == 'Star' }), "feedBackAnswer");
                                            let total = res.filter(r => { return r.feedBackAnswer })
                                            ratingList = rating ? rating / total.length : 0;
                                            countRes = total.length;
                                        }
                                    })
                                    return <tr key={String(idx)}>
                                        <td>{idx + 1}</td>
                                        <td>{f.feedbackTitle}</td>
                                        <td>
                                            <StarRatings
                                                rating={ratingList}
                                                starRatedColor="#1f74f4"
                                                numberOfStars={5}
                                                starDimension='30px'
                                                starSpacing='5px'
                                            />
                                            <h6 style={{ color: 'orange' }}>{ratingList.toFixed(2)}</h6>
                                        </td>
                                        <td>{countRes}</td>
                                        <td>{this.state.feedBackResponses.length}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="feedBackModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Feedback rating
                                  </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span className="closeicon" aria-hidden="true">
                                        &times;
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.response && this.state.response.map(r => {
                                  return  <div>{r.rating}</div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    handleChangeStart(val) {
        this.setState(
            {
                startDate: val
            },
            () => this._loadFeedbackResponses()
        );
    }
    handleChangeEnd(val) {
        this.setState(
            {
                endDate: val
            },
            err => {
                this._loadFeedbackResponses()
            }
        );
    }
    async _loadFeedbackResponses() {
        this.setState({
            fetchProgress: true
        });
        const filters =
            this.state.startDate && this.state.endDate
                ? { startDate: this.state.startDate, endDate: this.state.endDate }
                : null;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const feedBackResponse = await fetch(
            apiBasePath +
            apiRoutes.getFeedBackResponses +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}&start_date=${filters
                ? filters.startDate
                    ? moment(filters.startDate)
                        .utc(true)
                        .startOf("day")
                        .toISOString()
                    : ""
                : ""
            }&end_date=${filters
                ? filters.endDate
                    ? moment(filters.endDate)
                        .utc(true)
                        .endOf("day")
                        .toISOString()
                    : ""
                : ""
            }`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!feedBackResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const feedBackResponseResult = await feedBackResponse.json();
        if (!feedBackResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!feedBackResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: feedBackResponseResult.msg
            });
            return toast.error(feedBackResponseResult.msg);
        }
        feedBackResponseResult.data.map(d => {
            d.fb_response_rating = JSON.parse(d.fb_response_rating)
        })
        this.setState({
            feedBackResponses: feedBackResponseResult.data,
            fetchProgress: false,
        });

    }
    async _loadFeedBacks() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const feedBackResponse = await fetch(
            apiBasePath +
            apiRoutes.getFeedBacks +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}&restaurant_id=${this.props.user.restarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!feedBackResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const feedBackResponseResult = await feedBackResponse.json();
        if (!feedBackResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!feedBackResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: feedBackResponseResult.msg
            });
            return toast.error(feedBackResponseResult.msg);
        }

        this.setState({
            feedBacks: feedBackResponseResult.data,
            fetchProgress: false,
        });

    }
}

function DateFilters({
    handleChangeEnd,
    handleChangeStart,
    startDate,
    endDate
}) {
    return (
        <>
            <DatePicker
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                className="form-control"
                onChange={handleChangeStart}
                isClearable={true}
                placeholderText="Starting from..."
            />
            <span style={{ textAlign: "center", width: "20px" }}>-</span>
            <DatePicker
                selected={endDate}
                selectsEnd
                minDate={startDate}
                maxDate={new Date()}
                className="form-control"
                startDate={startDate}
                endDate={endDate}
                onChange={handleChangeEnd}
                isClearable={true}
                placeholderText="...to"
            />
        </>
    );
};
