import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Checkbox } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import orderType from "./orderType";
import { Multiselect } from "multiselect-react-dropdown";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <Charges
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class Charges extends Component {
    state = {
        charges: [],
        totalCharges: 0,
        currentPageNum: 1,
        recordsPerPage: 10,
        fetchProgress: true,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        noDataMsg: "No Charges Yet.",
        selectedChargeId: null,
        aggregatorPlatforms: "",
        excludedPlatforms: [],
        fullfillmentModes: "",
        fModes: [],
        allCharges: [],
        itemsData: [],
        selectedItemIdsList: [],
        chCode: "",
    };

    componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        let platformList = [];
        let list = [];
        list = Object.keys(this.props.CONSTANTS.aggregatorPlatforms);
        for (var i = 0; i < list.length; i++) {
            platformList.push({
                label: list[i],
                value: i + 1
            });
        }
        let modesList = [];
        let fList = [];
        fList = Object.keys(this.props.CONSTANTS.fullfillmentModes);
        for (var i = 0; i < fList.length; i++) {
            modesList.push({
                label: fList[i],
                value: i + 1
            });
        }
        this.setState({
            aggregatorPlatforms: platformList || [],
            fullfillmentModes: modesList
        });
        this._loadCharges(true);
    }

    componentDidMount() {
        this._loadItemsData();
        $(this.modalUI.current).on("shown.bs.modal", () => {
            // if (this.modalRef.current.state.categories.length) return;
            // this._loadCategories();
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedChargeId: null
            });
            this.modalRef.current.formApi.reset();
        });
    }

    componentWillUnmount() {
        toast.dismiss();
    }

    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                            <>
                                <div className="row mx-0 justify-content-between align-items-center">
                                    <div className="">
                                        <span className="categorycol">Charges</span>
                                    </div>
                                    {/* <div>
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => {
                                                this._onSyncCharges()
                                            }}
                                        >
                                            Sync UP Charges
                                        </button>
                                    </div> */}
                                    <div className="">
                                        <button
                                            type="button"
                                            className="btnaddcategory float-right"
                                            data-toggle="modal"
                                            data-target="#chargeModal"
                                            onClick={() => {
                                                this.setState({ selectedItemIdsList: [] })
                                            }}
                                        >
                                            <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                        &nbsp; Add Charge
                                         </button>
                                    </div>
                                </div>
                                {this.state.charges.length ? (
                                    <div className="table-responsive mt-3 tableofauto">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Code</th>
                                                    <th> Title</th>
                                                    <th>Description</th>
                                                    <th>Status</th>
                                                    <th>Structure Value</th>
                                                    <th>Structure applicable</th>
                                                    {/* <th>Clear items</th> */}
                                                    <th className="actionWidth">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.charges.map((c, idx) => (
                                                    <tr key={String(idx)}>
                                                        <td>
                                                            {(this.state.currentPageNum - 1) *
                                                                this.state.recordsPerPage +
                                                                idx +
                                                                1}
                                                        </td>
                                                        <td>{c.chargeCode}</td>
                                                        <td>{c.chargeTitle}</td>
                                                        <td>
                                                            {c.chargeDescription
                                                                ? c.chargeDescription.length <= 50
                                                                    ? c.chargeDescription
                                                                    : c.chargeDescription.substr(0, 50) +
                                                                    "..."
                                                                : ""}
                                                        </td>
                                                        <td>{c.chargeActive ? "Active" : "InActive"}</td>
                                                        <td>{c.chargeStructureValue}</td>
                                                        <td>{c.chargeStructureApplicableOn == "item.quantity" ? "Item Quantity" : "Order subtotal"}</td>
                                                        {/* <td>{c.chargeClearItems ? "true" : "false"}</td> */}
                                                        <td>
                                                            <img
                                                                alt=""
                                                                src={EDIT_IMG}
                                                                data-toggle="modal"
                                                                data-target="#chargeModal"
                                                                className="icon"
                                                                onClick={() => {
                                                                    let list = [];
                                                                    list = this.props.CONSTANTS.aggregatorPlatforms
                                                                    //to update included pplatforms
                                                                    let exPlatList = c.chargeExcludedPlatforms ? c.chargeExcludedPlatforms.chargeExcludedPlatforms : [];
                                                                    let newExPlatsList = [];
                                                                    if (exPlatList != null) {
                                                                        exPlatList.map(c => {
                                                                            newExPlatsList.push({
                                                                                label: list[c].platName,
                                                                                value: list[c].platId
                                                                            });
                                                                        }
                                                                        )
                                                                    } else {
                                                                        exPlatList = [];
                                                                    }
                                                                    let fList = []
                                                                    fList = this.props.CONSTANTS.fullfillmentModes
                                                                    let fModesList = c.chargeFullfillmentMode ? c.chargeFullfillmentMode.chargeFullfillmentMode : [];
                                                                    let newFModesList = []
                                                                    if (fModesList != null) {
                                                                        fModesList.map(f => {
                                                                            newFModesList.push({
                                                                                label: fList[f].modeDescription,
                                                                                value: fList[f].modeId
                                                                            })
                                                                        })
                                                                    } else {
                                                                        fModesList = [];
                                                                    }
                                                                    let itemIds = c.chargeItemRefIds ? c.chargeItemRefIds.chargeItemRefIds : []
                                                                    this.setState({
                                                                        selectedChargeId: c.chargeId,
                                                                        excludedPlatforms: newExPlatsList,
                                                                        fModes: newFModesList,
                                                                        selectedItemIdsList: itemIds
                                                                    });
                                                                    this.modalRef.current.setState({
                                                                        editMode: true
                                                                    });
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "charge__code",
                                                                        c.chargeCode
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "charge__title",
                                                                        c.chargeTitle
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "charge__description",
                                                                        c.chargeDescription
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "charge__active",
                                                                        c.chargeActive
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "charge__structureValue",
                                                                        c.chargeStructureValue
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "charge__structureApplicableOn",
                                                                        c.chargeStructureApplicableOn
                                                                    );
                                                                    // this.modalRef.current.formApi.setValue(
                                                                    //     "charge__clearItems",
                                                                    //     c.chargeClearItems
                                                                    // );

                                                                    // this.modalRef.current.formApi.setValue(
                                                                    //     "charge__clearLocations",
                                                                    //     c.chargeClearLocations
                                                                    // );
                                                                }}
                                                            />
                                                            {/* <img
                                                                alt=""
                                                                src={DELETE_IMG}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() =>
                                                                    this.confirmAndDelete(
                                                                        c.chargeId,
                                                                        c.chargeTitle
                                                                    )
                                                                }
                                                            /> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                        <div className="noDataMsgContainer">
                                            <h5>{this.state.noDataMsg}</h5>
                                        </div>
                                    )}
                            </>
                        )}
                </div>
                {this._pagination()}
                <ChargeModal
                    ref={this.modalRef}
                    submitChargeForm={this._submitChargeForm.bind(this)}
                    modalUiRef={this.modalUI}
                    setExcludedPlats={this.excludedPlatsCallbackFunc.bind(this)}
                    setFModes={this.fModesCallBackFunc.bind(this)}
                    aggregatorPlatforms={this.state.aggregatorPlatforms}
                    excludedPlatInitVal={this.state.excludedPlatforms}
                    fullfillmentModes={this.state.fullfillmentModes}
                    fModesInitVal={this.state.fModes}
                    itemsData={this.state.itemsData}
                    selectedItemsData={this.state.selectedItemIdsList}
                    setSelectedItemsData={(itemIdList) => { this.setState({ selectedItemIdsList: itemIdList }) }}
                />
            </>
        );
    }
    excludedPlatsCallbackFunc = (exPlats) => {
        this.setState({ excludedPlatforms: exPlats })
    }
    fModesCallBackFunc = (fModes) => {
        this.setState({ fModes: fModes })
    }
    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totalCharges / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totalCharges / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadCharges(true)
            }
        );
    }
    async _onSyncCharges() {
        await this._loadCharges(false);
        if (this.state.saveProgress) {
            return false;
        }
        const { upApiBasePath } = this.props.CONSTANTS
        let charges = this.state.allCharges.map((c, index) => {
            return {
                code: c.chargeCode,
                title: c.chargeTitle,
                description: c.chargeDescription,
                active: c.chargeActive,
                structure: {
                    applicable_on: c.chargeStructureApplicableOn,
                    value: c.chargeStructureValue
                },
                fulfillment_modes: c.chargeFullfillmentMode.chargeFullfillmentMode,
                excluded_platforms: c.chargeExcludedPlatforms.chargeExcludedPlatforms,
                item_ref_ids: c.chargeItemRefIds ? c.chargeItemRefIds.chargeItemRefIds : []
            }
        })
        this.setState({
            saveProgress: true
        });
        let body = {
            charges: charges
        }
        const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/locations/" + this.props.user.restarantId + "/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (savedResponseResult.status == "error") {
            this.setState({
                saveProgress: false
            });
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference)
            setTimeout(() => {
                toast.success(
                    "Successfully synched"
                );
            }, 1500);
            return toast.success(savedResponseResult.message);
        }
    }
    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: restId,
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanCatalogueResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while saving the webhook response"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
        this.setState({
            saveProgress: false
        });
    }
    async _loadCharges(limit) {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const chargeResponse = await fetch(
            apiBasePath +
            apiRoutes.getCharges +
            `?user_id=${this.props.user.userId}&limit=${
            limit ? this.state.recordsPerPage : 0
            }&offset=${limit ? (this.state.currentPageNum - 1) *
                this.state.recordsPerPage : 0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!chargeResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const chargeResponseResult = await chargeResponse.json();
        if (!chargeResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!chargeResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: chargeResponseResult.msg
            });
            return toast.error(chargeResponseResult.msg);
        }
        limit ?
            throttleTime(
                () => {
                    this.setState({
                        charges: chargeResponseResult.data,
                        totalCharges: chargeResponseResult.total,
                        fetchProgress: false,
                    });
                },
                requestSendTime,
                500
            ) :
            this.setState({
                fetchProgress: false,
                allCharges: chargeResponseResult.data
            })
    }

    async _submitChargeForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let chargeId = this.state.selectedChargeId;
        let selectedexcludedPlats = this.state.excludedPlatforms;
        let exPlatforms = []
        for (let i = 0; i < selectedexcludedPlats.length; i++) {
            if (selectedexcludedPlats[i].value) {
                exPlatforms[i] = selectedexcludedPlats[i].label;
            } else {
                exPlatforms[i] = selectedexcludedPlats[i]
            }
        }
        let selectedFModes = this.state.fModes;
        let fFModes = []
        for (let i = 0; i < selectedFModes.length; i++) {
            if (selectedFModes[i].value) {
                fFModes[i] = selectedFModes[i].label;
            } else {
                fFModes[i] = selectedFModes[i]
            }
        }
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath +
            (chargeId ? apiRoutes.editCharge : apiRoutes.createCharge),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: chargeId ? "PUT" : "POST",
                body: JSON.stringify({
                    charge_id: chargeId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId,
                    charge_title: formValues.charge__title.trim(),
                    charge_code: formValues.charge__code,
                    charge_description: formValues.charge__description && formValues.charge__description,
                    charge_active: formValues.charge__active,
                    charge_structure_value: formValues.charge__structureValue,
                    charge_structure_applicable_on: formValues.charge__structureApplicableOn,
                    // charge_clear_items: formValues.charge__clearItems,
                    // charge_clear_locations: formValues.charge__clearLocations,
                    charge_excluded_platforms: exPlatforms,
                    charge_fufillment_modes: fFModes,
                    charge_item_ref_ids: this.state.selectedItemIdsList
                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${
                chargeId ? "saving the changes" : "adding the charge "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${
                chargeId ? "saving the changes" : "adding the charge "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1,
                excludedPlatforms: [],
                fModes: [],
                selectedItemIdsList: []
            },
            err => {
                this._loadCharges(true)
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${
            chargeId ? "saved the changes" : "added " + formValues.charge__code
            }.`,
            { autoClose: 4000 }
        );
    }

    confirmAndDelete(cId, name) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${name || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteCharge(cId)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }
    async _loadItemsData() {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const itemsResponse = await fetch(
            apiBasePath +
            apiRoutes.getMenuItems +
            `?user_id=${this.props.user.userId}&limit=0&offset=0&restarant_id=${this.props.user.selectedRestarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!itemsResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const itemsResponseResult = await itemsResponse.json();
        if (!itemsResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!itemsResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: itemsResponseResult.msg
            });
            return toast.error(itemsResponseResult.msg);
        }
        let itemIdTitleList = itemsResponseResult.data.map(i => {
            return {
                label: i.itemTitle,
                value: i.itemId
            };
        });

        this.setState({
            itemsData: itemIdTitleList
        });

    }
    async _deleteCharge(cId) {
        if (this.state.deleteProgress) {
            return false;
        }

        this.setState({
            deleteProgress: true
        });
        const charge = this.state.charges.find(
            i => i.chargeId == cId
        );
        const chargeCode = charge.chargeCode;

        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deleteCharge,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "DELETE",
                body: JSON.stringify({
                    charge_id: cId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the charge.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the charge.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: "",
            currentPageNum:
                this.state.charges.length > 1
                    ? this.state.currentPageNum
                    : this.state.currentPageNum - 1 > 0
                        ? this.state.currentPageNum - 1
                        : 1
        });
        this._loadCharges(true);
        toast.success(`Deleted ${chargeCode}`, { autoClose: 5000 });
    }
}

class ChargeModal extends Component {
    state = {
        editMode: false,
        saveProgress: false,
        chCode: ""
    };

    onSelect_excludedPlats = (selectedList, selectedItem) => {
        let selectedValues_excluded = [];
        let i = 0;
        for (i = 0; i < selectedList.length; i++) {
            selectedValues_excluded[i] = { label: selectedList[i].label, value: selectedList[i].value }
        }

        this.props.setExcludedPlats(selectedValues_excluded)
    }
    onRemove_excludedPlats = (selectedList) => {
        this.props.setExcludedPlats(selectedList)

    }
    onSelect_fModes = (selectedList, selectedItem) => {
        let selectedValues = [];
        let i = 0;
        for (i = 0; i < selectedList.length; i++) {
            selectedValues.push({ label: selectedList[i].label, value: selectedList[i].value })
        }
        this.props.setFModes(selectedValues)
    }
    onRemove_fModes = (selectedList, selectedItem) => {
        this.props.setFModes(selectedList)
    }
    onItemsDataChange = (selected) => {
        this.props.setSelectedItemsData(selected)
    };
    render() {
        const { modalUiRef, submitChargeForm } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="chargeModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit Charge"
                                    : "Add Charge"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitChargeForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {
                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="row mb-sm-3">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group required">
                                                        <label>Code
                                                        <Select
                                                                field="charge__code"
                                                                validateOnBlur
                                                                validateOnChange
                                                                validate={val => {
                                                                    this._validateSelectChargeCode(val, "ChargeCode")
                                                                    if (!val) return "Please select charge code.";
                                                                }}
                                                                className={
                                                                    formState.errors.charge__code
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }

                                                            >
                                                                {/* @todo - dynamic values */}
                                                                <option >-select Charge Code-</option>
                                                                <option >PC_F</option>
                                                                <option >PC_P</option>
                                                                <option >DC_F</option>
                                                                <option >DC_P</option>
                                                            </Select>
                                                            {formState.errors.charge__code && (
                                                                <div className="input_error">
                                                                    {formState.errors.charge__code}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> title
                                                        <Select
                                                                disabled
                                                                field="charge__title"
                                                                validateOnBlur
                                                                validateOnChange
                                                                validate={val => {
                                                                    if (!val) return "Please select charge title.";
                                                                }}
                                                                className={
                                                                    formState.errors.charge__title
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }

                                                            >
                                                                {/* @todo - dynamic values */}
                                                                <option >-select Charge title-</option>
                                                                <option >Packaging charge</option>
                                                                <option >Delivery Charge</option>
                                                            </Select>
                                                            {formState.errors.charge__title && (
                                                                <div className="input_error">
                                                                    {formState.errors.charge__title}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> charge value
                                                        <Text
                                                                validate={val => {
                                                                    if (!val) return "please enter charge value"
                                                                    if (val && !new RegExp(/^[0-9.]+$/).test(val)) return "please input a valid number";
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "Item Sort Order"
                                                                    );
                                                                    if (this.state.chCode == "PC_P" || this.state.chCode == "DC_P") {
                                                                        if (Number(val) > 100) {
                                                                            return "charge value cannot be more than 100%.";
                                                                        }
                                                                    }
                                                                    if (this.state.chCode == "PC_F" || this.state.chCode == "DC_F") {
                                                                        if (Number(val) > 20000) {
                                                                            return "charge value cannot be more than 20000.";
                                                                        }
                                                                    }

                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"charge__structureValue"}
                                                                type="text"
                                                                placeholder="Enter structure value"
                                                                className={
                                                                    formState.errors.charge__structureValue
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.charge__structureValue && (
                                                                <div className="input_error">
                                                                    {formState.errors.charge__structureValue}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> charge applicable
                                                        <Select
                                                                field="charge__structureApplicableOn"
                                                                validateOnBlur
                                                                validateOnChange
                                                                validate={val => {
                                                                    if (!val) return "Please select charge code.";
                                                                }}
                                                                className={
                                                                    formState.errors.charge__structureApplicableOn
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }

                                                            >
                                                                {/* @todo - dynamic values */}
                                                                <option value="">-select charge applicable-</option>
                                                                <option value="item.quantity" >Item Quantity</option>
                                                                <option value="order.order_subtotal">Order subtotal</option>

                                                            </Select>
                                                            {formState.errors.charge__structureApplicableOn && (
                                                                <div className="input_error">
                                                                    {formState.errors.charge__structureApplicableOn}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-6 col-md-6 col-lg-5 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="charge__active"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Active<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-7 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="charge__active"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="charge__clearItems"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Clear items  <span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="charge__clearItems"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="charge__clearLocations"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Clear Locations  <span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="charge__clearLocations"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6" />
                                                <div className="col-12 col-sm-6 col-md-12 col-lg-12">
                                                    <label> fullfillmentModes
                                                        <Multiselect
                                                            field="rFullfillmentModes"
                                                            options={this.props.fullfillmentModes}
                                                            displayValue="label"
                                                            onSelect={this.onSelect_fModes}
                                                            onRemove={this.onRemove_fModes}
                                                            selectedValues={this.props.fModesInitVal}
                                                            showCheckbox={true}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-12 col-lg-12">
                                                    <label> excludedPlatforms
                                                        <Multiselect
                                                            field="rExcludedPlatforms"
                                                            options={this.props.aggregatorPlatforms}
                                                            displayValue="label"
                                                            onSelect={this.onSelect_excludedPlats}
                                                            onRemove={this.onRemove_excludedPlats}
                                                            selectedValues={this.props.excludedPlatInitVal}
                                                            showCheckbox={true} />
                                                        {formState.errors.charge__excludedPlatforms && (
                                                            <div className="input_error">
                                                                {formState.errors.charge__excludedPlatforms}
                                                            </div>
                                                        )}
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="form-group"  >
                                                    <label>Item Ref Ids </label>
                                                    <DualListBox
                                                        options={this.props.itemsData}
                                                        selected={this.props.selectedItemsData}
                                                        onChange={this.onItemsDataChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <TextArea
                                                    validate={val => {

                                                        if (Number(val) > 500) {
                                                            return "charge  Description cannot be more than 500 Characters.";
                                                        }
                                                    }
                                                    }
                                                    // validate={val =>
                                                    //     validateText(
                                                    //         val,
                                                    //         10,
                                                    //         formState.touched.charge__description,
                                                    //         "charge  Description",
                                                    //         400
                                                    //     )
                                                    // }
                                                    rows={5}
                                                    validateOnBlur
                                                    validateOnChange
                                                    field={"charge__description"}
                                                    className={
                                                        formState.errors.charge__description
                                                            ? "form-control input_error"
                                                            : "form-control"
                                                    }
                                                    placeholder="Enter  description"
                                                />
                                                {formState.errors.charge__description && (
                                                    <div className="input_error">
                                                        {formState.errors.charge__description}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    _setFormApi(formApi) {
        this.formApi = formApi;
    }
    _validateSelectInput(val, name) {
        if (!val) {
            return `Select ${name}.`;
        }
    }
    _validateSelectChargeCode(val, name) {
        if (!val) {
            return `Select ${name}.`;
        }
        if (val == "PC_F" || val == "PC_P") {
            this.formApi.setValue("charge__title", "Packaging charge")
        }
        else {
            this.formApi.setValue("charge__title", "Delivery Charge")
        }
        this.setState({
            chCode: val
        })

    }

}