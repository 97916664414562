import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Radio, RadioGroup } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker"
import CustomMessagesFooter from "./customMsgFooter"

export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
                return (
                    <DataContext.Consumer>
                        {({ fetchPaytmIntegration }) => {
                            return (
                                <PaytmIntegration
                                    user={user}
                                    authToken={authToken}
                                    CONSTANTS={CONSTANTS}
                                    isSuperAdmin={isSuperAdmin}
                                    fetchPaytmIntegration={fetchPaytmIntegration}
                                />
                            );
                        }}
                    </DataContext.Consumer>
                );
            }}
        </AuthContext.Consumer>
    );
};

class PaytmIntegration extends Component {

    state = {
        PaytmIntegration: [],
        fetchProgress: false,
        noDataMsg: "No Paytm Details Yet.",
        totalPaytmIntegration: 0,
        currentPageNum: 1,
        recordsPerPage: 5,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        selectedPaytmIntegrationId: null,

    }

    componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        this._loadPaytmIntegration();
    }

    componentDidMount() {
        this._loadPaytmIntegration();
        $(this.modalUI.current).on("shown.bs.modal", () => {
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedPaytmIntegrationId: null
            });
            this.modalRef.current.formApi.reset();
        });
    }

    componentWillUnmount() {
        toast.dismiss();
    }

    render() {
        const paytmActive = this.state.PaytmIntegration.filter(function (d) { return d.status == 'Active' })
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                            <>
                                <div className="row mx-0 justify-content-between align-items-center">
                                    <div className="">
                                        <span className="categorycol">Paytm Integration</span>
                                    </div>
                                    <div className="">
                                        <button
                                            // disabled = {this.state.PaytmIntegration.length>2}
                                            type="button"
                                            className="btnaddcategory float-right"
                                            data-toggle="modal"
                                            data-target="#paytmIntegrationModal"
                                        >
                                            <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                            &nbsp; Add Paytm Details
                        </button>
                                    </div>
                                </div>
                                {this.state.PaytmIntegration.length ? (
                                    <div className="table-responsive mt-3 tableofauto">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Name</th>
                                                    <th>Merchant Id</th>
                                                    <th> Merchant Key</th>
                                                    <th>Status(on/off)</th>
                                                    <th className="actionWidth">Action(edit/delete)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.PaytmIntegration.map((pi, idx) => (
                                                    <tr key={String(idx)}>
                                                        <td>
                                                            {(this.state.currentPageNum - 1) *
                                                                this.state.recordsPerPage +
                                                                idx +
                                                                1}
                                                        </td>
                                                        <td>{pi.accountName}</td>
                                                        <td>{pi.merchantId}</td>
                                                        <td>{pi.merchantKey}</td>
                                                        <td>{pi.status}</td>
                                                        <td>
                                                            <img
                                                                alt=""
                                                                src={EDIT_IMG}
                                                                data-toggle="modal"
                                                                data-target="#paytmIntegrationModal"
                                                                className="icon"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        selectedPaytmIntegrationId: pi.paytmDetailsId
                                                                    });
                                                                    this.modalRef.current.setState({
                                                                        editMode: true
                                                                    });
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "merchant_id",
                                                                        pi.merchantId
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "merchant_key",
                                                                        pi.merchantKey
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "account_name",
                                                                        pi.accountName
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "status",
                                                                        pi.status
                                                                    );
                                                                }}
                                                            />
                                                            <img
                                                                alt=""
                                                                src={DELETE_IMG}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() =>
                                                                    this.confirmAndDelete(
                                                                        pi.paytmDetailsId,
                                                                        pi.accountName,

                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                        <div className="noDataMsgContainer">
                                            <h5>{this.state.noDataMsg}</h5>
                                        </div>
                                    )}
                            </>
                        )}
                    {this._pagination()}
                    <PaytmIntegrationModal
                        ref={this.modalRef}
                        paytmActive={paytmActive}
                        submitPaytmIntegrationForm={this._submitPaytmIntegrationForm.bind(this)}
                        modalUiRef={this.modalUI}
                    />
                </div>
            </>
        )
    }

    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totalPaytmIntegration / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totalPaytmIntegration / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadPaytmIntegration();
            }
        );
    }

    async _submitPaytmIntegrationForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let PaytmIntId = this.state.selectedPaytmIntegrationId;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath +
            (PaytmIntId ? apiRoutes.editPaytmDetails : apiRoutes.createPaytmDetails),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: PaytmIntId ? "PUT" : "POST",
                body: JSON.stringify({
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId,
                    merchant_id: formValues.merchant_id,
                    account_name: formValues.account_name,
                    status: formValues.status,
                    merchant_key: formValues.merchant_key,
                    paytm_details_id: PaytmIntId,

                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${
                PaytmIntId ? "saving the changes" : "adding the merchant_id"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${
                PaytmIntId ? "saving the changes" : "adding the merchant_id"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1
            },
            err => {
                this._loadPaytmIntegration();
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${
            PaytmIntId ? "saved the changes" : "added " + "Paytm details" + formValues.merchant_id
            }.`,
            { autoClose: 4000 }
        );
    }

    async _loadPaytmIntegration() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const PaytmResponse = await fetch(
            apiBasePath +
            apiRoutes.getPaytmDetails +
            `?user_id=${this.props.user.userId}
            &limit=${
            this.state.recordsPerPage
            }&offset=${(this.state.currentPageNum - 1) *
            this.state.recordsPerPage}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!PaytmResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const PaytmResult = await PaytmResponse.json();
        if (!PaytmResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!PaytmResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: PaytmResult.msg
            });
            return toast.error(PaytmResult.msg);
        }
        throttleTime(
            () => {
                this.setState({
                    PaytmIntegration: PaytmResult.data,
                    totalPaytmIntegration: PaytmResult.total,
                    fetchProgress: false
                });
            },
            requestSendTime,
            500
        );
    }

    confirmAndDelete(paytmId, name) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${name || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteCustomMsg(paytmId, name)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }

    async _deleteCustomMsg(paytmId, name) {
        if (this.state.deleteProgress) {
            return false;
        }
        this.setState({
            deleteProgress: true
        });
        const paytm = this.state.PaytmIntegration.find(
            i => i.paytmDetailsId == paytmId
        );
        //   const headerMessageTitle = CustomMsg.headerMessageTitle;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deletePaytmDetails,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "PUT",
                body: JSON.stringify({
                    paytm_details_id: paytmId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the paytm details.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the paytm detail.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: "",
            currentPageNum:
                this.state.PaytmIntegration.length > 1
                    ? this.state.currentPageNum
                    : this.state.currentPageNum - 1 > 0
                        ? this.state.currentPageNum - 1
                        : 1
        });
        this._loadPaytmIntegration();
        toast.success(` successfully Deleted  ${name}`, { autoClose: 5000 });
    }

}

//  create form for headerCustomMsgs
class PaytmIntegrationModal extends Component {
    state = {
        editMode: false,
        saveProgress: false,
    };

    render() {
        const { modalUiRef, submitPaytmIntegrationForm, paytmActive } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="paytmIntegrationModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit  Paytm Details"
                                    : "Add Paytm Details"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                            </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitPaytmIntegrationForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {
                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="row mb-sm-3">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val =>
                                                                validateText(
                                                                    val,
                                                                    3,
                                                                    formState.touched.account_name,
                                                                    "account_name",
                                                                    50
                                                                )
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            field={"account_name"}
                                                            type="text"
                                                            placeholder="Enter account name"
                                                            className={
                                                                formState.errors.account_name
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.account_name && (
                                                            <div className="input_error">
                                                                {formState.errors.account_name}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* message title  */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val =>
                                                                validateText(
                                                                    val,
                                                                    3,
                                                                    formState.touched.merchant_id,
                                                                    "merchant_id",
                                                                    30
                                                                )
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            field={"merchant_id"}
                                                            type="text"
                                                            placeholder="Merchant Id"
                                                            className={
                                                                formState.errors.merchant_id
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.merchant_id && (
                                                            <div className="input_error">
                                                                {formState.errors.merchant_id}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* msg full text */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val =>
                                                                validateText(
                                                                    val,
                                                                    3,
                                                                    formState.touched.merchant_key,
                                                                    "merchant_key",
                                                                    50
                                                                )
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            field={"merchant_key"}
                                                            type="text"
                                                            placeholder="Enter Merchant Key"
                                                            className={
                                                                formState.errors.merchant_key
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.merchant_key && (
                                                            <div className="input_error">
                                                                {formState.errors.merchant_key}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Select
                                                            field="status"
                                                            validate={val =>
                                                                this._validateStatus(val, paytmActive)
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            className={
                                                                formState.errors.status
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        >

                                                            <option value="" disabled>
                                                                - Select Status -
                                                              </option>
                                                            <option >Active</option>
                                                            <option>InActive</option>
                                                        </Select>
                                                        {formState.errors.status && (
                                                            <div className="input_error">
                                                                {formState.errors.status}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    _setFormApi(formApi) {
        this.formApi = formApi;
    }
    _validateStatus(val, paytmActive) {
        if (val === "Active" && paytmActive.length)
            return `You have to choose only one Active.`;

    }
}