import React, { Component, PureComponent } from "react";
import {
  fetchMenuItems,
  submitMenuItemForm,
  deleteItem,
  submitTaxPercentage,
  getRestarantTaxRate
} from "../services";

export const MenuItemsContext = React.createContext({
  selectedItemId: null,
  fetchMenuItems,
  recordsPerPage: 10,
  menuItems: [],
  currentPageNum: 1,
  totalItems: 0,
  itemsLoadingProgress: true,
  setSelectedItemId: function(id) {
    this.selectedItemId = id;
  },
  taxRate: 0,
  discountRate:0,
  setCurrentPageNum: function(pageNum) {
    this.currentPageNum = pageNum;
  },
  deleteItem,
  submitMenuItemForm,
  user: null,
  authToken: "",
  CONSTANTS: null,
  isSuperAdmin: false,
  setAboveValues: function({ user, authToken, CONSTANTS, isSuperAdmin }) {
    this.user = user;
    this.authToken = authToken;
    this.CONSTANTS = CONSTANTS;
    this.isSuperAdmin = isSuperAdmin;
  },
  getRestarantTaxRate,
  submitTaxPercentage
});

// export class MenuItemsContextProvider extends PureComponent {
//   state = {
//     items: [],
//   };

//   async componentWillMount(){
//     const itemsResult = await fetchMenuItems({
//       CONSTANTS: this.props.CONSTANTS,
//       userId: this.props.user.userId,
//       limit: 0,
//       offset: 0,
//       authToken: this.props.authToken
//     })
//     .catch(err => console.error(err));
//     if(!itemsResult){
//       let msg = "An Error Occured while fetching sub-categories.";
//        this.setState({
//          fetchProgress: false,
//          noDataMsg: msg
//        });
//        return alert(msg);
//    }

//    if(!itemsResult.success){
//      let msg = itemsResult.msg;
//      this.setState({
//        fetchProgress: false,
//        noDataMsg: msg,
//        items:[]
//      });
//    }

//    this.setState({
//      items:itemsResult.data
//    })
//   }

//   render() {
//     return (
//       <MenuItemsContext.Provider value={{items:this.state.items,fetchMenuItems}}>
//         {this.props.children}
//       </MenuItemsContext.Provider>
//     );
//   }
// }
