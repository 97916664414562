import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Radio, RadioGroup } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker"

export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
                return (
                    <DataContext.Consumer>
                        {({ fetchCustomMessagesFooter }) => {
                              return (
                                <CustomMessagesFooter
                                  user={user}
                                  authToken={authToken}
                                  CONSTANTS={CONSTANTS}
                                  isSuperAdmin={isSuperAdmin}
                                  fetchCustomMessagesFooter={fetchCustomMessagesFooter}
                                />
                              );
                        }}
                    </DataContext.Consumer>
                );
            }}
        </AuthContext.Consumer>
    );
};

class CustomMessagesFooter extends Component {

    state = {
        CustomMessagesFooter: [],
        fetchProgress: false,
        noDataMsg: "No Custom-Messages Yet.",
        totalCustomMsgs: 0,
        currentPageNum: 1,
        recordsPerPage: 5,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        selectedCustomMsgId: null,

    }

    componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        this._loadCustomMsgs();
    }

    componentDidMount() {
        this._loadCustomMsgs();
        $(this.modalUI.current).on("shown.bs.modal", () => {
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedCustomMsgId: null
            });
            this.modalRef.current.formApi.reset();
        });
    }

    componentWillUnmount() {
        toast.dismiss();
    }

    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                            <>
                                <div className="row mx-0 justify-content-between align-items-center">
                                    <div className="">
                                        <span className="categorycol">Custom-Messages Footer</span>
                                    </div>
                                    <div className="">
                                        <button
                                            type="button"
                                            className="btnaddcategory float-right"
                                            data-toggle="modal"
                                            data-target="#customMessagesFooterModal"
                                        >
                                            <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                            &nbsp; Add Custom-Messages footer
                        </button>
                                    </div>
                                </div>
                                {this.state.CustomMessagesFooter.length ? (
                                    <div className="table-responsive mt-3 tableofauto">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Message-Title</th>
                                                    <th> Message Full Text</th>
                                                    <th className="actionWidth">Action(edit/delete)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.CustomMessagesFooter.map((cm, idx) => (
                                                    <tr key={String(idx)}>
                                                        <td>
                                                            {(this.state.currentPageNum - 1) *
                                                                this.state.recordsPerPage +
                                                                idx +
                                                                1}
                                                        </td>
                                                        <td>{cm.footerMessageTitle}</td>
                                                        {/* <td>{cm.messagePosition}</td> */}
                                                        <td>{cm.footerMessageText}</td>
                                                        <td>
                                                            <img
                                                                alt=""
                                                                src={EDIT_IMG}
                                                                data-toggle="modal"
                                                                data-target="#customMessagesFooterModal"
                                                                className="icon"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        selectedCustomMsgId: cm.customFooterId
                                                                    });
                                                                    this.modalRef.current.setState({
                                                                        editMode: true
                                                                    });
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "footer_Message_title",
                                                                        cm.footerMessageTitle
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "footer_Message_full_text",
                                                                        cm.footerMessageText
                                                                    );
                                                                }}
                                                            />
                                                            <img
                                                                alt=""
                                                                src={DELETE_IMG}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() =>
                                                                    this.confirmAndDelete(
                                                                        cm.customFooterId,
                                                                        cm.footerMessageTitle,
                                                                         cm.footerMessageText
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                        <div className="noDataMsgContainer">
                                            <h5>{this.state.noDataMsg}</h5>
                                        </div>
                                    )}
                            </>
                        )}
                    {this._pagination()}
                    <CustomMessagesFooterModal
                        ref={this.modalRef}
                        submitCustomMsgForm={this._submitCustomMsgForm.bind(this)}
                        modalUiRef={this.modalUI}
                    />
                </div>
            </>
        )
    }

    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totalCustomMsgs / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totalCustomMsgs / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadCustomMsgs();
            }
        );
    }

    async _submitCustomMsgForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let CustomMsgId = this.state.selectedCustomMsgId;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath +
            (CustomMsgId ? apiRoutes.editCustomMessages : apiRoutes.createCustomMessages),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: CustomMsgId ? "PUT" : "POST",
                body: JSON.stringify({
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId,
                    footer_message_title: formValues.footer_Message_title,
                    //    : formValues.message_position.trim(),
                    footer_message_full_text: formValues.footer_Message_full_text,
                    custom_msg_id: CustomMsgId,

                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${
                CustomMsgId ? "saving the changes" : "adding the footer_Message_title"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${
                CustomMsgId ? "saving the changes" : "adding the footer_Message_title"
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1
            },
            err => {
                this._loadCustomMsgs();
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${
            CustomMsgId ? "saved the changes" : "added " + "customMsg" + formValues.footer_Message_title
            }.`,
            { autoClose: 4000 }
        );
    }

    async _loadCustomMsgs() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const CustomMsgsResponse = await fetch(
            apiBasePath +
            apiRoutes.getCustomMessagesFooter +
            `?user_id=${this.props.user.userId}
            &limit=${
            this.state.recordsPerPage
            }&offset=${(this.state.currentPageNum - 1) *
            this.state.recordsPerPage}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!CustomMsgsResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const CustomMsgsFooterResult = await CustomMsgsResponse.json();
        if (!CustomMsgsFooterResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!CustomMsgsFooterResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: CustomMsgsFooterResult.msg
            });
            return toast.error(CustomMsgsFooterResult.msg);
        }
        throttleTime(
            () => {
                this.setState({
                    CustomMessagesFooter: CustomMsgsFooterResult.data,
                    totalCustomMsgs: CustomMsgsFooterResult.total,
                    fetchProgress: false
                });
            },
            requestSendTime,
            500
        );
    }

    confirmAndDelete(footerMessageTitle, CustomMsgId) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${CustomMsgId || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteCustomMsg(footerMessageTitle, CustomMsgId)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }

    async _deleteCustomMsg(CustomMsgId) {
        if (this.state.deleteProgress) {
            return false;
        }
        this.setState({
            deleteProgress: true
        });
        const CustomMsg = this.state.CustomMessagesFooter.find(
            i => i.customFooterId == CustomMsgId
          );
          const footerMessageTitle = CustomMsg.footerMessageTitle;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deleteCustomMessages,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "DELETE",
                body: JSON.stringify({
                    custom_msg_id: CustomMsgId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the footerCustomTitle.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the FooterCustomTitle.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: "",
            currentPageNum:
                this.state.CustomMessagesFooter.length > 1
                    ? this.state.currentPageNum
                    : this.state.currentPageNum - 1 > 0
                        ? this.state.currentPageNum - 1
                        : 1
        });
        this._loadCustomMsgs();
        toast.success(` successfully Deleted  ${footerMessageTitle}`, { autoClose: 5000 });
    }

}

// create form for footerCustomMsgs
class CustomMessagesFooterModal extends Component {
    state = {
        editMode: false,
        saveProgress: false,
    };

    render() {
        const { modalUiRef, submitCustomMsgForm } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="customMessagesFooterModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit  footerCustomMsgs"
                                    : "Add footerCustomMsgs"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                            </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitCustomMsgForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {
                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="row mb-sm-3">
                                                {/* message title  */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val =>
                                                                validateText(
                                                                    val,
                                                                    3,
                                                                    formState.touched.footer_Message_title,
                                                                    "footer_Message_title",
                                                                    30
                                                                )
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            field={"footer_Message_title"}
                                                            type="text"
                                                            placeholder="Enter footer Message title"
                                                            className={
                                                                formState.errors.footer_Message_title
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.footer_Message_title && (
                                                            <div className="input_error">
                                                                {formState.errors.footer_Message_title}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* msg full text */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <Text
                                                            validate={val =>
                                                                validateText(
                                                                    val,
                                                                    3,
                                                                    formState.touched.footer_Message_full_text,
                                                                    "footer_Message_full_text",
                                                                    50
                                                                )
                                                            }
                                                            validateOnBlur
                                                            validateOnChange
                                                            field={"footer_Message_full_text"}
                                                            type="text"
                                                            placeholder="Enter footer message name"
                                                            className={
                                                                formState.errors.footer_Message_full_text
                                                                    ? "form-control input_error"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {formState.errors.footer_Message_full_text && (
                                                            <div className="input_error">
                                                                {formState.errors.footer_Message_full_text}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    _setFormApi(formApi) {
        this.formApi = formApi;
    }

   }