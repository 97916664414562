import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Radio, RadioGroup } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber,validateInput } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker"
import Coupons from "./coupons";

export default () => {
  return (
    <AuthContext.Consumer>
      {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
        return (
          <DataContext.Consumer>
            {({ fetchDiscounts }) => {
              return (
                <Discounts
                  user={user}
                  authToken={authToken}
                  CONSTANTS={CONSTANTS}
                  isSuperAdmin={isSuperAdmin}
                  fetchDiscounts={fetchDiscounts}
                />
              );
            }}
          </DataContext.Consumer>
        );
      }}
    </AuthContext.Consumer>
  );
};

class Discounts extends Component {

  state = {
    Discounts: [],
    dtype: "",
    fetchProgress: false,
    noDataMsg: "No Discounts Yet.",
    totalDiscounts: 0,
    currentPageNum: 1,
    recordsPerPage: 5,
    saveProgress: false,
    deleteProgress: false,
    saveFailMsg: "",
    deleteFailMsg: "",
    selectedDiscountId: null,
    // discounts: []
  }

  componentWillMount() {
    this.modalRef = React.createRef();
    this.modalUI = React.createRef();
    this._loadDiscounts();
  }

  componentDidMount() {
    this._loadDiscounts();
    $(this.modalUI.current).on("shown.bs.modal", () => {
    });

    $(this.modalUI.current).on("hidden.bs.modal", () => {
      this.modalRef.current.setState({
        editMode: false
      });
      this.setState({
        selectedDiscountId: null
      });
      this.modalRef.current.formApi.reset();
    });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
            <>
              <div className="row mx-0 justify-content-between align-items-center">
                <div className="">
                  <span className="categorycol">Discounts</span>
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btnaddcategory float-right"
                    data-toggle="modal"
                    data-target="#discountsModal"
                  >
                    <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                    &nbsp; Add Discounts
                  </button>
                </div>
              </div>
              {this.state.Discounts.length ? (
                <div className="table-responsive mt-3 tableofauto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Bill Amount</th>
                        <th>Discount-Type</th>
                        <th> Discount Value</th>
                        <th>Status(on/off)</th>
                        <th className="actionWidth">Action(edit/delete)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.Discounts.map((d, idx) => (
                        <tr key={String(idx)}>
                          <td>
                            {(this.state.currentPageNum - 1) *
                              this.state.recordsPerPage +
                              idx +
                              1}
                          </td>
                          <td>{d.billAmount}</td>
                          <td>{d.discountType}</td>
                          <td>{d.discountValue}</td>
                          <td>{d.status}</td>
                          <td>
                            <img
                              alt=""
                              src={EDIT_IMG}
                              data-toggle="modal"
                              data-target="#discountsModal"
                              className="icon"
                              onClick={() => {
                                this.setState({
                                  selectedDiscountId: d.discountId
                                });
                                this.modalRef.current.setState({
                                  editMode: true
                                });
                                this.modalRef.current.formApi.setValue(
                                  "bill_amount",
                                  d.billAmount
                                );
                                this.modalRef.current.formApi.setValue(
                                  "discount_type",
                                  d.discountType
                                );
                                this.modalRef.current.formApi.setValue(
                                  "discount_value",
                                  d.discountValue
                                );
                                this.modalRef.current.formApi.setValue(
                                  "discount_status",
                                  d.status
                                );
                                this.modalRef.current.formApi.setValue(
                                  "max_discount_value",
                                  d.discountValueMax
                                );
                                this.modalRef.current.formApi.setValue(
                                  "discountDescription",
                                  d.description
                                );
                                // this.modalRef.current.formApi.setValue(
                                //   "discount__id",
                                //   d.discountId
                                // );
                                //here we want to pass id
                              }}
                            />
                            <img
                              alt=""
                              src={DELETE_IMG}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.confirmAndDelete(
                                  d.billAmount,
                                  d.discountId
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="noDataMsgContainer">
                  <h5>{this.state.noDataMsg}</h5>
                </div>
              )}
            </>
          )}
          {this._pagination()}
          <DiscountsModal
            ref={this.modalRef}
            submitDiscountForm={this._submitDiscountForm.bind(this)}
            modalUiRef={this.modalUI}
          />
        </div>
        <Coupons />
      </>
    )
  }

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalDiscounts / this.state.recordsPerPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.state.totalDiscounts / this.state.recordsPerPage
            )}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this._getMore(data.selected + 1)}
            containerClassName={"pagination"}
            activeLinkClassName={"active"}
            forcePage={this.state.currentPageNum - 1}
          />
        ) : null}
      </div>
    );
  }

  _getMore(val) {
    this.setState(
      {
        currentPageNum: val
      },
      err => {
        this._loadDiscounts();
      }
    );
  }

  async _submitDiscountForm(formValues) {
    if (this.state.saveProgress) return false;
    this.modalRef.current.setState({
      saveProgress: true
    });
    let DiscountId = this.state.selectedDiscountId;
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    let body = {
      user_id: this.props.user.userId,
      restarant_id: this.props.user.selectedRestarantId,
      billAmount: formValues.bill_amount,
      discountType: formValues.discount_type.trim(),
      discountValue: formValues.discount_value,
      status: formValues.discount_status.trim(),
      discountValueMax: formValues.max_discount_value,
      discount_id: DiscountId,
      description: formValues.discountDescription
    }
    if (formValues.discount_type == "Amount") {
      body.discountValueMax = null;
    }
    const savedResponse = await fetch(
      apiBasePath +
      (DiscountId ? apiRoutes.editDiscounts : apiRoutes.createDiscounts),
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: DiscountId ? "PUT" : "POST",
        body: JSON.stringify(body)
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while ${DiscountId ? "saving the changes" : "adding the bill_amount"
        }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while ${DiscountId ? "saving the changes" : "adding the bill_amount"
        }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      this.modalRef.current.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.msg
      });
      return toast.error(savedResponseResult.msg);
    }

    this.modalRef.current.setState({
      saveProgress: false,
      saveFailMsg: ""
    });
    this.setState(
      {
        currentPageNum: 1
      },
      err => {
        this._loadDiscounts();
      }
    );
    $(this.modalUI.current).modal("hide");
    toast.success(
      `Successfully ${DiscountId ? "saved the changes" : "added " + "discount" + formValues.bill_amount
      }.`,
      { autoClose: 4000 }
    );
  }

  async _loadDiscounts() {
    this.setState({
      fetchProgress: true
    });
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const requestSendTime = Date.now();
    const DiscountsResponse = await fetch(
      apiBasePath +
      apiRoutes.getDiscounts +
      `?user_id=${this.props.user.userId}
            &limit=${this.state.recordsPerPage
      }&offset=${(this.state.currentPageNum - 1) *
      this.state.recordsPerPage}`,
      { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!DiscountsResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const DiscountsResult = await DiscountsResponse.json();
    if (!DiscountsResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!DiscountsResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: DiscountsResult.msg
      });
      return toast.error(DiscountsResult.msg);
    }
    throttleTime(
      () => {
        this.setState({
          Discounts: DiscountsResult.data,
          totalDiscounts: DiscountsResult.total,
          fetchProgress: false
        });
      },
      requestSendTime,
      500
    );
  }

  confirmAndDelete(billAmount, DiscountId) {
    confirmAlert({
      title: "Delete?",
      message: `Are you sure to delete ${billAmount || ""}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteDiscount(billAmount, DiscountId)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteDiscount(billAmount, DiscountId) {
    if (this.state.deleteProgress) {
      return false;
    }
    this.setState({
      deleteProgress: true
    });

    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const deletedResponse = await fetch(
      apiBasePath + apiRoutes.deleteDiscounts,
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: "DELETE",
        body: JSON.stringify({
          discount_id: DiscountId,
          user_id: this.props.user.userId,
          restarant_id: this.props.user.selectedRestarantId
        })
      }
    ).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the Discount.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the Dsicount.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    if (!deletedResponseResult.success) {
      this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });

      return toast.error(deletedResponseResult.msg);
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: "",
      currentPageNum:
        this.state.Discounts.length > 1
          ? this.state.currentPageNum
          : this.state.currentPageNum - 1 > 0
            ? this.state.currentPageNum - 1
            : 1
    });
    this._loadDiscounts();
    toast.success(` successfully Deleted Discount ${billAmount}`, { autoClose: 5000 });
  }

}

//  create form for discounts
class DiscountsModal extends Component {
  state = {
    editMode: false,
    saveProgress: false,
    discountPercentage: "",
    startDate: new Date(),
    endDate: "",
    dtype: ""
  };

  render() {
    const { modalUiRef, submitDiscountForm } = this.props;
    return (
      <div
        ref={modalUiRef}
        className="modal fade"
        id="discountsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.editMode
                  ? "Edit Discounts"
                  : "Add Discounts"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              onSubmit={submitDiscountForm}
              getApi={this._setFormApi.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row mb-sm-3">
                        {/* amount  */}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!val && !formState.touched.bill_amount) {
                                  return;
                                }
                                if (isNaN(val)) {
                                  return "Please Enter a valid amount.";
                                }
                                if (Number(val) > 20000) {
                                  return "Amount cannot be more than 20000.";
                                }
                                // if (!val) { return " amount cannot be empty"; }
                                let notPositive = validateWholeNumber(
                                  val,
                                  "Amount"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="bill_amount"
                              type="text"
                              placeholder="Enter Amount"
                              className={
                                formState.errors.bill_amount
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.bill_amount && (
                              <div className="input_error">
                                {formState.errors.bill_amount}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val =>
                                validateInput(
                                  val,
                                  3,
                                  formState.touched.discountDescription,
                                  "discount description",
                                  25
                                )
                              }
                              validateOnBlur
                              validateOnChange
                              field="discountDescription"
                              type="text"
                              placeholder="Enter Discount Description"
                              className={
                                formState.errors.discountDescription
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                            />
                            {formState.errors.discountDescription && (
                              <div className="input_error">
                                {formState.errors.discountDescription}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* discount type */}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Select
                              field="discount_type"
                              validate={val =>
                                this._validateSelectDiscountType(val, "DiscountType")
                              }
                              validateOnBlur
                              validateOnChange
                              className={
                                formState.errors.discount_type
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            >
                              <option value="" disabled>
                                - Select DiscountType -
                              </option>
                              <option >Percentage</option>
                              <option>Amount</option>
                            </Select>
                            {formState.errors.discount_type && (
                              <div className="input_error">
                                {formState.errors.discount_type}
                              </div>
                            )}
                          </div>
                        </div>
                       
                        {/* value */}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              type="number"
                              validate={val => {
                                if (!val && !formState.touched.discount_value) {
                                  return "Enter a discount value.";
                                }
                                if (isNaN(val) < 0) {
                                  return " Enter a discount value.";
                                }
                                if (this.state.dtype == "Percentage") {
                                  if (Number(val) > 100) {
                                    return "Discount percentage cannot be more than 100%.";
                                  }
                                }
                                if (this.state.dtype == "Amount") {
                                  if (Number(val) > 20000) {
                                    return "Discount Amount cannot be more than 20000.";
                                  }
                                }
                                if (!val) { return " discount value cannot be empty"; }
                                let notPositive = validateWholeNumber(
                                  val,
                                  "Value"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="discount_value"
                              placeholder="Discount value(% or amount)"
                              value={this.state.discountPercentage || ""}
                              onChange={e => {
                                this.setState({
                                  discountPercentage: Number(e.nativeEvent.target.value)

                                });
                              }}
                              className={
                                formState.errors.discount_value
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.discount_value && (
                              <div className="input_error">
                                {formState.errors.discount_value}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* {this.formApi.getState().values.discount_type == "Percentage"? */}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              type="number"
                              validate={val => {
                                if (this.formApi.getState().values.discount_type == "Percentage") {
                                  if (!val && !formState.touched.max_discount_value) {
                                    return "Enter a max discount value.";
                                  }
                                  if (isNaN(val) < 0) {
                                    return " Enter a max discount value.";
                                  }
                                  if (!val) { return " discount value cannot be empty"; }
                                  let notPositive = validateWholeNumber(
                                    val,
                                    "Value"
                                  );
                                }
                              }}
                              validateOnBlur
                              validateOnChange
                              field="max_discount_value"
                              placeholder="Enter max discount value"
                              className={
                                formState.errors.discount_value
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                              disabled={this.formApi.getState().values.discount_type == "Percentage" ? false : true}
                            />
                            {formState.errors.max_discount_value && (
                              <div className="input_error">
                                {formState.errors.max_discount_value}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* :null} */}
                        {/* status */}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Select
                              field="discount_status"
                              validate={val =>
                                this._validateSelectDiscountStatus(val, "DiscountStatus")
                              }
                              validateOnBlur
                              validateOnChange
                              className={
                                formState.errors.discount_status
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            >
                              <option value="" disabled>
                                - Select DiscountStatus -
                              </option>
                              <option >Active</option>
                              <option>InActive</option>
                            </Select>
                            {formState.errors.discount_status && (
                              <div className="input_error">
                                {formState.errors.discount_status}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.state.editMode ? "Save Changes" : "Add"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _setFormApi(formApi) {
    this.formApi = formApi;
  }

  _validateSelectDiscountType(val, name) {
    this.setState({
      dtype: val
    })
    if (!val) {
      return `Select ${name}.`;
    }
  }
  _validateSelectDiscountStatus(val, name) {
    if (!val) {
      return `Select ${name}.`;
    }
  }
}