import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Link } from "react-router-dom";
import {
  AuthContext,
  DataContext,
  RootConsumer,
  DataProvider
} from "../contextProvider";
import Loader from "react-loader-spinner";
import { Form, Text } from "informed";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import { shouldShowPagination, ellipsize, throttleTime } from "../utilities";
import { toast } from "react-toastify";

//if we want to
// export default () => {
//   return (
//     // <DataContext.Consumer>
//     //   {({ restarants, fetchRestarants }) => {
//     //     return (
//     <AuthContext.Consumer>
//       {({ user, authToken: token, CONSTANTS }) => {
//         return (
//           <RestarantsDisplay
//             // restarants={restarants}
//             // fetchRestarants={fetchRestarants}
//             userId={user["userId"]}
//             token={token}
//             CONSTANTS={CONSTANTS}
//           />
//         );
//       }}
//     </AuthContext.Consumer>
//     //     );
//     //   }}
//     // </DataContext.Consumer>
//   );
// };

export default class RestarantsList extends Component {
  state = {
    paginationHighlight: 1,
    perPage: 10,
    totalItemsCount: null,
    restarants: [],
    surroundingButtonsCount: 2,
    fetchProgress: true,
    saveProgress: false,
    searchTxt: '',
    allRestarants: [],
    urbanActiveRestIds: [],
    urbanSyncDetails: []
  };

  async componentDidMount() {
    await this._loadRestaurants();
    await this._fetchCities();
    if (this.state.urbanActiveRestIds.length) this._loadUrbanStoreSyncDetails()
  }
  static contextType = AuthContext;
  filterRestarants(event) {
    this.setState({ searchTxt: event.target.value });
    let filterRestarants = this.state.allRestarants.filter(rest => rest.name.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1);
    filterRestarants = event.target.value ? filterRestarants : this.state.allRestarants
    this.setState({
      restarants: filterRestarants
    })
  }
  render() {
    return (
      <DataProvider
        {...this.context}
        loadRestarantsOnWillMount
        recordsPerPage={this.state.perPage}
      >
        <div className="contentvthbtn">
          <DataContext.Consumer>
            {({ restarants, restarantFetchProgress }) =>
              this.state.restarantFetchProgress ? (
                <Loader
                  color="#00BFFF"
                  height="100"
                  width="100"
                  style="loadingProgress"
                  type="Audio"
                />
              ) : (
                  <>
                    <div className="row mx-0 justify-content-between">
                      <div className="">
                        <span className="categorycol">Restaurants</span>
                      </div>
                      <div className="">
                        <input className="form-control"
                          type="text"
                          placeholder="Search items"
                          aria-label="Search"
                          onChange={this.filterRestarants.bind(this)}
                          value={this.state.searchTxt}
                        />
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="btnaddcategory float-right"
                          // data-toggle="modal"
                          // data-target="#addRestarantModal"
                          onClick={() => {
                            this.props.history.push("add-restarant");
                          }}
                        >
                          <i className="fa fa-plus plusicon" aria-hidden="true" />
                        &nbsp; Add Restaurant
                      </button>
                      </div>
                    </div>
                    {this.state.restarants.length ? (
                      <div className="table-responsive mt-3 text-capitalize tableofauto">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Restaurant name</th>
                              <th>Restaurant head</th>
                              <th>contact number</th>
                              <th>Address</th>
                              <th>status</th>
                              <th className="actionWidth">action</th>
                              <th> UrbanPiper</th>
                              <th>Sync Errors</th>
                              <th>Sync Updated</th>
                              <th>Sync created</th>
                              <th>Sync Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.restarants.map((r, idx) => {
                              let restName = ellipsize(r.name, 40);
                              let restAddr = ellipsize(r.address, 40);
                              let head = r.heads.find(
                                h =>
                                  h.role ==
                                  this.context.CONSTANTS.userRoles["ADMIN"]
                              );
                              head = head || r.heads[0];
                              let active =
                                r.status ===
                                this.context.CONSTANTS.statusCodes[
                                "RESTAURANT_ACTIVE"
                                ];
                              let urbanSyncDetails =this.state.urbanSyncDetails && this.state.urbanSyncDetails.length ? this.state.urbanSyncDetails.filter(u => u.storeId == r.id) : []
                              return (
                                <tr key={String(idx)}>
                                  <td>
                                    {(this.state.paginationHighlight - 1) *
                                      this.state.perPage +
                                      idx +
                                      1}
                                  </td>
                                  <td>{restName}</td>
                                  <td>{head.name ? ellipsize(head.name, 40) : null}</td>
                                  <td>{r.phone}</td>
                                  <td>{restAddr}</td>
                                  <td
                                    className={
                                      active ? "activecol" : "inactivecol"
                                    }
                                  >
                                    {this.context.CONSTANTS && active
                                      ? "Active"
                                      : "InActive"}
                                  </td>
                                  <td>
                                    {/* <Link to={"restaurant-details/"}> */}
                                    <img
                                      onClick={() =>
                                        this.props.history.push(
                                          "restaurant-details",
                                          { restarantId: r.id ,restEncryptId: r.restEncryptId,restarantName: r.name}
                                        )
                                      }
                                      src={EDIT_IMG}
                                      alt=""
                                      className="icon img-fluid"
                                    />
                                    {/*                               
                              <img
                                src={DELETE_IMG}
                                alt="Delete"
                                className="img-fluid"
                              /> */}
                                  </td>
                                  <td className={r.urbanpiper ? "activecol" : "inactivecol"}>
                                    {r.urbanpiper ? "Active" : "In Active"}
                                    {/* {r.urbanpiper ?
                                      <button
                                        type="button"
                                        className="btnaddcategory"
                                        onClick={() => {
                                          this._onSyncRestaurant(r, this.state.records);
                                        }}
                                      >
                                        Sync
                                            </button>
                                      : null} */}
                                  </td>
                                  <td>{urbanSyncDetails.length ? urbanSyncDetails[0].errors : null}</td>
                                  <td>{urbanSyncDetails.length ? urbanSyncDetails[0].updated : null}</td>
                                  <td>{urbanSyncDetails.length ? urbanSyncDetails[0].created : null}</td>
                                  <td>{urbanSyncDetails.length ? new Date(urbanSyncDetails[0].lastSynched).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' }) : null}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                        // eslint-disable-next-line react/style-prop-objects
                        <div className="noDataMsgContainer">
                          <h5>No Restarants Here.</h5>
                        </div>
                      )}
                  </>
                )
            }
          </DataContext.Consumer>
        </div>
        {/* <DataContext.Consumer>
          {({ totalRestCount, fetchRestarants }) => {
            return (
              <div className="container-fluid">
                {shouldShowPagination(totalRestCount, this.state.perPage) ? (
                  <ReactPaginate
                    previousLabel={
                      <i
                        className="fa fa-angle-double-left"
                        aria-hidden="true"
                      />
                    }
                    nextLabel={
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      />
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(totalRestCount / this.state.perPage)}
                    // pageCount={10}
                    marginPagesDisplayed={this.state.surroundingButtonsCount}
                    pageRangeDisplayed={3}
                    pageLinkClassName={"pagebtn"}
                    onPageChange={data => {
                      this.setState({
                        paginationHighlight: data.selected + 1
                      });
                      fetchRestarants(
                        this.state.perPage,
                        (data.selected + 1 - 1) * this.state.perPage
                      );
                    }}
                    containerClassName={"pagination"}
                    // subContainerClassName={''}
                    activeLinkClassName={"active"}
                  />
                ) : null}
              </div>
            );
          }}
        </DataContext.Consumer> */}
        <AddRestarantModal
          submitAddRestaurant={this._submitAddRestaurant.bind(this)}
        />
      </DataProvider>
    );
  }

  async _onSyncRestaurant(r, cities) {
    if (this.state.saveProgress) {
      return false;
    }
    const { up_Authorization, CONSTANTS } = this.context;
    if (!r.name || !r.cityId || !r.id) {
      return toast.error(`please fill the required fields`, {
        autoClose: 4000
      });
    }
    const city = cities.find(
      c => c.cityId == r.cityId
    );
    const cityName = city.cityName;
    let excludedPlatforms = r.excludedPlatforms ? JSON.parse(r.excludedPlatforms).rExcludedPlatforms : []
    let includedPlatforms = r.includedPlatforms ? JSON.parse(r.includedPlatforms).rIncludedPlatforms : []
    let zipCodes = r.excludedPlatforms ? JSON.parse(r.zipCodes).rDeliverableZipCodes : []
    let timings = JSON.parse(r.restarantTimings).restaurant_timings
    this.setState({
      saveProgress: true
    });
    let body = {
      stores: [
        {
          city: cityName,
          name: r.name,
          min_pickup_time: r.minPickupTime,
          min_delivery_time: r.minDeliveryTime,
          contact_phone: r.phone,
          notification_phones: [
            r.heads[0].phone,
            r.owner.length ? r.owner[0].phone : ""
          ],
          ref_id: r.id,
          min_order_value: r.minOrderValue,
          hide_from_ui: r.hideFromUi,
          address: r.address,
          notification_emails: [
            r.heads[0].email,
            r.owner.length ? r.owner[0].email : ""
          ],
          zip_codes: zipCodes,
          geo_longitude: r.geoLongitude,
          active: r.urbanpiper,
          geo_latitude: r.geoLatitude,
          ordering_enabled: r.orderingEnabled,
          translations: [],
          excluded_platforms: excludedPlatforms,
          included_platforms: includedPlatforms,
          timings: timings
        }
      ]
    }

    const savedResponse = await fetch(CONSTANTS.upApiBasePath + "/external/api/v1/stores/", {
      async: true,
      crossDomain: true,
      method: "POST",
      headers: {
        Authorization: up_Authorization,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }
    )
    if (!savedResponse) {
      let msg = "An error Occurred"
      this.setState({
        saveProgress: false
      });
      return toast.error(msg)
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (savedResponseResult.status == "error") {
      this.setState({
        saveProgress: false
      });
      return toast.error(savedResponseResult.message)
    }
    if (savedResponseResult.status == "success") {
      this.saveWebhookResponse(r.id, savedResponseResult.reference)
      setTimeout(() => {
        toast.success(
          "Successfully synched"
        );
      }, 1500);
      return toast.success(savedResponseResult.message);

    }


  }

  async saveWebhookResponse(restId, referenceId) {
    const { CONSTANTS, authToken } = this.context
    let body = {
      restaurant_id: restId,
      reference_id: referenceId,
    }

    let apiRoute = CONSTANTS.apiRoutes.UrbanStoreAddUpdateResponse;

    const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));

    if (!savedResponse) {
      this.setState({
        saveProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      this.setState({
        saveProgress: false,

      });
      return toast.error("An error occurred while storing the response.")
    }
    if (!savedResponseResult.success) {
      toast.error(savedResponseResult.msg, { autoClose: 8000 });
      return this.setState({
        saveProgress: false
      });
    }
    this.setState({
      saveProgress: false
    });
  }

  async _loadRestaurants() {
    this.setState({
      fetchProgress: true
    });
    const { user, authToken, CONSTANTS } = this.context;
    const requestSendTime = Date.now();
    let limit = this.state.perPage;
    let offset = (this.state.paginationHighlight - 1) * this.state.perPage;
    let fetchResponse = await fetch(
      CONSTANTS.apiBasePath +
      CONSTANTS.apiRoutes.restaurantsList +
      `?limit=${0}&offset=${0}&userId=${user.userId}`,
      { headers: { Authorization: authToken } }
    ).catch(err => console.error(err));

    if (!fetchResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const restarantsData = await fetchResponse.json(); //@TODO - handle error

    if (!restarantsData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("Could not get restaurants.");
    }

    const reRenderTime = Date.now();
    const waitMoreTime = Math.max(500 - (reRenderTime - requestSendTime), 0);
    let urbanActiveRestIds = []
    restarantsData.data.map(r => {
      if (r.urbanpiper) {
        urbanActiveRestIds.push(r.id)
      }
    })
    setTimeout(() => {
      this.setState({
        restarants: restarantsData.data,
        totalItemsCount: restarantsData.totalRestarants,
        fetchProgress: false,
        allRestarants: restarantsData.data,
        urbanActiveRestIds: urbanActiveRestIds
      });
    }, waitMoreTime);
  }
  async _fetchCities() {
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath } = this.context.CONSTANTS;
    const citiesResponse = await fetch(
      apiBasePath +
      apiRoutes.fetchCities +
      `?user_id=${this.context.user.userId}&limit=${
      this.state.recordsPerPage
      }&offset=${(this.state.currentPageNum - 1) *
      this.state.recordsPerPage}`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));

    if (!citiesResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching cities");
    }

    const citiesData = await citiesResponse
      .json()
      .catch(err => console.error(err));
    if (!citiesData) {
      return toast.error("An Error Occured while fetching cities");
    }
    if (!citiesData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error(citiesData.msg);
    }
    throttleTime(
      () => {
        this.setState({
          records: citiesData.data,
          fetchProgress: false,
          totalRecords: citiesData.total
        });
      },
      requestSendTime,
      500
    );
  }
  async _loadUrbanStoreSyncDetails() {
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const urbanStoreSyncDetails = await fetch(
      apiBasePath +
      apiRoutes.getUrbanStoreAddSyncDetails +
      `?user_id=${this.context.user.userId}&restarant_id=${this.state.urbanActiveRestIds}`,
      { headers: { Authorization: this.context.authToken } }
    ).catch(err => console.error(err));
    if (!urbanStoreSyncDetails) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const urbanStoreSyncDetailsResult = await urbanStoreSyncDetails.json();
    if (!urbanStoreSyncDetailsResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    this.setState({
      urbanSyncDetails: urbanStoreSyncDetailsResult.data
    })

  }

  //@TODO continues...
  async _submitAddRestaurant() {
    return toast.error("not yet implemented.");
  }

  _handlePageClick(data) {
    this.setState(
      {
        paginationHighlight: data.selected + 1
      },
      err => {
        this._loadRestaurants();
      }
    );
  }
}

class AddRestarantModal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    $(this.modalRef.current).on("hide.bs.modal", this.formApi.reset);
  }

  render() {
    return (
      <div
        className="modal fade"
        id="addRestarantModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Add Restaurant Modal"
        aria-hidden="true"
        ref={this.modalRef}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Restaurant
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                getApi={this.setFormApi.bind(this)}
                onSubmit={() =>
                  this.props.submitAddRestaurant(this.formApi.getState().values)
                }
              >
                {({ formState }) => {
                  return (
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group modal-form-group">
                          <label htmlFor="rName">Restaurant Name</label>
                          <Text
                            field="rName"
                            type="text"
                            id="rName"
                            placeholder="Enter Restaurant name"
                            className={
                              formState.errors.rName
                                ? "form-control input_error"
                                : "form-control"
                            }
                            validateOnBlur
                            validateOnChange
                            validate={this._validaterName.bind(this)}
                          />
                          {formState.errors.rName && (
                            <div className="input_error">
                              {formState.errors.rName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group modal-form-group">
                          <label htmlFor="rName">Restaurant Head</label>
                          <Text
                            field="rHName"
                            type="text"
                            placeholder="Enter Restaurant Head name"
                            className={
                              formState.errors.rHName
                                ? "form-control input_error"
                                : "form-control"
                            }
                            validateOnBlur
                            validateOnChange
                            validate={this._validateHeadName.bind(this)}
                          />
                          {formState.errors.rHName && (
                            <div className="input_error">
                              {formState.errors.rHName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group modal-form-group">
                          <label htmlFor="rName">Restaurant Phone</label>
                          <Text
                            field="rPhone"
                            type="tel"
                            placeholder="Enter Restaurant Phone"
                            className={
                              formState.errors.rPhone
                                ? "form-control input_error"
                                : "form-control"
                            }
                            validateOnBlur
                            validateOnChange
                            validate={this._validatePhone.bind(this)}
                          />
                          {formState.errors.rPhone && (
                            <div className="input_error">
                              {formState.errors.rPhone}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group modal-form-group">
                          <label htmlFor="rName">Restaurant Location</label>
                          <Text
                            field="rLocation"
                            type="text"
                            placeholder="Enter Restaurant Location"
                            className={
                              formState.errors.rLocation
                                ? "form-control input_error"
                                : "form-control"
                            }
                            validateOnBlur
                            validateOnChange
                            validate={this._validateLocation.bind(this)}
                          />
                          {formState.errors.rLocation && (
                            <div className="input_error">
                              {formState.errors.rLocation}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Form>
            </div>
            <div className="modal-footer d-block">
              <button
                type="button"
                className="btnmodaladd text-uppercase float-right"
                onClick={this.submitForm.bind(this)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  setFormApi(formApi) {
    this.formApi = formApi;
  }

  submitForm() {
    let formState = this.formApi.getState();

    if (Object.keys(formState.errors).length || formState.pristine) {
      return false;
    }
    this.formApi.submitForm();
  }

  _validaterName(val) {
    return (!val || val.length < 4) && this.formApi.getState().touched.rName
      ? "Please input valid name"
      : null;
  }
  _validateHeadName(val) {
    return (!val || val.length < 4) && this.formApi.getState().touched.rHName
      ? "Please input valid name"
      : null;
  }
  _validatePhone(val) {
    return (!val || val.length < 6 || !new RegExp(/^[0-9+-]+$/).test(val)) &&
      this.formApi.getState().touched.rPhone
      ? "Please input valid Phone Number"
      : null;
  }
  _validateLocation(val) {
    return (!val || val.length < 4) && this.formApi.getState().touched.rLocation
      ? "Please input valid Location"
      : null;
  }
}
