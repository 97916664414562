//@ts-check
import React, { Component, PureComponent } from "react";
import { fetchCategories, getOtp, submitOtp, resetPwd } from "./services";
import { toast } from "react-toastify";
import firebase from "firebase";
import { sendFcmTokenToServer } from "./services";
let basePath;
switch (process.env.REACT_APP_ENVIRONMENT) {
  case "DEVELOPMENT":
    basePath = "http://localhost:3001";
    break;
  case "TESTING":
    basePath = "http://3.233.143.64";
    break;
  default:
    basePath = "https://buvvasmini.com";
    break;
}

export const CONSTANTS = {
  apiBasePath: basePath + "/api",
  apiRoutes: {
    restaurantsList: "/getRestaurants",
    getRestarantDetails: "/get-restarant-details",
    getRestarantAndMenu: "/get-restarant-and-menu",
    createRestaurant: "/admin/create-restaurant",
    adminEditRestaurant: "/admin/edit-restarant",
    createCategory: "/add-category", //admin
    editCategory: "/edit-category",
    deleteCategory: "/delete-category",
    //sub-category
    getSubCategories: "/get-sub-categories",
    addSubCategory: "/add-sub-category",
    editSubCategory: "/edit-sub-category",
    deleteSubCategory: "/delete-sub-category",
    //menuItems
    getMenuItems: "/get-menu-items",
    createMenuItem: "/create-menu-item",
    editMenuItem: "/edit-menu-item",
    deleteMenuItem: "/delete-menu-item",
    //itemRoutes
    getItemRoute: "/get-item-route",
    createItemRoute: "/add-item-route",
    editItemRoute: "/edit-item-route",
    deleteItemRoute: "/delete-item-route",
    //ordertypes
    getOrderType: "/get-order-type",
    createOrderType: "/add-order-type",
    editOrderType: "/edit-order-type",
    deleteOrderType: "/delete-order-type",
    // discounts
    createDiscounts: "/add-discounts",
    editDiscounts: "/edit-discounts",
    getDiscounts: "/get-discounts",
    deleteDiscounts: "/delete-discounts",
    getCoupons: "/get-coupons",
    //tables
    getAllTables: "/get-all-tables",
    editTable: "/edit-table",
    createTable: "/create-table",
    deleteTable: "/delete-table",
    assignTableToBearer: "/assign-table-to-bearer",
    getAssignedTables: "/bearer-assigned-tables",
    getBearerTableAssignments: "/admin/bearer-table-assignments",
    //employees
    getEmployees: "/get-employees",
    addEmployee: "/add-employee",
    editEmployee: "/edit-employee",
    deleteEmployee: "/delete-employee",
    //orders
    fetchGuestsList: "/guests-list",
    guestVisitedRestarants: "/guest-visited-restarants",
    getRestarantOrders: "/restaurant-orders",
    saveTableReservation: "/save-table-reservation",
    guestPastOrders: "/guest-past-orders",
    guestInvoiceDetails: "/invoice-details-for-guest",
    login: "/login",
    adminLogin: "/admin-login",
    getMenuCategories: "/get-categories",
    getCategoryItems: "/get-menu-items",
    addGuest: "/add-guest",
    updateGuestStatus: "/update-guest-status",
    getAvailableTables: "/get-available-tables",
    getAssignedAndActiveTables: "/get-assigned-and-active-tables",
    assignTablesToGuests: "/assign-tables-to-guests",
    saveSplitTableAssignment: "/split-table-assignment",
    bookTable_reception: "/book-table-receptionist",
    getCurrentGuestsAndTableAssignments:
      "/get-current-guests-and-table-assignments",
    getReceptionistQueueList: "/get-receptionist-queue-list",
    createOrder: "/create-order",
    updateOrderCart: "/update-order-cart",
    getOngoingOrders: "/get-ongoing-orders",
    updateOrderStatus: "/update-order-status",
    updateTableAvailability: "/update-table-availability",
    requestInvoice: "/request-invoice",
    chefPastOrders: "/chef-past-orders",
    uploadFile: "/upload-file",
    submitUserFeedback: "/submit-user-feedback",
    updateProfile: "/update-profile",
    updatePassword: "/update-password",
    errorReport: "/error-report",
    submitInvoiceConfirmation: "/submit-invoice-confirmation",
    generateInvoice: "/generate-invoice",
    getRestarantCompletedOrders: "/restaurant-completed-orders",
    guestReservations: "/guest-reservations",
    editReservation: "/edit-reservation",
    fetchCities: "/get-cities",
    addCity: "/add-city",
    editCity: "/edit-city",
    deleteCity: "/delete-city",
    setRestarantTaxRate: "/set-tax-percent",
    getRestarantTaxRate: "/get-tax-rate",
    orderFeedback: "/order-feedback",
    resetPwd: "/reset-pwd",
    forgotPwd: "/forgot-password",
    forgotPwdEmail: "/forgot-password-email",
    verifyOtp: "/verify-otp",
    getTakeAwayOrderHistory: "/current-day-takeaway-order-history",
    getOrderDetails: "/get-take-away-order-details",
    userOrderList: '/user-orders',
    userOrderDetails: '/user-order-details',
    getSupportDetails: "/get-support-details",
    getFeedBacks: "/get-feed-backs",
    saveFeedBack: "/save-feed-back",
    saveGenericFeedBack: "/save-generic-feed-back"
  },

  userRoles: {
    ADMIN: 1,
    HEAD: 2,
    CHEF: 3,
    RECEPTIONIST: 4,
    BEARER: 5,
    GUEST: 9
  },
  statusCodes: {
    TABLE_AVAILABLE: 1,
    TABLE_UNAVAILABLE: 2,
    TABLE_OCCUPIED: 3,
    TABLE_SPLIT: 4,
    RESTAURANT_ACTIVE: 5,
    RESTAURANT_INACTIVE: 6,
    TABLE_ASSIGNMENT_ASSIGNED: 7,
    TABLE_ASSIGNMENT_SEATED: 8,
    TABLE_ASSIGNMENT_COOKING: 9,
    TABLE_ASSIGNMENT_SERVED: 10,
    TABLE_ASSIGNMENT_ENDED: 11,
    TABLE_ASSIGNMENT_PENDING: 12,
    ORDER_PLACED: 13,
    ORDER_COOKING: 14,
    ORDER_READY_TO_SERVE: 15,
    ORDER_SERVED: 16,
    ORDER_CLOSED: 17
  }
};

const combine = providersORConsumers => {
  const { length } = providersORConsumers;
  if (length === 1) {
    const CombinedContextChild = providersORConsumers[0];
    return props => (
      <CombinedContextChild {...props}>{props.children}</CombinedContextChild>
    );
  }

  return providersORConsumers.reduce(
    (ContextChildA, ContextChildB) => props => (
      <ContextChildA>
        <ContextChildB>{props.children}</ContextChildB>
      </ContextChildA>
    )
  );
};

// Initialize Firebase
var config = {
  // apiKey: "AIzaSyDcdQs6od0c7IyyX0rmPhWmZuupmDlUFSc",
  // authDomain: "buvvasproj.firebaseapp.com",
  // databaseURL: "https://buvvasproj.firebaseio.com",
  // projectId: "buvvasproj",
  // storageBucket: "buvvasproj.appspot.com",
  messagingSenderId: "375916045508"
};
firebase.initializeApp(config);

async function requestPermission(messaging) {
  console.log("Requesting permission...");
  // [START request_permission]
  let error = undefined;
  const permission = await messaging.requestPermission().catch(function (err) {
    error = err;
    console.log("Unable to get permission to notify.", err);
  });
  console.log("permission", permission);
  return !error;
  // [END request_permission]
}

async function getToken(messaging) {
  return await messaging.getToken().catch(function (err) {
    console.log("An error occurred while retrieving token. ", err);
  });
}

async function onMessage(message) {
  console.log("focus", message);
  // if (!window.isWindowFocussed) {
  //   return false;
  // }
  // console.log('onmessage',message);
  const payLoad = message.notification;
  // console.log("in focus - onmessage", payLoad);
  const msg = payLoad.msg || payLoad.body;
  var desktopNotification = new Notification(payLoad.title, {
    icon: "/ic_launcher.png",
    body: msg.length > 50 ? msg.substr(0, 50) + "..." : msg
  });
  desktopNotification.onclick = function (e) {
    window.focus();
    e.target.close(); //close notification
    desktopNotification = null;
  };
  desktopNotification.onerror = function (event) {
    console.warn("notification error ????");
  };
  // function closeNotification() {
  //   if (desktopNotification) {
  //     desktopNotification.close();
  //   }
  //   window.removeEventListener("focus", closeNotification);
  // }
  // window.addEventListener("focus", closeNotification);
}

export const AuthContext = React.createContext();
// export const AuthConsumer = AuthContext.Consumer;

class AuthProvider extends PureComponent {
  constructor() {
    super();
    let messaging;
    if (
      window.location.protocol != "http:" ||
      window.location.hostname == "localhost"
    ) {
      try {
        messaging = firebase.messaging();
      } catch (err) {
        console.error(err);
      }
    }
    this.state.messaging = messaging;
  }
  state = {
    isLoggedIn: false,
    authToken: "",
    user: localStorage.getItem(btoa("user").replace(/==$/g, ""))
      ? JSON.parse(
        decodeURIComponent(
          atob(localStorage.getItem(btoa("user").replace(/==$/, "")))
        )
      )
      : {},
    CONSTANTS,
    isSuperAdmin: false,
    loading: false,
    locationAPIKey: atob(localStorage.getItem(btoa("locationAPIKey")) || ""),
    upSatelliteScriptUrl: atob(localStorage.getItem(btoa("upSatelliteScriptUrl") || "")),
    upEnv: atob(localStorage.getItem(btoa("upEnv") || "")),
    fcmToken: localStorage.getItem(btoa("fcmToken").replace(/==$/g, "")) || ""
  };

  componentWillMount() {
    if (!this.state.fcmToken && this.state.messaging) {
      this._pushNotifications();
    }
    let authToken = localStorage.getItem(btoa("AuthToken"));
    let constantsBase64 = localStorage.getItem(btoa("constants"));
    const constants = constantsBase64
      ? JSON.parse(atob(constantsBase64))
      : CONSTANTS;
    let userBase64 = localStorage.getItem(btoa("user"));
    // const user = userBase64 ? JSON.parse(atob(userBase64)) : null;
    const user = this.state.user;
    try {
      this.setState({
        isLoggedIn: !!authToken,
        authToken: authToken,
        CONSTANTS: constants,
        isSuperAdmin: user && user.isSuperAdmin
      });
    } catch (e) {
      console.error(e);
      this.logout();
    }
  }

  componentDidMount() {
    this.state.messaging && this.state.messaging.onMessage(onMessage);
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          login: this.login.bind(this),
          logOut: this.logout.bind(this),
          getOtp: this.getOtp.bind(this),
          submitOtp: this.submitOtp.bind(this),
          resetPwd: this.resetPwd.bind(this),
          updateLocalProfile: this.updateLocalProfile.bind(this),
          locationAPIKey: this.state.locationAPIKey,
          upSatelliteScriptUrl: this.state.upSatelliteScriptUrl,
          upEnv: this.state.upEnv,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }

  async submitOtp(otpId, otp) {
    this.setState({
      loading: true
    });
    const response = await submitOtp({
      apiBasePath: CONSTANTS.apiBasePath,
      apiRoutes: CONSTANTS.apiRoutes,
      otpId,
      otp,
      authToken: this.state.authToken
    }).catch(err => console.error(err));
    this.setState({
      loading: false
    });
    return response;
  }

  async resetPwd(resetToken, pwd) {
    this.setState({
      loading: true
    });
    const response = await resetPwd({
      apiBasePath: CONSTANTS.apiBasePath,
      apiRoutes: CONSTANTS.apiRoutes,
      pwd,
      resetToken,
      authToken: this.state.authToken
    }).catch(err => console.error(err));
    this.setState({
      loading: false
    });
    return response;
  }

  async login(email, password) {
    this.setState({
      loading: true
    });
    const loginResponse = await fetch(
      CONSTANTS.apiBasePath + CONSTANTS.apiRoutes.adminLogin,
      {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: { "Content-type": "application/json" }
      }
    ).catch(err => console.error(err));

    if (!loginResponse) {
      this.setState({
        loading: false
      });
      return toast.error("Login attempt failed. Please try again later.");
    }
    const loginData = await loginResponse
      .json()
      .catch(err => console.error(err));

    if (!loginData) {
      this.setState({
        loading: false
      });
      return toast.error("Login attempt failed. Please try again later.");
    }
    if (!loginData.success) {
      this.setState({
        loading: false
      });
      return toast.error(loginData.msg);
    }
    window.constants = loginData.constants;

    if (!loginData.user) {
      this.setState({
        loading: false
      });
      return toast.error("Error while login.");
    }

    let isSuperAdmin = loginData.user.userRoles.includes(
      loginData.constants.userRoles["SUPER_ADMIN"]
    );

    let USER = isSuperAdmin
      ? { ...loginData.user, isSuperAdmin }
      : {
        ...loginData.user,
        selectedRestarantId: loginData.user.restarants[0].restarantId
      };
    this.setState(
      {
        isLoggedIn: !!loginData.token,
        authToken: loginData.token,
        user: USER,
        CONSTANTS: loginData.constants,
        isSuperAdmin,
        locationAPIKey: loginData.locationAPIKey,
        upSatelliteScriptUrl: loginData.upSatelliteScriptUrl,
        upEnv: loginData.upEnv,
        loading: false
      },
      () => {
        localStorage.setItem(btoa("AuthToken"), loginData.token);
        localStorage.setItem(
          btoa("user").replace(/==$/g, ""),
          btoa(encodeURIComponent(JSON.stringify(USER)))
        );
        localStorage.setItem(
          btoa("constants"),
          btoa(JSON.stringify(loginData.constants))
        );
        localStorage.setItem(
          btoa("locationAPIKey"),
          btoa(loginData.locationAPIKey)
        );
        localStorage.setItem(
          btoa("upSatelliteScriptUrl"),
          btoa(loginData.upSatelliteScriptUrl)
        );
        localStorage.setItem(
          btoa("upEnv"),
          btoa(loginData.upEnv)
        );
      }
    );

    this.setState({
      loading: false
    });

    //now that login is done, register for notifications
    this._pushNotifications();
  }

  async getOtp(email) {
    this.setState({
      loading: true
    });
    const response = await getOtp({
      apiBasePath: CONSTANTS.apiBasePath,
      apiRoutes: CONSTANTS.apiRoutes,
      email,
      authToken: this.state.authToken
    }).catch(err => console.error(err));
    this.setState({
      loading: false
    });
    return response;
  }

  logout() {
    this.setState(
      {
        isLoggedIn: false,
        authToken: ""
      },
      () => {
        localStorage.removeItem(btoa("AuthToken"));
        localStorage.removeItem(btoa("user").replace(/==$/g, ""));
      }
    );
  }

  setSelectedRestarantId(id) {
    if (!this.state.user.restarants.find(r => r.restarantId == id)) {
      return console.error("User does not have access to the restarant.");
    }
    this.setState(
      {
        user: { ...this.state.user, selectedRestarantId: id }
      },
      err => {
        localStorage.setItem(
          btoa("user").replace(/==$/g, ""),
          btoa(encodeURIComponent(JSON.stringify(this.state.user)))
        );
      }
    );
  }

  updateLocalProfile({
    user_image_url,
    user_name,
    user_phone,
    user_email,
    user_address
  }) {
    this.setState(
      {
        user: {
          ...this.state.user,
          profilePic: user_image_url,
          userName: user_name,
          userUserName: user_name,
          userEmail: user_email,
          userPhone: user_phone,
          userAddress: user_address
        }
      },
      err => {
        if (err) return console.error(err);
        let isSuperAdmin = this.state.user.userRoles.includes(
          this.state.CONSTANTS.userRoles["SUPER_ADMIN"]
        );

        let USER = isSuperAdmin
          ? { ...this.state.user, isSuperAdmin }
          : {
            ...this.state.user,
            selectedRestarantId: this.state.user.restarants[0].restarantId
          };

        //update localStorage
        localStorage.setItem(
          btoa("user").replace(/==$/g, ""),
          btoa(encodeURIComponent(JSON.stringify(this.state.user)))
        );
      }
    );
  }

  async _pushNotifications() {
    if (!this.state.messaging) return false;
    let permission = await requestPermission(this.state.messaging);
    if (!permission) {
      return toast.info(
        "Allow notifications to get updates on order completion."
      );
    }
    let fcmToken = await getToken(this.state.messaging);
    this.setState(
      {
        fcmToken
      },
      err => {
        if (err) return console.error(err);
        sendFcmTokenToServer({
          userId: this.state.user.userId,
          fcmToken: this.state.fcmToken,
          oldToken: this.state.oldFcmToken,
          baseUrl: this.state.CONSTANTS.apiBasePath,
          route: this.state.CONSTANTS.apiRoutes.saveAdminFCMToken,
          authToken: this.state.authToken,
          userRoles: this.state.user.userRoles
        });

        localStorage.setItem(btoa("fcmToken").replace(/=*$/g, ""), fcmToken);
      }
    );
    this.state.messaging.onTokenRefresh(async () => {
      let token = await getToken(this.state.messaging);
      this.setState(
        {
          fcmToken: token
        },
        err => {
          if (err) return console.error(err);
          localStorage.setItem(btoa("fcmToken").replace(/==$/g, ""), token);
        }
      );
    });
  }
}

export const DataContext = React.createContext({});

export class DataProvider extends PureComponent {
  state = {
    restarantFetchProgress: false,
    restarants: [],
    totalRestCount: 0,
    CONSTANTS: this.props.CONSTANTS,
    user: this.props.user,
    currentPageNum: 1,
    fetchProgress: false,
    recordsPerPage: this.props.recordsPerPage || 0
  };

  componentWillMount() {
    let limit = this.state.recordsPerPage;
    let offset = (this.state.currentPageNum - 1) * this.state.recordsPerPage;
    this.props.loadRestarantsOnWillMount && this.fetchRestarants(limit, offset);
  }

  render() {
    return (
      <DataContext.Provider
        value={{
          ...this.state,
          fetchRestarants: this.fetchRestarants.bind(this),
          fetchCategories: fetchCategories,
          restarantFetchProgress: this.state.fetchProgress,
          totalRestarants: this.state.totalRestCount
        }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }

  async fetchRestarants(limit, offset) {
    if (this.state.fetchProgress) return false;
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    let fetchResponse = await fetch(
      this.state.CONSTANTS.apiBasePath +
      this.state.CONSTANTS.apiRoutes.restaurantsList +
      `?limit=${limit}&offset=${offset}&user_id=${this.state.user.userId}`,
      { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!fetchResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const restarantsData = await fetchResponse.json(); //@TODO - handle error

    if (!restarantsData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("Could not get restaurants.");
    }
    const reRenderTime = Date.now();
    const waitMoreTime = Math.max(500 - (reRenderTime - requestSendTime), 0);
    setTimeout(() => {
      this.setState({
        restarants: restarantsData.data,
        totalRestCount: restarantsData.totalRestarants,
        fetchProgress: false
      });
    }, waitMoreTime);
  }
}

//for use in cities page and restarant-details.
export const CityListContext = React.createContext();
export class CityListProvider extends PureComponent {
  state = {
    fetchProgress: true,
    noDataMsg: "No items Yet.",
    records: [],
    totalRecords: 0,
    apiBasePath: this.props.apiBasePath,
    apiRoutes: this.props.apiRoutes
  };

  componentDidMount() {
    this.fetchCities();
  }

  render() {
    return (
      <CityListContext.Provider
        value={{
          ...this.state,
          fetchCities: this.fetchCities.bind(this)
        }}
      >
        {this.props.children}
      </CityListContext.Provider>
    );
  }

  async fetchCities(limit, offset) {
    this.setState({
      fetchProgress: true
    });
    const { apiRoutes, apiBasePath } = this.state;
    if (!apiRoutes || !apiBasePath) return false;
    const citiesResponse = await fetch(
      apiBasePath +
      apiRoutes.fetchCities +
      `?user_id=${this.props.user.userId}&limit=${limit ||
      0}&offset=${offset || 0}`,
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));

    if (!citiesResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching cities");
    }

    const citiesData = await citiesResponse
      .json()
      .catch(err => console.log(err));
    if (!citiesData) {
      return toast.error("An Error Occured while fetching cities");
    }
    if (!citiesData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error(citiesData.msg);
    }
    this.setState({
      records: citiesData.data,
      fetchProgress: false,
      totalRecords: citiesData.total
    });
  }
}

export const RootProvider = combine([AuthProvider, DataProvider]);
export const RootConsumer = combine([
  AuthContext.Consumer,
  DataContext.Consumer
]);
