import { throttleTime } from "./utilities";

import { } from "./";

export async function fetchCategories({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const categoriesResponse = await fetch(
    apiBasePath +
    apiRoutes.getMenuCategories +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!categoriesResponse) {
    let msg = "An Error Occured.";
    return { msg, success: false };
  }

  if (categoriesResponse.status == 403) {
    return {
      success: false,
      logout: true,
      msg: "Your session Expired. Please login again."
    };
  }

  const categoriesResult = await categoriesResponse.json();
  if (!categoriesResult) {
    let msg = "An Error Occured";
    return { msg, success: false };
  }
  if (!categoriesResult.success) {
    return {
      success: false,
      msg: categoriesResult.msg
    };
  }
  return categoriesResult;
}

export async function fetchSubCategories({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const subCatResponse = await fetch(
    apiBasePath +
    apiRoutes.getSubCategories +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!subCatResponse) {
    let msg = "An Error Occured.";
    return {
      success: false,
      msg
    };
  }

  const subCatResult = await subCatResponse.json();
  if (!subCatResult) {
    let msg = "An Error Occured";
    return {
      success: false,
      msg: msg
    };
  }
  if (!subCatResult.success) {
    return { msg: subCatResult.msg, success: false };
  }
  return subCatResult;
}

export async function fetchCustomMessages({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const CustomMsgsResponse = await fetch(
    apiBasePath +
    apiRoutes.getCustomMessagesHeader +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!CustomMsgsResponse) {
    let msg = "An Error Occured.";
    return {
      success: false,
      msg
    };
  }

  const CustomMsgsResult = await CustomMsgsResponse.json();
  if (!CustomMsgsResult) {
    let msg = "An Error Occured";
    return {
      success: false,
      msg: msg
    };
  }
  if (!CustomMsgsResult.success) {
    return { msg: CustomMsgsResult.msg, success: false };
  }
  return CustomMsgsResult;
}

export async function fetchCustomMessagesFooter({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const CustomMsgsFooterResponse = await fetch(
    apiBasePath +
    apiRoutes.getCustomMessagesFooter +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!CustomMsgsFooterResponse) {
    let msg = "An Error Occured.";
    return {
      success: false,
      msg
    };
  }

  const CustomMsgsFooterResult = await CustomMsgsFooterResponse.json();
  if (!CustomMsgsFooterResult) {
    let msg = "An Error Occured";
    return {
      success: false,
      msg: msg
    };
  }
  if (!CustomMsgsFooterResult.success) {
    return { msg: CustomMsgsFooterResult.msg, success: false };
  }
  return CustomMsgsFooterResult;
}

export async function fetchItemRoutes({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const itemRouteResponse = await fetch(
    apiBasePath +
    apiRoutes.getItemRoute +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!itemRouteResponse) {
    let msg = "An Error Occured.";
    return {
      success: false,
      msg
    };
  }

  const itemRouteResult = await itemRouteResponse.json();
  if (!itemRouteResult) {
    let msg = "An Error Occured";
    return {
      success: false,
      msg: msg
    };
  }
  if (!itemRouteResult.success) {
    return { msg: itemRouteResult.msg, success: false };
  }
  return itemRouteResult;
}


export async function fetchOrderTypes({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const OrderTypeResponse = await fetch(
    apiBasePath +
    apiRoutes.getOrderType +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!OrderTypeResponse) {
    let msg = "An Error Occured.";
    return {
      success: false,
      msg
    };
  }

  const OrderTypeResult = await OrderTypeResponse.json();
  if (!OrderTypeResult) {
    let msg = "An Error Occured";
    return {
      success: false,
      msg: msg
    };
  }
  if (!OrderTypeResult.success) {
    return { msg: OrderTypeResult.msg, success: false };
  }
  return OrderTypeResult;
}

export async function fetchMenuItems({
  CONSTANTS,
  userId,
  restarantId,
  authToken
}) {
  if (!this || this === window) {
    return console.error(
      "the function fetchMenuItems is called on window, but should be called through a provider context like this.context.fetchMenuItems."
    );
  }
  let limit = this.recordsPerPage;
  let offset = (this.currentPageNum - 1) * this.recordsPerPage;
  this.itemsLoadingProgress = true;
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const requestSendTime = Date.now();
  const itemsResponse = await fetch(
    apiBasePath +
    apiRoutes.getMenuItems +
    `?user_id=${userId}&limit=${0}&offset=${0}&restarant_id=${restarantId}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!itemsResponse) {
    let msg = "An Error Occured.";
    this.itemsLoadingProgress = false;
    return {
      success: false,
      msg
    };
  }

  const itemsResult = await itemsResponse.json();
  if (!itemsResult) {
    let msg = "An Error Occured";
    this.itemsLoadingProgress = false;

    return {
      success: false,
      msg: msg
    };
  }
  if (itemsResponse.status == 403) {
    return { ...itemsResult, logout: true };
  }
  if (!itemsResult.success) {
    this.itemsLoadingProgress = false;
    return { msg: itemsResult.msg, success: false };
  }
  let self = this;
  throttleTime(
    () => {
      // this.menuItems = itemsResult.data;
      // self.itemsLoadingProgress = false;
    },
    requestSendTime,
    500
  );
  this.menuItems = itemsResult.data;
  this.allMenuItems = itemsResult.data;
  this.itemsLoadingProgress = false;
  this.totalItems = itemsResult.total;
  return itemsResult;
}

export async function submitMenuItemForm(formValues) {
  let selectedItemId = this.selectedItemId;
  const { apiBasePath, apiRoutes } = this.CONSTANTS;
  const savedResponse = await fetch(
    apiBasePath +
    (selectedItemId ? apiRoutes.editMenuItem : apiRoutes.createMenuItem),
    {
      headers: {
        Authorization: this.authToken,
        "Content-type": "application/json"
      },
      method: selectedItemId ? "PUT" : "POST",
      body: JSON.stringify({
        user_id: this.user.userId,
        item_title: formValues.item__name.trim(),
        item_short_title: formValues.item__short__name.trim(),
        item_quantity: formValues.item__qtyTitle.trim(),
        item_description: formValues.item__desc.trim(),
        item_type: formValues.item__type,
        item_price: formValues.item__price,
        item_category_id: formValues.item__cat,
        item_sub_category_id: formValues.item__subCat,
        item_cgst: formValues.item__cgst,
        item_sgst: formValues.item__sgst,
        item_discount: formValues.item__discount,
        item_markup_price: formValues.item__markup_price,
        item_weight: formValues.item__weight,
        item_sort_order: formValues.item__sort_order,
        item_current_stock: formValues.item__current_stock,
        item_route: formValues.item__route,
        item_state: formValues.item__state,
        item_pos_status: formValues.item__pos__status,
        item_online_status: formValues.item__online__status,
        item_id: selectedItemId //add-query - undefined - no problem
      })
    }
  ).catch(err => console.error(err));
  if (!savedResponse) {
    let msg = "An Error Occured while saving the changes.";
    this.saveProgress = false;
    return { success: false, msg };
  }

  const savedResponseResult = await savedResponse
    .json()
    .catch(err => console.error(err));
  if (!savedResponseResult) {
    let msg = "An Error Occured while saving the changes.";
    this.saveProgress = false;
    return {
      success: false,
      msg
    };
  }
  if (!savedResponseResult.success) {
    this.saveProgress = false;
    return {
      success: false,
      msg: savedResponseResult.msg
    };
  }

  return savedResponseResult;
}

export async function fetchDiscounts({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const DiscountsResponse = await fetch(
    apiBasePath +
    apiRoutes.getDiscounts +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!DiscountsResponse) {
    let msg = "An Error Occured.";
    return {
      success: false,
      msg
    };
  }

  const DiscountsResult = await DiscountsResponse.json();
  if (!DiscountsResult) {
    let msg = "An Error Occured";
    return {
      success: false,
      msg: msg
    };
  }
  if (!DiscountsResult.success) {
    return { msg: DiscountsResult.msg, success: false };
  }
  return DiscountsResult;
}

export async function fetchCoupons({
  CONSTANTS,
  userId,
  authToken,
  limit,
  offset
}) {
  const { apiBasePath, apiRoutes } = CONSTANTS;
  const CouponsResponse = await fetch(
    apiBasePath +
    apiRoutes.getCoupons +
    `?user_id=${userId}&limit=${limit}&offset=${offset}`,
    { headers: { Authorization: authToken } }
  ).catch(err => console.error(err));
  if (!CouponsResponse) {
    let msg = "An Error Occured.";
    return {
      success: false,
      msg
    };
  }

  const CouponsResult = await CouponsResponse.json();
  if (!CouponsResult) {
    let msg = "An Error Occured";
    return {
      success: false,
      msg: msg
    };
  }
  if (!CouponsResult.success) {
    return { msg: CouponsResult.msg, success: false };
  }
  return CouponsResult;
}



export async function deleteItem(itemId) {
  const { apiBasePath, apiRoutes } = this.CONSTANTS;
  const deletedResponse = await fetch(apiBasePath + apiRoutes.deleteMenuItem, {
    headers: {
      Authorization: this.authToken,
      "Content-type": "application/json"
    },
    method: "DELETE",
    body: JSON.stringify({
      item_id: itemId,
      user_id: this.user.userId,
      restarant_id: this.user.selectedRestarantId
    })
  }).catch(err => console.error(err));

  if (!deletedResponse) {
    let msg = "An Error Occured while deleting the sub-category.";
    return {
      msg,
      success: false
    };
  }

  const deletedResponseResult = await deletedResponse
    .json()
    .catch(err => console.error(err));
  if (!deletedResponseResult) {
    let msg = "An Error Occured while deleting the sub-category.";
    return {
      msg,
      success: false
    };
  }
  if (!deletedResponseResult.success) {
    return {
      msg: deletedResponseResult.msg,
      success: false
    };
  }
  this.currentPageNum =
    this.menuItems.length > 1
      ? this.currentPageNum
      : this.currentPageNum - 1 > 0
        ? this.currentPageNum - 1
        : 1;

  return {
    success: true
  };
}

export async function fetchBearers(url, options) {
  const bearersResponse = await fetch(url, options).catch(err =>
    console.error(err)
  );

  if (!bearersResponse)
    return {
      success: false,
      msg: "An Error Occured while fetching bearers."
    };

  const bearersResult = await bearersResponse
    .json()
    .catch(err => console.error(err));
  if (!bearersResult)
    return {
      success: false,
      msg: "An Error Occured while fetching bearers."
    };
  return bearersResult;
}

export async function submitTaxPercentage(taxRate, taxName, discountRate) {
  const { apiBasePath, apiRoutes } = this.CONSTANTS;
  const savedResponse = await fetch(
    apiBasePath + apiRoutes.setRestarantTaxRate,
    {
      headers: {
        Authorization: this.authToken,
        "Content-type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        user_id: this.user.userId,
        tax_rate: taxRate,
        tax_name: taxName,
        discount_rate: discountRate,
        restarant_id: this.user.selectedRestarantId
      })
    }
  ).catch(err => console.error(err));
  if (!savedResponse) {
    let msg = "An Error Occured while saving the tax rate.";
    this.saveProgress = false;
    return { success: false, msg };
  }

  const savedResponseResult = await savedResponse
    .json()
    .catch(err => console.error(err));
  if (!savedResponseResult) {
    let msg = "An Error Occured while saving the tax rate.";
    this.saveProgress = false;
    return {
      success: false,
      msg
    };
  }
  if (!savedResponseResult.success) {
    this.saveProgress = false;
    return {
      success: false,
      msg: savedResponseResult.msg
    };
  }

  return savedResponseResult;
}

export async function getRestarantTaxRate() {
  if (!this || this === window) {
    return console.error(
      "the function fetchMenuItems is called on window, but should be called through a provider context like this.context.fetchMenuItems."
    );
  }

  this.fetchTaxRateProgress = true;
  const { apiBasePath, apiRoutes } = this.CONSTANTS;
  const { user } = this;

  const taxRateResponse = await fetch(
    apiBasePath +
    apiRoutes.getRestarantTaxRate +
    `?user_id=${user.userId}&restarant_id=${user.selectedRestarantId}`,
    { headers: { Authorization: this.authToken } }
  ).catch(err => console.error(err));
  if (!taxRateResponse) {
    let msg = "An Error Occured.";
    this.itemsLoadingProgress = false;
    return {
      success: false,
      msg
    };
  }

  const taxRateResult = await taxRateResponse
    .json()
    .catch(err => console.error(err));
  if (!taxRateResult) {
    let msg = "An Error Occured";
    this.fetchTaxRateProgress = false;

    return {
      success: false,
      msg: msg
    };
  }
  if (!taxRateResult.success) {
    this.fetchTaxRateProgress = false;
    return { msg: taxRateResult.msg, success: false };
  }

  return taxRateResult;
}

export async function getOtp({ apiBasePath, apiRoutes, email }) {
  if (!apiBasePath || !apiRoutes || !email) {
    console.error("undefined_params", arguments);
    return { success: false, msg: "An Error Occured while requesting OTP" };
  }

  const getOtpResponse = await fetch(apiBasePath + apiRoutes.forgotPwdEmail, {
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ email }),
    method: "POST"
  }).catch(err => console.error(err));
  if (!getOtpResponse) {
    return { success: false, msg: "An Error Occured while requesting OTP" };
  }
  let getOtpResponseJSON = await getOtpResponse
    .json()
    .catch(err => console.error(err));
  // if(!getOtpResponseJSON.success){
  return getOtpResponseJSON;
  // }
}

export async function submitOtp({ apiBasePath, apiRoutes, otpId, otp }) {
  if (!apiBasePath || !apiRoutes || !otpId || !otp) {
    console.error("undefined_params", arguments);
    return { success: false, msg: "An Error Occured while submitting OTP." };
  }

  const submitOtpResponse = await fetch(apiBasePath + apiRoutes.verifyOtp, {
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ otp_id: otpId, otp }),
    method: "POST"
  }).catch(err => console.error(err));
  if (!submitOtpResponse) {
    return { success: false, msg: "An Error Occured while validating OTP" };
  }
  let submitOtpResponseJSON = await submitOtpResponse
    .json()
    .catch(err => console.error(err));
  // if(!getOtpResponseJSON.success){
  return submitOtpResponseJSON;
  // }
}

export async function resetPwd({ apiBasePath, apiRoutes, resetToken, pwd }) {
  if (!apiBasePath || !apiRoutes || !pwd || !resetToken) {
    console.error("undefined_params", arguments);
    return { success: false, msg: "An Error Occured while resetting password" };
  }

  const submitPwdResponse = await fetch(apiBasePath + apiRoutes.resetPwd, {
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ reset_token: resetToken, pwd }),
    method: "PUT"
  }).catch(err => console.error(err));
  if (!submitPwdResponse) {
    return { success: false, msg: "An Error Occured while requesting OTP" };
  }
  let submitPwdResponseJSON = await submitPwdResponse
    .json()
    .catch(err => console.error(err));
  // if(!getOtpResponseJSON.success){
  return submitPwdResponseJSON;
  // }
}

export async function fetchFilteredOrders({
  filters,
  CONSTANTS,
  user,
  authToken,
  restarantId
}) {
  const { apiRoutes, apiBasePath, userRoles } = CONSTANTS;
  const url =
    apiBasePath +
    apiRoutes.getRestarantCompletedOrders +
    `?user_id=${
    user.userId
    }&restaurant_id=${restarantId}&limit=0&offset=0&start_date=${
    filters.startDate
    }&end_date=${filters.endDate}`;
  const ordersResponse = await fetch(url, {
    headers: {
      Authorization: authToken,
      "Content-type": "application/json"
    }
  }).catch(err => console.error(err));

  if (!ordersResponse) {
    return { success: false, msg: "An Error Occured while fetching orders" };
  }

  const ordersData = await ordersResponse
    .json()
    .catch(err => console.error(err));
  if (!ordersData) {
    return { success: false, msg: "An Error Occured while fetching orders" };
  }
  return ordersData;
}

export async function fetchFilteredTakeAwayOrders({
  filters,
  CONSTANTS,
  user,
  authToken,
  restarantId
}) {
  const { apiRoutes, apiBasePath, userRoles } = CONSTANTS;
  const url =
    apiBasePath +
    apiRoutes.getRestarantTakeAwayOrders +
    `?user_id=${
    user.userId
    }&restaurant_id=${restarantId}&limit=0&offset=0&start_date=${
    filters.startDate
    }&end_date=${filters.endDate}`;
  const ordersResponse = await fetch(url, {
    headers: {
      Authorization: authToken,
      "Content-type": "application/json"
    }
  }).catch(err => console.error(err));

  if (!ordersResponse) {
    return { success: false, msg: "An Error Occured while fetching orders" };
  }

  const ordersData = await ordersResponse
    .json()
    .catch(err => console.error(err));
  if (!ordersData) {
    return { success: false, msg: "An Error Occured while fetching orders" };
  }
  return ordersData;
}

export async function sendFcmTokenToServer({
  userId,
  fcmToken,
  oldToken,
  baseUrl,
  route,
  authToken,
  userRoles
}) {
  let response = await fetch(baseUrl + route, {
    method: "post",
    headers: { "content-type": "application/json", Authorization: authToken },
    body: JSON.stringify({
      user_id: userId,
      fcm_token: fcmToken,
      old_token: oldToken,
      userRoles
    })
  }).catch(err => console.error(err));
  if (!response) {
    return { success: false, msg: "An Error Occured while saving fcmToken." };
  }
  response = await response.json().catch(err => console.error);
  if (!response) {
    return { success: false, msg: "An Error Occured while saving fcmToken." };
  }
  return response;
}
