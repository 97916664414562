import buvvas_logo from './login_logo.png';
import edit_img from './edit.png';
import delete_img from './delete.png';
import noteImg from './note.png';
import fries from './fries.png';
export const BUVVAS_LOGO = buvvas_logo;
export const EDIT_IMG = edit_img;
export const DELETE_IMG = delete_img;
export const NOTE_IMG = noteImg;
export const STARTERS_IMAGE = fries;
export const USER_ICON = require("./user_icon.png");
export const CURRIES_IMAGE = require("./curry.png");
export const BIRYANI_IMAGE = require("./biryani.png");
export const SANDWICH_IMAGE = require("./sandwich.png");
export const FRIED_RICE_IMAGE = require("./fried-rice.png");
export const COOL_DRINK_IMAGE = require("./cool-drink.png");
export const MOCK_TAILS_IMAGE = require("./mock-tails.png");
export const FRUIT_JUICES_IMAGE = require("./fruit-juices.png");
export const DESSERTS_IMAGE = require("./desserts.png");
export const TEA_COFFEE_IMAGE = require("./tea-coffe.png");
export const FB = require("./social-1.png")
export const LINKEDIN = require("./social-2.png")
export const TWITTER = require("./social-3.png")
export const INSTA = require("./social-4.png")
export const BURGER = require("./burger.png")
export const NOODLES = require("./noodles.png")
export const SOUP = require("./soup.png")
export const ICECREAM = require("./ice-cream.png")
export const INDIANBREAD = require("./indian-breads.png")
export const TANDOORIITEMS = require("./tandoori items.png")
export const SHAKES = require("./shakes.png")
export const BEVERGERS = require("./bevergers.png")
export const BREAKFAST = require("./breakfast.png")
export const LUNCH = require("./lunch.png")
export const ALCOHOLICBEVERAGES = require("./alcoholic-bevergers.png")
export const PIZZA = require("./pizza.png")