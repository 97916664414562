import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateInput } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

export default () => {
  return (
    <AuthContext.Consumer>
      {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
        return (
          <DataContext.Consumer>
            {({ fetchOrderTypes }) => {
              return (
                <OrderType
                  user={user}
                  authToken={authToken}
                  CONSTANTS={CONSTANTS}
                  isSuperAdmin={isSuperAdmin}
                  fetchOrderTypes={fetchOrderTypes}
                />
              );
            }}
          </DataContext.Consumer>
        );
      }}
    </AuthContext.Consumer>
  );
};

class OrderType extends Component {
  state = {
    ordertypes: [],
    fetchProgress: true,
    noDataMsg: "No Oder-types Yet.",
    totalOrderTypes: 0,
    currentPageNum: 1,
    recordsPerPage: 10,
    saveProgress: false,
    deleteProgress: false,
    saveFailMsg: "",
    deleteFailMsg: "",
    selectedOrderTypeId: null

  }

  componentWillMount() {
    this.modalRef = React.createRef();
    this.modalUI = React.createRef();
  }

  componentDidMount() {
    this._loadOrderTypes();
    $(this.modalUI.current).on("shown.bs.modal", () => {
    });

    $(this.modalUI.current).on("hidden.bs.modal", () => {
      this.modalRef.current.setState({
        editMode: false
      });
      this.setState({
        selectedOrderTypeId: null
      });
      this.modalRef.current.formApi.reset();
    });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  render() {
    return (
      <>
        <div className="content">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
              <>
                <div className="row mx-0 justify-content-between align-items-center">
                  <div className="">
                    <span className="categorycol">Order-Type</span>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btnaddcategory float-right"
                      data-toggle="modal"
                      data-target="#orderTypeModal"
                    >
                      <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                      &nbsp; Add Order-Type
                    </button>
                  </div>
                </div>
                {this.state.ordertypes.length ? (
                  <div className="table-responsive mt-3 tableofauto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Order-Type</th>
                          <th className="actionWidth">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ordertypes.map((ot, idx) => (
                          <tr key={String(idx)}>
                            <td>
                              {(this.state.currentPageNum - 1) *
                                this.state.recordsPerPage +
                                idx +
                                1}
                            </td>

                            <td>{ot.orderTypeName}</td>
                            <td>
                              <img
                                alt=""
                                src={EDIT_IMG}
                                data-toggle="modal"
                                data-target="#orderTypeModal"
                                className="icon"
                                onClick={() => {
                                  this.setState({
                                    selectedOrderTypeId: ot.orderTypeId
                                  });
                                  this.modalRef.current.setState({
                                    editMode: true
                                  });
                                  this.modalRef.current.formApi.setValue(
                                    "OrderType__name",
                                    ot.orderTypeName
                                  );
                                  // this.modalRef.current.formApi.setValue(
                                  //   "OrderType__Id",
                                  //   ot.OrderTypeId
                                  // );
                                  //here we want to pass id
                                }}
                              />
                              <img
                                alt=""
                                src={DELETE_IMG}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.confirmAndDelete(
                                    ot.orderTypeName,
                                    ot.orderTypeId
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                    <div className="noDataMsgContainer">
                      <h5>{this.state.noDataMsg}</h5>
                    </div>
                  )}
              </>
            )}
          {this._pagination()}
          <OrderTypeModal
            ref={this.modalRef}
            submitOrderTypeForm={this._submitOrderTypeForm.bind(this)}
            modalUiRef={this.modalUI}
          />
        </div>
      </>
    )
  }
  //above render mtd close

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalOrderTypes / this.state.recordsPerPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.state.totalOrderTypes / this.state.recordsPerPage
            )}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this._getMore(data.selected + 1)}
            containerClassName={"pagination"}
            activeLinkClassName={"active"}
            forcePage={this.state.currentPageNum - 1}
          />
        ) : null}
      </div>
    );
  }

  _getMore(val) {
    this.setState(
      {
        currentPageNum: val
      },
      err => {
        this._loadOrderTypes();
      }
    );
  }

  async _loadOrderTypes() {
    this.setState({
      fetchProgress: true
    });
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const requestSendTime = Date.now();
    const OrderTypeResponse = await fetch(
      apiBasePath +
      apiRoutes.getOrderType +
      `?user_id=${this.props.user.userId}&limit=${
      this.state.recordsPerPage
      }&offset=${(this.state.currentPageNum - 1) *
      this.state.recordsPerPage}`,
      { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!OrderTypeResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const OrderTypeResult = await OrderTypeResponse.json();
    if (!OrderTypeResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!OrderTypeResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: OrderTypeResult.msg
      });
      return toast.error(OrderTypeResult.msg);
    }
    throttleTime(
      () => {
        this.setState({
          ordertypes: OrderTypeResult.data,
          totalOrderTypes: OrderTypeResult.total,
          fetchProgress: false
        });
      },
      requestSendTime,
      500
    );
  }

  async _submitOrderTypeForm(formValues) {
    if (this.state.saveProgress) return false;
    this.modalRef.current.setState({
      saveProgress: true
    });
    let aggOrdertypesList = Object.keys(this.props.CONSTANTS.aggregatorPlatforms);
    let aggOrdertype = aggOrdertypesList.filter(o => { return o == formValues.OrderType__name })
    if (aggOrdertype.length) {
      this.modalRef.current.setState({
        saveProgress: false
      });
      return toast.error(`couldn't add the orderType ${formValues.OrderType__name}`);
    }
    let OrderTypeId = this.state.selectedOrderTypeId;
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const savedResponse = await fetch(
      apiBasePath +
      (OrderTypeId ? apiRoutes.editOrderType : apiRoutes.createOrderType),
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: OrderTypeId ? "PUT" : "POST",
        body: JSON.stringify({
          user_id: this.props.user.userId,
          restarant_id: this.props.user.selectedRestarantId,
          order_type: formValues.OrderType__name.trim(),
          ordertype_id: OrderTypeId

        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while ${
        OrderTypeId ? "saving the changes" : "adding the order-type"
        }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while ${
        OrderTypeId ? "saving the changes" : "adding the order-type"
        }.`;
      this.modalRef.current.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      this.modalRef.current.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.msg
      });
      return toast.error(savedResponseResult.msg);
    }

    this.modalRef.current.setState({
      saveProgress: false,
      saveFailMsg: ""
    });
    this.setState(
      {
        currentPageNum: 1
      },
      err => {
        this._loadOrderTypes();
      }
    );
    $(this.modalUI.current).modal("hide");
    toast.success(
      `Successfully ${
      OrderTypeId ? "saved the changes" : "added " + formValues.OrderType__name
      }.`,
      { autoClose: 4000 }
    );
  }

  confirmAndDelete(name, OrderTypeId) {
    confirmAlert({
      title: "Delete?",
      message: `Are you sure to delete ${name || ""}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteOrderType(name, OrderTypeId)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteOrderType(name, OrderTypeId) {
    if (this.state.deleteProgress) {
      return false;
    }
    this.setState({
      deleteProgress: true
    });

    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const deletedResponse = await fetch(
      apiBasePath + apiRoutes.deleteOrderType,
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: "DELETE",
        body: JSON.stringify({
          ordertype_id: OrderTypeId,
          user_id: this.props.user.userId,
          restarant_id: this.props.user.selectedRestarantId
        })
      }
    ).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the order-type.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the order type.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    if (!deletedResponseResult.success) {
      this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });

      return toast.error(deletedResponseResult.msg);
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: "",
      currentPageNum:
        this.state.ordertypes.length > 1
          ? this.state.currentPageNum
          : this.state.currentPageNum - 1 > 0
            ? this.state.currentPageNum - 1
            : 1
    });
    this._loadOrderTypes();
    toast.success(` successfully Deleted ${name}`, { autoClose: 5000 });
  }

  //above only write
}

//here i create form of add order type
class OrderTypeModal extends Component {
  state = {
    editMode: false,
    categories: [],
    saveProgress: false
  };

  render() {
    const { modalUiRef, submitOrderTypeForm } = this.props;
    return (
      <div
        ref={modalUiRef}
        className="modal fade"
        id="orderTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.editMode
                  ? "Edit Order-Types"
                  : "Add Order-Types"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                  </span>
              </button>
            </div>
            <Form
              onSubmit={submitOrderTypeForm}
              getApi={this._setFormApi.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row mb-sm-3">

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val =>
                                validateInput(
                                  val,
                                  1,
                                  formState.touched.OrderType__name,
                                  "order type",
                                  30
                                )
                              }
                              validateOnBlur
                              validateOnChange
                              field={"OrderType__name"}
                              type="text"
                              placeholder="Enter Order-type"
                              className={
                                formState.errors.OrderType__name
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.OrderType__name && (
                              <div className="input_error">
                                {formState.errors.OrderType__name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.state.editMode ? "Save Changes" : "Add"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _setFormApi(formApi) {
    this.formApi = formApi;
  }

  _validateorderTypeName(name) {
    if (!this.formApi.getState().touched.OrderType__name) {
      //this is the first focus, do not validate.
      return false;
    }
    name = name && name.trim();
    if (!/[a-zA-Z]*/.test(name)) {
      return "Enter a valid Order Type Name.";
    }
    if (!name || name.length < 3) {
      return "order type name must contain atleast 3 Characters.";
    }
  }

}