export const throttleTime = (func, requestSendTime, ms) => {
  const waitMoreTime = Math.max(ms - (Date.now() - requestSendTime), 0);
  setTimeout(function() {
    func();
  }, waitMoreTime);
};

export const validateNumber = (val, touched, name, conditions) => {
  if (!touched) return null;
  if (val === "" || isNaN(val)) return "please input a valid number";
};

export const validatePositiveNonZeroNumber = (val, touched, name) => {
  if (!touched) return null;
  if (val === "" || isNaN(val)) return "please input a valid number";

  if (Number(val) <= 0) {
    return `${name} should be greater than zero.`;
  }
};

export const validateWholeNumber = (val, name) => {
  if (val === "" || isNaN(val)) return "please input a valid number";

  if (Number(val) < 0) {
    return `${name} should be greater than zero.`;
  }
};

export const validatePositiveInteger = (val, touched, name) => {
  let err = validatePositiveNonZeroNumber(val, touched, name);
  if (err) return err;
  return Number.isInteger(val) ? null : `Enter a valid Number.`;
};

export const validateText = (val, minLength, touched, name, maxLength) => {
  if (
    typeof val == "undefined" ||
    typeof minLength == "undefined" ||
    typeof touched == "undefined" ||
    typeof name == "undefined"
  ) {
    // console.warn("missing arguments ", val, touched, name);
  }

  if (!name) name = "value";
  if (typeof minLength == "undefined") minLength = 4;
  if (!touched) {
    //this is the first focus, do not validate.
    return null;
  }
  val = val && val.trim();
  if (!/[a-zA-Z]+/.test(val)) {
    return `Enter a valid ${name}.`;
  }
  if (!val) return `${name} cannot be empty`;
  if (!val || val.length < minLength) {
    return `${name} must contain atleast ${minLength} Characters.`;
  }
  if (maxLength && val.length > maxLength) {
    return `${name} must not exceed ${maxLength} characters`;
  }
};

//same as validateText, but do not require presence of alphabets.
export const validateInput = (val, minLength, touched, name, maxLength) => {
  if (
    typeof val == "undefined" ||
    typeof minLength == "undefined" ||
    typeof touched == "undefined" ||
    typeof name == "undefined"
  ) {
    // console.warn("missing arguments ", val, touched, name);
  }

  if (!name) name = "value";
  if (typeof minLength == "undefined") minLength = 4;
  if (!touched) {
    //this is the first focus, do not validate.
    return null;
  }
  val = val && val.trim();
  if (!/[a-zA-Z]*/.test(val)) {
    //<-- here * instead of + as in validateText
    return `Enter a valid ${name}.`;
  }
  if (!val) return `${name} cannot be empty`;
  if (!val || val.length < minLength) {
    return `${name} must contain atleast ${minLength} Characters.`;
  }
  if (maxLength && val.length > maxLength) {
    return `${name} must not exceed ${maxLength} characters`;
  }
};

export const validateStrictText = (
  val,
  minLength,
  touched,
  name,
  maxLength = 50
) => {
  if (
    typeof val == "undefined" ||
    typeof minLength == "undefined" ||
    typeof touched == "undefined" ||
    typeof name == "undefined"
  ) {
    // console.warn("missing arguments ", val, touched, name);
  }

  if (!name) name = "value";
  if (typeof minLength == "undefined") minLength = 4;
  if (!touched) {
    //this is the first focus, do not validate.
    return false;
  }
  val = val && val.trim();
  if (!/^[a-zA-Z\s]*$/.test(val)) {
    return `Enter a valid ${name}.`;
  }
  if (!val) return `${name} cannot be empty`;
  if (!val || val.length < minLength || val.length > maxLength) {
    return `${name} must contain ${minLength} to ${maxLength} Characters.`;
  }
};

export function validatePhone(val, touched) {
  let err =
    (!val ||
      val.length < 6 ||
      val.length > 16 ||
      !new RegExp(/^[0-9]+$/).test(val)) && //new RegExp(/^[\+-]{0,1}[0-9]+$/)
    touched
      ? "Please input valid Phone Number"
      : null;
  return err;
}

export function validateEmail(val, touched) {
  if (typeof touched == "undefined") touched = true;
  if (!touched) return null;
  if (!val) return "Enter Email.";
  let valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val);

  if (!valid) return "Enter a valid Email";
}

export function shouldShowPagination(totalValues, perPage) {
  return Math.floor(totalValues / perPage) >= 1;
}

//https://stackoverflow.com/a/5100158/7314900
export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

//got from https://stackoverflow.com/a/23796060/7314900
export const enumerateDaysBetweenDates = function(startDate, endDate) {
  var now = startDate.clone().startOf("day"),
    dates = [];

  while (now.isSameOrBefore(endDate.startOf("day"))) {
    dates.push(now.format("D/M/YYYY"));
    now.add(1, "days");
  }
  return dates;
};

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function ellipsize(str, maxLength) {
  if (!maxLength) return str;
  return str.length > maxLength ? str.substr(0, maxLength - 1) + "..." : str;
}
export const validateStrictGst = (
  val,
  minLength,
  touched,
  name,
  maxLength = 15
) => {
  if (!name) name = "value";
  if (typeof minLength == "undefined") minLength = 4;
  if (!touched) {
    return false;
  }
  val = val && val.trim();
  if (val && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(val)) {
    return `Enter a valid ${name}.`;
  }
  //if (!val) return `${name} cannot be empty`;
  if (val && ( val.length < minLength || val.length > maxLength)) {
    return `${name} must contain ${minLength} to ${maxLength} Characters.`;
  }
};
export const validateStrictGstdescription = (
  val,
  minLength,
  touched,
  name,
  maxLength = 40
) => {
  if (!name) name = "value";
  if (typeof minLength == "undefined") minLength = 4;
  if (!touched) {
    return false;
  }
  val = val && val.trim();
  if (!/^[a-zA-Z\s]*$/.test(val)) {
    return `Enter a valid ${name}.`;
  }
};

