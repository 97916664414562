import React, { Component } from "react";
import { NOTE_IMG, EDIT_IMG, DELETE_IMG } from "../assets/images";
import { AuthContext, DataProvider, DataContext } from "../contextProvider";
import { throttleTime } from "../utilities";
import Loader from "react-loader-spinner";
import { Form, Text, Select } from "informed";
import {
  validateNumber,
  validatePositiveNonZeroNumber,
  validatePositiveInteger,
  validateText
} from "../utilities";
import $ from "jquery";
import { fetchBearers } from "../services";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
export default class TablesList extends Component {
  state = {
    fetchProgress: true,
    totalTables: 0,
    perPage: 10,
    currentPageNum: 1,
    tables: [],
    noDataMsg: "No Records.",
    selectedTableId: null,
    saveProgress: false,
    bearers: [],
    assignProgress: false,
    selectedRestarantId: this.context.user.selectedRestarantId || 0
  };

  static contextType = AuthContext;

  componentWillMount() {
    this.editModalUI = React.createRef();
    this.assignModalUI = React.createRef();
    if (this.props.match.params.rId) {
      this.setState(
        {
          selectedRestarantId: this.props.match.params.rId
        },
        err => {
          this._fetchTables();
        }
      );
    } else {
      this._fetchTables();
    }
  }

  componentDidMount() {
    $(this.editModalUI.current).on("hidden.bs.modal", () => {
      this.tableInputForm.reset();
      this.setState({
        selectedTableId: null
      });
    });

    $(this.assignModalUI.current).on("hidden.bs.modal", () => {
      this.assignTableForm.reset();
      this.setState({
        selectedTableId: null
      });
    });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  render() {
    return (
      <DataProvider
        loadRestarantsOnWillMount={this.context.isSuperAdmin}
        {...this.context}
      >
        <div className="contentvthbtn">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
              <>
                <div className="row mx-0 justify-content-between">
                  <div className="">
                    <span className="categorycol">Tables</span>
                  </div>
                  <div className="">
                    {this.context.isSuperAdmin && (
                      <DataContext.Consumer>
                        {({ restarants }) => {
                          return (
                            <select
                              onChange={e =>
                                this.filterByRestarant(e.nativeEvent.target.value)
                              }
                              className="select_Restaurent"
                              defaultValue={this.state.selectedRestarantId}
                            >
                              <option value={0}>All Restaurants</option>
                              {restarants &&
                                restarants.map((r, idx) => (
                                  <option value={r.id} key={String(idx)}>
                                    {r.name}
                                  </option>
                                ))}
                            </select>
                          );
                        }}
                      </DataContext.Consumer>
                    )}
                    <button
                      type="button"
                      className="btnaddcategory"
                      data-toggle="modal"
                      data-target="#addTableModal"
                    >
                      <i className="fa fa-plus plusicon" aria-hidden="true" />
                    &nbsp; Add tables
                  </button>
                  </div>
                </div>
                {this.state.tables.length ? (
                  <div className="table-responsive mt-3 text-capitalize tableofauto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>table number</th>
                          <th>table Name</th>
                          {/* <th className="text-centerimp">Seating capacity</th> */}
                          <th className="actionWidth1">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tables.map((t, idx) => {
                          return (
                            <tr key={String(idx)}>
                              <td>
                                {(this.state.currentPageNum - 1) *
                                  this.state.perPage +
                                  (idx + 1)}
                              </td>
                              <td>table {t.tableNum}</td>
                              <td>{t.tableName}</td>
                              {/* <td className="text-center">{t.tableCapacity}</td> */}
                              <td>
                                {/* <img
                                  src={NOTE_IMG}
                                  alt="Note"
                                  className="icon img-fluid"
                                  onClick={e =>
                                    this._showAssignTableModal(
                                      t.tableId,
                                      t.restarantId
                                    )
                                  }
                                /> */}
                                <img
                                  src={EDIT_IMG}
                                  alt="Edit"
                                  className="icon img-fluid"
                                  data-edit={t.tableId}
                                  onClick={e =>
                                    this._showEditModal(t.tableId, t.restarantId)
                                  }
                                />
                                <img
                                  src={DELETE_IMG}
                                  alt="Delete"
                                  className="img-fluid icon"
                                  onClick={() => this.confirmAndDelete(t.tableId)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                    <div className="noDataMsgContainer">
                      <h5>{this.state.noDataMsg}</h5>
                    </div>
                  )}
              </>
            )}
        </div>
        {this._pagination()}
        {this._assignTablesModal()}
        {this._addTablesModal()}
      </DataProvider>
    );
  }

  filterByRestarant(value) {
    this.setState(
      {
        selectedRestarantId: value,
        currentPageNum: 1
      },
      err => {
        this._fetchTables();
      }
    );
  }

  _addTablesModal() {
    return (
      <div
        ref={this.editModalUI}
        className="modal fade"
        id="addTableModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.selectedTableId ? "Edit" : "Add"} table
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              getApi={formApi => this._setFormApi(formApi, "tableInputForm")}
              onSubmit={this._submitTableForm.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row">
                        {this.context.isSuperAdmin &&
                          !this.state.selectedTableId && (
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div className="form-group">
                                <Select
                                  field="emp_restarant"
                                  type="text"
                                  className={
                                    formState.errors.emp_restarant
                                      ? "form-control mr-sm-4 input_error"
                                      : "form-control mr-sm-4"
                                  }
                                  defaultValue=""
                                  validate={v => {
                                    console.log(v);
                                    return v ? null : "Select Restaurant";
                                  }}
                                  validateOnBlur
                                >
                                  <option value="">-Select Restaurant-</option>
                                  <DataContext.Consumer>
                                    {({ restarants }) =>
                                      restarants &&
                                      restarants.map(
                                        (r, idx) =>
                                          r.name && (
                                            <option
                                              value={r.id}
                                              key={String(idx)}
                                            >
                                              {r.name.length > 40
                                                ? r.name.substr(0, 37) + "..."
                                                : r.name}
                                            </option>
                                          )
                                      )
                                    }
                                  </DataContext.Consumer>
                                </Select>
                                {formState.errors.emp_restarant && (
                                  <div className="input_error">
                                    {formState.errors.emp_restarant}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="table_num"
                              validate={val => {
                                if (!val && !formState.touched.table_num) {
                                  return; //undefined so that formState.errors will not have a key of this fieldname
                                }
                                if (val && String(val).length > 3)
                                  return "Table number is too large.";
                                return validatePositiveInteger(
                                  Number(val),
                                  formState.touched.table_num,
                                  "Table No."
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              type="text"
                              placeholder="Enter table number"
                              className={
                                formState.errors.table_num
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                            />
                            {formState.errors.table_num && (
                              <div className="input_error">
                                {formState.errors.table_num}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!val) return "please enter table name";
                                if (!/[a-zA-Z]*/.test(val)) return `Enter a valid table name.`;
                              }}
                              validateOnBlur
                              validateOnChange
                              field="table_name"
                              type="text"
                              placeholder="Enter table name"
                              className={
                                formState.errors.table_name
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                            />
                            {formState.errors.table_name && (
                              <div className="input_error">
                                {formState.errors.table_name}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!val && !formState.touched.table_capacity) {
                                  return; //undefined so that formState.errors will not have a key of this fieldname
                                }
                                if (val == undefined)
                                  return "Please Enter Table capacity.";
                                if (val > 255)
                                  return "Table capacity cannot be more than 255";
                                return validatePositiveInteger(
                                  Number(val),
                                  formState.touched.table_capacity,
                                  "Table Capacity"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="table_capacity"
                              type="text"
                              placeholder="Enter seating capacity"
                              className={
                                formState.errors.table_capacity
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                            />
                            {formState.errors.table_capacity && (
                              <div className="input_error">
                                {formState.errors.table_capacity}
                              </div>
                            )}
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="modal-footer clearfix d-block">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.state.selectedTableId
                          ? "Save Changes "
                          : "Add Table"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _assignTablesModal() {
    return (
      <div
        ref={this.assignModalUI}
        className="modal fade"
        id="assignTableToBearerModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">
                Assign to
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              getApi={formApi => this._setFormApi(formApi, "assignTableForm")}
              onSubmit={this._assigntTableToBearer.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row mr-2">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <Text
                            field="table_num"
                            type="text"
                            value="Table 1"
                            className="form-control"
                            disabled
                          />
                          {formState.errors.table_num && (
                            <div className="input_error">
                              {formState.errors.table_num}
                            </div>
                          )}
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <Select
                            field="bearer_id"
                            validateOnChange
                            placeholder="- Select Bearer -"
                            validate={v => {
                              if (!v) return "Please select a Bearer";
                            }}
                            className="form-control ml-sm-3 p-10"
                          >
                            <option value="" disabled>
                              - Select Bearer -
                            </option>
                            {this.state.bearers.map((b, idx) => {
                              return (
                                <option value={b.id} key={String(idx)}>
                                  {b.name}
                                </option>
                              );
                            })}
                          </Select>
                          {formState.errors.bearer_id && (
                            <div className="input_error">
                              {formState.errors.bearer_id}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button type="submit" className="btnmodaladd float-right">
                        Add
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalTables / this.state.perPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(this.state.totalTables / this.state.perPage)}
            marginPagesDisplayed={this.state.surroundingButtonsCount}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this.getMore(data.selected + 1)}
            containerClassName={"pagination"}
            activeLinkClassName={"active"}
            forcePage={this.state.currentPageNum - 1}
          />
        ) : null}
      </div>
    );
  }

  getMore(pageNum) {
    this.setState(
      {
        currentPageNum: pageNum
      },
      err => {
        this._fetchTables();
      }
    );
  }

  async _fetchTables() {
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath } = this.context.CONSTANTS;
    const tablesResponse = await fetch(
      apiBasePath +
      apiRoutes.getAllTables +
      `?user_id=${this.context.user.userId}&restarant_id=${
      this.context.isSuperAdmin
        ? this.state.selectedRestarantId
        : this.context.user.selectedRestarantId
      }&limit=${this.state.perPage}&offset=${(this.state.currentPageNum - 1) *
      this.state.perPage}`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));

    if (!tablesResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching tables");
    }

    const tablesData = await tablesResponse
      .json()
      .catch(err => console.log(err));
    if (!tablesData) {
      return toast.error("An Error Occured while fetching tables");
    }

    if (!tablesData.success) {
      toast.error(tablesData.msg);
      if (tablesData.invalidSession) {
        $(this.editModalUI.current).modal("hide");
        return this.context.logOut();
      }
      return this.setState({
        fetchProgress: false
      });
    }
    throttleTime(
      () => {
        this.setState({
          tables: tablesData.data,
          fetchProgress: false,
          totalTables: tablesData.total
        });
      },
      requestSendTime,
      500
    );
  }

  _setFormApi(formApi, formTitle) {
    this[formTitle] = formApi;
  }

  async _submitTableForm(formValues) {
    if (this.state.saveProgress) return false;
    this.setState({
      saveProgress: true
    });
    let tableId = this.state.selectedTableId;
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const savedResponse = await fetch(
      apiBasePath + (tableId ? apiRoutes.editTable : apiRoutes.createTable),
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: tableId ? "PUT" : "POST",
        body: JSON.stringify({
          user_id: this.context.user.userId,
          restarant_id: this.context.isSuperAdmin
            ? formValues.emp_restarant || this.state.selectedTableRestarantId
            : this.context.user.selectedRestarantId,
          // table_capacity: Number(String(formValues.table_capacity).trim()),
          table_name: formValues.table_name.trim(),
          table_num: Number(String(formValues.table_num).trim()),
          table_status: 1,
          table_id: tableId //will be undefined in add request
        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while saving the ${
        tableId ? "changes" : "tables"
        }.`;
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while saving the ${
        tableId ? "changes" : "tables"
        }.`;
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      toast.error(savedResponseResult.msg);
      if (savedResponseResult.invalidSession) {
        $(this.editModalUI.current).modal("hide");
        return this.context.logOut();
      }
      return this.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.msg
      });
    }

    this.setState(
      {
        saveProgress: false,
        saveFailMsg: "",
        currentPageNum: 1
      },
      err => {
        //call this after changing current page no. to 1, so that it redirects to first page, no matter what page it is in.
        this._fetchTables();
      }
    );
    $(this.editModalUI.current).modal("hide");
    toast.success(
      `Successfully ${tableId ? "updated" : "added"} table no.${
      formValues.table_num
      }.`
    );
  }

  _showEditModal(tableId, restarantId) {
    this.setState(
      {
        selectedTableId: tableId,
        selectedTableRestarantId: restarantId
      },
      err => {
        if (err) return console.error(err);
        //fillvalues in modal
        let selectedItem = this.state.tables.find(t => t.tableId == tableId);
        this.tableInputForm.setValue("table_num", selectedItem.tableNum);
        // this.tableInputForm.setValue(
        //   "table_capacity",
        //   selectedItem.tableCapacity
        // );
        this.tableInputForm.setValue("table_name", selectedItem.tableName);
        //show modal
        $(this.editModalUI.current).modal("show");
      }
    );
  }

  _showAssignTableModal(tableId, restarantId) {
    this.setState(
      {
        selectedTableId: tableId
      },
      err => {
        if (err) return console.error(err);
        //fillvalues in modal
        let selectedItem = this.state.tables.find(t => t.tableId == tableId);
        this.assignTableForm.setValue("table_num", selectedItem.tableNum);
        selectedItem.bearerId &&
          this.assignTableForm.setValue("bearer_id", selectedItem.bearerId); //set value only if there is a value, if not validate function on the field is triggered now itself
        this._fetchBearers(restarantId);
        //show modal
        $(this.assignModalUI.current).modal("show");
      }
    );
  }

  confirmAndDelete(id) {
    let selectedItem = this.state.tables.find(t => t.tableId == id) || {};
    confirmAlert({
      title: "Delete Table?",
      message: `Are you sure to delete Table ${selectedItem.tableNum || ""}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteTable(id, selectedItem.tableNum)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteTable(tableId, tableNum) {
    if (this.state.deleteProgress) {
      return false;
    }

    this.setState({
      deleteProgress: true
    });

    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const deletedResponse = await fetch(apiBasePath + apiRoutes.deleteTable, {
      headers: {
        Authorization: this.context.authToken,
        "Content-type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        table_id: tableId,
        user_id: this.context.user.userId,
        restarant_id: this.context.user.selectedRestarantId
      })
    }).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the table.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg, { autoClose: 5000 });
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the table.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg, { autoClose: 5000 });
    }

    if (!deletedResponseResult.success) {
      toast.error(deletedResponseResult.msg, { autoClose: 5000 });
      return this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: "",
      currentPageNum:
        this.state.tables.length > 1
          ? this.state.currentPageNum
          : this.state.currentPageNum - 1 > 0
            ? this.state.currentPageNum - 1
            : 1
    });
    this._fetchTables();
    toast.success(`Deleted table no.${tableNum}`, { autoClose: 4000 });
  }

  async _fetchBearers(restarant_id) {
    const {
      CONSTANTS: { apiBasePath, apiRoutes, userRoles },
      user
    } = this.context;
    const bearersData = await fetchBearers(
      apiBasePath +
      apiRoutes.getEmployees +
      `?role=${userRoles["BEARER"]}&user_id=${user.userId}&restarant_id=${
      this.context.isSuperAdmin ? restarant_id : user.selectedRestarantId
      }&limit=0&offset=0`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.log(err));

    if (!bearersData || !bearersData.success) {
      return toast.error(
        bearersData
          ? bearersData.msg
          : "An Error Occured while fetching bearers list."
      );
    }

    this.setState({
      bearers: bearersData.data
    });
  }

  async _assigntTableToBearer(formValues) {
    if (this.state.assignProgress) return false;
    this.setState({
      assignProgress: true
    });
    let tableId = this.state.selectedTableId;
    if (!tableId) return console.error("No tableId selected?  weird!");
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    let table = this.state.tables.find(t => t.tableId == tableId);
    if (!table) return console.error("No table"); //@todo - show ui
    console.table({
      user_id: this.context.user.userId,
      // restarant_id: this.context.isSuperAdmin ?  table.restarantId: this.context.user.selectedRestarantId,
      restarant_id: table.restarantId,
      bearer_id: formValues.bearer_id,
      table_num: formValues.table_num,
      table_id: tableId //will be undefined in add request
    });
    const savedResponse = await fetch(
      apiBasePath + apiRoutes.assignTableToBearer,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          user_id: this.context.user.userId,
          // restarant_id: this.context.isSuperAdmin ?  table.restarantId: this.context.user.selectedRestarantId,
          restarant_id: table.restarantId,
          bearer_id: formValues.bearer_id,
          table_num: formValues.table_num,
          table_id: tableId //will be undefined in add request
        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured during table assignment.`;
      this.setState({
        assignFailMsg: msg,
        assignProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while saving the assignment.`;
      this.setState({
        assignFailMsg: msg,
        assignProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      this.setState({
        assignFailMsg: savedResponseResult.msg,
        assignProgress: false
      });
      return toast.error(savedResponseResult.msg);
    }

    this.setState({
      assignProgress: false,
      assignFailMsg: "",
      selectedTableId: null
    });
    this._fetchTables();
    $(this.assignModalUI.current).modal("hide");
    let bearerDetails = (this.state.bearers || []).find(
      b => b.id == formValues.bearer_id
    );
    let bearerName = bearerDetails ? bearerDetails.name : "Waiter";
    toast.success(
      `Table No.${
      formValues.table_num
      } is successfully assigned to ${bearerName}.`,
      { autoClose: 3000 }
    );
  }
}
