import React, { Component } from "react";
import {
    AuthContext,
} from "../contextProvider";
import { ellipsize, throttleTime } from "../utilities";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";
import Loader from "react-loader-spinner";
export default class UrbanSyncCatalogueDetails extends Component {
    state = {
        fetchProgress: false,
        syncDetails: [],
        restarants: []
    }
    async componentDidMount() {
        await this._loadRestaurants();
        await this._fetchSyncDetails();

    }
    static contextType = AuthContext;
    render() {
        return (
            <div className="contentvthbtn">
                <div className="row mx-0 justify-content-between">
                    <div className="">
                        <span className="categorycol">Sync Catalogue Details</span>
                    </div>
                </div>
                            <div className="table-responsive mt-3 text-capitalize tableofauto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Restaurnat Name</th>
                                            <th>Errors</th>
                                            <th>Created</th>
                                            <th>Updated</th>
                                            <th>Deleted</th>
                                            <th>sync At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {this.state.syncDetails.length ? (
                                    <tbody>
                                        {this.state.syncDetails.map((r, idx) => {
                                            let rName = this.state.restarants.find(res=> res.id == r.storeId).name;
                                            return (
                                                <tr key={String(idx)}>
                                                    <td>{idx + 1}</td>
                                                    <td>{rName}</td>
                                                    <td>{r.errors}</td>
                                                    <td>{r.created}</td>
                                                    <td>{r.updated}</td>
                                                    <td>{r.deleted}</td>
                                                    <td>{moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}</td>
                                                   <td> {r.publishData == 1 ?
                                                        <h5 style={{ color: '#2fde2f' }}><b>Published</b></h5> : <button
                                                            className="btnaddcategory"
                                                            onClick={() => {
                                                                this.publishData(r);
                                                            }}>
                                                            Publish
                                                        </button>}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    ) : (
                                        // eslint-disable-next-line react/style-prop-objects
                                        <div className="noDataMsgContainer">
                                            <h5>No Sync Details.</h5>
                                        </div>
                                    )}
                                </table>
                            </div>
            </div>
        )
    }
    async _fetchSyncDetails() {
        if (this.state.fetchProgress) return false;
        this.setState({
            fetchProgress: true
        });
        const filters ={ startDate: new Date(), endDate: new Date()};
        const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
        const url =
            apiBasePath +
            apiRoutes.getRestaurantsUrbanCatalogueSyncDetails +
            `?user_id=${this.context.user.userId}&restaurant_id=${0
            }&limit=${0}&offset=${0}&start_date=${filters
                ? filters.startDate
                    ? moment(filters.startDate)
                        .utc(true)
                        .startOf("day")
                        .toISOString()
                    : ""
                : ""
            }&end_date=${filters
                ? filters.endDate
                    ? moment(filters.endDate)
                        .utc(true)
                        .endOf("day")
                        .toISOString()
                    : ""
                : ""
            }`;
        const Response = await fetch(url, {
            headers: {
                Authorization: this.context.authToken,
                "Content-type": "application/json"
            }
        }).catch(err => console.error(err));

        if (!Response) {
            this.setState({
                fetchProgress: false,
                syncDetails: []
            });
            return toast.error("An Error Occured while fetching details");
        }

        const ResponseData = await Response
            .json()
            .catch(err => console.log(err));
        if (!ResponseData) {
            return toast.error("An Error Occured while fetching details");
        }
        if (ResponseData.status == 403) {
            this.setState({
                fetchProgress: false,
                syncDetails: []
            });
            toast.error(ResponseData.msg, { autoClose: 8000 });
            return this.context.logOut();
        }
        if (!ResponseData.success) {
            this.setState({
                fetchProgress: false,
                syncDetails: [],
            });
            return toast.error(ResponseData.msg);
        }

        this.setState({
            syncDetails: ResponseData.data,
            fetchProgress: false,
        });
    }

    async _loadRestaurants() {
        const { user, authToken, CONSTANTS } = this.context;
        const requestSendTime = Date.now();
        let fetchResponse = await fetch(
          CONSTANTS.apiBasePath +
          CONSTANTS.apiRoutes.restaurantsList +
          `?limit=${0}&offset=${0}&userId=${user.userId}`,
          { headers: { Authorization: authToken } }
        ).catch(err => console.error(err));
    
        if (!fetchResponse) {
          this.setState({
            fetchProgress: false
          });
          return toast.error("An Error Occured.");
        }
        const restarantsData = await fetchResponse.json(); //@TODO - handle error
    
        if (!restarantsData.success) {
          this.setState({
            fetchProgress: false
          });
          return toast.error("Could not get restaurants.");
        }
    
        let urbanActiveRestIds = []
        restarantsData.data.map(r => {
          if (r.urbanpiper) {
            urbanActiveRestIds.push(r.id)
          }
        })
          this.setState({
            restarants: restarantsData.data
          });
      }
    async publishData(data) {
        const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
        let body = {
            user_id: this.context.user.userId,
            sync_id: data.id,
        }
        const savedResponse = await fetch(apiBasePath + apiRoutes.updateCataloguePublishData, {
            method: "PUT",
            headers: {
                "Content-type": "application/json",
                Authorization: this.context.authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return toast.error("An error occurred while sending the data.")
        }
        if (!savedResponseResult.success) {
            toast.error(savedResponseResult.msg, { autoClose: 8000 });
        }
        this._fetchSyncDetails();
    }
}
