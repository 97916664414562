import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { Form, Text, TextArea, Select, Checkbox } from "informed";
import { AuthContext, DataContext } from "../contextProvider";
import { throttleTime, validateText, validateStrictText, validateWholeNumber } from "../utilities";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import orderType from "./orderType";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <Taxes
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class Taxes extends Component {
    state = {
        taxes: [],
        totalTaxes: 0,
        currentPageNum: 1,
        recordsPerPage: 10,
        fetchProgress: true,
        saveProgress: false,
        deleteProgress: false,
        saveFailMsg: "",
        deleteFailMsg: "",
        noDataMsg: "No Taxes Yet.",
        selectedTaxId: null,
        foodTypes: this.props.CONSTANTS.foodTypes || [],
        allTaxes: [],
        items: [],
        selectedItems: []
    };

    componentWillMount() {
        this.modalRef = React.createRef();
        this.modalUI = React.createRef();
        this._loadTaxes(true);
    }

    componentDidMount() {
        $(this.modalUI.current).on("shown.bs.modal", () => {
            // if (this.modalRef.current.state.categories.length) return;
            // this._loadCategories();
        });

        $(this.modalUI.current).on("hidden.bs.modal", () => {
            this.modalRef.current.setState({
                editMode: false
            });
            this.setState({
                selectedTaxId: null
            });
            this.modalRef.current.formApi.reset();
        });

        this._loadItemsData();
    }

    componentWillUnmount() {
        toast.dismiss();
    }

    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            //eslint-disable-next-line react/style-prop-objects
                            style="loadingProgress"
                        />
                    ) : (
                            <>
                                <div className="row mx-0 justify-content-between align-items-center">
                                    <div className="">
                                        <span className="categorycol">Taxes</span>
                                    </div>
                                    {/* <div>
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => {
                                                this._onSyncTaxes()
                                            }}
                                        >
                                            Sync UP Taxes
                                        </button>
                                    </div> */}
                                    <div className="">
                                        <button
                                            type="button"
                                            className="btnaddcategory float-right"
                                            data-toggle="modal"
                                            data-target="#taxModal"
                                        >
                                            <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                                        &nbsp; Add Tax
                                        </button>
                                    </div>
                                </div>
                                {this.state.taxes.length ? (
                                    <div className="table-responsive mt-3 tableofauto">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Code</th>
                                                    <th> Title</th>
                                                    <th>Description</th>
                                                    <th>Pos Status</th>
                                                    <th>Online Status</th>
                                                    <th>Structure Value</th>
                                                    {/* <th>Clear items</th> */}
                                                    <th className="actionWidth">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.taxes.map((t, idx) => (
                                                    <tr key={String(idx)}>
                                                        <td>
                                                            {(this.state.currentPageNum - 1) *
                                                                this.state.recordsPerPage +
                                                                idx +
                                                                1}
                                                        </td>
                                                        <td>{t.taxCode}</td>
                                                        <td>{t.taxTitle}</td>
                                                        <td>
                                                            {t.taxDescription
                                                                ? t.taxDescription.length <= 50
                                                                    ? t.taxDescription
                                                                    : t.taxDescription.substr(0, 50) +
                                                                    "..."
                                                                : ""}
                                                        </td>
                                                        <td>{t.taxActive ? "Active" : "InActive"}</td>
                                                        <td>{t.taxOnlineActive ? "Active" : "InActive"}</td>
                                                        <td>{t.taxStructureValue}</td>
                                                        {/* <td>{t.taxClearItems ? "true" : "false"}</td> */}
                                                        <td>
                                                            <img
                                                                alt=""
                                                                src={EDIT_IMG}
                                                                data-toggle="modal"
                                                                data-target="#taxModal"
                                                                className="icon"
                                                                onClick={() => {
                                                                    let itemData = t.taxItemRefIds ? t.taxItemRefIds.taxItemRefIds : []
                                                                    this.setState({
                                                                        selectedTaxId: t.taxId,
                                                                        selectedItems: itemData
                                                                    });
                                                                    this.modalRef.current.setState({
                                                                        editMode: true
                                                                    });
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "tax__code",
                                                                        t.taxCode
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "tax__title",
                                                                        t.taxTitle
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "tax__description",
                                                                        t.taxDescription
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "tax__active",
                                                                        t.taxActive
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "tax__onlineActive",
                                                                        t.taxOnlineActive
                                                                    );
                                                                    this.modalRef.current.formApi.setValue(
                                                                        "tax__structureValue",
                                                                        t.taxStructureValue
                                                                    );
                                                                    // this.modalRef.current.formApi.setValue(
                                                                    //     "tax__clearItems",
                                                                    //     t.taxClearItems
                                                                    // );

                                                                    // this.modalRef.current.formApi.setValue(
                                                                    //     "tax__clearLocations",
                                                                    //     t.taxClearLocations
                                                                    // );
                                                                }}
                                                            />
                                                            {/* <img
                                                                alt=""
                                                                src={DELETE_IMG}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() =>
                                                                    this.confirmAndDelete(
                                                                        t.taxId,
                                                                        t.taxTitle
                                                                    )
                                                                }
                                                            /> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                        <div className="noDataMsgContainer">
                                            <h5>{this.state.noDataMsg}</h5>
                                        </div>
                                    )}
                            </>
                        )}
                </div>
                {this._pagination()}
                <TaxModal
                    ref={this.modalRef}
                    submitTaxForm={this._submitTaxForm.bind(this)}
                    modalUiRef={this.modalUI}
                    itemsData={this.state.items}
                    setSelectedItems={(itemsSelected) => { this.setState({ selectedItems: itemsSelected }) }}
                    selectedItemsData={this.state.selectedItems}
                />
            </>
        );
    }

    _pagination() {
        return (
            <div className="container-fluid">
                {this.state.totalTaxes / this.state.recordsPerPage > 1 ? (
                    <ReactPaginate
                        previousLabel={
                            <i className="fa fa-angle-double-left" aria-hidden="true" />
                        }
                        nextLabel={
                            <i className="fa fa-angle-double-right" aria-hidden="true" />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            this.state.totalTaxes / this.state.recordsPerPage
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        pageLinkClassName={"pagebtn"}
                        onPageChange={data => this._getMore(data.selected + 1)}
                        containerClassName={"pagination"}
                        activeLinkClassName={"active"}
                        forcePage={this.state.currentPageNum - 1}
                    />
                ) : null}
            </div>
        );
    }

    async _getMore(val) {
        this.setState(
            {
                currentPageNum: val
            },
            err => {
                this._loadTaxes(true)
            }
        );
    }

    async _onSyncTaxes() {
        const { upApiBasePath } = this.props.CONSTANTS
        await this._loadTaxes(false);
        if (this.state.saveProgress) {
            return false;
        }
        let taxes = this.state.allTaxes.map((t, index) => {
            return {
                code: t.taxCode,
                title: t.taxTitle,
                description: t.taxDescription,
                active: t.taxActive,
                structure: {
                    value: t.taxStructureValue
                },
                item_ref_ids: t.taxItemRefIds.taxItemRefIds
            }
        })
        this.setState({
            saveProgress: true
        });
        let body = {
            taxes: taxes
        }
        const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/locations/" + this.props.user.restarantId + "/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (savedResponseResult.status == "error") {
            this.setState({
                saveProgress: false
            });
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference)
            setTimeout(() => {
                toast.success(
                    "Successfully synched"
                );
            }, 1500);
            return toast.success(savedResponseResult.message);
        }
    }

    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: restId,
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanCatalogueResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while saving the webhook response"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
        this.setState({
            saveProgress: false
        });
    }
    async _loadTaxes(limit) {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const taxResponse = await fetch(
            apiBasePath +
            apiRoutes.getTaxes +
            `?user_id=${this.props.user.userId}&limit=${
            limit ? this.state.recordsPerPage : 0
            }&offset=${limit ? (this.state.currentPageNum - 1) *
                this.state.recordsPerPage : 0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!taxResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const taxResponseResult = await taxResponse.json();
        if (!taxResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!taxResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: taxResponseResult.msg
            });
            return toast.error(taxResponseResult.msg);
        }
        limit ?
            throttleTime(
                () => {
                    this.setState({
                        taxes: taxResponseResult.data,
                        totalTaxes: taxResponseResult.total,
                        fetchProgress: false
                    });
                },
                requestSendTime,
                500
            ) :
            this.setState({
                fetchProgress: false,
                allTaxes: taxResponseResult.data
            })

    }

    async _submitTaxForm(formValues) {
        if (this.state.saveProgress) return false;
        this.modalRef.current.setState({
            saveProgress: true
        });
        let taxId = this.state.selectedTaxId;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const savedResponse = await fetch(
            apiBasePath +
            (taxId ? apiRoutes.editTax : apiRoutes.createTax),
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: taxId ? "PUT" : "POST",
                body: JSON.stringify({
                    tax_id: taxId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId,
                    tax_title: formValues.tax__title.trim(),
                    tax_code: formValues.tax__code,
                    tax_description: formValues.tax__description && formValues.tax__description,
                    tax_active: formValues.tax__active,
                    tax_online_active: formValues.tax__onlineActive,
                    tax_structure_value: formValues.tax__structureValue,
                    // tax_clear_items: formValues.tax__clearItems,
                    // tax_clear_locations: formValues.tax__clearLocations,
                    tax_item_ref_ids: this.state.selectedItems
                })
            }
        ).catch(err => console.error(err));
        if (!savedResponse) {
            let msg = `An Error Occured while ${
                taxId ? "saving the changes" : "adding the tax "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }

        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            let msg = `An Error Occured while ${
                taxId ? "saving the changes" : "adding the tax "
                }.`;
            this.modalRef.current.setState({
                saveFailMsg: msg,
                saveProgress: false
            });
            return toast.error(msg);
        }
        if (!savedResponseResult.success) {
            this.modalRef.current.setState({
                saveProgress: false,
                saveFailMsg: savedResponseResult.msg
            });
            return toast.error(savedResponseResult.msg);
        }

        this.modalRef.current.setState({
            saveProgress: false,
            saveFailMsg: ""
        });
        this.setState(
            {
                currentPageNum: 1,
                selectedItems: []
            },
            err => {
                this._loadTaxes(true)
            }
        );
        $(this.modalUI.current).modal("hide");
        toast.success(
            `Successfully ${
            taxId ? "saved the changes" : "added " + formValues.tax__code
            }.`,
            { autoClose: 4000 }
        );
    }

    confirmAndDelete(tId, name) {
        confirmAlert({
            title: "Delete?",
            message: `Are you sure to delete ${name || ""}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this._deleteTax(tId)
                },
                {
                    label: "No",
                    onClick: () => null
                }
            ]
        });
    }

    async _deleteTax(tId) {
        if (this.state.deleteProgress) {
            return false;
        }

        this.setState({
            deleteProgress: true
        });
        const tax = this.state.taxes.find(
            i => i.taxId == tId
        );
        const taxCode = tax.taxCode;

        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const deletedResponse = await fetch(
            apiBasePath + apiRoutes.deleteTax,
            {
                headers: {
                    Authorization: this.props.authToken,
                    "Content-type": "application/json"
                },
                method: "DELETE",
                body: JSON.stringify({
                    tax_id: tId,
                    user_id: this.props.user.userId,
                    restarant_id: this.props.user.selectedRestarantId
                })
            }
        ).catch(err => console.error(err));

        if (!deletedResponse) {
            let msg = "An Error Occured while deleting the tax.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        const deletedResponseResult = await deletedResponse
            .json()
            .catch(err => console.error(err));
        if (!deletedResponseResult) {
            let msg = "An Error Occured while deleting the tax.";
            this.setState({
                deleteFailMsg: msg,
                deleteProgress: false
            });
            return toast.error(msg);
        }

        if (!deletedResponseResult.success) {
            this.setState({
                deleteProgress: false,
                deleteFailMsg: deletedResponseResult.msg
            });

            return toast.error(deletedResponseResult.msg);
        }

        this.setState({
            deleteProgress: false,
            deleteFailMsg: "",
            currentPageNum:
                this.state.taxes.length > 1
                    ? this.state.currentPageNum
                    : this.state.currentPageNum - 1 > 0
                        ? this.state.currentPageNum - 1
                        : 1
        });
        this._loadTaxes(true)
        toast.success(`Deleted ${taxCode}`, { autoClose: 5000 });
    }

    async _loadItemsData() {

        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const restId = this.props.user.selectedRestarantId;
        const itemsResponse = await fetch(
            apiBasePath +
            apiRoutes.getMenuItems +
            `?user_id=${this.props.user.userId}&limit=0&offset=0&restarant_id=${this.props.user.selectedRestarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err)
        );
        if (!itemsResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const itemsResponseResult = await itemsResponse.json();
        if (!itemsResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!itemsResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: itemsResponseResult.msg
            });
            return toast.error(itemsResponseResult.msg);
        }
        let itemIdTitleList = itemsResponseResult.data.map(i => {
            return {
                value: i.itemId,
                label: i.itemTitle
            };
        });

        this.setState({
            items: itemIdTitleList,
            fetchProgress: false,
            restaurantId: restId
        });


    }
}



class TaxModal extends Component {
    state = {
        editMode: false,
        saveProgress: false
    };

    handleItemSelect = selectedItems => {
        this.props.setSelectedItems(selectedItems)
    }


    render() {
        const { modalUiRef, submitTaxForm } = this.props;
        return (
            <div
                ref={modalUiRef}
                className="modal fade"
                id="taxModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {this.state.editMode
                                    ? "Edit Tax"
                                    : "Add Tax"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                </span>
                            </button>
                        </div>
                        <Form
                            onSubmit={submitTaxForm}
                            getApi={this._setFormApi.bind(this)}
                        >
                            {({ formState }) => {
                                return (
                                    <>
                                        <div className="modal-body">
                                            <div className="row mb-sm-3">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group required">
                                                        <label>Code
                                                        <Select
                                                                field="tax__code"
                                                                validateOnBlur
                                                                validateOnChange
                                                                validate={val => {
                                                                    if (!val) return "Please select tax code.";
                                                                    if (val == "CGST_P") {
                                                                        this.formApi.setValue("tax__title", "cgst")
                                                                    }
                                                                    if (val == "SGST_P") {
                                                                        this.formApi.setValue("tax__title", "sgst")
                                                                    }
                                                                    if (val == "IGST_P") {
                                                                        this.formApi.setValue("tax__title", "gst")
                                                                    }
                                                                }}
                                                                className={
                                                                    formState.errors.tax__code
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }

                                                            >
                                                                {/* @todo - dynamic values */}
                                                                <option >-select Tax Code-</option>
                                                                <option >CGST_P</option>
                                                                <option >SGST_P</option>
                                                                <option >IGST_P</option>
                                                            </Select>
                                                            {formState.errors.tax__code && (
                                                                <div className="input_error">
                                                                    {formState.errors.tax__code}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> title
                                                        <Select
                                                                disabled
                                                                field="tax__title"
                                                                validateOnBlur
                                                                validateOnChange
                                                                validate={val => {
                                                                    if (!val) return "Please select tax title.";
                                                                }}
                                                                className={
                                                                    formState.errors.tax__title
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }

                                                            >
                                                                {/* @todo - dynamic values */}
                                                                <option >-select Tax title-</option>
                                                                <option >cgst</option>
                                                                <option >sgst</option>
                                                                <option >gst</option>
                                                                {/* <option>municipality</option>
                                                                <option>kerala cess</option> */}
                                                            </Select>

                                                            {formState.errors.tax__title && (
                                                                <div className="input_error">
                                                                    {formState.errors.tax__title}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label> tax value
                                                        <Text
                                                                validate={val => {
                                                                    if (!val) return "please enter tax value"
                                                                    if (val && !new RegExp(/^[0-9.]+$/).test(val)) return "please input a valid number";
                                                                    let notPositive = validateWholeNumber(
                                                                        val,
                                                                        "Item Sort Order"
                                                                    );
                                                                    if (Number(val) > 100) {
                                                                        return "tax value cannot be more than 100%.";
                                                                    }
                                                                }}
                                                                validateOnBlur
                                                                validateOnChange
                                                                field={"tax__structureValue"}
                                                                type="text"
                                                                placeholder="Enter structure value"
                                                                className={
                                                                    formState.errors.tax__structureValue
                                                                        ? "form-control input_error"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {formState.errors.tax__structureValue && (
                                                                <div className="input_error">
                                                                    {formState.errors.tax__structureValue}
                                                                </div>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6" />
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="tax__active"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                               Pos Active<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="tax__active"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="tax__onlineActive"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                               Online Active<span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5  form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="tax__onlineActive"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="tax__clearItems"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Clear items  <span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="tax__clearItems"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-8 col-md-8 col-lg-7 restarant_input__labelWrapper mb-3">
                                                            <label
                                                                htmlFor="tax__clearLocations"
                                                                className="row mx-0 w-100 justify-content-between"
                                                            >
                                                                Clear Locations  <span className="">:</span>
                                                            </label>
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-5 form-group">
                                                            <div className="">
                                                                <Checkbox
                                                                    field="tax__clearLocations"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6" ></div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div>
                                                        <label>Select Applicable Items </label>
                                                        <DualListBox
                                                            options={this.props.itemsData}
                                                            selected={this.props.selectedItemsData}
                                                            onChange={this.handleItemSelect}
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <TextArea
                                                    validate={val => {

                                                        if (Number(val) > 500) {
                                                            return "tax Description cannot be more than 500 Characters.";
                                                        }
                                                    }
                                                    }
                                                    // validate={val =>
                                                    //     validateText(
                                                    //         val,
                                                    //         10,
                                                    //         formState.touched.tax__description,
                                                    //         "tax  Description",
                                                    //         400
                                                    //     )
                                                    // }
                                                    rows={5}
                                                    validateOnBlur
                                                    validateOnChange
                                                    field={"tax__description"}
                                                    className={
                                                        formState.errors.tax__description
                                                            ? "form-control input_error"
                                                            : "form-control"
                                                    }
                                                    placeholder="Enter  description"
                                                />
                                                {formState.errors.tax__description && (
                                                    <div className="input_error">
                                                        {formState.errors.tax__description}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block clearfix">
                                            <button
                                                type="submit"
                                                className="btnmodaladd text-uppercase float-right"
                                            >
                                                {this.state.editMode ? "Save Changes" : "Add"}
                                                {this.state.saveProgress && (
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#fff"
                                                        height={20}
                                                        width={30}
                                                        style="loaderInsideBtn"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    _setFormApi(formApi) {
        this.formApi = formApi;
    }
    _validateSelectInput(val, name) {
        if (!val) {
            return `Select ${name}.`;
        }
    }


}
