import React, { Component } from "react";
import { DELETE_IMG, EDIT_IMG } from "../assets/images";
import { EditReceptionistModal } from "./partials/modals";
import { AuthContext, DataContext, DataProvider } from "../contextProvider";
import {
  throttleTime,
  validateText,
  validateStrictText,
  validatePhone,
  validateInput
} from "../utilities";
import { Form, Text, Select } from "informed";
import Loader from "react-loader-spinner";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast } from "react-toastify";

export default class EmployeesList extends Component {
  static contextType = AuthContext;

  state = {
    currentPageNum: 1,
    recordsPerPage: 10,
    totalRecords: 0,
    fetchProgress: true,
    fetchFailMsg: "No Records Found.",
    records: [],
    empRoleId: null,
    empTypeTitle: "",
    empTypeTitleSingular: "",
    saveProgress: false,
    saveFailMsg: "",
    deleteProgress: false,
    deleteFailMsg: "",
    selectedRestarantId: null
  };

  componentWillMount() {
    this.setState(
      {
        empRoleId: this.context.CONSTANTS.userRoles[this.props.roleName],
        empTypeTitle: this.props.empTypeTitle,
        empTypeTitleSingular: this.props.empTypeTitleSingular
      },
      err => {
        if (err) return console.error(err);
        this._fetchEmployees();
      }
    );

    this.editEmpModal = React.createRef();
  }

  componentDidMount() {
    $(this.editEmpModal.current).on("hidden.bs.modal", () => {
      this.editFormApi.reset();
      this.setState({
        selectedEmpId: null
      });
    });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  render() {
    return (
      <DataProvider
        loadRestarantsOnWillMount={this.context.isSuperAdmin}
        {...this.context}
      >
        <div className="contentvthbtn">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
              <>
                <div className="row mx-0 justify-content-between">
                  <div className="mb-3">
                    <span className="categorycol">{this.state.empTypeTitle}</span>
                  </div>
                  <div className="d-flex flex-wrap">
                    {this.context.isSuperAdmin && (
                      <select
                        onChange={e =>
                          this.filterByRestarant(e.nativeEvent.target.value)
                        }
                        className="select_Restaurent mb-3"
                        defaultValue={this.state.selectedRestarantId}
                      >
                        <option value={0}>All Restaurants</option>
                        <DataContext.Consumer>
                          {({ restarants }) => {
                            return (
                              restarants &&
                              restarants.map(
                                (r, idx) =>
                                  r.name && (
                                    <option value={r.id} key={String(idx)}>
                                      {r.name.length > 40
                                        ? r.name.substr(0, 37) + "..."
                                        : r.name}
                                    </option>
                                  )
                              )
                            );
                          }}
                        </DataContext.Consumer>
                      </select>
                    )}
                    {this.state.records.length < 1 ? <button
                      type="button"
                      className="btnaddcategory mb-3"
                      data-toggle="modal"
                      data-target="#editEmpModal"
                    >
                      <i className="fa fa-plus plusicon" aria-hidden="true" />{" "}
                    &nbsp; Add {this.state.empTypeTitleSingular}
                    </button> : null}
                  </div>
                </div>
                {this.state.records.length ? (
                  <div className="table-responsive mt-3 text-capitalize tableofauto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Name</th>
                          <th>User name</th>
                          <th>Contact number</th>
                          {/* <th>password</th> */}
                          <th className="actionWidth">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.records.map((record, idx) => {
                          return (
                            <tr key={String(idx)}>
                              <td>
                                {(this.state.currentPageNum - 1) *
                                  this.state.recordsPerPage +
                                  (idx + 1)}
                              </td>
                              <td>{record.name}</td>
                              <td>{record.userName}</td>
                              <td>{record.phone}</td>
                              {/* <td>{record.empPassWord}</td> */}
                              <td>
                                <img
                                  alt="Edit"
                                  src={EDIT_IMG}
                                  data-toggle="modal"
                                  data-target="#editEmployeeModal"
                                  className="icon img-fluid"
                                  onClick={e =>
                                    this._showEditModal(
                                      record.id,
                                      record.restarantId
                                    )
                                  }
                                />
                                <img
                                  alt="Delete"
                                  src={DELETE_IMG}
                                  className="img-fluid"
                                  onClick={e =>
                                    this.confirmAndDelete(
                                      record.id,
                                      record.userName
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                    <div className="noDataMsgContainer">
                      <h5>{this.state.fetchFailMsg}</h5>
                    </div>
                  )}
              </>
            )}
        </div>
        <div className="container-fluid">
          {Math.floor(this.state.totalRecords / this.state.recordsPerPage) >
            0 ? (
              <ReactPaginate
                previousLabel={
                  <i className="fa fa-angle-double-left" aria-hidden="true" />
                }
                nextLabel={
                  <i className="fa fa-angle-double-right" aria-hidden="true" />
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(
                  this.state.totalRecords / this.state.recordsPerPage
                )}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                pageLinkClassName={"pagebtn"}
                onPageChange={data => this.getMore(data.selected + 1)}
                containerClassName={"pagination"}
                activeLinkClassName={"active"}
                forcePage={this.state.currentPageNum - 1}
              />
            ) : null}
        </div>
        {this.editReceptionistModal()}
      </DataProvider>
    );
  }

  editReceptionistModal() {
    return (
      <div
        ref={this.editEmpModal}
        className="modal fade"
        id="editEmpModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editEmpModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {!this.state.selectedEmpId ? "Add" : "Edit"}{" "}
                {this.state.empTypeTitleSingular}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              getApi={formApi => this._setFormApi(formApi)}
              onSubmit={this._submitEditForm.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row">
                        {this.context.isSuperAdmin &&
                          !this.state.selectedEmpId && (
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div className="form-group">
                                <Select
                                  field="emp_restarant"
                                  type="text"
                                  className={
                                    formState.errors.emp_restarant
                                      ? "form-control mr-sm-4 input_error"
                                      : "form-control mr-sm-4"
                                  }
                                  defaultValue=""
                                  validate={val => {
                                    if (!val) return "Select a Restaurant.";
                                  }}
                                >
                                  <option value="">-Select Restaurant-</option>
                                  <DataContext.Consumer>
                                    {({ restarants }) =>
                                      restarants &&
                                      restarants.map((r, idx) => (
                                        <option value={r.id} key={String(idx)}>
                                          {r.name}
                                        </option>
                                      ))
                                    }
                                  </DataContext.Consumer>
                                </Select>
                                {formState.errors.emp_restarant && (
                                  <div className="input_error">
                                    {formState.errors.emp_restarant}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="emp_name"
                              type="text"
                              //@todo - specific name
                              placeholder={
                                "Enter " +
                                this.state.empTypeTitleSingular +
                                " name"
                              }
                              className={
                                formState.errors.emp_name
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                              validate={val =>
                                validateStrictText(
                                  val,
                                  3,
                                  formState.touched.emp_name,
                                  "Employee name",
                                  30
                                )
                              }
                              validateOnBlur
                              validateOnChange
                            />
                            {formState.errors.emp_name && (
                              <div className="input_error">
                                {formState.errors.emp_name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="emp_user_name"
                              type="text"
                              placeholder="Enter user name"
                              className={
                                formState.errors.emp_user_name
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                              validate={val => {
                                if (!val && !formState.touched.emp_user_name) {
                                  return;
                                }
                                if (!val) {
                                  return "Please Enter Employee username.";
                                }
                                if (val.split(" ").length > 1 || val.split("-").length > 1) {
                                  return "UserName cannot have spaces and hyphens.";
                                }
                                if (val.length < 3 || val.length > 20) {
                                  return "UserName must have 3 to 20 characters.";
                                }
                              }}
                              validateOnBlur
                              validateOnChange
                            />
                            {formState.errors.emp_user_name && (
                              <div className="input_error">
                                {formState.errors.emp_user_name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="emp_phone"
                              type="text"
                              placeholder="Enter contact number"
                              className={
                                formState.errors.emp_phone
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                              validate={val => {
                                // validatePhone(
                                //   val && val.trim(),
                                //   formState.touched.emp_phone
                                // )
                                if (!val || val.length < 10 || val.length > 10 || !new RegExp(/^[0-9]+$/).test(val) && formState.touched.emp_phone)
                                  return "please enter a valid phone number"
                              }}
                              validateOnBlur
                              validateOnChange
                            />
                            {formState.errors.emp_phone && (
                              <div className="input_error">
                                {formState.errors.emp_phone}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="emp_pwd"
                              type="text"
                              autoComplete="new-password"
                              placeholder="Enter password"
                              className={
                                formState.errors.emp_pwd
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                              //mandatory only in add mode
                              validate={val =>
                                val || !this.state.selectedEmpId
                                  ? validateInput(
                                    val,
                                    6,
                                    formState.touched.emp_pwd,
                                    "Password"
                                  )
                                  : null
                              }
                              validateOnBlur
                              validateOnChange
                            />
                            {formState.errors.emp_pwd && (
                              <div className="input_error">
                                {formState.errors.emp_pwd}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.state.selectedEmpId ? "Save Changes" : "Add"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _showEditModal(id, restId) {
    this.setState(
      {
        selectedEmpId: id,
        selectedEmployeeRestarantId: restId
      },
      err => {
        if (err) return console.error(err);
        $(this.editEmpModal.current).modal("show");

        //prefill values
        let emp = this.state.records.find(
          emp => emp.id == this.state.selectedEmpId
        );
        if (!emp) return console.error("no selected emp");
        this.editFormApi.setValue("emp_name", emp.name);
        this.editFormApi.setValue("emp_user_name", emp.userName);
        this.editFormApi.setValue("emp_phone", emp.phone);
        //   this.editFormApi.setValue('emp_pwd','')
      }
    );
  }

  _setFormApi(formApi) {
    this.editFormApi = formApi;
  }

  confirmAndDelete(empId, empName) {
    confirmAlert({
      title: "Delete Employee?",
      message: `Are you sure to delete ${empName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteEmployee(empId, empName)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteEmployee(empId, empName) {
    if (this.state.deleteProgress) {
      return false;
    }

    this.setState({
      deleteProgress: true
    });

    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const deletedResponse = await fetch(
      apiBasePath + apiRoutes.deleteEmployee,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: "DELETE",
        body: JSON.stringify({
          emp_id: empId,
          user_id: this.context.user.userId,
          restarant_id: this.context.user.selectedRestarantId
        })
      }
    ).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the table.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the sub-category.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    if (!deletedResponseResult.success) {
      toast.error(deletedResponseResult.msg);
      return this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: ""
    });
    this._fetchEmployees();
    toast.success(`Successfully removed ${empName}`);
  }

  getMore(pageNum) {
    this.setState(
      {
        currentPageNum: pageNum
      },
      err => {
        err && console.error(err);
        this._fetchEmployees();
      }
    );
  }

  async _fetchEmployees() {
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
    const empResponse = await fetch(
      apiBasePath +
      apiRoutes.getEmployees +
      `?user_id=${this.context.user.userId}&restarant_id=${
      this.context.isSuperAdmin
        ? this.state.selectedRestarantId || 0
        : this.context.user.selectedRestarantId
      }&limit=${this.state.recordsPerPage}&offset=${(this.state
        .currentPageNum -
        1) *
      this.state.recordsPerPage}&role=${userRoles[this.props.roleName]}`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));

    if (!empResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching employees");
    }

    const empData = await empResponse.json().catch(err => console.error(err));
    if (!empData) {
      return toast.error("An Error Occured while fetching employees");
    }
    if (!empData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error(empData.msg);
    }
    throttleTime(
      () => {
        this.setState({
          records: empData.data,
          fetchProgress: false,
          totalRecords: empData.total
        });
      },
      requestSendTime,
      500
    );
  }

  async filterByRestarant(val) {
    this.setState(
      {
        selectedRestarantId: val,
        currentPageNum: 1
      },
      err => {
        this._fetchEmployees();
      }
    );
  }

  async _submitEditForm(formValues) {
    if (this.state.saveProgress) return false;
    this.setState({
      saveProgress: true
    });
    // if() return false
    let empId = this.state.selectedEmpId;
    const { apiBasePath, apiRoutes, userRoles } = this.context.CONSTANTS;
    const savedResponse = await fetch(
      apiBasePath + (empId ? apiRoutes.editEmployee : apiRoutes.addEmployee),
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: empId ? "PUT" : "POST",
        body: JSON.stringify({
          user_id: this.context.user.userId,
          restarant_id: this.context.isSuperAdmin
            ? formValues.emp_restarant || this.state.selectedEmployeeRestarantId
            : this.context.user.selectedRestarantId, //if super admin selectedRestarantId
          emp_name: formValues.emp_name.trim(),
          emp_user_name: formValues.emp_user_name.trim(),
          emp_role: userRoles[this.props.roleName],
          emp_phone: formValues.emp_phone.trim(),
          emp_pwd: formValues.emp_pwd,
          emp_id: empId //will be undefined in add request
        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while saving the ${
        empId ? "changes" : "employee"
        }.`;
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while saving the ${
        empId ? "changes" : "employee."
        }.`;
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }
    if (!savedResponseResult.success) {
      this.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.duplicate
          ? ""
          : savedResponseResult.msg
      });
      if (savedResponseResult.duplicate) {
        this.editFormApi.setError(
          savedResponseResult.duplicate,
          savedResponseResult.msg
        );
      }
      return !savedResponseResult.duplicate
        ? toast.error(savedResponseResult.msg)
        : null;
    }

    this.setState(
      {
        saveProgress: false,
        saveFailMsg: "",
        currentPageNum: empId ? this.state.currentPageNum : 1
      },
      err => {
        this._fetchEmployees();
      }
    );

    $(this.editEmpModal.current).modal("hide");
    toast.success(
      "Successfully " +
      (empId ? "updated " : "added ") +
      formValues.emp_name.trim() +
      ".",
      { autoClose: 3000 }
    );
  }
}
