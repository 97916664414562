import React, { Component } from "react";
import { AuthContext } from "../contextProvider";
import { toast } from "react-toastify";
import { CONSTANTS } from "../contextProvider";

export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, CONSTANTS, isSuperAdmin }) => {
                return (
                    <SupportDetails
                        {...props}
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                    />

                );
            }}
        </AuthContext.Consumer>
    );
};

class SupportDetails extends Component {
    state = {
        fetchProgress: true,
        supportDetails: "",
    };

   async componentDidMount() {
        await this._loadSupportDetails(this.props.match.params.id)
    }
    render() {
        let support = this.state.supportDetails
        return (
            <div>
                <div className="bg-white rounded mx-auto shadow-sm p-3 py-lg-5">
                {support?  <div className="text-center">
                        <div style={{ fontWeight: 'bold' }}>
                            <div style={{ fontSize: 30 }}>   {support.restarantName}</div>
                            <div> {support.restarantAdress}</div>
                            <div>  phone :  {support.restarantPhone}</div>
                            {support.restarantGst ? <div>  GSTIN :  {support.restarantGst}</div> : null}
                            {support.restarantGstDesc ? <div>   GST Description :   {support.restarantGstDesc}</div> : null}
                        </div>
                        <hr />
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: 30 }}>Admin Details</div>
                            <div>  Name: {support.adminName}</div>
                            <div> email:{support.adminEmail}</div>
                            <div>  phone :  {support.adminPhone}</div>
                        </div>
                        <hr />
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: 30 }}>Reception Details</div>
                            <div>  Name: {support.emp_name}</div>
                            <div>  phone :  {support.emp_phone}</div>
                        </div>
                        <hr />
                        <div>
                            <div style={{fontWeight: 'bold', fontSize: 20}}>Contact No: {support.phone_number} </div>
                            <div style={{ fontWeight: 'bold', fontSize: 30 }}>Issues</div>
                            {
                                support.problem ? support.problem.map(p => {
                                    let selected = p.value.filter(v => { return v.selected });
                                    if (selected.length) {
                                        return <div key={String(p.id)}>
                                            <div style={{ fontWeight: 'bold', fontSize: 20 }}> {p.id}</div>
                                            <div style={{ flex: 1, flexDirection: 'row' }}>
                                                {
                                                    p.value.map(v => {
                                                        if (v.selected) {
                                                            return <div>
                                                                <input type="Radio" checked={v.selected} readOnly={true} style={{ marginRight: 30 }} />
                                                                {v.label}
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div>{p.data}</div>
                                        </div>
                                    }
                                    if (p.id == "Other Help" && p.data) {
                                      return  <div>
                                            <div style={{ fontWeight: 'bold', fontSize: 20 }}> {p.id}</div>
                                            <div>{p.data}</div>
                                        </div>
                                    }
                                }) : null
                            }
                        </div>
                    </div>:null}
                </div  >
            </div>

        );
    }

    async _loadSupportDetails(id) {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = CONSTANTS;
        const requestSendTime = Date.now();
        const SupportResponse = await fetch(
            apiBasePath +
            apiRoutes.getSupportDetails +
            `?support_id=${id}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!SupportResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        const SupportResult = await SupportResponse.json();
        if (!SupportResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!SupportResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: SupportResult.msg
            });
            return toast.error(SupportResult.msg);
        }

        this.setState({
            supportDetails: SupportResult.data,
            fetchProgress: false
        });

    }
}