import React, { Component } from "react";
import { AuthContext } from "../contextProvider";
import { toast } from "react-toastify";
import StarRatings from 'react-star-ratings';
import DatePicker from "react-datepicker";
import moment from "moment";
import $ from "jquery";
import { Checkbox } from "@material-ui/core";

export default () => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <GenericFeedbackResponses
                        user={user}
                        authToken={authToken}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                        upAuthToken={up_Authorization}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class GenericFeedbackResponses extends Component {
    state = {
        fetchProgress: false,
        feedBackResponses: [],
        selectedModal: "",
        customMsgModal: '',
        reviewCheck: false
    }
    async componentDidMount() {
        await this.setState({
            startDate: new Date(),
            endDate: new Date()
        })
        this._loadFeedbackResponses();
    }
    render() {
        let feedbacks = this.state.feedBackResponses.filter(f=>{
            if(this.state.reviewedCheck){
                return f.review_feedback != 1
            } else{
                return f
            }
        });
        return (
            <>
                <div className="content">
                    <div className="row mx-0 justify-content-between align-items-center">
                        <div className="">
                            <span className="categorycol">Generic FeedBack Responses</span>
                        </div>
                        <div>
                        <label> Un Reviewed</label>
                            <Checkbox onClick={() => {
                               this.setState({
                                reviewedCheck: !this.state.reviewedCheck
                               })
                            }} checked={this.state.reviewedCheck} 
                            color='#1f74f4'
                            />
                        </div>
                        <div className="contentvthbtn">
                            <div className="row mx-0 justify-content-between">
                                <DateFilters
                                    handleChangeEnd={this.handleChangeEnd.bind(this)}
                                    handleChangeStart={this.handleChangeStart.bind(this)}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                />
                            </div>
                        </div>
                        {feedbacks.length ?
                            <div className="table-responsive mt-3 tableofauto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th className="actionWidth">Action</th>
                                            <th>Custom Message</th>
                                            <th>Review Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {feedbacks.map((f, idx) => {
                                            let customerdetails = JSON.parse(f.customer_details);
                                            return <tr key={String(idx)}>
                                                <td>{idx + 1}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btnaddcategory"
                                                        data-toggle="modal"
                                                        data-target="#feedBackModal"
                                                        style={{ marginLeft: 10 }}
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedModal: f
                                                            })
                                                        }}
                                                    >
                                                        Feedback
                                                    </button>
                                                </td>
                                                <td>
                                                    {customerdetails.phone ? <button
                                                        type="button"
                                                        className="btnaddcategory"
                                                        data-toggle="modal"
                                                        data-target="#customMsgModal"
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedModal: f
                                                            })
                                                        }}
                                                    >
                                                        {f.fb_response_custom_msg ? "Sent" : "Send Message"}
                                                    </button> : null}</td>
                                                    <td>
                                                   {f.review_feedback == 1?
                                                   <h5 style={{color:'#2fde2f'}}><b>Reviewed</b></h5>: <button
                                                        className="btnaddcategory"
                                                        onClick={() => {
                                                            this.reviewFeedback(f)
                                                        }}>
                                                        Review
                                                    </button>}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div> : <div className="noDataMsgContainer">
                                <h5>No Responses</h5>
                            </div>}
                    </div>
                </div>
                {/* {this.orderModal(this.state.selectedModal)} */}
                {this.feedbackModal(this.state.selectedModal)}
                {this.customMsgModal(this.state.selectedModal)}
            </>
        )
    }
    async reviewFeedback(feedbackData) {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        let body = {
            user_id: this.props.user.userId,
            fb_response_id: feedbackData.fb_response_id,
            generic_feedback: true
        }
        const savedResponse = await fetch(apiBasePath + apiRoutes.updateReviewFeedback, {
            method: "PUT",
            headers: {
                "Content-type": "application/json",
                Authorization: this.props.authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return toast.error("An error occurred while sending the data.")
        }
        if (!savedResponseResult.success) {
            toast.error(savedResponseResult.msg, { autoClose: 8000 });
        }
        this._loadFeedbackResponses();
    }
    feedbackModal(selectedModal) {
        return (
            <div
                className="modal fade"
                id="feedBackModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Feedback
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>
                        {selectedModal ?
                            <div className="modal-body">
                                {
                                    JSON.parse(selectedModal.fb_response_rating).map((f, idx) => {
                                        if (f.feedBackAnswer)
                                            return <div key={String(f.feedbackId)}>
                                                <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: 10 }}>{f.feedbackTitle}</div>
                                                {f.feedbackTitleType == "Star" ? <StarRatings
                                                    rating={f.feedBackAnswer}
                                                    starRatedColor="green"
                                                    numberOfStars={5}
                                                    name={String(idx)}
                                                    starDimension='40px'
                                                    starSpacing='10px'
                                                /> : null}
                                                {f.feedbackTitleType == "textbox" || f.feedbackTitleType == "Yes/No" || f.feedbackTitleType == "Options" ? <p>{f.feedBackAnswer}</p> : null}
                                            </div>
                                    })
                                }
                                {/* {selectedModal.fb_response_comment ? <div className="form-group">
                                    <textarea
                                        value={selectedModal.fb_response_comment}
                                        rows={5}
                                        style={{ width: "100%", marginTop: 20 }}
                                        placeholder="Any Suggestions/Comments"
                                        readOnly={true}
                                    />
                                </div> : null} */}
                                {
                                    selectedModal.customer_details ?
                                        <div style={{ marginTop: 20 }}>
                                            <b>Customer Details:</b>
                                            <p>Name: {JSON.parse(selectedModal.customer_details).name}</p>
                                            <p>Phone: {JSON.parse(selectedModal.customer_details).phone}</p>
                                            <p>Email: {JSON.parse(selectedModal.customer_details).email}</p>
                                            <p>DOB: {JSON.parse(selectedModal.customer_details).DOB}</p>
                                        </div> : null
                                }
                            </div> :
                            <div className="modal-body">
                                <h5> Thanks for Providing feedback</h5>
                                <h5>Have a nice day.</h5>
                            </div>}

                    </div>
                </div>
            </div>
        )
    }
    customMsgModal(selectedModal) {
        return (
            <div
                className="modal fade"
                id="customMsgModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Send Custom Message
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.setState({ customMsgModal: "" })}
                            >
                                <span className="closeicon" aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {!selectedModal.fb_response_custom_msg ? <textarea
                                value={this.state.customMsgModal}
                                rows={5}
                                style={{ width: "100%", marginTop: 20 }}
                                placeholder="Enter Custom Message"
                                onChange={(val) => this.setState({ customMsgModal: val.target.value })}
                            /> : <p>{selectedModal.fb_response_custom_msg}</p>}
                        </div>
                        <div className="modal-footer d-block clearfix">
                            {!selectedModal.fb_response_custom_msg ? <button
                                type="submit"
                                className="btnmodaladd text-uppercase float-right"
                                onClick={() => this.onSubmitCustomMsg(selectedModal)}
                            >
                                Send
                            </button> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async onSubmitCustomMsg(selectedModal) {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        if (!this.state.customMsgModal) {
            return toast.error("Please Enter Custom Message")
        }
        let customerdetails = JSON.parse(selectedModal.customer_details)
        let body = {
            custom_msg: this.state.customMsgModal,
            user_id: this.props.user.userId,
            fb_response_id: selectedModal.fb_response_id,
            phone: customerdetails.phone || selectedModal.customerPhone,
            generic_feedback: true,
            rName: this.props.user.restarantName
        }
        const savedResponse = await fetch(apiBasePath + apiRoutes.customFeedbackMessageSend, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: this.props.authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return toast.error("An error occurred while sending the message.")
        }
        if (!savedResponseResult.success) {
            toast.error(savedResponseResult.msg, { autoClose: 8000 });
        }
        this.setState({ customMsgModal: "" });
        this._loadFeedbackResponses();
        $('#customMsgModal').modal('hide');
        toast.success(savedResponseResult.msg, { autoClose: 8000 });
    }
    handleChangeStart(val) {
        this.setState(
            {
                startDate: val
            },
            () => this._loadFeedbackResponses()
        );
    }
    handleChangeEnd(val) {
        this.setState(
            {
                endDate: val
            },
            err => {
                this._loadFeedbackResponses()
            }
        );
    }
    async _loadFeedbackResponses() {
        this.setState({
            fetchProgress: true
        });
        const filters =
            this.state.startDate && this.state.endDate
                ? { startDate: this.state.startDate, endDate: this.state.endDate }
                : null;
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const feedBackResponse = await fetch(
            apiBasePath +
            apiRoutes.getGenericFeedBackResponses +
            `?user_id=${this.props.user.userId}&limit=${0}&offset=${0}&start_date=${filters
                ? filters.startDate
                    ? moment(filters.startDate)
                        .utc(true)
                        .startOf("day")
                        .toISOString()
                    : ""
                : ""
            }&end_date=${filters
                ? filters.endDate
                    ? moment(filters.endDate)
                        .utc(true)
                        .endOf("day")
                        .toISOString()
                    : ""
                : ""
            }`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!feedBackResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const feedBackResponseResult = await feedBackResponse.json();
        if (!feedBackResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!feedBackResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: feedBackResponseResult.msg
            });
            return toast.error(feedBackResponseResult.msg);
        }
        this.setState({
            feedBackResponses: feedBackResponseResult.data,
            fetchProgress: false,
        });

    }
}
function DateFilters({
    handleChangeEnd,
    handleChangeStart,
    startDate,
    endDate
}) {
    return (
        <>
            <DatePicker
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                className="form-control"
                onChange={handleChangeStart}
                isClearable={true}
                placeholderText="Starting from..."
            />
            <span style={{ textAlign: "center", width: "20px" }}>-</span>
            <DatePicker
                selected={endDate}
                selectsEnd
                minDate={startDate}
                maxDate={new Date()}
                className="form-control"
                startDate={startDate}
                endDate={endDate}
                onChange={handleChangeEnd}
                isClearable={true}
                placeholderText="...to"
            />
        </>
    );
};