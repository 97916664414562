import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { AuthContext } from "../contextProvider";
import Loader from "react-loader-spinner";
import { Form, Text } from "informed";
import $ from "jquery";

import ReactPaginate from "react-paginate";
import { validateText, throttleTime } from "../utilities";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
export default class Cities extends Component {
  static contextType = AuthContext;
  state = {
    currentPageNum: 1,
    recordsPerPage: 10,
    fetchProgress: true,
    saveProgress: false,
    deleteProgress: false,
    saveFailMsg: "",
    deleteFailMsg: "",
    noDataMsg: "No items Yet.",
    records: [],
    totalRecords: 0
  };

  componentWillMount() {
    this._fetchCities();
    this.editModalUI = React.createRef();
  }

  componentDidMount() {
    $(this.editModalUI.current).on("hidden.bs.modal", () => {
      this.editCityForm.reset();
      this.setState({
        selectedCityId: null
      });
    });
  }

  render() {
    return (
      <>
        <div className="contentvthbtn">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
            <>
              <div className="row mx-0 justify-content-between">
                <div className="">
                  <span className="categorycol">{"Cities"}</span>
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btnaddcategory float-right"
                    onClick={() => this._showEditModal()}
                  >
                    <i className="fa fa-plus plusicon" aria-hidden="true" />
                    &nbsp; Add City
                  </button>
                </div>
              </div>
              {this.state.records.length ? (
                <div className="table-responsive mt-3 text-capitalize tableofauto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No.</th>

                        <th>City</th>
                        <th className="actionWidth">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.records.map((item, idx) => {
                        return (
                          <tr key={String(idx)}>
                            <td>
                              {(this.state.currentPageNum - 1) *
                                this.state.recordsPerPage +
                                idx +
                                1}
                            </td>
                            <td>{item.cityName}</td>
                            <td>
                              <img
                                src={EDIT_IMG}
                                alt=""
                                className="icon img-fluid"
                                onClick={() => this._showEditModal(item.cityId)}
                              />
                              <img
                                alt=""
                                src={DELETE_IMG}
                                className="img-fluid"
                                onClick={() =>
                                  this.confirmAndDelete(
                                    item.cityId,
                                    item.cityName
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="noDataMsgContainer">
                  <h5>{this.state.noDataMsg}</h5>
                </div>
              )}
            </>
          )}
        </div>
        {this._editModal()}
        {this._pagination()}
      </>
    );
  }

  _showEditModal(cityId) {
    if (!cityId) {
      $(this.editModalUI.current).modal("show");
    } else {
      this.setState(
        {
          selectedCityId: cityId
        },
        err => {
          if (err) return console.error(err);
          let selectedCity = this.state.records.find(c => c.cityId == cityId);
          if (!selectedCity) {
            return console.error("no city with cityId");
          }
          this.editCityForm.setValue("city_name", selectedCity.cityName);
          $(this.editModalUI.current).modal("show");
        }
      );
    }
  }

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalRecords / this.state.recordsPerPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.state.totalRecords / this.state.recordsPerPage
            )}
            marginPagesDisplayed={this.state.surroundingButtonsCount}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this.getMore(data.selected + 1)}
            containerClassName={"pagination"}
            activeLinkClassName={"active"}
            forcePage={this.state.currentPageNum - 1}

          />
        ) : null}
      </div>
    );
  }

  getMore(pageNum) {
    this.setState({ currentPageNum: pageNum }, err => {
      this._fetchCities();
    });
  }

  _setFormApi(formApi, formTitle) {
    this[formTitle] = formApi;
  }

  async _fetchCities() {
    this.setState({
      fetchProgress: true
    });
    const requestSendTime = Date.now();
    const { apiRoutes, apiBasePath } = this.context.CONSTANTS;
    const citiesResponse = await fetch(
      apiBasePath +
        apiRoutes.fetchCities +
        `?user_id=${this.context.user.userId}&limit=${
          this.state.recordsPerPage
        }&offset=${(this.state.currentPageNum - 1) *
          this.state.recordsPerPage}`,
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        }
      }
    ).catch(err => console.error(err));

    if (!citiesResponse) {
      this.setState({
        fetchProgress: false
      });
      return toast.error("An Error Occured while fetching cities");
    }

    const citiesData = await citiesResponse
      .json()
      .catch(err => console.error(err));
    if (!citiesData) {
      return toast.error("An Error Occured while fetching cities");
    }
    if (!citiesData.success) {
      this.setState({
        fetchProgress: false
      });
      return toast.error(citiesData.msg);
    }
    throttleTime(
      () => {
        this.setState({
          records: citiesData.data,
          fetchProgress: false,
          totalRecords: citiesData.total
        });
      },
      requestSendTime,
      500
    );
  }

  confirmAndDelete(id, cityName) {
    confirmAlert({
      title: "Delete?",
      message: `Are you sure to delete ${cityName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteCity(id, cityName)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteCity(cityId, cityName) {
    if (this.state.deleteProgress) {
      return false;
    }

    this.setState({
      deleteProgress: true
    });
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const deletedResponse = await fetch(apiBasePath + apiRoutes.deleteCity, {
      headers: {
        Authorization: this.context.authToken,
        "Content-type": "application/json"
      },
      method: "DELETE",
      body: JSON.stringify({
        city_id: cityId,
        user_id: this.context.user.userId
      })
    }).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the table.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while deleting the city.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    if (!deletedResponseResult.success) {
      toast.error(deletedResponseResult.msg);
      return this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: "",
      currentPageNum:
        this.state.records.length > 1
          ? this.state.currentPageNum
          : this.state.currentPageNum - 1 > 0
          ? this.state.currentPageNum - 1
          : 1
    });
    this._fetchCities();
    toast.success(`Successfully deleted ${cityName}`);
  }

  async _submitEditForm(formValues) {
    if (this.state.saveProgress) return false;
    this.setState({
      saveProgress: true
    });
    let cityId = this.state.selectedCityId;
    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    const savedResponse = await fetch(
      apiBasePath + (cityId ? apiRoutes.editCity : apiRoutes.addCity),
      {
        headers: {
          Authorization: this.context.authToken,
          "Content-type": "application/json"
        },
        method: cityId ? "PUT" : "POST",
        body: JSON.stringify({
          user_id: this.context.user.userId,
          city_name: formValues.city_name.trim(),
          city_location: "",
          city_status: 1,
          city_id: cityId //will be undefined in add request
        })
      }
    ).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = `An Error Occured while saving the ${
        cityId ? "changes." : "city name."
      }.`;
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = `An Error Occured while ${
        cityId ? "saving the changes" : "adding the city."
      }.`;
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg);
    }

    if (!savedResponseResult.success) {
      this.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.msg
      });
      return toast.error(savedResponseResult.msg);
    }

    this.setState(
      {
        saveProgress: false,
        saveFailMsg: "",
        currentPageNum: cityId ? this.state.currentPageNum : 1
      },
      err => {
        this._fetchCities();
      }
    );
    $(this.editModalUI.current).modal("hide");
    toast.success(`Successfully ${cityId ? "updated" : "added"} City.`, {
      autoClose: 3000
    });
  }

  _editModal() {
    return (
      <div
        ref={this.editModalUI}
        className="modal fade"
        id="addTableModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.selectedCityId ? "Edit" : "Add"} City
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              getApi={formApi => this._setFormApi(formApi, "editCityForm")}
              onSubmit={this._submitEditForm.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12 ">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (val && val.trim().length > 20) {
                                  return "City name cannot have more than 20 characters.";
                                }
                                return validateText(
                                  val && val.trim(),
                                  3,
                                  formState.touched.city_name,
                                  "City Name"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="city_name"
                              type="text"
                              placeholder="Enter city name"
                              className={
                                formState.errors.table_name
                                  ? "form-control mr-sm-4 input_error"
                                  : "form-control mr-sm-4"
                              }
                            />
                            {formState.errors.city_name && (
                              <div className="input_error">
                                {formState.errors.city_name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer clearfix d-block">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.state.selectedCityId
                          ? "Save Changes"
                          : "Add City"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
