import React, { Component, PureComponent } from "react";
import { fetchCategories } from "../services";
import { toast } from "react-toastify";

export const CategoriesContext = React.createContext();
export class CategoriesProvider extends PureComponent {
  state = {
    categories: [],
  };


  async componentWillMount(){
      const categoriesResult = await fetchCategories({
          CONSTANTS: this.props.CONSTANTS,
          userId: this.props.user.userId,
          limit: 0,
          offset: 0,
          authToken: this.props.authToken
        })
        .catch(err => console.error(err));
        if(!categoriesResult){
      let msg = "An Error Occured while fetching Categories.";
       this.setState({
         fetchProgress: false,
         noDataMsg: msg
       });
       return toast.error(msg);
   }

   if(!categoriesResult.success){
     let msg = categoriesResult.msg;
     this.setState({
       fetchProgress: false,
       noDataMsg: msg,
       categories:[]
     });

     return toast.error(msg)
   }

   this.setState({
     categories:categoriesResult.data
   })
  }

  render() {
    return (
      <CategoriesContext.Provider value={{categories:this.state.categories,fetchCategories:fetchCategories}}>
        {this.props.children}
      </CategoriesContext.Provider>
    );
  }
}