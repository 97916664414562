import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthPage from "./components/AuthPage";
import Dashboard from "./components/Dashboard";
import OrderDetails from "./components/smsOrder";
import SupportDetails from "./components/support";
import { RootProvider, AuthConsumer, AuthContext, RootConsumer } from "./contextProvider";
import { ToastContainer, toast } from 'react-toastify'
import GenericFeedback from "./components/GenericFeedback";
import IdleTimer from 'react-idle-timer';
import { Beforeunload } from 'react-beforeunload';

class App extends Component {
  // componentDidMount(){
  //   const script = document.createElement("script");
  //   script.src = lightBootstrap;
  //   script.async = true;
  //   document.body.appendChild(script);
  // }
  render() {
    return (
      <RootProvider >
        <BrowserRouter>
          <AuthContext.Consumer>
            {({ isLoggedIn, user, authToken, CONSTANTS, logOut }) => (
              <>
                <ToastContainer />
                <Switch>
                  {/* <Route path="/" render={() => isLoggedIn ? <Redirect to="/Dashboard" /> : <Redirect to="/" />} /> */}
                  <Route
                    exact
                    path="/"
                    render={props =>
                      isLoggedIn ? (
                        <Redirect to="/Dashboard" />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                  {/* <Route exact path="/login" component={AuthPage} /> */}
                  <Route
                    exact
                    path="/login"
                    render={props =>
                      !isLoggedIn ? (
                        <AuthPage {...props} />
                      ) : (
                        <Redirect to="/Dashboard" />
                      )
                    }
                  />
                  <Beforeunload onBeforeunload={(event) => {
                    event.preventDefault();
                    logOut();
                  }} >
                    <IdleTimer
                      ref={ref => { this.idleTimer = ref }}
                      timeout={1000 * 60 * 60}
                      onActive={() => { }}
                      onIdle={() => {
                        toast.error("Session TimeOut");
                        logOut();
                      }}
                      onAction={() => { }}
                      debounce={250}
                    >
                      <Route
                        path="/Dashboard"
                        render={props =>
                          isLoggedIn ? <Dashboard {...props} isLoggedIn={isLoggedIn} user={user} authToken={authToken} CONSTANTS={CONSTANTS} logOut={logOut} /> : <Redirect to="/" />
                        }
                      />
                    </IdleTimer>
                  </Beforeunload>
                  <Route
                    exact path="/Order/:id"
                    render={(props) =>
                      <OrderDetails  {...props} id={props.match.params.id} />
                    }

                  />
                  <Route
                    exact path="/Support/:id"
                    render={(props) =>
                      <SupportDetails  {...props} id={props.match.params.id} />
                    }

                  />
                  <Route
                    exact path="/Feedback/:name/:id"
                    render={(props) =>
                      <GenericFeedback  {...props} id={props.match.params.id} />
                    }

                  />
                  <Redirect to="/" />
                </Switch>
              </>
            )}
          </AuthContext.Consumer>

        </BrowserRouter>
      </RootProvider>
    );
  }
}

export default App;
