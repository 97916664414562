import React, { Component } from "react";
import {
    AuthContext,
} from "../contextProvider";
import { ellipsize, throttleTime } from "../utilities";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";
import Loader from "react-loader-spinner";

export default class OrdersCountReports extends Component {
    state = {
        restarants: [],
        fetchProgress: false,
        saveProgress: false,
        searchTxt: '',
        allRestarants: [],
        totalOrders: [],
        startDate: new Date(),
        endDate: new Date()
    }
    async componentDidMount() {
        await this._loadRestaurants();
        await this._fetchOrdersList();
    }
    static contextType = AuthContext;
    filterRestarants(event) {
        this.setState({ searchTxt: event.target.value });
        let filterRestarants = this.state.allRestarants.filter(rest => rest.name.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1);
        filterRestarants = event.target.value ? filterRestarants : this.state.allRestarants
        this.setState({
            restarants: filterRestarants
        })
    }
    render() {
        return (
            <div className="contentvthbtn">
                {this.state.fetchProgress ? (
                    <Loader
                        color="#00BFFF"
                        height="100"
                        width="100"
                        style="loadingProgress"
                        type="Audio"
                    />
                ) : (

                    <div className="row mx-0 justify-content-between">
                        <div className="">
                            <span className="categorycol">Restaurants Orders Count</span>
                        </div>
                        <div className="">
                            <input className="form-control"
                                type="text"
                                placeholder="Search items"
                                aria-label="Search"
                                onChange={this.filterRestarants.bind(this)}
                                value={this.state.searchTxt}
                            />
                        </div>
                        <DateFilters
                            handleChangeEnd={this.handleChangeEnd.bind(this)}
                            handleChangeStart={this.handleChangeStart.bind(this)}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                        />
                        {this.state.restarants.length ? (
                            <div className="table-responsive mt-3 text-capitalize tableofauto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Restaurant name</th>
                                            <th>takeaway</th>
                                            <th>dine in</th>
                                            <th>walk in</th>
                                            <th>swiggy</th>
                                            <th>zomato</th>
                                            <th> pos orders</th>
                                            <th>agg orders</th>
                                            <th>Total Orders</th>
                                            <th>Sms Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.restarants.map((r, idx) => {
                                            let restName = ellipsize(r.name, 40);
                                            let takeaway = this.state.totalOrders.filter(res => { return res.restarantId == r.id && res.orderType == 'takeaway' });
                                            let dinein = this.state.totalOrders.filter(res => { return res.restarantId == r.id && res.orderType == 'dine in' });
                                            let walkin = this.state.totalOrders.filter(res => { return res.restarantId == r.id && res.orderType == 'walk in' });
                                            let swiggy = this.state.totalOrders.filter(res => { return res.restarantId == r.id && res.orderType == 'swiggy' });
                                            let zomato = this.state.totalOrders.filter(res => { return res.restarantId == r.id && res.orderType == 'zomato' });
                                            let billSmsOrders = this.state.totalOrders.filter(res => { return res.restarantId == r.id && res.billType == 'SMS' });
                                            return (
                                                <tr key={String(idx)}>
                                                    <td>{idx + 1}</td>
                                                    <td>{restName}</td>
                                                    <td>{takeaway.length}</td>
                                                    <td>{dinein.length}</td>
                                                    <td>{walkin.length}</td>
                                                    <td>{swiggy.length}</td>
                                                    <td>{zomato.length}</td>
                                                    <td>{takeaway.length + dinein.length + walkin.length}</td>
                                                    <td>{swiggy.length + zomato.length}</td>
                                                    <td>{takeaway.length + dinein.length + walkin.length + swiggy.length + zomato.length}</td>
                                                    <td>{billSmsOrders.length}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>) : (
                            // eslint-disable-next-line react/style-prop-objects
                            <div className="noDataMsgContainer">
                                <h5>No Restarants Here.</h5>
                            </div>
                        )}
                    </div>
                )}
            </div>

        )
    }
    handleChangeStart(val) {
        this.setState(
            {
                startDate: val
            },
            () => { this._fetchOrdersList(); }
        )
    }
    handleChangeEnd(val) {
        this.setState(
            {
                endDate: val
            },
            err => {
                this._fetchOrdersList();
            }
        );
    }
    async _loadRestaurants() {
        this.setState({
            fetchProgress: true
        });
        const { user, authToken, CONSTANTS } = this.context;
        const requestSendTime = Date.now();
        let fetchResponse = await fetch(
            CONSTANTS.apiBasePath +
            CONSTANTS.apiRoutes.restaurantsList +
            `?limit=${0}&offset=${0}&userId=${user.userId}`,
            { headers: { Authorization: authToken } }
        ).catch(err => console.error(err));

        if (!fetchResponse) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const restarantsData = await fetchResponse.json(); //@TODO - handle error

        if (!restarantsData.success) {
            this.setState({
                fetchProgress: false
            });
            return toast.error("Could not get restaurants.");
        }

        const reRenderTime = Date.now();
        const waitMoreTime = Math.max(500 - (reRenderTime - requestSendTime), 0);
        this.setState({
            restarants: restarantsData.data,
            fetchProgress: false,
            allRestarants: restarantsData.data,
        });
    }


    async _fetchOrdersList() {
        if (this.state.fetchProgress) return false;
        this.setState({
            fetchProgress: true
        });
        const filters =
            this.state.startDate && this.state.endDate
                ? { startDate: this.state.startDate, endDate: this.state.endDate }
                : null;
        const requestSendTime = Date.now();
        const { apiRoutes, apiBasePath, userRoles } = this.context.CONSTANTS;
        const url =
            apiBasePath +
            apiRoutes.getRestarantTakeAwayOrders +
            `?user_id=${this.context.user.userId}&restaurant_id=${0
            }&limit=${0}&offset=${0}&start_date=${filters
                ? filters.startDate
                    ? moment(filters.startDate)
                        .utc(true)
                        .startOf("day")
                        .toISOString()
                    : ""
                : ""
            }&end_date=${filters
                ? filters.endDate
                    ? moment(filters.endDate)
                        .utc(true)
                        .endOf("day")
                        .toISOString()
                    : ""
                : ""
            }`;
        const ordersResponse = await fetch(url, {
            headers: {
                Authorization: this.context.authToken,
                "Content-type": "application/json"
            }
        }).catch(err => console.error(err));

        if (!ordersResponse) {
            this.setState({
                fetchProgress: false,
                totalRecords: 0,
                totalOrders: []
            });
            return toast.error("An Error Occured while fetching tables");
        }

        const ordersData = await ordersResponse
            .json()
            .catch(err => console.log(err));
        if (!ordersData) {
            return toast.error("An Error Occured while fetching tables");
        }
        if (ordersResponse.status == 403) {
            this.setState({
                fetchProgress: false,
                totalOrders: []
            });
            toast.error(ordersData.msg, { autoClose: 8000 });
            return this.context.logOut();
        }
        if (!ordersData.success) {
            this.setState({
                fetchProgress: false,
                totalOrders: [],
            });
            return toast.error(ordersData.msg);
        }

        this.setState({
            totalOrders: ordersData.data,
            fetchProgress: false,
        });


    }

}

function DateFilters({
    handleChangeEnd,
    handleChangeStart,
    startDate,
    endDate
}) {
    return (
        <>
            <DatePicker
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                className="form-control"
                onChange={handleChangeStart}
                isClearable={true}
                placeholderText="Starting from..."
            />
            <span style={{ textAlign: "center", width: "20px" }}>-</span>
            <DatePicker
                selected={endDate}
                selectsEnd
                minDate={startDate}
                maxDate={new Date()}
                className="form-control"
                startDate={startDate}
                endDate={endDate}
                onChange={handleChangeEnd}
                isClearable={true}
                placeholderText="...to"
            />
        </>
    );
}