/**
 * only for admin ( not for super admin )
 *  */

import React, { Component } from "react";
import {
  EDIT_IMG,
  DELETE_IMG,
  TEA_COFFEE_IMAGE,
  STARTERS_IMAGE,
  CURRIES_IMAGE,
  BIRYANI_IMAGE,
  SANDWICH_IMAGE,
  FRIED_RICE_IMAGE,
  COOL_DRINK_IMAGE,
  MOCK_TAILS_IMAGE,
  FRUIT_JUICES_IMAGE,
  DESSERTS_IMAGE
} from "../assets/images";
import Loader from "react-loader-spinner";
import { throttleTime, validateText } from "../utilities";
import { AuthContext, DataContext } from "../contextProvider";
import $ from "jquery";
import { Form, Text, TextArea, RadioGroup, Radio } from "informed";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
export default (props) => {
  return (
    <AuthContext.Consumer>
      {({ user, authToken, CONSTANTS, up_Authorization, isSuperAdmin, logOut }) => {
        return (
          <DataContext.Consumer>
            {({ fetchCategories }) => {
              return (
                <Categories
                  {...props}
                  user={user}
                  authToken={authToken}
                  CONSTANTS={CONSTANTS}
                  isSuperAdmin={isSuperAdmin}
                  fetchCategories={fetchCategories}
                  upAuthToken={up_Authorization}
                  logOut={logOut}
                />
              );
            }}
          </DataContext.Consumer>
        );
      }}
    </AuthContext.Consumer>
  );
};

class Categories extends Component {
  state = {
    categories: [],
    allCategories: [],
    currentPageNum: 1,
    recordsPerPage: 0,
    fetchProgress: true,
    saveProgress: false,
    deleteProgress: false,
    saveFailMsg: "",
    deleteFailMsg: "",
    noDataMsg: "No Categories Yet.",
    modalShowing: false,
    selectedCategoryId: null,
    isSubCategories: this.props.location.pathname.indexOf('sub-categories') > -1 ? true : false,
    itemsData: []
  };

  categoryIcons = [
    {
      name: "STARTERS_IMAGE",
      id: "STARTERS_IMAGE",
      img: STARTERS_IMAGE
    },
    {
      name: "CURRIES_IMAGE",
      id: "CURRIES_IMAGE",
      img: CURRIES_IMAGE
    },
    {
      name: "BIRYANI_IMAGE",
      id: "BIRYANI_IMAGE",
      img: BIRYANI_IMAGE
    },
    {
      name: "SANDWICH_IMAGE",
      id: "SANDWICH_IMAGE",
      img: SANDWICH_IMAGE
    },
    {
      name: "FRIED_RICE_IMAGE",
      id: "FRIED_RICE_IMAGE",
      img: FRIED_RICE_IMAGE
    },
    {
      name: "COOL_DRINK_IMAGE",
      id: "COOL_DRINK_IMAGE",
      img: COOL_DRINK_IMAGE
    },
    {
      name: "MOCK_TAILS_IMAGE",
      id: "MOCK_TAILS_IMAGE",
      img: MOCK_TAILS_IMAGE
    },
    {
      name: "FRUIT_JUICES_IMAGE",
      id: "FRUIT_JUICES_IMAGE",
      img: FRUIT_JUICES_IMAGE
    },
    {
      name: "TEA_COFFEE_IMAGE",
      id: "TEA_COFFEE_IMAGE",
      img: TEA_COFFEE_IMAGE
    }
  ];

  componentWillMount() {
    this.modal = React.createRef();
    this.modalComponentRef = React.createRef();
    this._fetchCategories(this.props.CONSTANTS);
  }

  componentDidMount() {
    this._loadAllCategories();
    this._loadItemsData();
    $(this.modal.current).on("hide.bs.modal", () => {
      this.formApi.reset();
    });
  }

  componentWillUnmount() {
    // toast.dismiss();
  }
  filterCategories(event) {
    this.setState({ searchTxt: event.target.value });
    let filterCats = this.state.allCategories.filter(cat => cat.categoryName.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1);
    filterCats = event.target.value ? filterCats : this.state.allCategories;
    this.setState({
      categories: filterCats
    })
  }
  render() {
    return (
      <>
        <div className="content">
          {this.state.fetchProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              style="loadingProgress"
            />
          ) : (
              <>
                <div className="row mx-0 justify-content-between align-items-center">
                  <div className="pr-3">
                    <span className="categorycol">{!this.state.isSubCategories ? "categories" : "Sub-Categories"}</span>
                  </div>
                  <div className="">
                    <input className="form-control"
                      type="text"
                      placeholder="Search ..."
                      aria-label="Search"
                      onChange={this.filterCategories.bind(this)}
                      value={this.state.searchTxt}
                    />
                  </div>
                  {/* {this.props.user.urbanPiperStatus == 1 ?
                    <div>
                      <button
                        type="button"
                        className="btnaddcategory"
                        onClick={() => {
                          this._onSyncCategories()
                        }}
                      >
                        Sync Categories
                    </button>
                    </div>
                    : null
                  } */}
                  <div className="">
                    <button
                      type="button"
                      className="btnaddcategory float-right"
                      // data-toggle="modal"
                      // data-target="#categoryEditModal"
                      onClick={() => {
                        this.state.isSubCategories ? this.props.history.push("add-sub-category") : this.props.history.push("add-category");
                      }}
                    >
                      <i
                        className="fa fa-plus plusicon"
                        style={{ marginRight: "5px" }}
                        aria-hidden="true"
                      />
                      {!this.state.isSubCategories ? "Add categories" : "Add sub-categories"}
                    </button>
                  </div>
                </div>
                {this.state.categories.length ? (
                  <div className="table-responsive mt-3 tableofauto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Category name</th>
                          <th>Description</th>
                          <th>pos Active</th>
                          <th>online Active</th>
                          <th className="actionWidth">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.categories.filter(ctg => {
                          return this.state.isSubCategories ? ctg.categoryParentId !== null : ctg.categoryParentId === null;
                        }).map((category, index) => {
                          return (
                            <tr key={category.categoryId}>
                              <td>
                                {(this.state.currentPageNum - 1) *
                                  this.state.recordsPerPage +
                                  index +
                                  1}
                              </td>
                              <td>{category.categoryName}</td>
                              <td>
                                {category.categoryDescription
                                  ? category.categoryDescription.length <= 50
                                    ? category.categoryDescription
                                    : category.categoryDescription.substr(0, 50) +
                                    "..."
                                  : ""}
                              </td>
                              <td>{category.posActive == "33" ? "Active" : "InActive"}</td>
                              <td>{category.onlineActive == "35" ? "Active" : "InActive"}</td>
                              <td>
                                <img
                                  src={EDIT_IMG}
                                  className="icon"
                                  alt="Edit"
                                  onClick={() =>
                                    this.props.history.push(
                                      "category-details",
                                      {
                                        categoryId: category.categoryId,
                                        isSubCategories: this.state.isSubCategories
                                      }
                                    )
                                  }
                                />
                                {this.props.user.restarantMenuDelete == 1 ?
                                <img
                                  src={DELETE_IMG}
                                  alt="Delete"
                                  onClick={() =>{
                                    if(this.state.isSubCategories){
                                      let items = this.state.itemsData;
                                      let catItems = items.filter(ite => { return  ite.subCategoryId == category.categoryId});
                                      if(catItems.length){
                                          return toast.error("There are still items for this category. ")
                                      }
                                    }
                                    this.setState(
                                      { selectedCategoryId: category.categoryId },
                                      err => {
                                        this.confirmAndDelete(
                                          category.categoryId,
                                          category.categoryName
                                        );
                                      }
                                    )
                                  }}
                                />:null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                    <div className="noDataMsgContainer">
                      <h5>{this.state.noDataMsg}</h5>
                    </div>
                  )}
              </>
            )}
        </div>

        {/* <AddCategoryModal /> */}
        {/* {this._pagination()} */}
        <EditCategoryModal
          reference={this.modal}
          setFormApi={formApi => {
            this.formApi = formApi;
          }}
          categoryIcons={this.categoryIcons}
          ref={this.modalComponentRef}
          submitCategoryForm={this._submitCategoryForm.bind(this)}
          initialValues={{ catName: "", catDesc: "" }}
          show={this.state.modalShowing}
        />
      </>
    );
  }

  _pagination() {
    return (
      <div className="container-fluid">
        {this.state.totalCategories / this.state.recordsPerPage > 1 ? (
          <ReactPaginate
            previousLabel={
              <i className="fa fa-angle-double-left" aria-hidden="true" />
            }
            nextLabel={
              <i className="fa fa-angle-double-right" aria-hidden="true" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.state.totalCategories / this.state.recordsPerPage
            )}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            pageLinkClassName={"pagebtn"}
            onPageChange={data => this._getMore(data.selected + 1)}
            containerClassName={"pagination"}
            activeLinkClassName={"active"}
            forcePage={this.state.currentPageNum - 1}
          />
        ) : null}
      </div>
    );
  }

  _getMore(val) {
    this.setState(
      {
        currentPageNum: val
      },
      err => {
        this._fetchCategories(this.props.CONSTANTS);
        this._loadAllCategories();
      }
    );
  }

  async _fetchCategories(CONSTANTS) {
    this.setState({
      fetchProgress: true
    });

    const requestSendTime = Date.now();
    let categoriesResult = await this.props
      .fetchCategories({
        CONSTANTS,
        userId: this.props.user.userId,
        authToken: this.props.authToken,
        limit: this.state.recordsPerPage,
        offset: (this.state.currentPageNum - 1) * this.state.recordsPerPage,
        restaurant_id: this.props.selectedRestarantId
      })
      .catch(err => console.error(err));
    if (!categoriesResult) {
      let msg = "An Error Occured while fetching Categories.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg, { autoClose: 8000 });
    }

    if (categoriesResult.logout) {
      toast.error(categoriesResult.msg, { autoClose: 8000 });
      return this.props.logOut();
    }

    if (!categoriesResult.success) {
      let msg = categoriesResult.msg;
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg, { autoClose: 8000 });
    }

    throttleTime(
      () => {
        this.setState({
          categories: categoriesResult.data,
          totalCategories: categoriesResult.total,
          fetchProgress: false
        });
      },
      requestSendTime,
      500
    );
  }

  async _onSyncCategories() {
    if (this.state.saveProgress) {
      return false;
    }
    const { apiBasePath, upApiBasePath } = this.props.CONSTANTS;
    let baseUrl = apiBasePath.split("/api")[0]
    let cat = this.state.allCategories.map((c, index) => {
      return {
        ref_id: c.categoryId,
        name: c.categoryName,
        description: c.categoryDescription,
        sort_order: c.categorySortOrder,
        active: c.categoryActive,
        img_url: c.categoryImage ? baseUrl + c.categoryImage : "",
        parent_ref_id: c.categoryParentId,
        translations: []
      }
    })
    this.setState({
      saveProgress: true
    });
    let body = {
      categories: cat
    }

    const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/locations/" + this.props.user.restarantId + "/", {
      async: true,
      crossDomain: true,
      method: "POST",
      headers: {
        Authorization: this.props.user.urbanAuthorization,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }
    )
    if (!savedResponse) {
      let msg = "An error Occurred"
      this.setState({
        saveProgress: false
      });
      return toast.error(msg)
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (savedResponseResult.status == "error") {
      this.setState({
        saveProgress: false
      });
      return toast.error(savedResponseResult.message)
    }
    if (savedResponseResult.status == "success") {
      this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference)
      setTimeout(() => {
        toast.success(
          "Successfully synched"
        );
      }, 1500);
      return toast.success(savedResponseResult.message);
    }

  }

  async saveWebhookResponse(restId, referenceId) {
    const { CONSTANTS, authToken } = this.props
    let body = {
      restaurant_id: restId,
      reference_id: referenceId,
    }

    let apiRoute = CONSTANTS.apiRoutes.UrbanCatalogueResponse;

    const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));

    if (!savedResponse) {
      this.setState({
        saveProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      this.setState({
        saveProgress: false,

      });
      return toast.error("An error occurred while storing the response.")
    }
    if (!savedResponseResult.success) {
      toast.error(savedResponseResult.msg, { autoClose: 8000 });
      return this.setState({
        saveProgress: false
      });
    }
    this.setState({
      saveProgress: false
    });
  }
  async _loadAllCategories() {
    let categoriesResponse = await this.props
      .fetchCategories({
        CONSTANTS: this.props.CONSTANTS,
        userId: this.props.user.userId,
        limit: 0,
        offset: 0,
        authToken: this.props.authToken
      })
      .catch(err => console.error(err));

    if (!categoriesResponse) {
      let msg = "An Error Occured while fetching Categories.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    if (!categoriesResponse.success) {
      let msg = categoriesResponse.msg;
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    this.setState({
      allCategories: categoriesResponse.data
    });
  }
  async _loadItemsData() {
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const itemsResponse = await fetch(
        apiBasePath +
        apiRoutes.getMenuItems +
        `?user_id=${this.props.user.userId}&limit=0&offset=0&restarant_id=${this.props.user.selectedRestarantId}`,
        { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!itemsResponse) {
        let msg = "An Error Occured.";
        this.setState({
            fetchProgress: false,
            noDataMsg: msg
        });
        return toast.error(msg);
    }

    const itemsResponseResult = await itemsResponse.json();
    if (!itemsResponseResult) {
        let msg = "An Error Occured";
        this.setState({
            fetchProgress: false,
            noDataMsg: msg
        });
        return toast.error(msg);
    }
    if (!itemsResponseResult.success) {
        this.setState({
            fetchProgress: false,
            noDataMsg: itemsResponseResult.msg
        });
        return toast.error(itemsResponseResult.msg);
    }

    this.setState({
        itemsData: itemsResponseResult.data
    });

}
  _validateCatDesc(desc) {
    desc = desc && desc.trim();
    if (!desc || desc.length < 10) {
      return "Description must be atleast 10 Characters long";
    }
    if (desc.length > 400) {
      return "Description must not be larger than 400 characters";
    }
  }

  _showAddModal() {
    this.setState({
      selectedCategoryId: null
    });
    this.modalComponentRef.current.setState({
      editMode: false
    });
  }

  _submitCategoryForm(formValues) {
    this.state.selectedCategoryId
      ? this._submitEditCategory(formValues)
      : this._submitAddCategory(formValues);
  }

  async _submitAddCategory(formValues) {
    if (this.state.saveProgress) return false;
    this.setState({
      saveProgress: true
    });
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const savedResponse = await fetch(apiBasePath + apiRoutes.createCategory, {
      headers: {
        Authorization: this.props.authToken,
        "Content-type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        user_id: this.props.user.userId,
        restarant_id: this.props.user.selectedRestarantId,
        cat_name: formValues.catEdit__name.trim(),
        cat_sort_order: formValues.catEdit__sort_order,
        cat_desc: formValues.catEdit__desc.trim(),
        cat_status: 1,
        cat_icon: formValues.category_icon
      })
    }).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = "An Error Occured while saving the Category.";
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg, { autoClose: 8000 });
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = "An Error Occured while saving the Category.";
      toast.error(msg, { autoClose: 7000 });
      return this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
    }
    if (!savedResponseResult.success) {
      toast.error(savedResponseResult.msg, { autoClose: 8000 });
      return this.setState({
        saveProgress: false
      });
    }

    this.setState(
      {
        saveProgress: false,
        saveFailMsg: "",
        currentPageNum: 1
      },
      err => {
        this._fetchCategories(this.props.CONSTANTS);
        this._loadAllCategories();
      }
    );
    $(this.modal.current).modal("hide");
    toast.success("Successfully added category.", { autoClose: 4000 });
  }

  _showEditModal(id) {
    this.setState(
      {
        selectedCategoryId: id
      },
      err => {
        $(this.modal.current).modal("show");
      }
    );
    this.formApi.setValue(
      "catEdit__name",
      this.state.categories.find(c => c.categoryId == id).categoryName
    );
    this.formApi.setValue(
      "catEdit__sort_order",
      this.state.categories.find(c => c.categoryId == id).categorySortOrder
    );
    this.formApi.setValue(
      "catEdit__desc",
      this.state.categories.find(c => c.categoryId == id).categoryDescription
    );
    this.formApi.setValue(
      "category_icon",
      this.state.categories.find(c => c.categoryId == id).categoryIcon ||
      "STARTERS_IMAGE"
    );
    this.modalComponentRef.current.setState({
      editMode: true
    });
  }

  async _submitEditCategory(formValues) {
    this.setState({
      saveProgress: true
    });
    const catId = this.state.selectedCategoryId;
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const savedResponse = await fetch(apiBasePath + apiRoutes.editCategory, {
      headers: {
        Authorization: this.props.authToken,
        "Content-type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        category_id: catId,
        user_id: this.props.user.userId,
        restarant_id: this.props.user.selectedRestarantId,
        cat_name: formValues.catEdit__name.trim(),
        cat_sort_order: formValues.catEdit__sort_order,
        cat_desc: formValues.catEdit__desc.trim(),
        cat_icon: formValues.category_icon,
        cat_status: 1
      })
    }).catch(err => console.error(err));
    if (!savedResponse) {
      let msg = "An Error Occured while saving the changes.";
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg, {});
    }

    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      let msg = "An Error Occured while saving the changes.";
      this.setState({
        saveFailMsg: msg,
        saveProgress: false
      });
      return toast.error(msg, {});
    }

    if (!savedResponseResult.success) {
      this.setState({
        saveProgress: false,
        saveFailMsg: savedResponseResult.msg
      });
      return toast.error(savedResponseResult.msg);
    }

    this.setState({
      saveProgress: false,
      saveFailMsg: ""
    });

    this._fetchCategories(this.props.CONSTANTS);
    this._loadAllCategories();
    $(this.modal.current).modal("hide");
    return toast.success("Successfully edited category.");
  }

  confirmAndDelete(id, catName) {
    confirmAlert({
      title: "Delete Category?",
      message: `Are you sure to delete ${catName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteCategory(id)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _deleteCategory(id) {
    if (this.state.deleteProgress) {
      return false;
    }
    this.setState({
      deleteProgress: true
    });

    const catId = this.state.selectedCategoryId;
    const categoryName = this.state.categories.find(i => i.categoryId == catId)
      .categoryName;
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const deletedResponse = await fetch(
      apiBasePath + apiRoutes.deleteCategory,
      {
        headers: {
          Authorization: this.props.authToken,
          "Content-type": "application/json"
        },
        method: "DELETE",
        body: JSON.stringify({
          cat_id: catId,
          user_id: 1,
          restarant_id: this.props.user.selectedRestarantId
        })
      }
    ).catch(err => console.error(err));

    if (!deletedResponse) {
      let msg = "An Error Occured while deleting the category.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    const deletedResponseResult = await deletedResponse
      .json()
      .catch(err => console.error(err));
    if (!deletedResponseResult) {
      let msg = "An Error Occured while saving the changes.";
      this.setState({
        deleteFailMsg: msg,
        deleteProgress: false
      });
      return toast.error(msg);
    }

    if (!deletedResponseResult.success) {
      this.setState({
        deleteProgress: false,
        deleteFailMsg: deletedResponseResult.msg
      });
      return toast.error(deletedResponseResult.msg);
    }

    this.setState({
      deleteProgress: false,
      deleteFailMsg: "",
      selectedCategoryId: null,
      currentPageNum:
        this.state.categories.length > 1
          ? this.state.currentPageNum
          : this.state.currentPageNum - 1 > 0
            ? this.state.currentPageNum - 1
            : 1
    });
    this._fetchCategories(this.props.CONSTANTS);
    this._loadAllCategories();
    toast.success("Deleted the category " + categoryName, { autoClose: 6000 });
  }
}

class EditCategoryModal extends Component {
  state = { editMode: false };
  render() {
    const {
      reference,
      setFormApi,
      submitCategoryForm,
      initialValues,
      validateCatDesc,
      validateCatName
    } = this.props;
    return (
      <div
        ref={reference}
        className="modal fade"
        id="categoryEditModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Edit category dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editCategoryModal__title">
                {this.state.editMode ? "Edit Category" : "Add categories"}
              </h5>
              <button
                type="button"
                className="close editCategoryModal__close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              getApi={setFormApi}
              preSubmit={formValues => {
                if (!formValues.catEdit__desc) {
                  return formValues;
                }
                let formVals = formValues;
                formVals.catEdit__name = formValues.catEdit__name.trim();
                return formVals;
              }}
              onSubmit={submitCategoryForm}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row mb-sm-3">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="catEdit__name"
                              id="catEdit__name"
                              type="text"
                              placeholder="Enter category name"
                              className={
                                formState.errors.catEdit__name
                                  ? "form-control editCategoryModal__nameInput input_error"
                                  : "form-control editCategoryModal__nameInput"
                              }
                              initialValue={initialValues.catName}
                              validate={val =>
                                validateText(
                                  val,
                                  3,
                                  formState.touched.catEdit__name,
                                  "Category Title",
                                  30
                                )
                              }
                              validateOnBlur
                              validateOnChange
                            />
                            {formState.errors.catEdit__name && (
                              <div className="input_error">
                                {formState.errors.catEdit__name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validateOnBlur
                              validateOnChange
                              field="catEdit__sort_order"
                              type="text"
                              placeholder="Enter sort order"
                              className={
                                formState.errors.catEdit__sort_order
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.catEdit__sort_order && (
                              <div className="input_error">
                                {formState.errors.catEdit__sort_order}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <TextArea
                          field={"catEdit__desc"}
                          id="catEdit__desc"
                          className={
                            formState.errors.catEdit__desc
                              ? "categoryText editCategoryModal__descInput input_error"
                              : "categoryText editCategoryModal__descInput"
                          }
                          placeholder="Enter item description"
                          initialValue={initialValues.catDesc}
                          validate={val =>
                            validateText(
                              val,
                              10,
                              formState.touched.catEdit__desc,
                              "Category Description",
                              400
                            )
                          }
                          rows={5}
                          validateOnBlur
                          validateOnChange
                        />
                        {formState.errors.catEdit__desc && (
                          <div className="input_error">
                            {formState.errors.catEdit__desc}
                          </div>
                        )}
                      </div>

                      <p>Choose an Icon:</p>

                      <div className="form-group clearfix justify-content-between">
                        <RadioGroup
                          field="category_icon"
                          initialValue={"STARTERS_IMAGE"}
                        >
                          {this.props.categoryIcons.map(
                            ({ name, id, img }) => (
                              <label
                                className={
                                  formState.values.category_icon == id
                                    ? "category_icon img-thumbnail selected-icon"
                                    : "category_icon img-thumbnail"
                                }
                                key={id}
                              >
                                <img
                                  src={img}
                                  className=""
                                  alt={name}
                                  style={{
                                    maxWidth: "80%",
                                    maxHeight: "80%"
                                  }}
                                />{" "}
                                <Radio value={id} style={{ opacity: 0 }} />
                              </label>
                            )
                          )}
                        </RadioGroup>
                      </div>
                      {!formState.values.category_icon && (
                        <div className="input_error">
                          Please choose an Icon to display with Category.
                        </div>
                      )}

                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button
                        type="submit"
                        className="btnmodaladd float-right editCategoryModal__submit"
                      >
                        {this.state.editMode ? "Save Changes" : "Add"}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
