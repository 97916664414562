import React, { Component } from "react";
import { AuthContext } from "../contextProvider";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import Switch from 'react-switch';
import moment from "moment";
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);

export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <OptionActions
                        {...props}
                        user={user}
                        authToken={authToken}
                        upAuthToken={up_Authorization}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class OptionActions extends Component {
    state = {
        allOptions: [],
        options: [],
        checked: false,
        fetchProgress: false,
        saveProgress: false,
        searchTxt: '',
        urbanOptionActionSyncDetails: []
    };

    componentDidMount() {
        this._loadAllOptions()
    }
    handlePosChange(checked, index, optionId) {
        let posStatus = checked;
        const options = [...this.state.allOptions];
        options[index].optionPosActive = posStatus
        let onlineStatus = options[index].optionOnlineActive
        let referenceId = options[index].referenceId
        this.setState({ allOptions: options })
        this._updateOptionStatus(optionId, posStatus, onlineStatus, referenceId)
    }
    handleOnlineChange(checked, index, option) {
        if (!option.optionSoldAtStore) {
            return toast.error("option sold at store is off");
        }
        option.urbanOptionActionSyncDetails = null;
        this._onSyncOptionActions(option.optionId, checked, index)
    }
    filterOptions(event) {
        this.setState({ searchTxt: event.target.value });
        let filterOptions = this.state.options.filter(item => item.optionTitle.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1);
        filterOptions = event.target.value ? filterOptions : this.state.options
        this.setState({
            allOptions: filterOptions
        })
    }
    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            style="loadingProgress"
                        />
                    ) : (
                        <>
                            <div className="row mx-0 justify-content-between align-items-center">
                                <div className="">
                                    <span className="categorycol">Option Actions </span>
                                </div>
                                <div className="">
                                    <input className="form-control"
                                        type="text"
                                        placeholder="Search Options"
                                        aria-label="Search"
                                        onChange={this.filterOptions.bind(this)}
                                        value={this.state.searchTxt}
                                    />
                                </div>
                                {/* <div className="">
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => {
                                                let optionIds = []
                                                this.state.allOptions.map((i, index) => {
                                                    optionIds.push(i.itemId)
                                                })
                                                let action = "enable_all"
                                                this._onSyncOptionActions(optionIds, action)
                                            }}
                                        >
                                            Enable All
                    </button>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => {
                                                let optionIds = []
                                                this.state.allOptions.map((i, index) => {
                                                    optionIds.push(i.itemId)
                                                })
                                                let action = "disable_all"
                                                this._onSyncOptionActions(optionIds, action)
                                            }}
                                        >
                                            Disable All
                    </button>
                                    </div> */}
                            </div>
                            <div className="table-responsive mt-3 tableofauto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.no</th>
                                            <th>Option Name</th>
                                            <th>Pos-Status</th>
                                            {this.props.user.urbanPiperStatus == 1 ? <th>Online-Status</th> : null}
                                            {this.props.user.urbanPiperStatus == 1 ? <th>Sync Status</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.allOptions.sort((a, b) => {
                                            return a.optionTitle.localeCompare(b.optionTitle);
                                        })
                                            .map((o, index) => {
                                                return (
                                                    <tr key={"option" + o.optionId}>
                                                        <td>{index + 1} </td>
                                                        <td>{o.optionTitle}</td>
                                                        <td>
                                                            <Switch
                                                                checked={o.optionPosActive}
                                                                onChange={checked => this.handlePosChange(checked, index, o.optionId)}
                                                                onColor="#cccccc"
                                                                offColor="#cccccc"
                                                                onHandleColor="#42f705"
                                                                offHandleColor="#db2b0f"
                                                                handleDiameter={20}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={20}
                                                                width={55}
                                                                className="react-switch"
                                                                id="material-switch"
                                                            />
                                                        </td>
                                                        {this.props.user.urbanPiperStatus == 1 ? <td>
                                                            <Switch
                                                                checked={o.optionOnlineActive}
                                                                onChange={checked => this.handleOnlineChange(checked, index, o)}
                                                                onColor="#cccccc"
                                                                offColor="#cccccc"
                                                                onHandleColor="#42f705"
                                                                offHandleColor="#db2b0f"
                                                                handleDiameter={20}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={20}
                                                                width={55}
                                                                className="react-switch"
                                                                id="material-switch"
                                                            />
                                                        </td> : null}
                                                        {this.props.user.urbanPiperStatus == 1 ? <td>
                                                            {o.shoUrban ? o.urbanOptionActionSyncDetails ? o.urbanOptionActionSyncDetails.map(syncObj => {
                                                                let sucReq = JSON.parse(syncObj.options).options[0]
                                                                return <span key={syncObj.referenceId}>{(o.optionOnlineActive ? 'Enable request on ' : 'Disable request on ') + syncObj.platform + ' ' + sucReq.success + ' on ' + moment(syncObj.lastSynched).format("DD/MM/YYYY HH:mm:ss")}</span>
                                                            }) : 'Sync is pending...' : ''}
                                                            <div>
                                                                <a onClick={() => {
                                                                    o.shoUrban = !o.shoUrban;
                                                                    this._loadUrbanOptionActionsSyncDetails(o)
                                                                }} > <span className="showUrban" >{o.shoUrban ? 'HIDE' : 'SHOW'}</span>
                                                                </a>
                                                            </div>

                                                        </td> : null}
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }

    async _loadAllOptions() {
        this.setState({
            fetchProgress: true
        });
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const requestSendTime = Date.now();
        const optionResponseWithoutLimit = await fetch(
            apiBasePath +
            apiRoutes.getOptions +
            `?user_id=${this.props.user.userId}&limit=${0
            }&offset=${0}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!optionResponseWithoutLimit) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const allOptionsResponseResult = await optionResponseWithoutLimit.json();
        if (!allOptionsResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!allOptionsResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: allOptionsResponseResult.msg
            });
            return toast.error(allOptionsResponseResult.msg);
        }

        this.setState({
            allOptions: allOptionsResponseResult.data,
            fetchProgress: false,
            options: allOptionsResponseResult.data
        });

    }
    async _onSyncOptionActions(optionId, checked, index) {
        const { upApiBasePath } = this.props.CONSTANTS;
        let action = checked == true ? "enable" : "disable"
        const options = [...this.state.allOptions]
        options[index].optionOnlineActive = checked
        let posStatus = options[index].optionPosActive;
        let body = {
            location_ref_id: String(this.props.user.restarantId),
            item_ref_ids: [],
            option_ref_ids: [optionId + ""],
            action: action
        }
        const savedResponse = await fetch(upApiBasePath + "/hub/api/v1/items/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
            retries: 3,
            retryDelay: 1000,
            retryOn: [500, 503]
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (savedResponseResult.status == "error") {
            let action = checked == true ? "disable" : "enable"
            const option = [...this.state.allOptions]
            option[index].optionOnlineActive = checked
            this.setState({ allOptions: option })
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            // this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference_id)
            this._updateOptionStatus(optionId, posStatus, checked, savedResponseResult.reference_id)
            this.setState({ allOptions: options })
            setTimeout(() => {
                toast.success(
                    "Successfully synched"
                );
            }, 1500);
            return toast.success(savedResponseResult.message);
        }
    }

    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: restId,
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanItemOptionActionsResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while saving the webhook response"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
    }
    async _updateOptionStatus(optionId, posStatus, onlineStatus, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            option_id: optionId,
            option_online_active: onlineStatus,
            option_pos_active: posStatus,
            option_reference_id: referenceId
        }

        let apiRoute = CONSTANTS.apiRoutes.updateOptionStatus;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "PUT",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while updating the item status"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
    }
    async _loadUrbanOptionActionsSyncDetails(option) {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const urbanOptionActionSyncDetails = await fetch(
            apiBasePath +
            apiRoutes.getUrbanItemActionSyncDetails +
            `?user_id=${this.props.user.userId}&reference_id=${option.optionReferenceId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!urbanOptionActionSyncDetails) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const urbanOptionActionSyncDetailsResult = await urbanOptionActionSyncDetails.json();
        if (!urbanOptionActionSyncDetailsResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        option.urbanOptionActionSyncDetails = urbanOptionActionSyncDetailsResult.data;
        this.setState({
            fetchProgress: false
        });
    }
}