import React, { Component } from "react";
import { AuthContext, DataContext } from "../contextProvider";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import Switch from 'react-switch';
import moment from "moment";
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);
export default (props) => {
    return (
        <AuthContext.Consumer>
            {({ user, authToken, up_Authorization, CONSTANTS, isSuperAdmin }) => {
                return (
                    <ItemActions
                        {...props}
                        user={user}
                        authToken={authToken}
                        upAuthToken={up_Authorization}
                        CONSTANTS={CONSTANTS}
                        isSuperAdmin={isSuperAdmin}
                    />
                );
            }}
        </AuthContext.Consumer>
    );
};

class ItemActions extends Component {
    state = {
        allItems: [],
        checked: false,
        fetchProgress: false,
        saveProgress: false,
        searchTxt: '',
        items: '',
        urbanItemActionSyncDetails: []
    };

    componentDidMount() {
        this._loadAllMenuItems()
    }

    handleOnlineChange(checked, index, item) {
        if(!item.itemSoldAtStore){
            return toast.error("item sold at store is off");
        }
        item.urbanItemActionSyncDetails = null;
        this._onSyncItemActions(item.itemId, checked, index)
    }
    handlePosChange(checked, index, itemId) {
        let posStatus = checked == true ? "28" : "29";
        const items = [...this.state.allItems]
        items[index].posStatus = posStatus
        let onlineStatus = items[index].onlineStatus
        let referenceId = items[index].referenceId
        this.setState({ allItems: items })
        this._updateItemStatus(itemId, posStatus, onlineStatus, referenceId)
    }
    filterItems(event) {
        this.setState({ searchTxt: event.target.value });
        let filterItems = this.state.items.filter(item => item.itemTitle.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1);
        filterItems = event.target.value ? filterItems : this.state.items
        this.setState({
            allItems: filterItems
        })
    }
    render() {
        return (
            <>
                <div className="content">
                    {this.state.fetchProgress ? (
                        <Loader
                            color="#00BFFF"
                            height="100"
                            width="100"
                            style="loadingProgress"
                        />
                    ) : (
                            <>
                                <div className="row mx-0 justify-content-between align-items-center">
                                    <div className="">
                                        <span className="categorycol">Item Actions </span>
                                    </div>
                                    <div className="">
                                        <input className="form-control"
                                            type="text"
                                            placeholder="Search items"
                                            aria-label="Search"
                                            onChange={this.filterItems.bind(this)}
                                            value={this.state.searchTxt}
                                        />
                                    </div>
                                    {/* <div className="">
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => {
                                                let itemIds = []
                                                this.state.allItems.map((i, index) => {
                                                    itemIds.push(i.itemId)
                                                })
                                                let action = "enable_all"
                                                this._onSyncItemActions(itemIds, action)
                                            }}
                                        >
                                            Enable All
                    </button>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="btnaddcategory"
                                            onClick={() => {
                                                let itemIds = []
                                                this.state.allItems.map((i, index) => {
                                                    itemIds.push(i.itemId)
                                                })
                                                let action = "disable_all"
                                                this._onSyncItemActions(itemIds, action)
                                            }}
                                        >
                                            Disable All
                    </button>
                                    </div> */}
                                </div>
                                {this.state.allItems.length ? (
                                    <div className="table-responsive mt-3 tableofauto">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.no</th>
                                                    <th>Item Name</th>
                                                    <th>Pos Status</th>
                                                    {this.props.user.urbanPiperStatus == 1 ? <th>Online Status</th> : null}
                                                    {this.props.user.urbanPiperStatus == 1 ? <th>Sync Status </th> : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.allItems.sort((a, b) => {
                                                    return a.itemTitle.localeCompare(b.itemTitle);
                                                })
                                                    .map((item, index) => {
                                                        return (
                                                            <tr key={"item" + item.itemId}>
                                                                <td>{index + 1} </td>
                                                                <td>{item.itemTitle}</td>
                                                                <td>
                                                                    <Switch
                                                                        checked={item.posStatus == "28" ? true : false}
                                                                        onChange={checked => this.handlePosChange(checked, index, item.itemId)}
                                                                        onColor="#cccccc"
                                                                        offColor="#cccccc"
                                                                        onHandleColor="#42f705"
                                                                        offHandleColor="#db2b0f"
                                                                        handleDiameter={20}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                        height={20}
                                                                        width={55}
                                                                        className="react-switch"
                                                                        id="material-switch"
                                                                    />
                                                                </td>
                                                                {this.props.user.urbanPiperStatus == 1 ? <td>
                                                                    <Switch
                                                                        checked={item.onlineStatus == "30" ? true : false}
                                                                        onChange={checked => this.handleOnlineChange(checked, index, item)}
                                                                        onColor="#cccccc"
                                                                        offColor="#cccccc"
                                                                        onHandleColor="#42f705"
                                                                        offHandleColor="#db2b0f"
                                                                        handleDiameter={20}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                        height={20}
                                                                        width={55}
                                                                        className="react-switch"
                                                                        id="material-switch"
                                                                    />
                                                                </td> : null}
                                                                {this.props.user.urbanPiperStatus == 1 ? <td>
                                                                    {item.shoUrban ? item.urbanItemActionSyncDetails ? item.urbanItemActionSyncDetails.map(syncObj => {
                                                                        return <span key={syncObj.referenceId}>{(item.onlineStatus == "30" ? 'Enable request on ' : 'Disable request on ') + syncObj.platform + ' ' + syncObj.status + ' on ' + moment(syncObj.lastSynched).format("DD/MM/YYYY HH:mm:ss")}</span>
                                                                    }) : 'Sync is pending...' : ''}
                                                                    <div>
                                                                        <a onClick={() => {
                                                                            item.shoUrban = !item.shoUrban;
                                                                            this._loadUrbanItemActionsSyncDetails(item)
                                                                        }} > <span className="showUrban" >{item.shoUrban ? 'HIDE' : 'SHOW'}</span>
                                                                        </a>
                                                                    </div>

                                                                </td> : null}
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                        <div className=" row mx-0 justify-content-center align-items-center">
                                            <h5>No Items</h5>
                                        </div>
                                    )}
                            </>
                        )}
                </div>
            </>
        );
    }

    async _loadAllMenuItems() {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        this.setState({
            fetchProgress: true
        });
        const itemsResponse = await fetch(
            apiBasePath +
            apiRoutes.getMenuItems +
            `?user_id=${this.props.user.userId}&limit=0&offset=0&restarant_id=${this.props.user.selectedRestarantId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!itemsResponse) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const itemsResponseResult = await itemsResponse.json();
        if (!itemsResponseResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        if (!itemsResponseResult.success) {
            this.setState({
                fetchProgress: false,
                noDataMsg: itemsResponseResult.msg
            });
            return toast.error(itemsResponseResult.msg);
        }
        this.setState({
            allItems: itemsResponseResult.data,
            fetchProgress: false,
            items: itemsResponseResult.data
        });
    }

    async _onSyncItemActions(itemId, checked, index) {
        const { upApiBasePath } = this.props.CONSTANTS;
        let onlineStatus = checked == true ? "30" : "31";
        let action = checked == true ? "enable" : "disable"
        const items = [...this.state.allItems];
        items[index].onlineStatus = onlineStatus;
        let posStatus = items[index].posStatus;
        let body = {
            location_ref_id: String(this.props.user.restarantId),
            item_ref_ids: [itemId],
            option_ref_ids: [],
            action: action
        }
        const savedResponse = await fetch(upApiBasePath + "/hub/api/v1/items/", {
            async: true,
            crossDomain: true,
            method: "POST",
            headers: {
                Authorization: this.props.user.urbanAuthorization,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
            retries: 3,
            retryDelay: 1000,
            retryOn: [500, 503]
        }
        )
        if (!savedResponse) {
            let msg = "An error Occurred"
            this.setState({
                saveProgress: false
            });
            return toast.error(msg)
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));

        if (savedResponseResult.status == "error") {
            let onlineStatus = checked == true ? "31" : "30";
            const item = [...this.state.allItems]
            item[index].onlineStatus = onlineStatus
            this.setState({ allItems: item })
            return toast.error(savedResponseResult.message)
        }
        if (savedResponseResult.status == "success") {
            // this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference_id)
            this._updateItemStatus(itemId, posStatus, onlineStatus, savedResponseResult.reference_id)
            this.setState({ allItems: items })
            setTimeout(() => {
                toast.success(
                    "Successfully synched"
                );
            }, 1500);
            return toast.success(savedResponseResult.message);
        }
    }

    async saveWebhookResponse(restId, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            restaurant_id: restId,
            reference_id: referenceId,
        }

        let apiRoute = CONSTANTS.apiRoutes.UrbanItemOptionActionsResponse;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while saving the webhook response"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
    }
    async _updateItemStatus(itemId, posStatus, onlineStatus, referenceId) {
        const { CONSTANTS, authToken } = this.props
        let body = {
            item_id: itemId,
            item_pos_status: posStatus,
            item_online_status: onlineStatus,
            item_reference_id: referenceId
        }

        let apiRoute = CONSTANTS.apiRoutes.updateItemStatus;

        const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
            method: "PUT",
            headers: {
                "Content-type": "application/json",
                Authorization: authToken
            },
            body: JSON.stringify(body)
        }).catch(err => console.error(err));

        if (!savedResponse) {
            this.setState({
                saveProgress: false
            });
            return toast.error("An Error Occured.");
        }
        const savedResponseResult = await savedResponse
            .json()
            .catch(err => console.error(err));
        if (!savedResponseResult) {
            return this.setState({
                saveProgress: false,
                msg: "An Error Occured while updating the item status"
            });
        }
        if (!savedResponseResult.success) {
            this.setState({
                saveProgress: false
            });
        }
    }
    async _loadUrbanItemActionsSyncDetails(item) {
        const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
        const urbanItemActionSyncDetails = await fetch(
            apiBasePath +
            apiRoutes.getUrbanItemActionSyncDetails +
            `?user_id=${this.props.user.userId}&reference_id=${item.itemReferenceId}`,
            { headers: { Authorization: this.props.authToken } }
        ).catch(err => console.error(err));
        if (!urbanItemActionSyncDetails) {
            let msg = "An Error Occured.";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }

        const urbanItemActionSyncDetailsResult = await urbanItemActionSyncDetails.json();
        if (!urbanItemActionSyncDetailsResult) {
            let msg = "An Error Occured";
            this.setState({
                fetchProgress: false,
                noDataMsg: msg
            });
            return toast.error(msg);
        }
        item.urbanItemActionSyncDetails = urbanItemActionSyncDetailsResult.data;
        this.setState({
            fetchProgress: false
        });
    }

}