import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { BUVVAS_LOGO } from "../../assets/images";
import menuItems from "../menu-items";

export default ({ parentRoute, routes, isSuperAdmin, subMenu, match }) => {
  const [route,setRoute] = React.useState(routes);
  const handleClick = (open,idx) => {
    let newroute = [...route];
      newroute[idx].open = !open;
      setRoute(newroute)
   };
  let menu = (subMenu) => {
    return <ul className=" text-capitalize">
      {subMenu.map((routesub, idxsub) => {
        return <li key={String(idxsub)}>
          <NavLink
            exact={true}
            activeClassName="active"
            to={`${parentRoute + routesub.route}`}
          >
            {routesub.title}
          </NavLink>
        </li>
      })}
    </ul>
  }

  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="logo text-center text-uppercase">
          <a href="/" style={{ display: "block" }}>
            <img alt="Buvvas" src={BUVVAS_LOGO} className="img-fluid" />
          </a>
        </div>
        <ul className="items text-capitalize">
          {/* {isSuperAdmin && (
            <li>
              <NavLink
                exact={true}
                activeClassName="active"
                to={`${parentRoute}/restaurants`}
              >
                Restaurants
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/categories`}
            >
              categories
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/sub-categories`}
            >
              sub-categories
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/menu-items`}
            >
              menu items
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/tables-list`}
            >
              Tables
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/assigned-tables-list`}
            >
              Assigned Tables
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/receptionists`}
            >
              Receptionist
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/chefs`}
            >
              Chef
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/bearers`}
            >
              Bearer
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/orders`}
            >
              Orders
            </NavLink>
          </li>
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/guests`}
            >
              Guests
            </NavLink>
          </li>         
          <li>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`${parentRoute}/reports`}
            >
              Reports
            </NavLink>
          </li> */}

          {
            route.map((r, idx) => {
              return <li key={String(idx)} className="mainmenu" >
                <NavLink
                  exact={true}
                  activeClassName="active"
                  to={`${parentRoute + r.segment}`}
                  onClick={()=> r.subMenu? handleClick(r.open,idx):""}
                >
                  {r.title}
                </NavLink>
                {r.subMenu && r.open ? menu(r.subMenu) : null}
              </li>
            })
          }
        </ul>
      </div>
    </div>
  );
};
