import React, { Component } from "react";
import { EDIT_IMG, DELETE_IMG } from "../assets/images";
import { AuthContext, DataContext } from "../contextProvider";
import { CategoriesContext, CategoriesProvider } from "../context";
import Loader from "react-loader-spinner";
import { Form, Text, TextArea, Select, Radio, RadioGroup, Checkbox } from "informed";
import { SubCategoriesProvider, SubCategoriesContext } from "../context";
import { ItemRoutesProvider, ItemRoutesContext } from "../context/ItemRoutesContext";
import $ from "jquery";
import {
  MenuItemsContextProvider,
  MenuItemsContext
} from "../context/MenuItemsContext";
import ReactPaginate from "react-paginate";
import {
  validateNumber,
  validateWholeNumber,
  validateText,
  validateInput,
  validatePositiveInteger
} from "../utilities";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

export default (props) => {
  return (
    <AuthContext.Consumer>
      {({ CONSTANTS, user, authToken, up_Authorization, isSuperAdmin, logOut }) => {
        return (
          <MenuItems
            {...props}
            user={user}
            authToken={authToken}
            CONSTANTS={CONSTANTS}
            isSuperAdmin={isSuperAdmin}
            upAuthToken={up_Authorization}
            logOut={logOut}
          />
        );
      }}
    </AuthContext.Consumer>
  );
};

class MenuItems extends Component {
  static contextType = MenuItemsContext;

  state = {
    currentPageNum: 1,
    recordsPerPage: 10,
    fetchProgress: true,
    saveProgress: false,
    deleteProgress: false,
    saveFailMsg: "",
    deleteFailMsg: "",
    noDataMsg: "No items Yet.",
    foodTypes: this.props.CONSTANTS.foodTypes || [],
    ItemRoutes: this.props.ItemRoutes,
    allItems: [],
    menuItems: [],
    allMenuItems: [],
    catList: [],
    sortAsc: true,
    sortIcon: "",
    searchItemTxt: '',
    searchCatTxt: "",
    posStatus: "",
    selOptions: {}
  };

  async componentWillMount() {
    this.context.setAboveValues(this.props);
    this._loadMenuItems();
    this._loadAllMenuItems()
    this.editModal = React.createRef();
    this.setState({
      fetchTaxRateProgress: true
    });
    let taxRate = await this.context
      .getRestarantTaxRate()
      .catch(err => console.error(err));
    this.setState({
      taxRate: taxRate.data[0].taxRate,
      taxName: taxRate.data[0].taxName,
      discountRate: taxRate.data[0].discountRate,
      fetchTaxRateProgress: false,
      taxRateInServer: taxRate.data[0].taxRate
    });
  }
  filterItems() {
    let filterItems = this.state.searchItemTxt ? this.state.allMenuItems.filter(itm => { return itm.itemTitle.toUpperCase().indexOf(this.state.searchItemTxt.toUpperCase()) > -1 }) : this.state.allMenuItems;
    let filterCat = this.state.searchCatTxt ? filterItems.filter(itm => { return itm.categoryName.toUpperCase().indexOf(this.state.searchCatTxt.toUpperCase()) > -1 }) : filterItems;
    let filterPos = this.state.filterPos ? filterCat.filter(itm => { return itm.posStatus == this.state.filterPos }) : filterCat;
    let filterOnline = this.state.filterOnline ? filterPos.filter(itm => { return itm.onlineStatus == this.state.filterOnline }) : filterPos;
    let filterMrp = this.state.filterMrpItems ? filterOnline.filter(itm => { return itm.itemDisableDiscount == (this.state.filterMrpItems == "Active" ? true : false) }) : filterOnline;
    this.setState({
      menuItems: filterMrp
    });
  }

  async filterByItems(event) {
    await this.setState({ searchItemTxt: event.target.value });
    this.filterItems();
  }
  async filterByCat(event) {
    await this.setState({ searchCatTxt: event.target.value });
    this.filterItems();
  }
  async filterPosStatus(event) {
    await this.setState({ filterPos: event.target.value });
    this.filterItems();
  }
  async filterOnlineStatus(event) {
    await this.setState({ filterOnline: event.target.value });
    this.filterItems();
  }
  async filterMrpItemsStatus(event) {
    await this.setState({ filterMrpItems: event.target.value });
    this.filterItems();
  }
  render() {
    return (
      <>
        <div className="contentvthbtn">
          {this.context.itemsLoadingProgress ? (
            <Loader
              color="#00BFFF"
              height="100"
              width="100"
              //eslint-disable-next-line react/style-prop-objects
              style="loadingProgress"
            />
          ) : (
            <>
              <div className="row mx-0 justify-content-between">
                <div className="">
                  <span className="categorycol"> Items</span>
                </div>


                {/* {this.props.user.urbanPiperStatus == 1 ?
                    <div>
                      <button
                        type="button"
                        className="btnaddcategory"
                        onClick={() => {
                          this._onSyncItems()
                        }}
                      >
                        Sync Items
                    </button>
                    </div>
                    : null
                  } */}
                <div className="row mx-0">
                  {/* <div className="pr-3">
                      <div className="form-group d-flex">
                        <label
                          style={{
                            minWidth: "40%",
                            // display: "flex",
                            // alignItems: "center",
                            margin: 0
                          }}
                        >
                          Tax  % :
                      </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.taxName || ""}
                            onChange={e => {
                              this.setState({
                                taxName: String(e.nativeEvent.target.value)
                              });
                            }}
                          />
                          <input
                            type="number"
                            className="form-control"
                            value={this.state.taxRate || 0}
                            onChange={e => {
                              this.setState({
                                taxRate: Number(e.nativeEvent.target.value)
                              });
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              onClick={this._confirmAndSetTaxPercentage.bind(
                                this
                              )}
                              type="button"
                              style={{ transition: "width 0.2s" }}
                            >
                              {this.state.fetchTaxRateProgress ||
                                this.state.taxSaveProgress ? (
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{
                                      fontSize: "18px",
                                      height: "18px",
                                      top: "calc((40px - 18px) / 2)"
                                    }}
                                  />
                                ) : (
                                  "Set Tax Rate"
                                )}{" "}
                            </button>
                          </div>
                        </div>

                      </div>
                    </div> */}
                  <div className="">
                    <button
                      type="button"
                      className="btnaddcategory float-right"
                      // data-toggle="modal"
                      // data-target="#menuItemModal"
                      onClick={() => {
                        this.props.history.push("add-menu-item");
                      }}
                    >
                      <i className="fa fa-plus plusicon" aria-hidden="true" />
                      &nbsp; Add menu item
                    </button>
                  </div>
                  {/* <div className="pr-3">
                    <div className="form-group d-flex">
                      <label
                        style={{
                          minWidth: "40%",
                          display: "flex",
                          alignItems: "center",
                          margin: 0
                        }}
                      >
                        Discount :
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.discountRate || 0}
                          onChange={e => {
                            this.setState({
                              discountRate: Number(e.nativeEvent.target.value)
                            });
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            onClick={this._confirmAndSetDiscountPercentage.bind(
                              this
                            )}
                            type="button"
                            style={{ transition: "width 0.2s" }}
                          >
                            {this.state.fetchdiscountRateProgress ||
                            this.state.discountSaveProgress ? (
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{
                                  fontSize: "18px",
                                  height: "18px",
                                  top: "calc((40px - 18px) / 2)"
                                }}
                              />
                            ) : (
                              "Set Discount  Rate"
                            )}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

              </div>
              <div className="row">
                {/* <div className="col-2 col-md-2 col-lg-2 restarant_input__labelWrapper mb-3">
                        <label style={{marginTop:"10px"}}
                          className="align-items-center"
                        >
                          Filter Items<span className="">:</span>
                        </label>
                      </div> */}
                {/* <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                          <div className="form-group">
                            <select
                              field="cat"
                              className="form-control"
                              onChange={this.filterItemsByCat.bind(this)}
                            >
                              <option value="">
                                - Select Category -
                              </option>
                              {this.state.catList
                                ? this.state.catList.map((c, idx) => {
                                  return (
                                    <option
                                      key={String(idx)}
                                      value={c}
                                    >
                                      {c}
                                    </option>
                                  );
                                })
                                : null}
                                
                            </select>
                          
                          </div>
                          

                         </div> */}
                {/* <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                           <input className="form-control"
                             type="text"
                              placeholder="Search Items..."
                              aria-label="Search"
                              onChange={this.filterItems.bind(this)}
                              value={this.state.searchTxt}
                          />
                         </div> */}
              </div>
              <div className="table-responsive mt-3 text-capitalize tableofauto">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>
                        <div>Item name</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Search Items..."
                            aria-label="Search"
                            onChange={this.filterByItems.bind(this)}
                            value={this.state.searchItemTxt}
                            style={{ fontSize: 10, backgroundColor: "#FFFFFF" }}
                          />
                        </div>
                      </th>
                      {/* <th>quantity</th> */}
                      <th>
                        <div>Category name</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Search Category..."
                            aria-label="Search"
                            onChange={this.filterByCat.bind(this)}
                            value={this.state.searchCatTxt}
                            style={{ fontSize: 10, backgroundColor: "#FFFFFF" }}
                          />
                        </div>
                      </th>
                      <th>price</th>
                      <th>Pos Status <select onChange={obj => this.filterPosStatus(obj)} style={{ width: 15, backgroundColor: "#98a4af", border: "none" }}>
                        <option value="">All</option>
                        <option value="28">Active</option>
                        <option value="29">In Active</option>
                      </select></th>
                      <th>Online Status <select onChange={obj => this.filterOnlineStatus(obj)} style={{ width: 15, backgroundColor: "#98a4af", border: "none" }}>
                        <option value="">All</option>
                        <option value="30">Active</option>
                        <option value="31">In Active</option>
                      </select></th>
                      <th>MRP Items <select onChange={obj => this.filterMrpItemsStatus(obj)} style={{ width: 15, backgroundColor: "#98a4af", border: "none" }}>
                        <option value="">All</option>
                        <option value="Active">Active</option>
                        <option value="In Active">In Active</option>
                      </select></th>
                      <th className="actionWidth">Action</th>
                    </tr>
                  </thead>
                  {this.state.menuItems.length ? (
                    <tbody>
                      {this.state.menuItems.map((item, idx) => {
                        return (
                          <tr key={String(idx)}>
                            <td>
                              {(this.context.currentPageNum - 1) *
                                this.context.recordsPerPage +
                                idx +
                                1}
                            </td>
                            <td><div>
                              {item.itemTitle}
                             {item.itemOptions.length ? <span
                                className="fa fa-plus plusicon"
                                aria-hidden="true"
                                data-toggle="modal"
                                data-target="#optionModal"
                                onClick={()=>{
                                  this.setState({selOptions: item});
                                }} />:null}
                            </div></td>
                            {/* <td>{item.itemShortTitle}</td>
                              <td>{item.subCategoryName}</td>
                              <td>{item.categoryName}</td>
                              <td>{item.itemRouteName}</td> */}
                            {/* <td>{item.itemQuantity}</td> */}
                            <td>{item.categoryName}</td>
                            <td> {item.itemPrice.toFixed(2)}</td>
                            <td>{item.posStatus == "28" ? "Active" : "InActive"}</td>
                            <td>{item.onlineStatus == "30" ? "Active" : "InActive"}</td>
                            <td>{item.itemDisableDiscount ? "Active" : "InActive"}</td>
                            <td>
                              <img
                                src={EDIT_IMG}
                                alt=""
                                className="icon img-fluid"
                                onClick={() => {
                                  this._showEditModal(item.itemId)
                                  this.props.history.push(
                                    "menu-item-details",
                                    { menuItemId: item.itemId }
                                  )
                                }
                                }
                              />
                              {this.props.user.restarantMenuDelete == 1 ?
                              <img
                                alt=""
                                src={DELETE_IMG}
                                className="img-fluid"
                                onClick={() => {
                                  if (item.itemOptions.length) {
                                    return toast.error("There are still option groups/options for this item. ");
                                  }
                                  if (item.itemCgst || item.itemSgst || item.itemIgst) {
                                    return toast.error("There are still taxes/charges for this item. ");
                                  }
                                  this.confirmAndDelete(item.itemId)
                                }
                                }
                              />:null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <div className="noDataMsgContainer">
                      <h5>{this.state.noDataMsg}</h5>
                    </div>
                  )}
                </table>
              </div>
            </>
          )}
        </div>
        {/* <div className="container-fluid">
          {this.context.totalItems / this.context.recordsPerPage > 1 ? (
            <ReactPaginate
              previousLabel={
                <i className="fa fa-angle-double-left" aria-hidden="true" />
              }
              nextLabel={
                <i className="fa fa-angle-double-right" aria-hidden="true" />
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(
                this.context.totalItems / this.context.recordsPerPage
              )}
              // pageCount={10}
              marginPagesDisplayed={this.state.surroundingButtonsCount}
              pageRangeDisplayed={3}
              pageLinkClassName={"pagebtn"}
              onPageChange={data => this.getMore(data.selected + 1)}
              containerClassName={"pagination"}
              // subContainerClassName={''}
              activeLinkClassName={"active"}
              forcePage={this.context.currentPageNum - 1}
            />
          ) : null}
        </div> */}
        <CategoriesProvider {...this.props}>
          <SubCategoriesProvider {...this.props}>
            <ItemRoutesProvider {...this.props}>
              <CategoriesContext.Consumer>
                {({ categories, fetchProgress, noDataMsg }) => {
                  return (
                    <SubCategoriesContext.Consumer>
                      {({ subCategories }) => (
                        <ItemRoutesContext.Consumer>
                          {({ ItemRoutes }) => (
                            <EditMenuItemModal
                              categories={categories}
                              subCategories={subCategories}
                              ItemRoutes={ItemRoutes}
                              fetchAndloadItems={() => {
                                this.context.setCurrentPageNum(1);

                                this._loadMenuItems();
                              }}
                              ref={instance => (this.editModalComponent = instance)}
                              foodTypes={this.state.foodTypes}
                            />
                          )}
                        </ItemRoutesContext.Consumer>
                      )}
                    </SubCategoriesContext.Consumer>
                  );
                }}
              </CategoriesContext.Consumer>
            </ItemRoutesProvider>
          </SubCategoriesProvider>
        </CategoriesProvider>
        <div
          className="modal fade"
          id="optionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Options
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="closeicon" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                {
                  Object.keys(this.state.selOptions).length && this.state.selOptions && this.state.selOptions.itemOptions.map(op => {
                    return <div key={String(op.optionGroupId)}>
                      <b>{op.optionGroupTitle}</b>
                      {op.options_list.map(ol => {
                        return <p key={String(ol.optionId)}>{ol.optionTitle} - {ol.optionPrice}/-</p>
                      })}
                    </div>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  _showEditModal(itemId) {
    this.context.setSelectedItemId(itemId);
    // this.editModalComponent._openModal();
  }

  getMore(pageNum) {
    this.context.setCurrentPageNum(pageNum);
    this.setState(
      {
        currentPageNum: pageNum
      },
      err => {
        this._loadMenuItems();
      }
    );
  }

  componentWillUnmount() {
    //since we are not using the pagination state in this component, but using it with the context(which doesnot gets destroyed on navigating away), we have to reset some values on unmount
    this.context.setCurrentPageNum(1);
    toast.dismiss();
  }

  confirmAndDelete(id) {
    let itemTitle = (this.context.menuItems.find(i => i.itemId == id) || {})
      .itemTitle;
    confirmAlert({
      title: "Delete?",
      message: `Are you sure to delete ${itemTitle}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._deleteItem(id)
        },
        {
          label: "No",
          onClick: () => null
        }
      ]
    });
  }

  async _loadMenuItems() {
    const itemsResponse = await this.context
      .fetchMenuItems({
        CONSTANTS: this.props.CONSTANTS,
        userId: this.props.user.userId,
        authToken: this.props.authToken,
        restarantId: this.props.user.selectedRestarantId
      })
      .catch(err => console.error(err));
    if (itemsResponse.logout) {
      toast.error(itemsResponse.msg, { autoClose: 8000 });
      return this.props.logOut();
    }
    if (!itemsResponse.success) {
      return this.setState({
        noDataMsg: itemsResponse.msg
      });
    }

    if (itemsResponse.data.length == 0) {
      return this.setState({
        noDataMsg: "No Items."
      });
    }

    let cats = itemsResponse.data ? [...new Set(itemsResponse.data.map(item => (item.categoryName)))] : [];


    this.setState({
      menuItems: itemsResponse.data,
      noDataMsg: "",
      // itemsLoadingProgress: false,
      allMenuItems: itemsResponse.data,
      catList: cats
    });
  }
  async _loadAllMenuItems() {
    const { apiBasePath, apiRoutes } = this.props.CONSTANTS;
    const itemsResponse = await fetch(
      apiBasePath +
      apiRoutes.getMenuItems +
      `?user_id=${this.props.user.userId}&limit=0&offset=0&restarant_id=${this.props.user.selectedRestarantId}`,
      { headers: { Authorization: this.props.authToken } }
    ).catch(err => console.error(err));
    if (!itemsResponse) {
      let msg = "An Error Occured.";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }

    const itemsResponseResult = await itemsResponse.json();
    if (!itemsResponseResult) {
      let msg = "An Error Occured";
      this.setState({
        fetchProgress: false,
        noDataMsg: msg
      });
      return toast.error(msg);
    }
    if (!itemsResponseResult.success) {
      this.setState({
        fetchProgress: false,
        noDataMsg: itemsResponseResult.msg
      });
      return toast.error(itemsResponseResult.msg);
    }
    this.setState({
      allItems: itemsResponseResult.data
    });
  }

  async _onSyncItems() {
    if (this.state.saveProgress) {
      return false;
    }
    const { apiBasePath, upApiBasePath } = this.props.CONSTANTS;
    let baseUrl = apiBasePath.split("/api")[0]
    let items = this.state.allItems.map((i, idx) => {
      let incPlatforms = i.itemIncludedPlatforms ? i.itemIncludedPlatforms.itemIncludedPlatforms : []
      return {
        ref_id: i.itemId,
        title: i.itemTitle,
        price: i.itemPrice,
        markup_price: i.itemMarkupPrice ? i.itemMarkupPrice : Number,
        ref_title: i.itemShortTitle,
        description: i.itemDescription,
        weight: i.itemWeight ? i.itemWeight : Number,
        sold_at_store: i.itemSoldAtStore,
        available: i.itemState == "Available" ? true : false,
        sort_order: i.itemSortOrder ? i.itemSortOrder : 0,
        current_stock: i.itemCurrentStock ? i.itemCurrentStock : Number,
        category_ref_ids: [String(i.categoryId)],
        food_type: String(i.itemType),
        recommended: i.itemRecommended,
        img_url: baseUrl + i.itemImage,
        translations: [],
        included_platforms: incPlatforms
      }
    })
    this.setState({
      saveProgress: true
    });
    let body = {
      items: items
    }
    const savedResponse = await fetch(upApiBasePath + "/external/api/v1/inventory/locations/" + this.props.user.selectedRestarantId + "/", {
      async: true,
      crossDomain: true,
      method: "POST",
      headers: {
        Authorization: this.props.user.urbanAuthorization,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }
    )
    if (!savedResponse) {
      let msg = "An error Occurred"
      this.setState({
        saveProgress: false
      });
      return toast.error(msg)
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (savedResponseResult.status == "error") {
      this.setState({
        saveProgress: false
      });
      return toast.error(savedResponseResult.message)
    }
    if (savedResponseResult.status == "success") {
      this.saveWebhookResponse(this.props.user.selectedRestarantId, savedResponseResult.reference)
      setTimeout(() => {
        toast.success(
          "Successfully synched"
        );
      }, 1500);
      return toast.success(savedResponseResult.message);
    }
  }

  async saveWebhookResponse(restId, referenceId) {
    const { CONSTANTS, authToken } = this.props
    let body = {
      restaurant_id: restId,
      reference_id: referenceId,
    }

    let apiRoute = CONSTANTS.apiRoutes.UrbanCatalogueResponse;

    const savedResponse = await fetch(CONSTANTS.apiBasePath + apiRoute, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken
      },
      body: JSON.stringify(body)
    }).catch(err => console.error(err));

    if (!savedResponse) {
      this.setState({
        saveProgress: false
      });
      return toast.error("An Error Occured.");
    }
    const savedResponseResult = await savedResponse
      .json()
      .catch(err => console.error(err));
    if (!savedResponseResult) {
      this.setState({
        saveProgress: false,

      });
      return toast.error("An error occurred while storing the response.")
    }
    if (!savedResponseResult.success) {
      toast.error(savedResponseResult.msg, { autoClose: 8000 });
      return this.setState({
        saveProgress: false
      });
    }
    this.setState({
      saveProgress: false
    });
  }


  async _deleteItem(itemId) {
    if (this.state.deleteProgress) return false;
    this.setState({
      deleteProgress: true
    });
    const deleted = await this.context
      .deleteItem(itemId)
      .catch(err => console.error(err));
    if (!deleted) {
      toast.error("An Error Occured while deleting");
    }

    if (!deleted.success) {
      toast.error(deleted.msg);
    }
    toast.success("Deleted successFully");
    this.setState({
      deleteProgress: false
    });
    this._loadMenuItems();
  }

  _confirmAndSetTaxPercentage(e) {
    if (this.state.taxSaveProgress) {
      return false;
    }

    if (this.state.taxRate < 0) {
      return toast.error("Tax Rate cannot be less than 0%");
    }

    if (this.state.taxRate > 1000) {
      return toast.error("Tax Rate cannot be more than 1000%");
    }
    confirmAlert({
      title: "Set Tax Rate?",
      message: `Are you sure to set the tax Rate at ${this.state.taxRate}%.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._setTaxPercentage()
        },
        {
          label: "No",
          onClick: () => this.setState({ taxRate: this.state.taxRateInServer })
        }
      ]
    });
  }
  _confirmAndSetDiscountPercentage(e) {
    if (this.state.discountSaveProgress) {
      return false;
    }

    if (this.state.discountRate < 0) {
      return toast.error("Discount Rate cannot be less than 0%.");
    }

    if (this.state.discountRate > 100) {
      return toast.error("Discount Rate cannot be more than 100%.");
    }
    confirmAlert({
      title: "Set Discount Rate?",
      message: `Are you sure to set the discount Rate at ${this.state.discountRate}%.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this._setDiscountPercentage()
        },
        {
          label: "No",
          onClick: () => this.setState({ discountRate: this.state.discountRateInServer })
        }
      ]
    });
  }

  _setTaxPercentage(e) {
    // let tax = e.nativeEvent.target.value;
    this.setState(
      {
        taxSaveProgress: true
      },
      async err => {
        if (err) return console.error(err);
        let changedResult = await this.context
          .submitTaxPercentage(this.state.taxRate, this.state.taxName, this.state.discountRate)
          .catch(err => console.error(err));
        if (!changedResult.success) {
          this.setState({
            taxSaveProgress: false
          });
          return toast.error(changedResult.msg);
        }
        this.setState({
          taxRateInServer: this.state.taxRate,
          taxNameInServer: this.state.taxName,
          taxSaveProgress: false
        });
        toast.success("Successfully updated tax rate", { autoClose: 3000 });
      }
    );
  }

  _setDiscountPercentage(e) {
    // let tax = e.nativeEvent.target.value;
    this.setState(
      {
        discountSaveProgress: true
      },
      async err => {
        if (err) return console.error(err);
        let changedResult = await this.context
          .submitTaxPercentage(this.state.taxRate, this.state.discountRate)
          .catch(err => console.error(err));
        if (!changedResult.success) {
          this.setState({
            taxSaveProgress: false
          });
          return toast.error(changedResult.msg);
        }
        this.setState({
          discountRateInServer: this.state.discountRate,
          discountSaveProgress: false
        });
        toast.success("Successfully updated discount rate.", { autoClose: 4000 });
      }
    );
  }
}

class EditMenuItemModal extends Component {
  static contextType = MenuItemsContext;

  componentWillMount() {
    this.uiModal = React.createRef();
  }

  componentDidMount() {
    $(this.uiModal.current).on("hidden.bs.modal", () => {
      this.context.setSelectedItemId(null);
      this.formApi.reset();
    });
    $(this.uiModal.current).on("show.bs.modal", () => {
      this.setState({
        selectedItemId: this.context.selectedItemId
      });
    });
  }

  state = {
    categories: this.props.categories || [],
    subCategories: this.props.subCategories,
    ItemRoutes: this.props.ItemRoutes,
    checked: false
  };

  render() {
    return (
      <div
        ref={this.uiModal}
        className="modal fade"
        id="menuItemModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="menuItemModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.selectedItemId ? "Edit" : "Add"} menu item
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="closeicon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <Form
              getApi={this._setFormApi.bind(this)}
              onSubmit={this._submitMenuItemForm.bind(this)}
            >
              {({ formState }) => {
                return (
                  <>
                    <div className="modal-body">
                      <div className="row">
                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="img_preview_box">
                            <label id="img_preview_label" htmlFor="img_preview">
                              <input
                                field="img_preview"
                                type="file"
                                className="img-preview"
                                accept="image/*"
                                id="img_preview"
                              />
                              <i
                                className="fa fa-upload img-upload-icon"
                                aria-hidden="true"
                              />
                            </label>
                          </div>
                        </div> */}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="item__name"
                              validate={this._validateItemTitle.bind(this)}
                              validateOnBlur
                              validateOnChange
                              type="text"
                              placeholder="Enter item name"
                              className={
                                formState.errors.item__name
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__name && (
                              <div className="input_error">
                                {formState.errors.item__name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="item__short__name"
                              validate={this._validateItemShortTitle.bind(this)}
                              validateOnBlur
                              validateOnChange
                              type="text"
                              placeholder="Enter  item  short name"
                              className={
                                formState.errors.item__short__name
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__short__name && (
                              <div className="input_error">
                                {formState.errors.item__short__name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Select
                              field="item__cat"
                              validate={val =>
                                this._validateSelectInput(val, "Category")
                              }
                              onValueChange={e => {
                                // console.log("what the hell?");
                                this.formApi.setValue(
                                  "item__subCat",
                                  undefined
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              className={
                                formState.errors.item__cat
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            >
                              <option value="" disabled>
                                - Select Category -
                              </option>
                              {this.props.categories.length
                                ? this.props.categories.map((c, idx) => {
                                  return (
                                    <option
                                      key={String(idx)}
                                      value={c.categoryId}
                                    >
                                      {c.categoryName}
                                    </option>
                                  );
                                })
                                : null}
                            </Select>
                            {formState.errors.item__cat && (
                              <div className="input_error">
                                {formState.errors.item__cat}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Select
                              field="item__subCat"
                              validate={val => {
                                return this._validateSelectInput(
                                  val,
                                  "sub category"
                                );
                              }}
                              // onChange={e => {
                              //   alert("what the hell?");
                              //   formState.values.item__subCat = null;
                              // }}
                              validateOnBlur
                              // validateOnChange
                              className={
                                formState.errors.item__subCat
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            >
                              <option value="" disabled>
                                - Select sub category -
                              </option>
                              {this.props.subCategories.length
                                ? this.props.subCategories
                                  .filter(
                                    sc =>
                                      sc.categoryId ==
                                      formState.values.item__cat
                                  )
                                  .map((c, idx) => {
                                    return (
                                      <option
                                        key={String(idx)}
                                        value={c.subCategoryId}
                                      >
                                        {c.subCategoryName}
                                      </option>
                                    );
                                  })
                                : null}
                            </Select>
                            {formState.errors.item__subCat && (
                              <div className="input_error">
                                {formState.errors.item__subCat}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!val && !formState.touched.item__price) {
                                  return;
                                }
                                if (isNaN(val)) {
                                  return "Please Enter a valid price.";
                                }
                                if (Number(val) > 10000) {
                                  return "Price cannot be more than 10000 and cannot have more than 2 decimal points.";
                                }
                                let notPositive = validateWholeNumber(
                                  val,
                                  "Item Price"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__price"
                              type="text"
                              placeholder="Enter item price"
                              className={
                                formState.errors.item__price
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__price && (
                              <div className="input_error">
                                {formState.errors.item__price}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!val && !formState.touched.item__cgst) {
                                  return;
                                }
                                // if (isNaN(val)) {
                                //   return "Please Enter a cgst.";
                                // }
                                // if (Number(val) > 10000) {
                                //   return "Price cannot be more than 10000 and cannot have more than 2 decimal points.";
                                // }
                                // let notPositive = validateWholeNumber(
                                //   val,
                                //   "Item Price"
                                // );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__cgst"
                              type="text"
                              placeholder="Enter CGST tax %"
                              className={
                                formState.errors.item__cgst
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__cgst && (
                              <div className="input_error">
                                {formState.errors.item__cgst}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!val && !formState.touched.item__sgst) {
                                  return;
                                }
                                // if (isNaN(val)) {
                                //   return "Please Enter a valid cgst.";
                                // }
                                // if (Number(val) > 10000) {
                                //   return "Price cannot be more than 10000 and cannot have more than 2 decimal points.";
                                // }
                                // let notPositive = validateWholeNumber(
                                //   val,
                                //   "Item Price"
                                // );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__sgst"
                              type="text"
                              placeholder="Enter  SGST Tax %"
                              className={
                                formState.errors.item__sgst
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__sgst && (
                              <div className="input_error">
                                {formState.errors.item__sgst}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              field="item__qtyTitle"
                              validate={val => {
                                if (formState.touched.item__qtyTitle && !val) {
                                  return "Please Enter quantity description / units";
                                }

                                if (
                                  formState.touched.item__qtyTitle &&
                                  val.length > 30
                                ) {
                                  return "Quantity description cannot be longer than 30 characters.";
                                }
                              }}
                              validateOnBlur
                              validateOnChange
                              //@todo - what is this ?
                              placeholder="Quantity (1 | Family Pack | 6pcs.)"
                              className={
                                formState.errors.item__qtyTitle
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__qtyTitle && (
                              <div className="input_error">
                                {formState.errors.item__qtyTitle}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                if (!val && !formState.touched.item__discount) {
                                  return;
                                }
                                // if (isNaN(val)) {
                                //   return "Please Enter a cgst.";
                                // }
                                // if (Number(val) > 10000) {
                                //   return "Price cannot be more than 10000 and cannot have more than 2 decimal points.";
                                // }
                                // let notPositive = validateWholeNumber(
                                //   val,
                                //   "Item Price"
                                // );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__discount"
                              type="text"
                              placeholder="Enter discount %"
                              className={
                                formState.errors.item__discount
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__discount && (
                              <div className="input_error">
                                {formState.errors.item__discount}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Select
                              field="item__route"
                              validate={val => {
                                return this._validateSelectInput(
                                  val,
                                  "item route name"
                                );
                              }}

                              validateOnBlur
                              validateOnChange
                              className={
                                formState.errors.item__route
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            >
                              <option value="" disabled>
                                - Select ItemRouteName -
                              </option>
                              {this.props.ItemRoutes.length
                                ? this.props.ItemRoutes
                                  .map((c, idx) => {
                                    return (
                                      <option
                                        key={String(idx)}
                                        value={c.itemRouteId}
                                      >
                                        {c.itemRouteName}
                                      </option>
                                    );
                                  })
                                : null}
                            </Select>
                            {formState.errors.item__route && (
                              <div className="input_error">
                                {formState.errors.item__route}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Select
                              field="item__state"
                              validate={val =>
                                this._validateSelectItemState(val, "ItemState")
                              }
                              validateOnBlur
                              validateOnChange
                              className={
                                formState.errors.item__state
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            >
                              <option value="" disabled>
                                - Select Item Status -
                              </option>
                              <option >Available</option>
                              <option>Not Available</option>
                            </Select>
                            {formState.errors.item__state && (
                              <div className="input_error">
                                {formState.errors.item__state}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {

                                if (Number(val) > 10000) {
                                  return "Price cannot be more than 10000 and cannot have more than 2 decimal points.";
                                }
                                let notPositive = validateWholeNumber(
                                  val,
                                  "Item Markup Price"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__markup_price"
                              type="text"
                              placeholder="Enter item markup price"
                              className={
                                formState.errors.item__markup_price
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__markup_price && (
                              <div className="input_error">
                                {formState.errors.item__markup_price}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {

                                if (Number(val) > 5000) {
                                  return "item weight cannot be more than 5000 and cannot have more than 2 decimal points.";
                                }
                                let notPositive = validateWholeNumber(
                                  val,
                                  "Item Weight"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__weight"
                              type="text"
                              placeholder="Enter weight(in grams)"
                              className={
                                formState.errors.item__weight
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__weight && (
                              <div className="input_error">
                                {formState.errors.item__weight}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                let notPositive = validateWholeNumber(
                                  val,
                                  "Item Sort Order"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__sort_order"
                              type="text"
                              placeholder="Enter sort order"
                              className={
                                formState.errors.item__sort_order
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__sort_order && (
                              <div className="input_error">
                                {formState.errors.item__sort_order}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <Text
                              validate={val => {
                                let notPositive = validateWholeNumber(
                                  val,
                                  "Item Current Stock"
                                );
                              }}
                              validateOnBlur
                              validateOnChange
                              field="item__current_stock"
                              type="text"
                              placeholder="Enter curent stock"
                              className={
                                formState.errors.item__current_stock
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            />
                            {formState.errors.item__current_stock && (
                              <div className="input_error">
                                {formState.errors.item__current_stock}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group row mx-0">
                            {/* <RadioGroup
                              field="item__type"
                              validate={val => {
                                if (!val) return "Please choose a food type";
                              }}
                            >
                              {Object.keys(this.props.foodTypes).map(
                                foodTypeKey => {
                                  let t = this.props.foodTypes[foodTypeKey];
                                  return (
                                    <label
                                      key={t.typeId}
                                      className="label-name col-6 px-0"
                                    >
                                      {t.typeTitle}
                                      <Radio value={t.typeId} />
                                    </label>
                                  );
                                }
                              )}
                            </RadioGroup> */}
                            <Select
                              field="item__type"
                              validate={val =>
                                this._validateSelectInput(val, "Category")
                              }
                              // onValueChange={e => {
                              //   // console.log("what the hell?");
                              //   this.formApi.setValue(
                              //     "item__subCat",
                              //     undefined
                              //   );
                              // }}
                              validateOnBlur
                              validateOnChange
                              className={
                                formState.errors.item__type
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                            >
                              <option value="" disabled>
                                - Select Food Type -
                              </option>
                              {Object.keys(this.props.foodTypes).map((c, idx) => {
                                let t = this.props.foodTypes[c];
                                return (
                                  <option
                                    key={t.typeId}
                                    value={t.typeId}
                                  >
                                    {t.typeTitle}
                                  </option>
                                );
                              })
                              }
                            </Select>

                            {formState.errors.item__type && (
                              <div className="input_error">
                                {formState.errors.item__type}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group row mx-0">
                            <label className="label-name col-6 px-0">Pos-status:
                              <Checkbox
                                field="item__pos__status"

                              /></label>
                            <label className="label-name col-6 px-0">Online-status:
                              <Checkbox
                                field="item__online__status"

                              /></label>
                            <label className="label-name col-6 px-0"> Sold at store :
                              <Checkbox
                                field="item__sold_at_store"
                              />
                            </label >
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group row mx-0">
                            <label className="label-name col-6 px-0"> Sold at store :
                              <Checkbox
                                field="item__sold_at_store"
                              />
                            </label >
                            <label className="label-name col-6 px-0"> Recommended
                              <Checkbox
                                field="item__recommended"
                              />
                            </label >
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <TextArea
                              rows={5}
                              field="item__desc"
                              validate={this._validateItemDesc.bind(this)}
                              validateOnBlur
                              validateOnChange
                              className={
                                formState.errors.item__desc
                                  ? "form-control input_error"
                                  : "form-control"
                              }
                              placeholder="Enter item description"
                            />
                            {formState.errors.item__desc && (
                              <div className="input_error">
                                {formState.errors.item__desc}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer d-block clearfix">
                      <button
                        type="submit"
                        className="btnmodaladd text-uppercase float-right"
                      >
                        {this.context.selectedItemId
                          ? "Save Changes"
                          : "Add Item"}
                        {this.state.saveProgress && (
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height={20}
                            width={30}
                            style="loaderInsideBtn"
                          />
                        )}
                      </button>
                    </div>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    );
  }

  _openModal() {
    $(this.uiModal.current).modal("show");
    //load categories and sub-catgories here.
    if (this.context.selectedItemId) {
      let selectedItem = this.context.menuItems.find(
        item => item.itemId == this.context.selectedItemId
      );
      if (!selectedItem) {
        console.error("No Details of selected item? :(");
        return false;
      }
      let {
        itemId,
        categoryId,
        subCategoryId,
        itemDescription,
        itemPrice,
        itemTitle,
        itemShortTitle,
        itemQuantity,
        itemType,
        itemCgst,
        itemSgst,
        itemDiscount,
        itemMarkupPrice,
        itemWeight,
        itemSortOrder,
        itemCurrentStock,
        itemSoldAtStore,
        itemRecommended,
        itemRouteId,
        itemRouteName,
        itemState,
        posStatus,
        onlineStatus
      } = selectedItem;
      this.formApi.setValue("item__name", itemTitle);
      this.formApi.setValue("item__short__name", itemShortTitle);
      this.formApi.setValue("item__desc", itemDescription);
      this.formApi.setValue("item__cat", categoryId);
      this.formApi.setValue("item__qtyTitle", itemQuantity);
      this.formApi.setValue("item__subCat", subCategoryId);
      this.formApi.setValue("item__price", itemPrice);
      this.formApi.setValue("item__type", itemType);
      this.formApi.setValue("item__cgst", itemCgst);
      this.formApi.setValue("item__sgst", itemSgst);
      this.formApi.setValue("item__discount", itemDiscount);
      this.formApi.setValue("item__markup_price", itemMarkupPrice);
      this.formApi.setValue("item__weight", itemWeight);
      this.formApi.setValue("item__sort_order", itemSortOrder);
      this.formApi.setValue("item__current_stock", itemCurrentStock);
      this.formApi.setValue("item__sold_at_store", itemSoldAtStore);
      this.formApi.setValue("item__recommended", itemRecommended);
      this.formApi.setValue("item__route", itemRouteId);
      this.formApi.setValue("item__state", itemState);
      this.formApi.setValue("item__pos__status", posStatus == "28" ? true : false);
      this.formApi.setValue("item__online__status", onlineStatus == "30" ? true : false);
    }
  }

  async _submitMenuItemForm(formValues) {
    if (this.state.saveProgress) return false;
    this.setState({
      saveProgress: true
    });
    let savedResponse = await this.context
      .submitMenuItemForm(formValues)
      .catch(err => console.error(err));

    if (!savedResponse.success) {
      this.setState({
        saveProgress: false
      });
      return toast.error(savedResponse.msg);
    }

    this.setState({
      saveProgress: false
    });
    $(this.uiModal.current).modal("hide");
    toast.success(
      this.context.selectedItemId
        ? "Saved changes to " + formValues.item__name + "."
        : "Added Menu Item " + formValues.item__name,
      { autoClose: 3000 }
    );

    this.props.fetchAndloadItems();
  }

  _setFormApi(formApi) {
    this.formApi = formApi;
  }

  _validateItemTitle(title) {
    if (!this.formApi.getState().touched.item__name) {
      return false;
    }
    title = title && title.trim();
    if (!/[a-zA-Z]*/.test(title)) {
      return "Enter a valid Item Name.";
    }
    if (!title || title.length < 3) {
      return "Item name must have atleast 3 characters.";
    }

    if (!title || title.length > 20) {
      return "Item name cannot have more than 20 characters.";
    }
  }
  _validateItemShortTitle(title) {
    if (!this.formApi.getState().touched.item__short__name) {
      return false;
    }
    title = title && title.trim();
    if (!/[a-zA-Z]*/.test(title)) {
      return "Enter a valid Item Name.";
    }
    if (!title || title.length < 3) {
      return "Item  short name must have atleast 3 characters.";
    }

    if (!title || title.length > 15) {
      return "Item name cannot have more than 15 characters.";
    }
  }

  _validateSelectInput(val, name) {
    if (!val) {
      return `Select ${name}.`;
    }
  }

  _validateItemDesc(desc) {
    if (!this.formApi.getState().touched.item__desc) {
      //this is the first focus, do not validate.
      return false;
    }
    desc = desc && desc.trim();
    if (!desc || desc.length < 10) {
      return "Description must be atleast 10 Characters long";
    }
    if (desc.length > 400) {
      return "Description must not be larger than 400 characters";
    }
  }
  _validateSelectItemState(val, name) {
    if (!val) {
      return `Select ${name}.`;
    }
  }
}
