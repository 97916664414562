import React, { Component } from "react";
import moment from "moment";
import { BUVVAS_LOGO } from "../assets/images";
import { AuthContext } from "../contextProvider";
import html2Pdf from "html2pdf.js";
import { dataURItoBlob } from "../utilities";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
export default class OrderDetails extends Component {
  static contextType = AuthContext;
  state = {
    order: this.props.location.state ? this.props.location.state.order : null,
    orderSummary:
      this.props.location.state &&
      this.props.location.state.order &&
      JSON.parse(this.props.location.state.order.orderSummary),
    paymentMode: "cash"
  };

  componentWillMount() {
    if (!this.props.location.state) {
      return this.props.history.push("/Dashboard");
    }
    if (!this.props.location.state.order) {
      return this.props.history.push("/orders");
    }
    this.setState({
      paymentMode: this.props.location.state.order.paymentMode || "cash"
    });
    this.content = React.createRef();
    this.pdfLink = React.createRef();
  }

  render() {
    if (!this.state.order) return null;
    return (
      <div className="content of-auto" ref={this.content}>
        <div className="mx-0">
          <div className="justify-content-center d-none pdf-logo">
            <a href="/" style={{ display: "block" }}>
              <img alt="Buvvas" src={BUVVAS_LOGO} className="img-fluid" />
            </a>
          </div>
          <div
            className="row mx-0 justify-content-between"
            data-html2canvas-ignore
          >
            <div className="">
              <div className="orderTitle">
                <i
                  className="fa fa-long-arrow-left"
                  aria-hidden="true"
                  onClick={this.props.history.goBack}
                />
                Orders
              </div>
            </div>
            <div className="payment-mode-select-wrapper">
              <select
                name="paymentMode"
                id="paymentMode"
                className="form-control payment-mode-select"
                defaultValue={this.state.paymentMode}
                onChange={e => this.setState({ paymentMode: e.target.value })}
                disabled={
                  !!this.state.order && !!this.state.order.invoiceGeneratedAt
                }
              >
                {/* <option value="choose" disabled>-Select payment mode-</option> */}
                <option value="cash">Cash</option>
                <option value="card">Card</option>
              </select>
            </div>
            <div className="" data-html2canvas-ignore>
              {!!this.state.order && !!this.state.order.invoiceLink ? ( //!!this.state.order.invoiceLink
                <a
                  ref={this.pdfLink}
                  className="btn pull-right printInvoiceButton"
                  href={
                    this.context.CONSTANTS.apiBasePath.split("/api")[0] +
                    this.state.order.invoiceLink
                  }
                  // download="true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-print" /> Print Pdf
                </a>
              ) : (
                <button
                  className="btn pull-right printInvoiceButton"
                  onClick={() =>
                    this._generateInvoice(this.state.order.invoiceId)
                  }
                >
                  Generate Invoice
                  {this.state.generateProgress && (
                    <Loader
                      type="ThreeDots"
                      color="#888"
                      height={20}
                      width={30}
                      style="loaderInsideBtn"
                    />
                  )}
                </button>
              )}
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
            <h3 className="orderhding" style={{ fontWeight: "bold" }}>
              Guest details
            </h3>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="guestdetails_key row mx-0 justify-content-between">
                      Guest name <span>:</span>
                    </div>{" "}
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {this.state.order.guestName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="guestdetails_key row mx-0 justify-content-between">
                      Phone Number <span>:</span>
                    </div>{" "}
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {this.state.order.guestPhone}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="row mx-0 justify-content-between guestdetails_key">
                      Date <span>:</span>
                    </div>{" "}
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {moment(this.state.order.orderDate).format("L")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                {" "}
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="guestdetails_key row mx-0 justify-content-between">
                      Invoice Id <span>:</span>
                    </div>{" "}
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {this.state.order.invoiceId || ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="orderhding pt-4" style={{ fontWeight: "bold" }}>
              Order details
            </h3>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="guestdetails_key row mx-0 justify-content-between">
                      {" "}
                      Order Id <span>:</span>
                    </div>{" "}
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {this.state.order.orderId}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="guestdetails_key row mx-0 justify-content-between">
                      {" "}
                      Bearer <span>:</span>
                    </div>{" "}
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {this.state.order.bearerName || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="guestdetails_key row mx-0 justify-content-between">
                      Table No. <span>:</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {this.state.orderSummary.tableNumDisplay ||
                        this.state.order.tableNum ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                <div className="row mx-0">
                  <div className="col-6 px-0">
                    <div className="guestdetails_key row mx-0 justify-content-between">
                      Time <span>:</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="guestdetails_value">
                      {moment(
                        this.state.order.invoiceConfirmedAt || undefined
                      ).format("LT")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive my-3 text-capitalize tableofauto">
            <table className="table my-3">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>item name</th>
                  <th className="text-centerimp">item price</th>
                  <th className="text-centerimp">quantity</th>
                  <th className="text-centerimp">total price</th>
                </tr>
              </thead>
              <tbody>
                {this.state.orderSummary.cart.map((item, idx) => {
                  return (
                    <tr key={String(idx)}>
                      <td>{idx + 1}</td>
                      <td>{item.itemTitle}</td>
                      <td className="text-center">
                        {" "}
                        {item.itemPrice.toFixed(2)}
                      </td>
                      <td className="text-center">{item.qty}</td>
                      <td className="text-center">
                        {(
                          (Number(item.itemPrice) || 0) *
                          (Number(item.qty) || 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mx-0 justify-content-end text-capitalize">
          <div className="mr-5">
            <div className="ordervalue">total amount</div>
            <div className="ordervalue">
              total taxes{" "}
              {this.state.orderSummary.taxRate
                ? `(@
              ${this.state.orderSummary.taxRate}%)`
                : ""}
            </div>
            <div className="ordervalue">
              <strong>Grand total</strong>
            </div>
          </div>
          <div>
            <div className="ordervalue">
              {Number(this.state.orderSummary.orderTotal).toFixed(2)}
            </div>
            <div className="ordervalue">
              {Number(this.state.orderSummary.orderTax).toFixed(2)}
            </div>
            <div className="ordervalue">
              <strong>
                {Number(
                  this.state.orderSummary.orderTotal +
                    this.state.orderSummary.orderTax
                ).toFixed(2)}
              </strong>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async _generateInvoice(invoice_id) {
    if (this.state.generateProgress) return false;
    if (!!this.state.order.invoiceGeneratedAt) {
      return this.print(invoice_id + ".pdf");
    }

    this.setState({
      generateProgress: true
    });

    const { apiBasePath, apiRoutes } = this.context.CONSTANTS;
    let dataUri = await this.print(invoice_id + ".pdf").catch(err =>
      console.error(err)
    );
    //upload generated file to server
    // let data = new FormData();
    // data.append("file", dataURItoBlob(dataUri), invoice_id + ".pdf");
    // data.append("filename", "myfile");
    // //post it to server
    // let saveResponse = await fetch(apiBasePath + apiRoutes.uploadFile, {
    //   method: "POST",
    //   headers: {
    //     Authorization: this.context.authToken
    //   },
    //   body: data
    // }).catch(err => console.error(err));

    // let invoice_link = "";
    // saveResponse = await saveResponse.json().catch(err => console.error(err));

    // if (!saveResponse) {
    //   console.error("Could not save invoice to server");
    // }
    // if (!saveResponse.success) {
    //   console.error(saveResponse.msg);
    // }
    // invoice_link = saveResponse.data;
    let generated = await fetch(apiBasePath + apiRoutes.generateInvoice, {
      headers: {
        Authorization: this.context.authToken,
        "Content-type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        invoice_id,
        // invoice_link,
        user_id: this.context.user.userId,
        payment_mode: this.state.paymentMode
      })
    }).catch(err => console.error(err));
    if (!generated) {
      let msg = "An Error Occured while saving the changes.";
      this.setState({
        saveFailMsg: msg,
        generateProgress: false
      });
      return toast.error(msg);
    }

    const generatedResult = await generated
      .json()
      .catch(err => console.error(err));
    if (!generatedResult) {
      let msg = "An Error Occured while saving the changes.";
      this.setState({
        saveFailMsg: msg,
        generateProgress: false
      });
      return toast.error(msg);
    }

    if (!generatedResult.success) {
      toast.error(generatedResult.msg);
      return this.setState({
        saveProgress: false,
        saveFailMsg: generatedResult.msg,
        generateProgress: false
      });
    }

    this.setState(
      {
        saveFailMsg: "",
        saveProgress: false,
        generateProgress: false,
        order: {
          ...this.state.order,
          // invoiceLink: invoice_link,
          invoiceGeneratedAt: moment()
        }
      },
      err => {
        if (err) return console.error(err);
        // this.pdfLink.current.click();
      }
    );
    toast.success("success", { autoClose: 4000 });
  }

  print(filename) {
    const _arguments = arguments;
    return new Promise((resolve, reject) => {
      var printWindow = window.open("", "", "height=600,width=800");
      printWindow.document.write(
        `<html><head><title>Buvvas</title><link href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous"><style>.printInvoiceButton,.orderTitle,.payment-mode-select-wrapper{display:none}.pdf-logo{display:flex!important}.content{width:300px!important}.table > thead > tr > th.text-centerimp {
          text-align: center !important;
        }.table thead {
          background-color: #98a4af;
      }.table {
        overflow: hidden;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
    }</style>`
      ); //table{max-width:300px}
      printWindow.document.write('</head><body style="padding:10px">');
      printWindow.document.write(
        this.content.current.innerHTML.replace(
          /(col-sm-6 col-md-4 col-lg-4 col-xl-3)/g,
          "col-12"
        )
      );
      printWindow.document.write("</body></html>");
      // printWindow.onload = printWindow.print;
      // printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
        printWindow.document.close();
      }, 500);
      resolve(true);
      //   if (!filename) {
      //     return reject(new Error("Missing data :" + String(_arguments)));
      //   }
      //   let mm = 3.7795275591//mm<->pixel ratio
      //   html2Pdf()
      //     .from(
      //       this.content.current.innerHTML
      //         .replace("content of-auto", "content-print")
      //         // .replace("printInvoiceButton", "printInvoiceButton d-none")
      //         // .replace("orderTitle", "orderTitle d-none")
      //         // .replace("payment-mode-select-wrapper", "d-none")
      //         // .replace("d-none pdf-logo", "d-flex pdf-logo")
      //         .replace(/(col-sm-6 col-md-4 col-lg-4 col-xl-3)/g, "col-sm-6")
      //         // .replace(/(col-sm-6 col-md-4 col-lg-4 col-xl-3)/g, "col")
      //     )
      //     .set({
      //       margin: [1, 1, 1, 1],
      //       filename,
      //       html2canvas:  { windowWidth:80*mm,windowHeight:300*mm,scale:2 },//width of html content
      //       // jsPDF:{ unit:"mm", format:[80*mm,300*mm], orientation: 'portrait', }
      //       // html2canvas:  { windowWidth:300,windowHeight:300*mm },
      //       // jsPDF:{ unit:"mm", format:[110,300*mm], orientation: 'portrait', }//width of pdf page
      //       jsPDF:{  format:"dl", orientation: 'portrait', }//width of pdf page

      //       //mm - smaller font size
      //       //px - shows full content(partially)
      //     })
      //     .output("datauristring")
      //     .then(function(val) {
      //       resolve(val);
      //     });
    });
  }
}
